<template>
	<div class="updatePassword">
		<p class="bread"><span class="buleColor">/</span>修改密码</p>
		<a-form-model :model="ruleForm" :rules="rules" ref="ruleForm" class="VForm" v-bind="formItemLayout">
			<a-form-model-item label="原密码" prop="old_password">
				<a-input v-model="ruleForm.old_password" type="password" />
			</a-form-model-item>
			<a-form-model-item label="设置新密码" prop="password">
				<a-input v-model="ruleForm.password" type="password" />
			</a-form-model-item>
			<a-form-model-item   prop="password_confirmation" label="确认密码" >
				<a-input  v-model="ruleForm.password_confirmation"  type="password" />
			</a-form-model-item>
			<div class="form-btn">
				<a-button type="primary" html-type="submit" @click="sureUpdate">确认</a-button>
			</div>
		</a-form-model>
	</div>
</template>

<script>
import { FormModel, Input, Button } from "ant-design-vue";
import services from "../../../config/services";
import utils from "../../../config/utils";
import { mapActions } from "vuex";
const { Item } = FormModel;
export default {
	name: "UpdatePassword",
	components: {
		AFormModel: FormModel,
		AFormModelItem: Item,
		AInput: Input,
		AButton: Button,
	},
	data() {
		let validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入新密码'));
			} else {
				const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
				if(!reg.test(value)){
					callback(new Error('密码为[8-16]位字母大小写及数字组合'));
				} else {
					callback();
				}
			}
		};
		let validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入新密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error("两次输入的密码不相同"));
			} else {
				callback();
			}
		};
		return {
			ruleForm: {
				old_password: '',
				password: '',
				password_confirmation: '',
			},
			rules: {
				old_password: [
					{ required: true, message: '请输入原密码', trigger: 'change' }
				],
				password: [
					{ validator: validatePass,  trigger: 'change' }
				],
				password_confirmation: [
					{ validator: validatePass2, trigger: 'change' }
				],
			},
			formItemLayout: {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 8 },
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 16 },
				},
			},
		};
	},
	mounted() {
		this.$store.commit("setNavTitle", {
			navTitle: "学习中心",
		});
	},
	methods: {
		...mapActions(['requestAppsEditPassword']),
		sureUpdate(){
			let that = this;
			this.$refs['ruleForm'].validate((err) => {
        if (err) {
          that.methodReset();
        }
      })
		},
		// 请求修改密码方法
    async methodReset() {
			let param = {
				...this.ruleForm,
				_method: 'put'
			}
      let that = this;
      let { setFields } =  this.$refs['ruleForm']
      that.requestAppsEditPassword(param).then((res) => {
        if(res.code == '0000') {
					that.$message.success(res.message ? res.message : "操作成功");
					utils.redirect(this, "/user/myClass")
        } else {
          if (res.errors) {
            let errors = res.errors
            for(let k in errors){
              setFields({
                [k]: {errors: [new Error(errors[k])]}
              })
            }
          } else {
            that.$message.error(res.message ? res.message : "操作失败");
          }
        }
      });
    },
	}
};
</script>

<style scoped>
</style>