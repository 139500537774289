<template>
  <!-- 导航栏 -->
  <div class="header-main-box">
    <div class="c flex-menu">
      <a href="javascript:void(0)" class="fl school-logo-box">
        <img
          src="../assets/site/logo-eol.png"
          @click="linkRouteEol"
          class="bar-logo"
        />
        <img
          src="../assets/site/logo-zjyk.png"
          @click="linkRouteHome"
          class="low-logo"
        />
      </a>
      <div class="new-navList">
        <router-link
          :class="
            $route.path == `/` || $route.path == `/home`
              ? 'nav-options active'
              : 'nav-options'
          "
          to="/"
          exact
          >首页</router-link
        >

        <router-link
          :class="
            $route.path.indexOf('/media') > -1
              ? 'nav-options active'
              : 'nav-options'
          "
          to="/media"
          exact
          >融媒智库</router-link
        >

        <router-link
          :class="
            $route.path.indexOf('/school/class') > -1
              ? 'nav-options active'
              : 'nav-options'
          "
          :to="'/school/class'"
          >课程资源</router-link
        >
        <!-- <router-link
          :class="
            $route.path.indexOf('/service') > -1
              ? 'nav-options active'
              : 'nav-options'
          "
          :to="'/service'"
          >院校服务</router-link
        > -->
        <a
          class="nav-options"
          href="https://zhijiao.eol.cn/page/2023/07/jxfw.html"
          target="_blank"
          >专业建设</a
        >
        <a class="nav-options" :href="services.SZ_SERVIER" target="_blank"
          >课程思政</a
        >
        <router-link
          :class="
            $route.path == '/thought' ? 'nav-options active' : 'nav-options'
          "
          :to="'/thought'"
          >党建思政</router-link
        >
        <a
          class="nav-options"
          :href="`${services.XUEXI_SITE}/center/100004/custom/party`"
          target="_blank"
          >大学生党校</a
        >
        <a
          class="nav-options"
          href="https://zhijiao.eol.cn/fw/xcbfw/index.html"
          target="_blank"
          >媒体宣传</a
        >
      </div>
      <div class="right-login-box fr h" v-if="!userAllInfo">
        <div class="user-text fl" @click="goToRegister">免费注册</div>
        <span class="user-lines fl"></span>
        <div class="user-text fl" @click="showLogin">登录</div>
      </div>
      <div class="right-login-box login fr" v-if="userAllInfo">
        <div class="user-icons fl">
          <img src="../assets/site/icons-user.png" alt="" />
        </div>
        <div class="user-text fl" @click="redirect('/user/myClass')">
          学习中心
        </div>
        <!-- <div v-if="userAllInfo && userAllInfo.type == 2" class="user-text acitveC fl">{{userAllInfo.username}}</div> -->
        <span class="right-login-line fl"></span>
        <div class="mine-submenu-box">
          <template v-if="userAllInfo">
            <div
              class="submenu-items classic"
              @click="redirect('/user/myClass')"
            >
              <span>我的课程</span>
            </div>
            <div
              class="submenu-items study"
              @click="redirect('/user/studyRecord')"
            >
              <span>学习记录</span>
            </div>
            <div class="submenu-items note" @click="redirect('/user/myNote')">
              <span>我的笔记</span>
            </div>
            <div
              class="submenu-items user"
              @click="redirect('/user/userMessage')"
            >
              <span>个人信息</span>
            </div>
            <div
              class="submenu-items edit"
              @click="redirect('/user/updatePassword')"
            >
              <span>修改密码</span>
            </div>
          </template>
          <div class="submenu-items logout" @click="logout">
            <span>退出登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../config/utils";
import services from "../config/services";

export default {
  name: "Nav",
  components: {},
  data() {
    return {
      services,
    };
  },
  computed: {
    userAllInfo() {
      return this.$store.state.appsLogin.appsUserInfo;
    },
  },
  async mounted() {
    await this.getAllAccount();
  },
  methods: {
    // 获取账号资料
    async getAllAccount() {
      await this.$store.dispatch("getAppsLoginUserInfo", {});
    },
    linkRouteEol() {
      window.open("https://www.eol.cn/", "_blank");
    },
    linkRouteHome() {
      utils.redirect(this, "/");
    },
    showLogin() {
      if (window.location.href.indexOf("createFarm") > -1) {
        if (
          this.$route.query.from &&
          this.$route.query.from.indexOf("createFarm") > -1
        ) {
          this.$router.push(
            `/login?${
              this.$route.query.from &&
              this.$route.query.from.indexOf("createFarm") > -1
                ? "from=" +
                  window.location.pathname +
                  window.location.search +
                  "&createFarm=1"
                : ""
            }`
          );
        } else {
          this.$router.push(`/login?from=createFarm`);
        }
      } else {
        this.$router.push("/login");
      }
    },
    goToRegister() {
      if (window.location.href.indexOf("createFarm") > -1) {
        if (
          this.$route.query.from &&
          this.$route.query.from.indexOf("createFarm") > -1
        ) {
          this.$router.push(
            `/register?${
              this.$route.query.from &&
              this.$route.query.from.indexOf("createFarm") > -1
                ? "from=" +
                  window.location.pathname +
                  window.location.search +
                  "&createFarm=1"
                : ""
            }`
          );
        } else {
          this.$router.push(`/register?from=createFarm`);
        }
      } else {
        this.$router.push("/register");
      }
    },

    redirect(path) {
      utils.redirect(this, path);
    },
    async logout() {
      let that = this;
      this.$store.dispatch("requestAppsLogout", {}).then((res) => {
        if (
          utils.blackList.includes(this.$route.path) ||
          this.$route.path.indexOf("/video/") > -1
        ) {
          this.showLogin();
        }
      });
    },
  },
};
</script>

<style scoped>
.header-main-box {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ebebeb;
  background-color: #ffffff;
}
.header-main-box .flex-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-main-box .school-logo-box {
  display: inline-block;
}
.header-main-box .school-logo-box .bar-logo {
  margin-right: 33px;
  height: auto;
  width: 158px;
}
.header-main-box .school-logo-box .low-logo {
  height: 31px;
  width: auto;
}
.header-main-box .new-navList {
  line-height: 70px;
  height: 70px;
  display: inline-block;
  clear: both;
}
.header-main-box .new-navList .nav-options {
  font-size: 16px;
  font-weight: 400;
  color: #2b2b2b;
  padding: 0 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.header-main-box .new-navList .nav-options:hover {
  color: #3d77ff;
}
.header-main-box .new-navList .nav-options.shixun-box::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../assets/new-down-icon.png");
  background-size: 100% auto;
  display: inline-block;
  vertical-align: middle;
  margin-left: 13px;
}
.header-main-box .new-navList .nav-options.shixun-box:hover::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../assets/new-down-icon-hover.png");
  background-size: 100% auto;
}
.header-main-box .new-navList .nav-options.shixun-box {
  position: relative;
  overflow: visible;
}
.header-main-box .new-navList .nav-options.shixun-box .shixun-sub-menu {
  position: absolute;
  padding: 20px;
  background-color: #fff;
  z-index: 1000;
  width: 160px;
  top: 98px;
  left: 0;
  border: 1px solid #dcdcdc;
  display: none;
  text-align: left;
}
.header-main-box .new-navList .shixun-sub-menu:before {
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  position: absolute;
  top: -16px;
  right: 77px;
  padding: 0;
  border-bottom: 8px solid #ffffff;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  display: block;
  content: "";
  z-index: 12;
}
.header-main-box .new-navList .shixun-sub-menu::after {
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  position: absolute;
  top: -18px;
  right: 76px;
  padding: 0;
  border-bottom: 9px solid #dcdcdc;
  border-top: 9px solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  display: block;
  content: "";
  z-index: 10;
}
.header-main-box .new-navList .nav-options.shixun-box:hover .shixun-sub-menu {
  display: block;
}
.header-main-box .new-navList .nav-options.shixun-box .shixun-sub-menu a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  line-height: 32px;
}
.header-main-box .new-navList .nav-options.shixun-box .shixun-sub-menu a:hover {
  color: #2462f5;
}
.header-main-box .new-navList .nav-options.active {
  color: #3d77ff;
  font-weight: bold;
}
.header-main-box .new-navList .navList-line {
  width: 1px;
  height: 18px;
  background-color: #d2d9ee;
  display: inline-block;
  vertical-align: middle;
}
.header-main-box .right-login-box.login {
  position: relative;
}
.header-main-box .right-login-box .user-lines {
  width: 1px;
  height: 12px;
  background: #2b2b2b;
  margin: 29px 10px 0 10px;
}
.header-main-box .right-login-box .user-text {
  color: #2b2b2b;
  font-size: 14px;
  line-height: 70px;
  height: 70px;
  cursor: pointer;
  vertical-align: middle;
}
.header-main-box .right-login-box .user-text:hover {
  color: #3d77ff;
}
.header-main-box .right-login-box .user-text.acitveC:hover {
  color: #2b2b2b;
}
.header-main-box .right-login-box .user-icons {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 67px;
  height: 70px;
  margin-right: 10px;
}
.header-main-box .user-icons img {
  width: 26px;
  height: auto;
  vertical-align: middle;
}
.header-main-box .user-icons p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: inline-block;
  vertical-align: middle;
}
.header-main-box .right-login-box .right-login-line {
  /* width: 1px;
  height: 18px;
  background-color: #999999;
  display: inline-block;
  vertical-align: middle; 
  margin: 26px 10px; */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #2462f5;
  margin: 31px 0 0 10px;
}
.header-main-box .right-login-box .right-login-line {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.header-main-box .right-login-box:hover .right-login-line {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.header-main-box .right-login-box.login .mine-submenu-box {
  display: none;
}
.header-main-box .right-login-box.login:hover .mine-submenu-box {
  display: block;
  /* min-width: 150px; */
  width: max-content;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 1000;
  text-align: left;
  padding: 16px 0 10px;
  background-color: #ffffff;
  border-top: 1px solid #2462f5;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 2px;
}
.header-main-box .right-login-box.login:hover .mine-submenu-box::after {
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  background-color: #2462f5;
  top: 0;
  left: -1px;
  right: -1px;
  border-radius: 2px 2px 0 0;
}

.mine-submenu-box::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #2462f5;
}
.mine-submenu-box .submenu-items {
  font-size: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
  cursor: pointer;
  padding: 0 20px;
}
.mine-submenu-box .submenu-items:hover {
  color: #125cfb;
}
.mine-submenu-box .submenu-items::before {
  display: inline-block;
  content: "";
  width: 14px;
  height: 14px;
  margin-right: 8px;
  vertical-align: -2px;
  background-repeat: no-repeat;
  background-position: center;
}
.mine-submenu-box .submenu-items.classic::before {
  background-image: url("../assets/site/mineMenu/classic.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.classic:hover::before {
  background-image: url("../assets/site/mineMenu/classic-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.study::before {
  background-image: url("../assets/site/mineMenu/study.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.study:hover::before {
  background-image: url("../assets/site/mineMenu/study-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.logout::before {
  background-image: url("../assets/site/mineMenu/logout.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.logout:hover::before {
  background-image: url("../assets/site/mineMenu/logout-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.note::before {
  background-image: url("../assets/site/mineMenu/note.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.note:hover::before {
  background-image: url("../assets/site/mineMenu/note-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.user::before {
  background-image: url("../assets/site/mineMenu/user.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.user:hover::before {
  background-image: url("../assets/site/mineMenu/user-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.edit::before {
  background-image: url("../assets/site/mineMenu/edit.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.edit:hover::before {
  background-image: url("../assets/site/mineMenu/edit-hover.png");
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items span {
  vertical-align: top;
}
</style>
