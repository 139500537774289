<template>
  <div :class="isClientHeight ? 'mainbody static-page-box' : 'mainbody'">
    <LoginHeader />
    <div class="main-container">
      <div class="container-box">
        <div class="middle-outer-box">
          <div class="login-inner-box show">
            <div class="top-label-box">
              <div class="reset-left-box register">免费注册</div>
              <div class="login-right-box">
                <router-link :to="`/login?${window.location.search}`"
                  >返回登录</router-link
                >
              </div>
            </div>

            <div class="right-form-box">
              <div class="form-content">
                <a-form
                  :form="mobileForm"
                  :destroyOnClose="true"
                  class="login-form"
                  @submit="handleSubmit"
                >
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">姓名：</div>
                      <a-input
                        class="input-txt"
                        v-decorator="[
                          'name',
                          {
                            rules: [{ required: true, message: '请输入姓名' }],
                          },
                        ]"
                        placeholder="请输入姓名"
                      ></a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">手机号：</div>
                      <a-input
                        class="input-txt"
                        placeholder="请输入手机号"
                        v-decorator="[
                          'mobile',
                          { rules: [{ validator: validateCheckPhone }] },
                        ]"
                      ></a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">密码：</div>
                      <a-input
                        class="input-txt"
                        type="password"
                        v-decorator="[
                          'password',
                          { rules: [{ validator: validateCheckPassword }] },
                        ]"
                        placeholder="请输入密码"
                      ></a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item flex-codeImg-box">
                      <div class="left-input-box">
                        <div class="label-box">验证码：</div>
                        <a-input
                          class="input-txt"
                          v-decorator="[
                            'captcha',
                            {
                              rules: [
                                { required: true, message: '请输入验证码' },
                              ],
                            },
                          ]"
                          placeholder="请输入验证码"
                        ></a-input>
                      </div>
                      <div class="code-img" @click="methodImageCaptcha()">
                        <img :src="imageCode.img" />
                      </div>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item flex-codeImg-box">
                      <div class="getCode-box">
                        <div class="label-box">短信验证码：</div>
                        <a-input
                          class="input-txt"
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                { required: true, message: '请输入短信验证码' },
                              ],
                            },
                          ]"
                          placeholder="请输入验证码"
                        ></a-input>
                      </div>
                      <div :class="loginSmsClass" @click="clickloginSms()">
                        {{ loginSmsText }}
                      </div>
                    </div>
                  </a-form-item>
                  <div class="agreement-box" @click.stop="isAgree = !isAgree">
                    <div
                      :class="
                        isAgree ? 'checkbox-icon active' : 'checkbox-icon'
                      "
                    ></div>
                    <div class="agreement-text">
                      我已认真阅读并同意<router-link
                        to="/policy"
                        target="_blank"
                        >服务协议和隐私政策</router-link
                      >
                    </div>
                  </div>
                  <a-button
                    type="primary"
                    html-type="submit"
                    class="jeol-login-btn"
                    >注册</a-button
                  >
                </a-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import LoginFooter from "../../components/loginFooter";
import LoginHeader from "../../components/loginHeader";
import { Form, Input, Button } from "ant-design-vue";
import { mapActions } from "vuex";

export default {
  name: "Register",
  components: {
    LoginFooter,
    LoginHeader,
    AForm: Form,
    AInput: Input,
    AFormItem: Form.Item,
    AButton: Button,
  },
  data() {
    return {
      isClientHeight: false,
      isAgree: false,
      imageCode: {
        key: "",
        img: "",
      },
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "jeol-getCode",
      window,
    };
  },
  beforeCreate() {
    this.mobileForm = this.$form.createForm(this);
  },
  mounted() {
    let that = this;
    that.getClientHeight();
    window.onresize = () => {
      that.getClientHeight();
    };
    that.methodImageCaptcha();
  },
  methods: {
    ...mapActions([
      "getAppsImageCaptcha",
      "getAppsSmsCaptcha",
      "requestAppsRegister",
      "getAppsLoginUserInfo",
    ]),
    // 用户名验证
    validateCheckUsername(rule, value, callback) {
      if (value) {
        if (/^[A-Za-z0-9]{6,16}$/.test(value)) {
          callback();
        } else {
          callback("请输入[6-16]位字母或数字组成的账号");
        }
      } else {
        callback("请输入账号");
      }
    },
    // 密码验证
    validateCheckPassword(rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          callback();
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback("请输入密码");
      }
    },
    // 手机号验证
    validateCheckPhone(rule, value, callback) {
      if (value) {
        if (/^1[3-9][0-9]{9}$/.test(value)) {
          callback();
        } else {
          callback("请输入正确的手机号");
        }
      } else {
        callback("请输入手机号");
      }
    },
    // 获取页面高度判断
    getClientHeight() {
      if (document.body.clientHeight < 760) {
        this.isClientHeight = true;
      } else {
        this.isClientHeight = false;
      }
    },
    // 获取图片验证码
    methodImageCaptcha() {
      let that = this;
      that.getAppsImageCaptcha().then((res) => {
        if (res.code == "0000") {
          that.imageCode = res.data;
        }
      });
    },
    // 提交注册用户表单
    handleSubmit(e) {
      let that = this;
      e.preventDefault();
      if (!that.isAgree) {
        that.$message.error("请查看并勾选同意服务协议和隐私政策");
        return false;
      }
      this.mobileForm.validateFields(
        ["name", "mobile", "password", "captcha", "code"],
        (err, values) => {
          if (!err) {
            that.methodlogin(values);
          }
        }
      );
    },
    // 请求登录方法
    async methodlogin(param) {
      let that = this;
      let { setFields } = this.mobileForm;
      that.requestAppsRegister(param).then((res) => {
        if (res.code == "0000") {
          // that.$message.success(res.message ? res.message : "登录成功");
          // 获取用户信息
          that.getAppsLoginUserInfo().then((res) => {
            if (res.code == "0000") {
              let from = that.$route.query.from;
              if (from) {
                that.$router.push(from);
              } else {
                this.$router.push("/");
              }
            }
          });
        } else {
          that.methodImageCaptcha();
          if (res.errors) {
            let errors = res.errors;
            for (let k in errors) {
              setFields({
                [k]: { errors: [new Error(errors[k])] },
              });
            }
          } else {
            that.$message.error(res.message ? res.message : "注册失败");
          }
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms() {
      let that = this;
      if (that.loginSmsClass === "jeol-getCode") {
        that.mobileForm.validateFields(["mobile", "captcha"], (err, values) => {
          if (!err) {
            values.key = that.imageCode.key;
            that.getAppsSmsCaptcha(values).then((res) => {
              if (res.code === "0000") {
                that.smsCountDown();
                that.$message.success(
                  res.message ? res.message : "短信验证码发送成功"
                );
              } else {
                that.methodImageCaptcha();
                if (res.errors) {
                  let errors = res.errors;
                  for (let k in errors) {
                    that.mobileForm.setFields({
                      [k]: { errors: [new Error(errors[k])] },
                    });
                  }
                } else {
                  that.$message.error(
                    res.message ? res.message : "短信验证码发送失败"
                  );
                }
              }
            });
          }
        });
      }
    },
    // 验证码倒计时
    smsCountDown() {
      let that = this;
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "jeol-getCode disabled";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "jeol-getCode";
          clearInterval(that.interval1);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped src="../../assets/css/register.css"></style>
<style lang="less" scoped>
/deep/ .form-content .has-error .ant-form-explain,
/deep/ .form-content .has-error .ant-form-split,
/deep/ .form-content .has-success .ant-form-explain,
/deep/ .form-content .has-success .ant-form-split {
  position: absolute !important;
}
.has-success .ant-form-item {
  margin-bottom: 0px;
}
.ant-form-item-with-help {
  margin-bottom: 0px;
}
</style>
