<template>
  <div :class="isClientHeight ? 'mainbody static-page-box' : 'mainbody'">
    <LoginHeader />
    <div class="main-container">
      <div class="container-box">
        <div class="middle-outer-box">
          <div class="login-inner-box show">
            <div class="left-imgs-box">
              <div id="jeol-carousel-login" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../assets/site/newLogin/login-bg.png" class="d-block" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="right-form-box">
              <div class="jeol-auth show">
                <div class="login-ways-box">
                  <div :class="
                      formType == 1 ? 'ways-option active' : 'ways-option'
                    " @click="changeFormWays(1)">
                    账号登录
                  </div>
                  <div class="split-line"></div>
                  <div :class="
                      formType == 2 ? 'ways-option active' : 'ways-option'
                    " @click="changeFormWays(2)">
                    验证码登录
                  </div>
                </div>
                <!-- 账号登录 -->
                <div class="form-content">
                  <div v-show="formType == 1">
                    <a-form :form="accountForm" :destroyOnClose="true" class="login-form" @submit="handleAccountSubmit">
                      <a-form-item class="form-item">
                        <a-input class="input-txt" placeholder="请输入账号" v-decorator="[
                          'username',
                          { rules: [{ validator: validateCheckUsername}]},
                        ]"></a-input>
                      </a-form-item>
                      <a-form-item class="form-item">
                        <a-input class="input-txt" type="password" placeholder="请输入密码" v-decorator="[
                          'password',
                          { rules: [{ validator: validateCheckPassword}] },
                        ]"></a-input>
                      </a-form-item>
                      <a-form-item v-if="loginBefore && loginBefore.is_captcha_check" class="form-item">
                        <div class="flex-codeImg-box">
                          <div class="left-input-box">
                            <a-input class="input-txt" placeholder="请输入验证码" v-decorator="[
                              'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"></a-input>
                          </div>
                          <div class="code-img" @click="methodImageCaptcha()">
                            <img :src="imageCode.img" />
                          </div>
                        </div>
                      </a-form-item>
                      <div class="forget-password">
                        <router-link class="left" to="/resetPassword">忘记密码？</router-link>
                        <router-link class="right" :to="`/register?${$route.query.from&&$route.query.from.indexOf('createFarm')?'from=/createFarm':''}`">免费注册</router-link>
                      </div>
                      <a-button type="primary" html-type="submit" class="jeol-login-btn">登录</a-button>
                    </a-form>
                  </div>
                  <!-- 验证码登录 -->
                  <div v-show="formType == 2">
                    <a-form :form="mobileForm" :destroyOnClose="true" class="login-form" @submit="handleMobileSubmit">
                      <a-form-item class="form-item">
                        <a-input class="input-txt" placeholder="请输入手机号" v-decorator="[
                          'mobile',
                          { rules: [{ validator: validateCheckPhone}] },
                        ]"></a-input>
                      </a-form-item>
                      <a-form-item class="form-item">
                        <div class="flex-codeImg-box">
                          <div class="left-input-box">
                            <a-input class="input-txt" placeholder="请输入验证码" v-decorator="[
                              'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"></a-input>
                          </div>
                          <div class="code-img" @click="methodImageCaptcha()">
                            <img :src="imageCode.img" />
                          </div>
                        </div>
                      </a-form-item>
                      <a-form-item class="form-item">
                        <div class="flex-codeImg-box">
                          <div class="getCode-box">
                            <a-input class="input-txt" placeholder="请输入短信验证码" v-decorator="[
                              'code', { rules: [{ required: true, message: '请输入短信验证码' }] },]"></a-input>
                          </div>
                          <div :class="loginSmsClass" @click="clickloginSms()">{{ loginSmsText }}</div>
                        </div>
                      </a-form-item>
                      <div class="forget-password">
                        <router-link class="left" to="/resetPassword">忘记密码？</router-link>
                        <router-link class="right" :to="'/register'">免费注册</router-link>
                      </div>
                      <a-button type="primary" html-type="submit" class="jeol-login-btn">登录</a-button>
                    </a-form>
                  </div>
                </div>
              </div>
              <!-- <div id="jeol-wechat-btn" class="wechat-box">
                <div class="top-box">
                  <div class="text">社交账号登录</div>
                </div>
                <div class="imgs-box" @click="wechatLogin()">
                  <img src="../../assets/site/wechat.png" />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import LoginFooter from "../../components/loginFooter";
import LoginHeader from "../../components/loginHeader";
import { Form, Input, Button } from "ant-design-vue";
import { mapActions } from "vuex";
import services from '../../config/services';

export default {
  name: "Userlogin",
  components: {
    LoginFooter,
    LoginHeader,
    AForm: Form,
    AInput: Input,
    AFormItem: Form.Item,
    AButton: Button,
  },
  data () {
    return {
      services,
      loginBefore: null,
      isClientHeight: false,
      formType: 1, // 1 账号登录 2 验证码登录
      imageCode: {
        key: '',
        img: '',
      },
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "jeol-getCode",
      fromPath: '', // 进入登录页面之前的路由
      fromQuery: {}
    };
  },
  beforeCreate () {
    this.accountForm = this.$form.createForm(this);
    this.mobileForm = this.$form.createForm(this);
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      let whiteList = ['/weSuccess', '/weError', "/school/Page404", "/school/Welcome", '/class/notfound', "/school/Error", "/login", "/register", '/resetPassword', '/policy']
      if (whiteList.includes(from.path)) {
        vm.fromPath = '/'
      } else {
        vm.fromPath = from.path  //获取上一级路由的路径
        vm.fromQuery = from.query
      }
    })
  },
  mounted () {
    let that = this;
    that.getClientHeight();
    window.onresize = () => {
      that.getClientHeight();
    };
    that.methodImageCaptcha();
    services.getLoginBeforeInfo().then(res => {
      if (res.code == '0000') {
        that.loginBefore = res.data
      }
    })

  },
  methods: {
    ...mapActions(["getAppsImageCaptcha", 'getAppsSmsCaptcha', 'requestAppsLoginApps', 'getAppsLoginUserInfo']),
    // 用户名验证
    validateCheckUsername (rule, value, callback) {
      if (value) {
        if (/^[A-Za-z0-9]{6,16}$/.test(value)) {
          callback();
        } else {
          callback("请输入[6-16]位字母或数字组成的账号");
        }
      } else {
        callback("请输入账号");
      }
    },
    // 密码验证
    validateCheckPassword (rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          callback();
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback('请输入密码');
      }
    },
    // 手机号验证
    validateCheckPhone (rule, value, callback) {
      if (value) {
        if (/^1[3-9][0-9]{9}$/.test(value)) {
          callback();
        } else {
          callback("请输入正确的手机号");
        }
      } else {
        callback('请输入手机号');
      }
    },
    // 微信登录方法
    wechatLogin () {
      if (process.env.NODE_ENV == 'development') {
        // 开发环境测试
        window.open('http://apps-api.dev.gophper.com' + "/api/weixin/login?school_code=0&from=" + window.location.origin, "_self")
      } else {
        // 生产环境
        window.open(services.APPS_SERVIER + "/api/weixin/login?school_code=0&from=" + window.location.origin, "_self")
      }
    },
    // 切换账号登录 和 验证码登录点击事件
    changeFormWays (type) {
      this.formType = type
      this.accountForm.resetFields()
      this.mobileForm.resetFields()
    },
    // 获取页面高度判断
    getClientHeight () {
      if (document.body.clientHeight < 760) {
        this.isClientHeight = true;
      } else {
        this.isClientHeight = false;
      }
    },
    // 获取图片验证码
    methodImageCaptcha () {
      let that = this;
      that.getAppsImageCaptcha().then((res) => {
        if (res.code == '0000') {
          that.imageCode = res.data
        }
      });
    },
    // 提交账号登录表单
    handleAccountSubmit (e) {
      let that = this;
      e.preventDefault();
      this.accountForm.validateFields(["username", "password", "captcha"], (err, values) => {
        if (!err) {
          let param = { ...values }
          if (that.loginBefore && that.loginBefore.is_captcha_check) {
            param.key = that.imageCode.key
          }
          that.methodlogin(param);
        }
      })
    },
    // 提交验证码登录表单
    handleMobileSubmit (e) {
      let that = this;
      e.preventDefault();
      this.mobileForm.validateFields(["mobile", "captcha", "code"], (err, values) => {
        if (!err) {
          that.methodlogin(values);
        }
      })
    },
    // 请求登录方法
    async methodlogin (param) {
      let that = this;
      let { setFields } = that.formType == 1 ? this.accountForm : this.mobileForm
      that.requestAppsLoginApps(param).then((res) => {
        if (res.code == '0000') {
          // that.$message.success(res.message ? res.message : "登录成功");
          // 获取用户信息
          that.getAppsLoginUserInfo().then(sub => {
            if (sub.code == '0000') {
              // if(that.$route.query.from){
              //   that.$router.push({ path: that.$route.query.from})
              // } else {
              //   this.$router.go(-1)
              // }
              that.$router.push({ path: that.fromPath, query: that.fromQuery })
            }
          })
        } else {
          that.methodImageCaptcha()
          if (res.errors) {
            let errors = res.errors
            for (let k in errors) {
              setFields({
                [k]: { errors: [new Error(errors[k])] }
              })
            }
          } else {
            that.$message.error(res.message ? res.message : "登录失败");
          }
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "jeol-getCode") {
        that.mobileForm.validateFields(["mobile", "captcha"], (err, values) => {
          if (!err) {
            values.key = that.imageCode.key;
            that.getAppsSmsCaptcha(values).then((res) => {
              if (res.code === "0000") {
                that.smsCountDown()
                that.$message.success(res.message ? res.message : "短信验证码发送成功");
              } else {
                that.methodImageCaptcha()
                if (res.errors) {
                  let errors = res.errors
                  for (let k in errors) {
                    that.mobileForm.setFields({
                      [k]: { errors: [new Error(errors[k])] }
                    })
                  }
                } else {
                  that.$message.error(res.message ? res.message : "短信验证码发送失败");
                }
              }
            });
          }
        })

      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "jeol-getCode disabled";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "jeol-getCode";
          clearInterval(that.interval1);
        }
      }, 1000);
    }
  },
};
</script>

<style scoped src="../../assets/css/yxtLogin.css">
</style>
<style lang="less" scoped>
/deep/ .form-content .form-item .has-error .ant-form-explain,
/deep/ .form-content .form-item .has-error .ant-form-split,
/deep/ .form-content .form-item .has-success .ant-form-explain,
/deep/ .form-content .form-item .has-success .ant-form-split {
  position: absolute !important;
}
.has-success .ant-form-item {
  margin-bottom: 0px;
}
.ant-form-item-with-help {
  margin-bottom: 0px;
}
</style>

