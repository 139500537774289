var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-all-box"},[_c('Nav'),_c('div',{staticClass:"need-bg-box"},[_c('div',{staticClass:"c"},[_c('div',{staticClass:"center-form-box"},[_c('div',{staticClass:"toptip"},[_vm._v("告诉我们您的需求，专属顾问为您提供详细服务方案。")]),_c('a-form',{staticClass:"VForm",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"需求类型"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'type',
								{
									rules: [
										{
											required: true,
											message: '请选择需求类型',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'type',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请选择需求类型',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],staticStyle:{"width":"120px"},attrs:{"placeholder":"请选择需求类型"},on:{"change":_vm.handleChange}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("实训平台")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("专业教学资源")]),_c('a-select-option',{attrs:{"value":"5"}},[_vm._v("课程思政资源")]),_c('a-select-option',{attrs:{"value":"6"}},[_vm._v("思政培训")]),_c('a-select-option',{attrs:{"value":"7"}},[_vm._v("大思政育人")]),_c('a-select-option',{attrs:{"value":"8"}},[_vm._v("媒体宣传服务")]),_c('a-select-option',{attrs:{"value":"9"}},[_vm._v("党建培训")]),_c('a-select-option',{attrs:{"value":"99"}},[_vm._v("其它")])],1)],1),_c('a-form-item',_vm._b({attrs:{"label":"联系人"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'contact_name',
								{
									rules: [
										{
											required: true,
											message: '请输入联系人姓名',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'contact_name',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入联系人姓名',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"联系方式"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'mobile',
								{
									rules: [
										{
											required: true,
											message: '请填写您的联系方式',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'mobile',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请填写您的联系方式',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"从教院校"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'school_name',
								{
									rules: [
										{
											required: true,
											message: '请输入从教院校',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'school_name',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入从教院校',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"专业/部门"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'belong',
								{
									rules: [
										{
											required: true,
											message: '请输入专业/部门',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'belong',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入专业/部门',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"职务"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'job',
								{
									rules: [
										{
											required: true,
											message: '请输入职务',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'job',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入职务',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"需求描述"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description',
									{
										rules: [
											{
												required: true,
												message: '请输入需求描述',
											},
											{
												validator: _vm.validateToDescribe,
											}
										],
									} ]),expression:"['description',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入需求描述',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tvalidator: validateToDescribe,\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"","auto-size":{ minRows: 3, maxRows: 3 }}})],1),_c('a-form-item',_vm._b({attrs:{"label":"验证码"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'captcha',
								{
									rules: [
										{
											required: true,
											message: '图形验证码不能为空',
										}
									],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'captcha',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '图形验证码不能为空',\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],staticClass:"login-code-box",attrs:{"placeholder":"请输入图形验证码"}}),_c('div',{staticClass:"login-code",on:{"click":_vm.loginCode}},[(_vm.imageCode)?_c('img',{attrs:{"src":_vm.imageCode.img,"alt":""}}):_vm._e()])],1),_c('div',{staticClass:"form-btn"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交留言")])],1)],1)],1)])]),_c('Aside'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }