<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/zjiet/logo.png" alt="" class="logo">
          <img src="../../../../assets/zjiet/schoolLogo.png" alt="" class="schoolLogo">
          <span class="bgTitle">课程思政教学研究中心</span>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item actived">
            <router-link :to="'/school/zjiet'" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/course'" exact>示范课程</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/1'" exact>新闻动态</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/2'" exact>通知文件</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/3'" exact>媒体报道</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/4'" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a href="https://sz.jiaoyu.cn/szzyk" target="_blank">思政资源库</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="banner">
        <a-carousel autoplay>
          <div>
            <img src="../../../../assets/zjiet/banner1.jpg" alt="" />
          </div>
          <div>
            <img src="../../../../assets/zjiet/banner2.jpg" alt="" />
          </div>
        </a-carousel>
      </div>
      <div class="course">
        <div class="course_top">
          <img src="../../../../assets/zjiet/left.png" alt="" />
          <img src="../../../../assets/zjiet/dang.png" alt="" class="dang" />
          <span class="course_top_name">示范课程</span>
          <img src="../../../../assets/zjiet/right.png" alt="" />
        </div>
        <ul class="course_box">
          <li v-for="(item, index) in dataList" :key="index" class="course_item">
            <a :href="item.url" target="_blank" class="course_img">
              <img :src="item.imgSrc" alt="" class="showImg" />
            </a>
            <div class="line"></div>
            <div class="course_info">
              <p class="course_name">{{item.name}}</p>
              <p class="course_study">
                <img src="../../../../assets/zjiet/study.png" alt="" />
                <span class="study">{{item.studyNum}}人已学</span>
              </p>
            </div>
          </li>
        </ul>
        <div class="seeMore">
          <div class="button">
            <router-link :to="'/school/zjiet/course'" exact class="button">查看更多</router-link>
          </div>
        </div>
      </div>
      <div class="radian">
        <img src="../../../../assets/zjiet/downRadian.png" alt="" />
      </div>
      <div class="news">
        <div class="news_left">
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" />
              <span class="news_top_title">新闻动态</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="'/school/zjiet/list/1'" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/1'">
                    首届浙江省供销社行业类专场校园招聘会成功举办
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/2'">
                    钱江晚报·小时新闻丨有企业开出万元月薪！首届浙江省供销社行业类专场校园招聘会举
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/3'">
                    我校在“第十五届全国高校商业精英挑战赛会展创新创业总决赛” 中荣获佳绩
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/4'">
                    省纪委省监委驻省经信厅纪检监察组 调研指导学校工作
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/5'">
                    喜报丨我校在第六届“踏瑞杯”全国高职高专人力资源管理技能大赛中荣获特等奖
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
            </ul>
          </div>
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" />
              <span class="news_top_title">通知文件</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="'/school/zjiet/list/2'" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/6'">
                    关于《潮韵》征稿启事的通知
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">校园建设处关于招聘基建项目管理顾问的公告</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">关于申报2021年度全省供销社课题研究项目的通知</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">浙江经贸职业技术学院关于2021年求职创业补贴申请发放的公示</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">浙江经贸职业技术学院关于举办“裕农通杯”第四届浙江省大学生乡村振兴创意大赛校内选拔赛的通知</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="news_line"></div>
        <div class="news_right">
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" class="news_dang" />
              <span class="news_top_title">媒体报道</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="'/school/zjiet/list/3'" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/11'">
                    人民新闻报：追寻红色印迹，共筑淳美住龙
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">中国教育在线：别人家的新生报到日 浙经贸合作经济学院带学生家长云逛校园</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">中华合作时报：“阳光空间”助力偏远山村产业协调发展</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">中华合作时报： 朱超云： 愿做直通学生心灵的“造桥师”</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">中国反邪教协会薄荷茶社：浙江经贸职业技术学院：喜迎建党百年，传播反邪强音</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
            </ul>
          </div>
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" class="news_dang" />
              <span class="news_top_title">政策文件</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="'/school/zjiet/list/4'" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/16'">
                    习近平：在纪念辛亥革命110周年大会上的讲话
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">习近平向2021年世界互联网大会乌镇峰会致贺信</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">坚定信心 共克时艰 共建更加美好的世界 ——在第七十六届联合国大会一般性辩论上的讲话 （2021年9月21日）</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">习近平在上海合作组织成员国元首理事会第二十一次会议上的讲话</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">坚定决心意志 埋头苦干实干 确保如期实现建军一百年奋斗目标</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
import { courseList } from "../zjietData.js"
export default {
  name: "zjiet",
  data(){
    return{
      dataList: courseList,
    }
  },
  methods: {},
};
</script>

<style scoped lang="less">
/deep/.ant-carousel .slick-slide {
  width: 100%;
  // height: 400px;
}
/deep/.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
}
</style>
<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./home.css" scoped>
</style>