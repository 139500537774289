<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/zjiet/logo.png" alt="" class="logo">
          <img src="../../../../assets/zjiet/schoolLogo.png" alt="" class="schoolLogo">
          <span class="bgTitle">课程思政教学研究中心</span>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item">
            <router-link :to="'/school/zjiet'" exact>首页</router-link>
          </li>
          <li class="nav_item actived">
            <router-link :to="'/school/zjiet/course'" exact>示范课程</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/1'" exact>新闻动态</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/2'" exact>通知文件</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/3'" exact>媒体报道</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/4'" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a href="https://sz.jiaoyu.cn/szzyk" target="_blank">思政资源库</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="banner swiper-container">
        <img src="../../../../assets/zjiet/course_banner.png" alt="" />
        <!-- <a-carousel autoplay>
          <div>
            <img src="../../../../assets/zjiet/course_banner.png" alt="" />
          </div>
          <div>
            <img src="../../../../assets/zjiet/course_banner.png" alt="" />
          </div>
        </a-carousel> -->
      </div>
      <a-anchor>
        <ul class="course_nav">
          <li class="course_nav_item actived" @click="active(0)">
            <a-anchor-link href="#course_box1" title="国家级课程" />
          </li>
          <li class="course_nav_item" @click="active(1)">
            <a-anchor-link href="#course_box2" title="省级课程" />
          </li>
          <li class="course_nav_item" @click="active(2)">
            <a-anchor-link href="#course_box3" title="校级课程" />
          </li>
        </ul>

      </a-anchor>

      <div class="course_box course_box1" id="course_box1">
        <div class="course_box_title">
          <span class="chineseTitle">国家级课程</span>
          <span class="englishTitle">National courses</span>
          <img src="../../../../assets/zjiet/course_line.png" alt="" />
        </div>
        <ul class="course_box">
          <li v-for="(item, index) in dataList" :key="index" class="course_item">
            <a :href="item.url" target="_blank" class="course_img">
              <img :src="item.imgSrc" alt="" class="showImg" />
            </a>
            <div class="line"></div>
            <div class="course_info">
              <p class="course_name">{{item.name}}</p>
              <p class="course_study">
                <img src="../../../../assets/zjiet/study.png" alt="" />
                <span class="study">{{item.studyNum}}人已学</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="course_box2" id="course_box2">
        <div class="course_box">
          <div class="course_box_title">
            <span class="chineseTitle">省级课程</span>
            <span class="englishTitle">Provincial Curriculum</span>
            <img src="../../../../assets/zjiet/course_line.png" alt="" />
          </div>
          <ul class="course_box">
          <li v-for="(item, index) in dataList" :key="index" class="course_item">
            <a :href="item.url" target="_blank" class="course_img">
              <img :src="item.imgSrc" alt="" class="showImg" />
            </a>
            <div class="line"></div>
            <div class="course_info">
              <p class="course_name">{{item.name}}</p>
              <p class="course_study">
                <img src="../../../../assets/zjiet/study.png" alt="" />
                <span class="study">{{item.studyNum}}人已学</span>
              </p>
            </div>
          </li>
        </ul>
        </div>
      </div>
      <div class="course_box course_box3" id="course_box3">
        <div class="course_box_title">
          <span class="chineseTitle">校级课程</span>
          <span class="englishTitle">School level curriculum</span>
          <img src="../../../../assets/zjiet/course_line.png" alt="" />
        </div>
        <ul class="course_box">
          <li v-for="(item, index) in dataList" :key="index" class="course_item">
            <a :href="item.url" target="_blank" class="course_img">
              <img :src="item.imgSrc" alt="" class="showImg" />
            </a>
            <div class="line"></div>
            <div class="course_info">
              <p class="course_name">{{item.name}}</p>
              <p class="course_study">
                <img src="../../../../assets/zjiet/study.png" alt="" />
                <span class="study">{{item.studyNum}}人已学</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
import { courseList } from "../zjietData.js"
export default {
  name: "course",
  data(){
    return{
      dataList: courseList,
    }
  },
  methods: {
    active (index) {
      document.getElementsByClassName("course_nav_item actived")[0].className = "course_nav_item";
      document.getElementsByClassName("course_nav_item")[index].className = "course_nav_item actived";
    }
  },
};
</script>
<style scoped lang="less">
/deep/.ant-anchor-link-title {
  color: #b64c3f;
  height: 100%;
  display: inline-block;
}
.ant-anchor-link {
  height: 100%;
  line-height: 70px;
}
/deep/.ant-affix {
  top: unset !important;
  position: relative !important;
}
</style>

<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./course.css" scoped>
</style>