<template>
  <div class="studyRecord">
    <template>
      <div v-if="data.length>0">
        <div v-for="items in this.data" :key="items.updated_time">
          <ul class="recordList" v-bind:key="items.updated_time">
            <li slot="renderItem">
              <p class="time">{{items.updated_time}}</p>
              <a-list class="ItemsList" item-layout="vertical" :data-source="items.data">
                <a-list-item slot="renderItem" slot-scope="item">
                  <img slot="extra" width="160" height="90" alt="logo" :src="item.photo" @click="classToDetail(item)" />
                  <template slot="actions">
                    <span>已学习：{{item.visit_time}}</span>
                  </template>
                  <a-list-item-meta :description="'所属课程：' + item.course_name">
                    <a slot="title" href="javascript:void(0)" @click="classToDetail(item)">{{ item.name }}</a>

                  </a-list-item-meta>
                  {{ item.content }}
                </a-list-item>
              </a-list>
            </li>
          </ul>
        </div>
      </div>
      <div class="noData-box" v-else>
        <img src="@/assets/empty.png" alt="">
        <div class="text">暂无相关内容</div>
      </div>

    </template>

  </div>
</template>

<script >
import { List } from "ant-design-vue";
import services from "../../../config/services";
import utils from '../../../config/utils';
const { Item } = List
const { Meta } = Item

export default {
  name: 'StudyRecord',
  components: {
    AList: List,
    AListItem: Item,
    AListItemMeta: Meta
  },
  data () {
    return {
      data: [],
      services: services
    }
  },
  methods: {
    classToDetail (item) {
      if (item.course_status == 2) {
        this.$message.info('课程已下架')
        return false;
      } else {
        utils.redirect(this, '/video/' + item.course_id + '/' + item.cm_id + '?from=' + this.$route.path, true);
      }
    },
    lodData (params) {
      services.getUserLearnLog({
        page: 1,
        size: 100,
        ...params
      }).then((res) => {
        if (res.code === '0000') {
          this.data = res.data.items
        }
      });

    }
  },
  mounted () {
    this.$store.commit("setNavTitle", {
      navTitle: "学习中心",
    });
    this.lodData()
  },


}
</script>

<style scoped lang="less">
.noData-box {
  width: 100%;
  text-align: center;
  height: 770px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
  img {
    width: 240px;
    height: auto;
    margin: 0 auto 30px;
  }
  .text {
    color: #76797e;
    font-size: 14px;
  }
}
</style>
