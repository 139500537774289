<template>
<div id="app">
    <router-view></router-view>
</div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import './assets/css/common.less'
import './assets/css/tcplayer.css'
import utils from './config/utils'

export default {
    name: 'App',
    components: { 
    },
    directives: {
    },
    data () {
        return {
        zh_CN

        }
    },
    mounted() {
        if (process.env.NODE_ENV == 'production') {
            // 第二层判断打包后是测试环境还是正式环境 
            if(process.env.VUE_APP_FLAG === 'pro'){
                utils.loadStatistics();
            } 
        }
        
    }
}
</script>