const redirect = (e, path, _blank) => {
  if (_blank) {
    let routeUrl = e.$router.resolve({
      path: path
    });
    window.open(routeUrl.href, '_blank');
  } else {
    e.$router.push({ path: path })
  }

}

// 动态加载js
const loadScript = (url, callback) => {
  if (typeof document !== "undefined") {
    var script = document.createElement("script");
  }
  script.type = "text/javascript";
  if (typeof callback !== "undefined") {
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }
  }
  script.src = url;
  if (typeof document !== "undefined") {
    document.head.appendChild(script);
  }
}

const loadStatistics = function () {
  if (typeof document !== "undefined") {
    var script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.src = 'https://s4.cnzz.com/z_stat.php?id=1280321318&web_id=1280321318';
    var outer = document.createElement("div");
    outer.style.height = '1px';
    outer.style.width = '1px';
    outer.style.overflow = 'hidden';
    outer.appendChild(script1);

    var script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://hm.baidu.com/hm.js?ec962afbc0872cd7e6756cf5a357da37";

    document.body.append(outer);
    document.body.append(script2);
  }
}

// 设置标题
const setTitle = (title) => {
  let schoolname = localStorage.getItem('schoolname');
  if (schoolname) {
    switch (title) {
      case 'Home':
        title = `${schoolname ? schoolname : ''}`
        break;
      case '学校概况':
      case '资讯':
        title = `${title}${schoolname ? '-' + schoolname : ''}`
        break;
      case '资讯查看':
        title = '-' + `${schoolname ? schoolname : ''}`
        break;
      case '课程查看':
      case '课程播放':
        title = '课程'
        break;
      default:
    }
  }
  if (title) {
    if (title == "首页") {
      title = "中教云课-全媒体人才培养实训教学平台"
    } else if (title == "思政资源库") {
      title = title + '-中国教育在线';
    } else {
      if (title == "智造农场") {
        title = '智造农场，数创未来'
      } else {
        title = title + '-中教云课';
      }
    }
    if (title.indexOf('sdcmc') > -1) {
      title = '山东传媒职业学院-课程思政教学研究中心';
    } else if (title.indexOf('zjiet') > -1) {
      title = '浙江经贸职业技术学院-课程思政教学研究中心';
    }

  } else {
    title = '中教云课-在线教育学习平台'
  }

  document.title = title
}

// localStorage方法
const setUserInfo = val => localStorage.setItem('userInfo', JSON.stringify(val));
const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo'));
};
const removeUserInfo = () => localStorage.removeItem('userInfo');
const clearLocal = () => localStorage.clear();

// 山东传媒职业学院 100017
const sdcmzyxyCode = 100017

// 白名单路由不校验登录状态
const whiteList = ['/weSuccess', '/weError', "/school/Page404", "/school/Error", '/class/notfound', "/login", "/register", "/policy", "/resetPassword", '/school/', '/school', `/school/${sdcmzyxyCode}`, '/media', '/media/detail', '/school/class', "/service", "/service/study", "/service/teaching", "/service/training", "/thought", "/need", "/djsz"];

// 需要校验登录状态黑名单路由
const routers = ['updatePassword', 'userMessage', 'myClass', 'myNote', 'studyRecord'];
const blackList = routers.map((data) => {
  return '/user/' + data
})
// 全媒体运营平台 中教云课站点id
const ZJYK_SITE_ID = 9; 
// 全媒体运营平台 中教云课栏目信息 测试和正式环境id不一致
var zjykCategory = [
  {type: 'mtkx',id: 469, name: '媒体快讯',},
  {type: 'rmzsk',id: 470, name: '融媒知识库',},
  {type: 'zjdt',id: 471, name: '职教动态',},
  {type: 'yxtdjsz',id: 473, name: '党建思政',},
  {type: 'rmggw',id: 472, name: '融媒广告位',},
];
if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_FLAG === 'pro') {
  zjykCategory = [
    {type: 'mtkx',id: 376, name: '媒体快讯',},
    {type: 'rmzsk',id: 377, name: '融媒知识库',},
    {type: 'zjdt',id: 378, name: '职教动态',},
    {type: 'yxtdjsz',id: 380, name: '党建思政',},
    {type: 'rmggw',id: 379, name: '融媒广告位',},
  ];
}
const getZjykCategoryId  = function (type) { 
  let id ='';
  for(let i =0;i<zjykCategory.length;i++){
    if(type == zjykCategory[i].type){
      id = zjykCategory[i].id
    }
  }
  return id
}
export default {
  redirect,
  loadScript,
  setTitle,
  loadStatistics,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  clearLocal,
  whiteList,
  blackList,
  sdcmzyxyCode,
  ZJYK_SITE_ID,
  zjykCategory,
  getZjykCategoryId
}
