<template>
	<div class="error-box">
		error
	</div>
</template>

<script>
export default {
	name: 'Error',
	data(){
		return {}
	},
	created() { },
}
</script>

<style scoped>
.error-box {
	width: 100%;
	text-align: center;
	line-height: 100px;
}
</style>