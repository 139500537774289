<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/zjiet/logo.png" alt="" class="logo">
          <img src="../../../../assets/zjiet/schoolLogo.png" alt="" class="schoolLogo">
          <span class="bgTitle">课程思政教学研究中心</span>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item">
            <router-link :to="'/school/zjiet'" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/course'" exact>示范课程</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/1'" exact>新闻动态</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/2'" exact>通知文件</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/3'" exact>媒体报道</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/list/4'" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a href="https://sz.jiaoyu.cn/szzyk" target="_blank">思政资源库</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="news-mianbao c h">
        <span class="labekl">当前位置:</span>
        <span class="labekl">资讯</span>
        <span class="iconfont">></span>
        <span class="labekl">资讯查看</span>
      </div>
      <template v-if="DetailIndex == '1'">
        <div class="art-title c">
          <div class="title">首届浙江省供销社行业类专场校园招聘会成功举办</div>
          <div class="time">2021-08-03 14:59:33</div>
        </div>
        <div class="school-text c">
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>11月23日，2021年浙江省技能人才岗位进校园系列招聘会暨浙江省供销社行业类专场校园招聘会在浙江经贸职业技术学院隆重举行。浙江省供销合作社联合社党委委员、理事会副主任张建，党委委员、人事处处长孙鲁东，浙江省职业介绍服务指导中心副主任张建，浙江经贸职业技术学院党委书记劳赐铭、校长陈德泉、副校长张红、副校长蒋丽君等出席启动仪式，会议由校长陈德泉主持。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt101.jpg" style="display: block; margin: 0 auto" />

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>浙江省供销社党委委员、理事会副主任张建发表讲话</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt102.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>校长陈德泉主持</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>启动仪式上，浙江省供销社党委委员、理事会副主任张建代表主办单位致辞，他表示，供销合作社是党领导下的为农服务的综合性合作经济组织，是党委政府推进“三农”工作、直接为农服务的重要载体。今年首次举办供销社行业类专场校园招聘会，是供销社搭建社有企业与毕业生供需平台的创新举措。他重点围绕人才队伍建设，对社有企业和院校改革发展提出了要求，对院校毕业生提出了殷切希望。他希望学校与社有企业进一步加强校企合作，深化产教融合协同育人，为供销合作事业发展培养更多高素质技术技能人才、能工巧匠、大国工匠，高水平助推共同富裕示范区建设。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt103.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>党委书记劳赐铭致欢迎辞</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>劳赐铭在致辞中对浙江省人力资源和社会保障厅、浙江省供销合作社联合社把2021年浙江省供销行业类专场校园招聘会放在我校举行表示衷心感谢。他指出，学校坚持“立足区域、依托行业、服务三农”的办学思路，一直以来把就业质量作为衡量学校办学能力和办学水平的重要指标。办学42年来，为社会输送了一批又一批高素质、高水平人才，毕业生去向落实率稳定在97%以上。作为承办单位，学校将高质量、高水平地做好各项工作，努力办成有影响、有特色、有深度的校园招聘会，进一步推动“校、社、政、企”四方合作机制，为政府、高校、用人单位和毕业生四方搭建就业“零距离”的沟通桥梁和平台，实现四方共赢。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt104.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>企业代表浙农集团股份有限公司人力资源部经理钟尧君发言</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt105.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span> 合作经济学院茶艺与茶叶营销专业周粤虹作毕业生代表发言</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>浙农集团股份有限公司人力资源部经理钟尧君作企业代表发言，介绍了与学校合作的农资订单班、汽车定向班、庄稼医生培训鉴定、智慧农资培训课程开发、大学生实训基地等项目，并对公司未来技能人才的培养生态、职业发展体系和学习发展体系提出了设想和规划，鼓励更多毕业生投身乡村振兴事业，加盟供销社企业共同奋斗。合作经济学院茶艺与茶叶营销专业周粤虹作毕业生代表发言，她畅谈了三年的学习经历，主动将个人理想融入供销社行业发展，积极投身于现代化农业事业，展示了“新时代供销人”积极进取、勇于担当的精神和良好的风貌。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt106.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span> 启动仪式现场</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>启动仪式结束后，与会领导来到招聘会现场，与部分参会企业代表和毕业生亲切交流。本次校园招聘会主要聚焦供销社行业类企业，定向邀请了省供销社本级企业、各市县供销社等216家企业组团参会，实现人才供需精准对接。招聘会上为吸引毕业生，企业纷纷推出优质岗位，如浙江省兴合集团有限责任公司的信息管理岗位，月薪为8000至10000元；浙农控股集团有限公司提供的财务审计类、信息管理类、人事行政类、营销业务类等岗位月薪6000至10000元，超过了普通高校应届毕业生的平均薪资水平。各企业累计提供700余个岗位，需求人数6000余人，涉及电子商务、信息技术、财务会计、生物制药、物流营销等多个领域。初步统计，3000余名学生参加了现场招聘会，投递简历累计达2000余份，其中600余名毕业生与用人单位达成了初步就业意向。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt107.jpg" />
          <img src="../../../../assets/zjiet/xwdt108.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>活动现场还专门设置了校企合作洽谈区、就业指导服务体验营。不少参会企业早早地来到现场，与各二级学院专业负责人交流用人需求，洽谈校企合作意向。就业指导服务体验营开设了“优秀简历展”、“求职礼仪指导”、“校友话就业”、“导师面对面”、“政策加油站”五个体验项目，不仅增加了毕业生求职应聘的趣味性，而且提升了毕业生的求职技能和职业生涯规划能力，深受毕业生的青睐。志愿者全程提供一对一引导和贴心服务，受到企业参会人员一致好评。</span>
          </p>
          <img src="../../../../assets/zjiet/xwdt109.jpg" />
          <img src="../../../../assets/zjiet/xwdt110.jpg" />
          <img src="../../../../assets/zjiet/xwdt111.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>本次校园招聘会由浙江省人力资源和社会保障厅、浙江省供销合作社联合社联合主办，浙江省职业介绍服务指导中心、杭州市就业管理服务中心、杭州市人才管理服务中心、杭州市钱塘区人力资源和社会保障局、浙江经贸职业技术学院等单位共同承办。</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '6'">
        <div class="art-title c">
          <div class="title">关于《潮韵》征稿启事的通知</div>
          <div class="time">2021-08-03 14:59:33</div>
        </div>
        <div class="school-text c">
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>全体校友、师生：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>由学校团委主办的文学刊物《潮韵》创办于1989年，旨在为广大师生提供一个发挥才能、传播文化的文学平台，为学校营造浓厚人文氛围，积淀特色校园文化。为进一步激发高校师生的责任担当，厚植学生的爱国主义情怀，现面向全体校友、全校师生征稿：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>一、栏目设置：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>1.团旗飘扬：紧扣“建党100周年”的时代主题，主要刊载反映党团重要思想理论、共青团动态以及学院重要活动等各类文稿。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>2.师者匠心：主要刊载全校教师于党建党100周年之际，与建党100周年相关的工作成果、专业知识和思想情感等方面的主题报道、散文、杂文等作品。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>3.潮人潮语：主要刊载经贸校友、学子对祖国的热爱、对建党100周年的思考、对党的认识等方面的主题散文、杂文、小说、诗歌等文学作品。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>4.党恩荣光：主要刊载建党100周年之际，经贸学子赞颂中国共产党的文学作品。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>（1）党在我心：体现大学生对生活、生活感悟、所见所闻的文章或随笔，以及原创的诗歌、散文或回忆录等，思想积极向上，能够传播正能量，且以“建党100周年”为主题。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>（2）风雨征程：围绕庆祝中国共产党成立100周年这一主线，深入贯彻落实习近平新时代中国特色社会主义思想，用亲历、亲见、亲闻之事，反映中国共产党的百年光辉历程。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>5、党光照校园：主要刊载全校师生反映校园生活、青春活力的书法、绘画、摄影等艺术作品。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span> 二、征稿要求</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>1.要求文稿以原创为主，摘录作品请标明作者、出处，字数在800-2000字之间；内容鲜活、生动、真实、健康、积极向上。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>2.来稿请提供电子稿件，注明投稿栏目、姓名、所在系（部）或班级、联系方式，以便及时与您联系，邮件主题请写“投稿文章标题+系别”，艺术作品请自行扫描为电子版并投稿。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>3.征稿截止时间为7月15日，所投稿件概不退还，请自留底稿；在不影响作者主旨思想的前提下，本刊有权删改，如有异议敬请注明。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>三、投稿方式</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>投稿信箱：chaoyun1989@163.com</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>联系方式：86929935（李海勇）。</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '11'">
        <div class="art-title c">
          <div class="title">人民新闻报：追寻红色印迹，共筑淳美住龙</div>
          <div class="time">2021-08-03 14:59:33</div>
        </div>
        <div class="school-text c">
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>2021年是“十四五”开局之年，是中国共产党建党一百周年，百年大党，风华正茂。为响应党和国家的号召，激发青年大学生爱国爱党情怀，深入学习党史，自觉接受红色教育，浙江经贸职业技术学院“寻访红色党史印迹”小分队于8月3日奔赴浙西南革命根据地—浙江省龙泉市住龙镇开展为期九天的红色主题暑期社会实践。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt101.jpg" style="display: block; margin: 0 auto" />

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>红色旧址忆往昔，学史力行担使命</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>8月3日，实践团队启程前往浙西南革命根据地浙江省龙泉市住龙镇。在张村长的带领下，实践团队参观了村里的红色历史印迹，聆听了红色党史故事。住龙是浙西南游击根据地的中心区域，是土地革命战争时期由粟裕、刘英率领的中国工农红军挺进师在浙江创建的第一块红色根据地。从红军街、弹孔广场、粟裕办公旧址到苏维埃政府会议旧址再到中共浙西南特委旧址，团队成员感受到了这个小镇深厚的红色历史积淀和宝贵的精神财富，印记着当年红军在这里战斗的烽火岁月。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>在特委旧址老屋中，成员们有幸见到老宅的主人范有森，他热情地带领成员参观旧址并细心地为成员讲解在那里发生的革命故事。实践团通过革命物品感受抗战的艰难困苦，在细致的讲解中领会革命年代英雄的不屈品格。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt102.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>今年正值建党百年这一重大历史节点，在活动前期团队成员与当地旅游公司取得联系，经过短期培训后，作为见习导游在本次活动中带领红色研学旅游团通过重温入党誓词、吃红军饭、听革命历史课、走红军路、唱红军歌等活动宣扬红色文化，追忆红色历史，用革命的精神涤荡心灵，将红色基因融入血脉中去。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt105.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>“七一”宣讲进万家，深入调研话基层</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>为深入学习贯彻习近平总书记的“七一”讲话精神，团队成员融合专业优势设计扇面，将习总书记“七一”讲话内容融入扇中，在当地开展宣讲活动。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt104.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>在结束宣讲后，团队成员来到便民服务中心，与住溪村村长张小云进行交流，谈话过程中，成员了解到该镇秉持让红色文化“活”起来，让绿色发展“强”起来的发展理念，积极践行浙西南革命精神，着力推进“红色小镇”建设。通过聆听张村长的介绍与教诲，团队成员对住龙镇厚重的人文历史、肥沃的产业土壤和广阔的发展空间有了初步认识。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt112.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>为进一步了解该地红色产业发展对村民生活的影响，团队成员走访住溪村村民，以发布调查问卷的形式开展实地调研。与村民的对话中，成员了解到近年来住溪村的基层治理实践颇有成效，为村民带来了便利与机遇。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>走访慰问送温暖，传承薪火守初心</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>百年风雨兼程，百年艰苦奋斗。为重温党史悟初心，锤炼党性强使命，团队成员慰问住龙镇内的一位百岁老兵和九位老党员，他们胸前的纪念章映照着五十载荣光，诠释着这个时代的荣誉与担当。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt106.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>共和国勋章获得者罗凤养出身于1918年，他是为红军送情报的老交通员，也是数十年深耕乡野，开渠修路、为民造福的“开拓者”。</span>
          </p>
          <img src="../../../../assets/zjiet/mtdt107.jpg" />
          <img src="../../../../assets/zjiet/mtdt108.jpg" />
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>曾任职10年支部书记的老党员罗岳祥今年已80岁高龄，在职期间他一直坚持为村民服务，致力于基层组织建设，是村民们心中的好干部。</span>
            <img src="../../../../assets/zjiet/mtdt109.jpg" />
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>“连队学习毛主席著作积极分子”，受部队口头嘉奖3次，并连续四年评上了“五好战士”的老党员张清文今年78岁，1964年冬加入中国人民解放军，1969年春退伍回乡，回忆起部队生活时脸上洋溢的兴奋自豪之情令人动容。他带领团队成员重温入党誓词，讲述党史故事，提笔写下“不忘初心，牢记使命”，承载着他对青年的殷切希望。</span>
            <img src="../../../../assets/zjiet/mtdt110.jpg" />
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>乡村振兴展新貌，专业优势助发展</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>近年来，住龙镇在当地党和政府的政策支持下围绕“红+绿”发展主线，践行“绿水青山就是金山银山”，以绿色发展理念充分利用当地生态资源，科学规划多功能旅游内容，推动经济持续健康发展。在外的住龙人纷纷返乡创业，奶茶店、书吧等城市商业形态也在山沟里立足，促进全乡旅游产业升级。为更深入了解住龙镇相关产业发展情况，团队成员开展调研并根据该地所需或所缺提供帮助，将红色文化融入成员的创意中，解当地产业之“少”，提学生专业技能之“高”。在活动前期团队主动联系了住龙镇负责旅游的工作人员吴陶涛，双方就住龙特色旅游相关问题展开线上交流与对接，并协助其团队完成“红色旅游路线图”，获得较高评价。实践期间团队成员走访位于住龙镇水塔村的蜜蜂养殖基地，拜访了当地的蜂蜜户钟香连。基于缺乏销售渠道影响农产品销售这一情况，团队成员与养殖户交换联系方式，计划在活动后期充分发挥专业所长为其设计产品logo，并利用学院传播专业所搭建的直播带货平台为农产品扩大销售渠道，达到学以致用，学用相长。助力红色发展，赋能乡村振兴，经贸青年在路上。</span>
            <img src="../../../../assets/zjiet/mtdt111.jpg" />
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>在本次社会实践中，团队成员以“学习百年党史，践行初心使命”为起点，围绕红色产业发展，助力乡村振兴等方面开展一系列活动，让团队成员对于习近平总书记关于“用好红色资源，传承好红色基因
              ”的思想有了更加深刻的理解。百年风雨兼程，精神薪火相传，作为新时代的青年学子，应勇于承担时代与社会赋予的责任，做时代的奋进者、开拓者，以热血致敬崇高敬仰，开启百年新篇章。</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '16'">
        <div class="art-title c">
          <div class="title">习近平：在纪念辛亥革命110周年大会上的讲话</div>
          <div class="time">2021-08-03 14:59:33</div>
        </div>
        <div class="school-text c">
          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新华社北京10月9日电</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: center;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>在纪念辛亥革命110周年大会上的讲话</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>110年前，以孙中山先生为代表的革命党人发动了震惊世界的辛亥革命，推翻了清朝政府，结束了在中国延续几千年的君主专制制度，近代以来中国发生的深刻社会变革由此拉开了序幕。这是中国人民和中国先进分子为实现民族独立、人民解放进行的一次伟大而艰辛探索。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>今年是辛亥革命110周年，是中国共产党成立100周年，中国人民正意气风发向着全面建成社会主义现代化强国的第二个百年奋斗目标迈进。在这个重要时刻，我们在这里隆重集会，缅怀孙中山先生等革命先驱的历史功勋，就是要学习和弘扬他们为振兴中华而矢志不渝的崇高精神，激励和团结海内外全体中华儿女为实现中华民族伟大复兴而共同奋斗。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们、朋友们！</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>辛亥革命的发生，有着深刻的社会历史背景，是近代以来中国社会矛盾激化和中国人民顽强斗争的必然结果。中华民族是世界上古老而伟大的民族，有着5000多年源远流长的文明历史，为人类文明进步作出了不可磨灭的贡献。1840年鸦片战争以后，西方列强在中华大地上恣意妄为，封建统治者孱弱无能，中国逐步成为半殖民地半封建社会，国家蒙辱、人民蒙难、文明蒙尘，中国人民和中华民族遭受了前所未有的劫难。英雄的中国人民始终没有屈服，在救亡图存的道路上一次次抗争、一次次求索，展现了不畏强暴、自强不息的顽强意志。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>从那时起，实现中华民族伟大复兴就成为中华民族最伟大的梦想。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>孙中山先生是伟大的民族英雄、伟大的爱国主义者、中国民主革命的伟大先驱。孙中山先生大声疾呼“亟拯斯民于水火，切扶大厦之将倾”，高扬反对封建专制统治的斗争旗帜，提出民族、民权、民生的三民主义政治纲领，率先发出“振兴中华”的呐喊。在孙中山先生领导和影响下，大批革命党人和无数爱国志士集聚在振兴中华旗帜之下，广泛传播革命思想，积极兴起进步浪潮，连续发动武装起义，推动了革命大势的形成。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>1911年10月10日，武昌城头枪声一响，拉开了中国完全意义上的近代民族民主革命的序幕。辛亥革命极大促进了中华民族的思想解放，传播了民主共和的理念，打开了中国进步潮流的闸门，撼动了反动统治秩序的根基，在中华大地上建立起亚洲第一个共和制国家，以巨大的震撼力和深刻的影响力推动了中国社会变革，为实现中华民族伟大复兴探索了道路。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>孙中山先生和辛亥革命先驱为中华民族建立的历史功绩彪炳千秋！在辛亥革命中英勇奋斗和壮烈牺牲的志士们名垂青史！辛亥革命永远是中华民族伟大复兴征程上一座巍然屹立的里程碑！</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>历史发展总是螺旋式上升、波浪式前进的。由于历史进程和社会条件的制约，由于没有找到解决中国前途命运问题的正确道路和领导力量，辛亥革命没有改变旧中国半殖民地半封建的社会性质和中国人民的悲惨境遇，没有完成实现民族独立、人民解放的历史任务。辛亥革命之后，在这场革命中接受洗礼的中国人民和中国先进分子继续探寻救国救民道路。十月革命一声炮响，给中国送来了马克思列宁主义，促进了中国人民的伟大觉醒，在马克思列宁主义同中国工人运动的紧密结合中，中国共产党应运而生。中国共产党一经诞生，就把为中国人民谋幸福、为中华民族谋复兴确立为自己的初心和使命，点亮了实现中华民族伟大复兴的灯塔。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>中国共产党人是孙中山先生革命事业最坚定的支持者、最忠诚的合作者、最忠实的继承者。中国共产党在成立之初就提出反帝反封建的民主革命纲领，并同孙中山先生领导的中国国民党携手合作，帮助国民党完成改组，建立最广泛的革命统一战线，掀起轰轰烈烈的大革命，给北洋军阀反动统治以沉重打击。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>孙中山先生逝世后，中国共产党人继承他的遗愿，同一切忠于他的事业的人们继续奋斗，不断实现和发展了孙中山先生和辛亥革命先驱的伟大抱负。中国共产党团结带领中国人民浴血奋战、百折不挠，打败国内外一切反动势力，取得了新民主主义革命伟大胜利，建立了人民当家作主的中华人民共和国，完成了民族独立、人民解放的历史任务，开启了中华民族发展进步的历史新纪元。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新中国成立后，中国共产党团结带领中国人民，自力更生、发愤图强，创造了社会主义革命和建设的伟大成就；解放思想、锐意进取，创造了改革开放和社会主义现代化建设的伟大成就；自信自强、守正创新，统揽伟大斗争、伟大工程、伟大事业、伟大梦想，创造了新时代坚持和发展中国特色社会主义的伟大成就。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>抚今追昔，孙中山先生振兴中华的深切夙愿，辛亥革命先驱对中华民族发展的美好憧憬，近代以来中国人民梦寐以求并为之奋斗的伟大梦想已经或正在成为现实，中华民族迎来了从站起来、富起来到强起来的伟大飞跃，中华民族伟大复兴进入了不可逆转的历史进程！</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>孙中山先生在《建国方略》中说：“吾心信其可行，则移山填海之难，终有成功之日”。今天，经过长期奋斗，实现中华民族伟大复兴具备了更为完善的制度保证、更为坚实的物质基础、更为主动的精神力量。前景光明辽阔，但前路不会平坦。我们要以史为鉴、开创未来，在全面建设社会主义现代化国家新征程上继续担当历史使命，掌握历史主动，不断把中华民族伟大复兴的历史伟业推向前进。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，必须有领导中国人民前进的坚强力量，这个坚强力量就是中国共产党。中国共产党领导是历史的选择、人民的选择，是党和国家的根本所在、命脉所在，是全国各族人民的利益所系、命运所系。没有中国共产党，就没有新中国，就没有中华民族伟大复兴。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新的征程上，我们必须坚持和加强党的全面领导，充分发挥党总揽全局、协调各方的领导核心作用，提高党科学执政、民主执政、依法执政水平。要弘扬伟大建党精神，推进党的建设新的伟大工程，增强自我净化、自我完善、自我革新、自我提高能力，确保中国共产党始终成为中国人民和中华民族最可靠的主心骨。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，道路是最根本的问题。中国特色社会主义是实现中华民族伟大复兴的唯一正确道路。这条道路符合中国实际、反映中国人民意愿、适应时代发展要求，不仅走得对、走得通，而且也一定能够走得稳、走得好。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新的征程上，我们必须坚持和发展中国特色社会主义不动摇，继续推进马克思主义中国化时代化，坚定志不改、道不变的决心，牢牢把中国发展进步的命运掌握在自己手中。我们要统筹推进“五位一体”总体布局、协调推进“四个全面”战略布局，全面深化改革和扩大开放，推进国家治理体系和治理能力现代化，不断满足人民过上美好生活的新期待，不断推进全体人民共同富裕。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，必须依靠中国人民自己的英勇奋斗。历史发展从来不是风平浪静的，而是充满曲折和艰辛的。正如毛泽东同志所说的：“我们的先人以不屈不挠的斗争反对内外压迫者，从来没有停止过”，“中国人民的不屈不挠的努力必将稳步地达到自己的目的”。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新的征程上，我们必须统筹中华民族伟大复兴战略全局和世界百年未有之大变局，抓住历史机遇，增强忧患意识、始终居安思危，保持革命精神和革命斗志，勇于进行具有许多新的历史特点的伟大斗争，以敢于斗争、善于斗争的意志品质，坚决战胜任何有可能阻碍中华民族复兴进程的重大风险挑战，坚决维护国家主权、安全、发展利益。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，中国人民和中华民族必须同舟共济，依靠团结战胜前进道路上一切风险挑战。孙中山先生说过：“要恢复民族的地位，便先要恢复民族的精神。”近代以来，中国人民和中华民族弘扬伟大爱国主义精神，心聚在了一起、血流到了一起，共同书写了抵御外来侵略、推翻反动统治、建设人民国家、推进改革开放的英雄史诗。统一战线始终是中国共产党凝聚人心、汇聚力量的重要法宝。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新的征程上，我们必须大力弘扬爱国主义精神，树立高度的民族自尊心和民族自信心，铸牢中华民族共同体意识，紧紧依靠全体中华儿女共同奋斗，坚持大团结大联合，不断巩固和发展最广泛的爱国统一战线，广泛凝聚中华民族一切智慧和力量，形成海内外全体中华儿女万众一心、共襄民族复兴伟业的生动局面。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，不仅需要安定团结的国内环境，而且需要和平稳定的国际环境。孙中山先生曾经说过：“中国如果强盛起来，我们不但是要恢复民族的地位，还要对于世界负一个大责任。”中华民族的血液中没有侵略他人、称王称霸的基因，中国人民不仅希望自己发展得好，也希望各国人民都能拥有幸福安宁的生活。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>新的征程上，我们必须始终高举和平、发展、合作、共赢旗帜，推动构建人类命运共同体，推动完善全球治理体系，弘扬和平、发展、公平、正义、民主、自由的全人类共同价值，加强同世界各国人民的团结，共同反对霸权主义和强权政治，做世界和平的建设者、全球发展的贡献者、国际秩序的维护者，努力为人类作出新的更大贡献。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>孙中山先生说过：“‘统一’是中国全体国民的希望。能够统一，全国人民便享福；不能统一，便要受害。”台湾问题因民族弱乱而产生，必将随着民族复兴而解决。这是中华民族历史演进大势所决定的，更是全体中华儿女的共同意志，正像孙中山先生所说：“世界潮流，浩浩荡荡，顺之则昌，逆之则亡”。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>以和平方式实现祖国统一，最符合包括台湾同胞在内的中华民族整体利益。我们坚持“和平统一、一国两制”的基本方针，坚持一个中国原则和“九二共识”，推动两岸关系和平发展。两岸同胞都要站在历史正确的一边，共同创造祖国完全统一、民族伟大复兴的光荣伟业。</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>中华民族具有反对分裂、维护统一的光荣传统。“台独”分裂是祖国统一的最大障碍，是民族复兴的严重隐患。凡是数典忘祖、背叛祖国、分裂国家的人，从来没有好下场，必将遭到人民的唾弃和历史的审判！台湾问题纯属中国内政，不容任何外来干涉。任何人都不要低估中国人民捍卫国家主权和领土完整的坚强决心、坚定意志、强大能力！祖国完全统一的历史任务一定要实现，也一定能够实现！</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p" style="
              margin: 11.5pt 0pt 0pt;
              padding: 0pt;
              text-align: justify;
              line-height: 200%;
              text-indent: 26pt;
            ">
            <span>经过近代以来的长期艰苦奋斗，中国人民创造了令世界刮目相看的伟大成就，迎来了民族复兴的光明前景。实现中华民族伟大复兴是全体中华儿女的共同光荣，也是全体中华儿女的共同使命。孙中山先生说：“惟愿诸君将振兴中国之责任，置之于自身之肩上。”我呼吁，海内外全体中华儿女更加紧密地团结起来，发扬孙中山先生等辛亥革命先驱的伟大精神，携手向着中华民族伟大复兴的目标继续奋勇前进！</span>
          </p>
        </div>

      </template>
    </main>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data () {
    return {
      DetailIndex: null,
    };
  },
  mounted () {
    this.DetailIndex = this.$route.params.id;
  },
  watch: {
    $route: {
      handler () {
        this.DetailIndex = this.$route.params.id;
      },
    },
    deep: true,
  },
};
</script>

<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./detail.css" scoped>
</style>