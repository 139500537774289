<template>
  <div class="bg-all-box">
    <Nav />
    <div class="service-all-box">
      <div class="service-desc-bg">
        <img src="../../assets/site/teaching/teaching-bg.jpg" />
        <!-- <div class="c">
          <div class="bg-con-box">
            <div class="title">教学资源</div>
          </div>
        </div> -->
      </div>
      
      <div class="second-box">
        <div class="bread-bg-box">
          <div class="c">
            <div class="bread-line">
              <router-link class="bread-link" to="/service" exact>院校服务</router-link>
              <span class="bread-icon">></span>
              <a class="bread-link">教学资源</a>
            </div>
          </div>
        </div>
        <div class="c">
          <p class="intro-box">借助中教双元集团自身多年运营中国教育在线融媒体平台丰富的行业经验、对全媒体相关领域发展的深入理解，以及多年深耕职业教育领域、深度参与行业多家1+X职业证书标准制定、教材开发；充分发挥传媒企业与教学标准的结合优势、结合人民在线媒体领域相关课程特点，开发出符合现代传媒行业要求、针对核心职业技能素养教育、紧贴不同层次院校教学特点的完整教材体系。</p>
          <div class="img-all-box">
            <div class="imgs-item">
              <img src="../../assets/site/teaching/imgs1.jpg" />
              <div class="label-box">视频微课/PPT课件</div>
            </div>
            <div class="imgs-item">
              <img src="../../assets/site/teaching/imgs2.jpg" />
              <div class="label-box">实训案例</div>
            </div>
            <div class="imgs-item">
              <img src="../../assets/site/teaching/imgs3.jpg" />
              <div class="label-box">练习题库</div>
            </div>
          </div>
        </div>
      </div>

      <div class="last-con-box">
        <div class="c">
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/service/fx-left.png" />
            <span>教学资源内容</span>
            <img class="fx-right" src="../../assets/site/service/fx-right.png" />
          </div>
          
          <div class="content-box">
            <div class="tr one">
              <div class="td"><span class="first-box">资源类型</span></div>
              <div class="inner-line"></div>
              <div class="td">教师教学资源</div>
              <div class="inner-line"></div>
              <div class="td">学生学习资源</div>
              <div class="inner-line"></div>
              <div class="td">测练资源</div>
            </div>
            <div class="outer-line"></div>
            <div class="tr two">
              <div class="td"><span class="first-box">详细内容</span></div>
              <div class="inner-line"></div>
              <div class="td">
                <div class="list-box">
                  <div class="opt spacing">配套教学案例素材</div>
                  <div class="opt spacing">教学教案</div>
                  <div class="opt spacing">章节重要知识点微课</div>
                  <div class="opt spacing">章节教学PPT</div>
                </div>
              </div>
              <div class="inner-line"></div>
              <div class="td"><div class="spacing">学生实训手册</div></div>
              <div class="inner-line"></div>
              <div class="td"><div class="spacing">综合测练试卷2套</div></div>
            </div>
          </div>
        

          
        </div>

      </div>
      <!-- 联系我们 -->
      <div class="contact-us-box">
        <img class="pos-img-left" src="../../assets/site/home/result-left.png" />
        <img class="pos-img-right" src="../../assets/site/home/result-right.png" />
        <div class="c">
          <div class="contact-text">联系我们，获取更详细服务方案！</div>
          <div class="contact-btn" @click="goToNeed">立即咨询</div>
        </div>
      </div>
    </div>
    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "../home/home.less";

export default {
  name: "Teaching",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      services: services,
    }
  },
  mounted() { 
  },
  methods: {
    goToNeed(){
      utils.redirect(this, "/need", false);
    }
  }
}
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.service-desc-bg {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .bg-con-box {
    width: 44%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #F5F6F9;
      margin-bottom: 15px;
    }
  }
}
.second-box {
  width: 100%;
  padding: 40px 0 60px;
  background-color: #FFFFFF;
}
.charter-lines {
  font-size: 30px;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 40px;
  span {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
  }
  .fx-right, .fx-left {
    display: inline-block;
    vertical-align: middle;
  }
}
.intro-box {
  font-size: 18px;
  color: #2B2B2B;
  line-height: 36px;
  margin-bottom: 45px;
}
.img-all-box {
  display: flex;
  justify-content: space-between;
  .imgs-item {
    flex: 0 0 380px;
    img {
      border: 1px solid #3D77FF;
      width: 100%;
      height: auto;
    }
    .label-box {
      font-size: 20px;
      color: #FFFFFF;
      line-height: 43px;
      height: 43px;
      background: #3D77FF;
      text-align: center;
      border-radius: 0px 0px 6px 6px;
    }
  }
  .imgs-item:nth-child(3) img {
    height: 196px;
    width: auto;
  }
}
.content-box {
  margin-bottom: 80px;
  .tr {
    display: flex;
    width: 88%;
    margin:  0 auto;
    .td {
      height: 80px;
      line-height: 80px;
      text-align: center;
      display: inline-block;
      .first-box {
        font-size: 20px;
        font-weight: bold;
        color: #2B2B2B;
        display: inline-block;
        position: relative;
        z-index: 1;
        margin-top: 10px;
        padding-left: 15px;
        line-height: normal;
      }
      .first-box::after {
        content: "";
        display: inline-block;
        width: 118px;
        height: 16px;
        background: #C8D9FF;
        border-radius: 6px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      .list-box {
        line-height: normal;
        padding:  14px 30px 14px 80px;
        width: 100%;
        box-sizing: border-box;
        .opt {
          display: inline-block;
          width: 45%;
          vertical-align: top;
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }
    .td:nth-child(1) {
      width: 164px;
    }
    .td:nth-child(3) {
      width: 460px;
      text-align: center;
    }
    .td:nth-child(5) {
      flex: 1;
    }
    .td:nth-child(7) {
      flex: 1;
    }
    .inner-line {
      width: 4px;
      background: #161E30;
      opacity: 0.04;
      height: 80px;
    }
  }
  .tr.one {
    font-size: 22px;
    color: #2B2B2B;
    .inner-line {
      border-radius: 3px 3px 0 0;
    }
  }
  .tr.two {
    font-size: 14px;
    color: #828181;
    .inner-line {
      border-radius: 0 0 3px 3px;
    }
  }
  .outer-line {
    height: 4px;
    background: #161E30;
    opacity: 0.04;
    border-radius: 3px;
    width: 88%;
    margin:  0 auto;
  }

}
.spacing::before {
  width: 9px;
  height: 10px;
  content: "";
  display: inline-block;
  margin-right: 6px;
  background-image: url("../../assets/site/service/fx-left.png");
  background-position: 0 -5px;
  transform: rotate(180deg);
}
.bread-bg-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  .bread-line {
    width: 100%;
    color: #9a9a9a;
    font-size: 15px;
    border-bottom: 1px solid #ececec;
    .bread-link {
      color: #9a9a9a;
      font-size: 15px;
    }
    .bread-link:hover {
      color: #3d77ff;
    }
    .bread-icon {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
</style>
