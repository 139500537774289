<template>
  <div class="note-outer-box">
    <div v-if="data.length > 0" class="note-lists">
      <div v-for="(item, index) in data" :key="index" class="note-options">
        <p v-if="item.isShowLong" class="note-txt">
          {{item.isShowLong &&  item.isShowFlag ? item.contentFormat : item.content }}
          <span @click="item.isShowFlag = !item.isShowFlag" class="more-btn"><span :class="item.isShowFlag ? 'more-icon up' : 'more-icon down' "></span>{{item.isShowFlag ? '展开' : '收起'}}</span>
        </p>
        <p v-else class="note-txt">
          {{item.content }}
        </p>
        <div class="middle-box">
          <div class="m-item date">{{item.created_at}}</div>
          <div v-if="item.type == 1" class="m-item position">记录位置：{{item.position | formatSeconds}}</div>
          <div v-else class="m-item position">记录位置：{{ item.position }}页</div>
        </div>
        <div class="bottom-class-box">
          <div class="class-label">
            <span>所属课程：</span>
            <a href="javascript:void(0)" @click="classToDetail(item)" class="link">{{item.course_name}}</a>
          </div>
          <div class="class-label second">
            <span>所属章节：</span>
            <a href="javascript:void(0)" @click="chapterToDetail(item)" class="link">{{item.chapter_name}}</a>
          </div>
          <div class="group-box">
            <div class="item edit" @click="editFunc(item)"><img src="../../../assets/site/note/mynote-edit.png" /></div>
            <div class="item" @click="deleteFunc(item)"><img src="../../../assets/site/note/note-del.png" /></div>
          </div>
        </div>
      </div>

    </div>
    <div class="pages" v-if="data.length > 0">
      <a-pagination :defaultPageSize="size" @change="onPageChange" :locale="zhCN" :default-current="1" :total="this.total" :current="currentPage" />
    </div>
    <div v-else class="noData-box">
      <img src="@/assets/empty.png" alt="">
      <div class="text">暂无相关内容</div>
    </div>

    <a-modal title="编辑" :visible="noteVisible">
      <template slot="closeIcon">
        <div class="close-note-btn" @click="closeNoteEdit"><img src="../../../assets/icons-close.png" /></div>
      </template>
      <template slot="footer">
        <div class="submit-note-btn" @click="submitNotes">确定</div>
      </template>
      <div class="areaText">
        <textarea v-model="notesText" @change="notesChangeTxt" placeholder="开始记笔记吧~" :maxlength="noteMaxNum"></textarea>
        <div class="areaNums">( {{notesText.length}}/{{noteMaxNum}} )</div>
        <div class="note-message"><span v-if="showNotesError">{{notesErrorTxt}}</span></div>
      </div>
    </a-modal>

    <a-modal title="删除" :visible="delVisible" :width="400">
      <template slot="closeIcon">
        <div class="close-note-btn" @click="closeNoteDel"><img src="../../../assets/icons-close.png" /></div>
      </template>
      <template slot="footer">
        <button type="button" class="ant-btn cancel-note-btn" @click="closeNoteDel"><span>取 消</span></button>
        <div class="submit-note-btn" @click="rightDelete">确定</div>
      </template>
      <div class="deleteText">
        <span>确定删除笔记吗？</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale-provider/zh_CN";
import { Pagination, Modal, Message } from "ant-design-vue";
import services from "../../../config/services";
// import utils from "../../../config/utils";

export default {
  name: "MyNote",
  components: {
    APagination: Pagination,
    AModal: Modal,
  },
  data () {
    return {
      zhCN,
      data: [],
      total: 0,
      size: 6,
      page: 1,
      currentPage: 1,
      maxNum: 74,
      note_id: '',
      noteVisible: false,
      notesText: '',
      showNotesError: false,
      notesErrorTxt: '',
      noteMaxNum: 1000,
      delVisible: false,
    };
  },
  filters: {
    formatSeconds (value) {
      let result = parseInt(value);
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
      return `${h}:${m}:${s}`;
    },
  },
  mounted () {
    this.$store.commit("setNavTitle", {
      navTitle: "学习中心",
    });
    this.lodData({ page: this.page, size: this.size, })
  },
  methods: {
    editFunc (item) {
      this.note_id = item.id;
      this.notesText = item.content;
      this.noteVisible = true;
    },
    closeNoteEdit () {
      this.noteVisible = false;
      this.notesErrorTxt = '';
      this.notesText = '';
      this.showNotesError = false;
    },
    closeNoteDel (){
      this.delVisible = false
      this.note_id = ''
    },
    // 笔记输入框验证
    notesChangeTxt () {
      if (this.notesText.length > 5) {
        this.showNotesError = false;
        this.notesErrorTxt = '';
      }
    },
    classToDetail (item) {
      if (item.course_status == 2) {
        this.$message.info('课程已下架')
        return false
      } else {
        window.open(window.location.origin + "/school/class/" + item.course_id);
      }
    },
    chapterToDetail (item) {
      // window.open(window.location.origin + "/video/" + item.course_id + "/" + (item.chapter_id ? item.chapter_id : 0) + '?from=' + this.$route.path);
      if (item.course_status == 2) {
        this.$message.info('课程已下架')
        return false
      } else {
        this.$router.push({
          path: "/video/" + item.course_id + "/" + (item.cm_id ? item.cm_id : 0),
          query: {
            type: item.type,
            position: item.position,
          }
        });
      }
    },
    onPageChange (pageNumber) {
      this.lodData({
        page: pageNumber,
        size: this.size,
      });
      this.currentPage = pageNumber;
    },
    lodData (param) {
      services.getUserCourseNote(param).then((res) => {
        if (res.code === "0000") {
          if (res.data.items && res.data.items.length > 0) {
            res.data.items.forEach(elem => {
              if (elem.content && elem.content.length > this.maxNum) {
                elem.isShowLong = true;
                elem.isShowFlag = true;
                elem.contentFormat = elem.content.substring(0, this.maxNum) + '...';
              } else {
                elem.isShowLong = false;
              }
            });
          }
          this.data = res.data.items;
          this.total = res.data.total;
        }
      });
    },
    // 保存笔记事件
    submitNotes () {
      if (!this.notesText || this.notesText.length < 5) {
        this.showNotesError = true;
        this.notesErrorTxt = '请至少输入5个字';
        return false;
      }
      services.editUserCourseNote({
        id: this.note_id,
        data: {
          _method: 'put',
          content: this.notesText,
        }
      }).then((res) => {
        this.notesAfterM(res);
      })
    },
    // 笔记保存请求结果后统一处理
    notesAfterM (res) {
      if (res.code === '0000') {
        Message.success("保存成功");
        this.page = 1;
        this.lodData({ page: this.page, size: this.size, })
      } else {
        Message.error(res.message);
      }
      this.closeNoteEdit();
    },
    // 点击删除笔记按钮
    deleteFunc (item) {
      var that = this;
      this.delVisible = true
      this.note_id = item.id
    },
    // 删除笔记事件
    rightDelete(){
      let that = this
      services.editUserCourseNote({
        id: this.note_id,
        data: {
          _method: 'delete',
        }
      }).then((res) => {
        that.closeNoteDel()
        if (res.code == '0000') {
          Message.success("删除成功");
          that.page = 1;
          that.lodData({ page: that.page, size: that.size })
        } else {
          Message.error(res.message);
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.note-outer-box {
  padding: 30px 20px 0;
}
.note-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
.note-options {
  flex: 0 0 49%;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  height: max-content;
}
.note-txt {
  background: #f1f2f5;
  border-radius: 4px;
  padding: 15px 10px;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  // min-height: 96px;
  // max-height: 170px;
  height: 128px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow: auto;
  .more-btn {
    cursor: pointer;
    color: #2462f5;
    font-size: 12px;
    .more-icon {
      margin: 0 5px;
      display: inline-block;
      width: 8px;
      height: 8px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      &.up {
        background-image: url('../../../assets/site/note/icon-down.png');
      }
      &.down {
        background-image: url('../../../assets/site/note/icon-up.png');
      }
    }
  }
}
.middle-box {
  display: flex;
  padding: 20px 0 18px;
  border-bottom: 2px solid #f3f4f9;
  .m-item {
    flex: 1;
    font-size: 13px;
    color: #aaaaaa;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      width: 14px;
      height: 14px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      vertical-align: -1px;
    }
    &.date::before {
      background-image: url('../../../assets/site/note/icon-date.png');
    }
    &.position::before {
      text-align: right;
      background-image: url('../../../assets/site/note/icon-position.png');
    }
    &.position {
      text-align: right;
    }
  }
}
.bottom-class-box {
  padding: 20px 0 0;
  position: relative;
  .class-label {
    display: block;
    margin-bottom: 10px;
    color: #969696;
    line-height: 24px;
    color: 14px;
    &.second {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 82%;
      height: 24px;
    }
    .link {
      color: #2462f5;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .group-box {
    display: block;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    .item {
      width: 18px;
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
      &.edit {
        width: 16px;
        vertical-align: -1px;
      }
    }
  }
}
.noData-box {
  width: 100%;
  text-align: center;
  height: 770px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
  img {
    width: 240px;
    height: auto;
    margin: 0 auto 30px;
  }
  .text {
    color: #76797e;
    font-size: 14px;
  }
}
.areaText {
  width: 100%;
  position: relative;
  background: #f1f2f5;
  // textarea::-webkit-scrollbar {
  //   display: none; /* Chrome Safari */
  // }
  textarea {
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    line-height: 22px;
    background: #f1f2f5;
    border: none;
    display: block;
    width: 100%;
    color: #333333;
    height: 112px;
    resize: none;
    box-sizing: border-box;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .areaNums {
    color: #333333;
    font-size: 12px;
    text-align: right;
    width: 100%;
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .note-message {
    color: #df453f;
    font-size: 13px;
    position: absolute;
    left: 10px;
    bottom: -24px;
  }
}
.submit-note-btn {
  width: 69px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ecedff;
  font-size: 14px;
  background: #2462f5;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto 20px;
}
.cancel-note-btn {
  margin: 0 20px 20px 0;
  height: 32px;
  line-height: 32px;
  &:hover, &:focus {
    color: #2462f5;
    background-color: #fff;
    border-color: #2462f5;
  }
}
.close-note-btn {
  width: 18px;
  margin-top: 13px;
  img {
    width: 100%;
    height: auto;
  }
}
.deleteText {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
</style>