import Vue from 'vue'
import services from '../config/services'
import utils from '../config/utils'

export default {
  state: {
    imageCode: {
      key: '',
      img: '',
    },
    appsUserInfo: null, // 登录后用户信息
    navTitle: '学习中心',
    satisfiedInfo: {}
  },
  getters: {
  },
  actions: {
    // 获取图片验证码
    getAppsImageCaptcha(context, payload) {
      return services.getAppsImageCaptcha(payload).then(res => {
        // console.log(res);
        if (res.code == '0000') {
          context.commit('setAppsImageCode', {
            'imageCode': res.data
          })
        }
        return res;
      })
    },
    // 获取手机短信验证码
    getAppsSmsCaptcha (context, payload) {
      return services.getAppsSmsCaptcha(payload).then(res => {
        return res;
      })
    },
    // 登录
    requestAppsLoginApps(context, payload) {
      return services.postAppsLogin(payload).then(res => {
        if (res.code == '0000') {
          localStorage.setItem("appsToken", res.data.token)
        }
        return res;
      })
    },
    // 获取用户信息
    getAppsLoginUserInfo(context, payload) {
      return services.getAppsUserInfo({
        is_append: 1,
        is_school: 2,
      }).then(res => {
        if (res.code == '0000') {
          if(res.data){
            utils.setUserInfo(res.data)
            context.commit('setAppsLoginUserInfo', {
              'userInfo': res.data ? res.data : {}
            })
          }
        }
        return res;
      })
    },
    // 修改密码、设置新密码
    requestAppsEditPassword(context, payload) {
      return services.postAppsEditPassword(payload).then(res => {
        return res;
      })
    },
    // 重置密码
    requestAppsResetPassword(context, payload) {
      return services.postAppsResetPassword(payload).then(res => {
        return res;
      })
    },
    // 官网注册
    requestAppsRegister(context, payload) {
      return services.postAppsRegister(payload).then(res => {
        if (res.code == '0000') {
          localStorage.setItem("appsToken", res.data.token)
        }
        return res;
      })
    },
    // 退出登录
    requestAppsLogout(context, payload){
      return services.postAppsLogout(payload).then(res => {
        if(res.code == '0000'){
          utils.removeUserInfo()
          context.commit('setAppsLoginUserInfo', {
            'userInfo': null
          })
        }
        return res;
      })
    },
    // 绑定/换绑手机号
    requestUpdateUserPhone(context, payload) {
      return services.updateUserPhone(payload).then(res => {
        return res;
      })
    },
    // 解绑微信
    requestWechatUnbind(context, payload) {
      return services.getWechatUnbind(payload).then(res => {
        return res;
      })
    },
    getSatisfiedInfo (context,payload) {
      return services.getSatisfied(payload).then(res => {
        if(res.code == "0000") {
          context.commit("setSatisfiedInfo",res.data )
        }
      })
    }
  },
  mutations: {
    setAppsImageCode(state, payload) {
      state.imageCode = payload.imageCode;
    },
    setAppsLoginUserInfo (state, payload) {
      state.appsUserInfo = payload.userInfo;
    },
    setNavTitle(state, payload) {
      Vue.set(state, "navTitle", payload.navTitle)
    },
    setSatisfiedInfo(state,payload) {
      state.satisfiedInfo = payload
    }
  }
}