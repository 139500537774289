import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import VueCookies from 'vue-cookies';
import utils from './config/utils.js';

import HomeIndex from './Index.vue'
import Home from './pages/home/Home.vue'
import User from './pages/user/User.vue'
import UpdatePassword from './pages/user/model/UpdatePassword.vue'
import UserMessage from './pages/user/model/UserMessage.vue'
import StudyRecord from './pages/user/model/StudyRecord.vue'
import MyClass from './pages/user/model/MyClass.vue'
import myNote from './pages/user/model/myNote.vue'
import Class from './pages/class/Class.vue'
import Class404 from './pages/class/Class404.vue'
import Home404 from './pages/home/Home404.vue'
import ClassDetail from './pages/class/ClassDetail.vue'
import ClassVideo from './pages/class/ClassVideo.vue'
import Page404 from './components/Page404.vue'
import NoAuth from './components/NoAuth.vue'
import Error from './components/Error.vue'
import Media from './pages/media/Media.vue'
import MediaDetails from './pages/media/Details.vue'
import Need from './pages/media/Need.vue'
import Service from './pages/service/Service.vue'
import Study from './pages/service/Study.vue'
import Teaching from './pages/service/Teaching.vue'
import Training from './pages/service/Training.vue'
import Thought from './pages/thought/Thought.vue'
import thoughtParty from './pages/thoughtParty/thoughtParty.vue'
import thoughtDetails from './pages/thoughtParty/thoughtDetails.vue'
import education from './pages/education/education.vue'

import Register from './pages/login/Register.vue'
import ResetPassword from './pages/login/ResetPassword.vue'
import Login from './pages/login/Login.vue'
import Policy from './pages/login/Policy.vue'
import WechatSuccess from './pages/wechat/success.vue'
import WechatError from './pages/wechat/error.vue'

import zjiet from './pages/school/zjiet/home/zjiet.vue'
import course from './pages/school/zjiet/course/course.vue'
import list from './pages/school/zjiet/List/list.vue'
import detail from './pages/school/zjiet/detail/detail.vue'

import sdcmc from './pages/school/sdcmc/home/sdcmc.vue'
import sdcmcCourse from './pages/school/sdcmc/course/sdcmcCourse.vue'
import sdcmcList from './pages/school/sdcmc/List/sdcmcList.vue'
import sdcmcDetail from './pages/school/sdcmc/detail/sdcmcDetail.vue'

import zbhf from "./pages/zbhf/index.vue"
import createFarm from "./pages/createFarm/createFarm.vue"
Vue.use(VueRouter)
var staticRoutes = [
  {
    path: "/",
    name: "首页",
    component: Home,
  },
  {
    // path: '/school/:id',
    path: '/school',
    // name:'HomeIndex',
    component: HomeIndex,
    children: [
      {
        path: 'class',
        name: '课程资源',
        component: Class
      },
      {
        path: 'class/:classId',
        name: '课程查看',
        component: ClassDetail
      },
    ]
  },
  {
    path: '/class/notfound',
    name: 'Class404',
    component: Class404
  },
  {
    path: '/school/notfound',
    name: 'Home404',
    component: Home404
  },
  {
    path: '/school/Page404',
    name: '页面无权限',
    component: NoAuth,
  },
  {
    path: '/school/Error',
    name: 'Error',
    component: Error,
  },
  {
    path: '/video/:classId/:videoId',
    name: '课程播放',
    component: ClassVideo
  },
  {
    path: '/user',
    name: '个人中心',
    component: User,
    children: [
      {
        path: 'updatePassword',
        name: '修改密码',
        component: UpdatePassword
      },
      {
        path: 'userMessage',
        name: '个人信息',
        component: UserMessage
      },
      {
        path: 'userMessage/modifyPhone',
        name: '修改手机号',
        component: UserMessage
      },
      {
        path: 'studyRecord',
        name: '学习记录',
        component: StudyRecord
      },
      {
        path: 'myClass',
        name: '我的课程',
        component: MyClass
      },
      {
        path: 'center',
        redirect: '/user/myClass'
      },
      {
        path: 'myNote',
        name: '我的笔记',
        component: myNote,
      },
    ]
  },
  {
    path: '*',
    name: '页面404',
    component: Page404,
  },
  {
    path: "/media",
    name: "融媒智库",
    component: Media,
  },
  {
    path: "/media/detail",
    name: "融媒智库详情",
    component: MediaDetails,
  },
  {
    path: "/need",
    name: "免费试用信息收集",
    component: Need,
  },
  {
    path: "/service",
    name: "院校服务",
    component: Service,
  },
  {
    path: "/service/study",
    name: "在线学习平台",
    component: Study,
  },
  {
    path: "/service/teaching",
    name: "教学资源",
    component: Teaching,
  },
  {
    path: "/service/training",
    name: "融媒体运营实训平台",
    component: Training,
  },
  {
    path: "/thought",
    name: "党建思政",
    component: Thought,
  },
  {
    path: "/djsz",
    name: "思政资源库",
    component: thoughtParty
  },
  {
    path: "/djsz/thoughtDetails",
    name: "思政资源库详情",
    component: thoughtDetails
  },
  {
    path: "/k12pj",
    name: "K12教育专题",
    component: education
  },
  {
    path: '/login',
    name: '登录',
    component: Login,
  },
  {
    path: '/register',
    name: '注册',
    component: Register,
  },
  {
    path: '/resetPassword',
    name: '重置密码',
    component: ResetPassword,
  },
  {
    path: '/policy',
    name: '服务协议和隐私政策',
    component: Policy,
  },
  {
    path: '/weSuccess',
    name: 'WechatSuccess',
    component: WechatSuccess
  },
  {
    path: '/weError',
    name: 'WechatError',
    component: WechatError
  },
  {
    path: '/createFarm',
    name: 'createFarm',
    component: createFarm
  },
  // 浙江经贸学院
  {
    path: '/school/zjiet',
    name: 'zjiet',
    component: zjiet,
  },
  {
    path: '/school/zjiet/course',
    name: 'zjietcourse',
    component: course,
  },
  {
    path: '/school/zjiet/list/:id',
    name: 'zjietlist',
    component: list,
  },
  {
    path: '/school/zjiet/detail/:id',
    name: 'zjietdetail',
    component: detail,
  },
  //山东传媒职业学院 
  {
    path: '/school/' + utils.sdcmzyxyCode,
    name: 'sdcmc',
    component: sdcmc,
  },
  {
    path: `/school/${utils.sdcmzyxyCode}/course`,
    name: 'sdcmcCourse',
    component: sdcmcCourse,
  },
  {
    path: `/school/${utils.sdcmzyxyCode}/list/:id`,
    name: 'sdcmcList',
    component: sdcmcList,
  },
  {
    path: `/school/${utils.sdcmzyxyCode}/detail/:id`,
    name: 'sdcmcDetail',
    component: sdcmcDetail,
  },
  // {
  //   path: '/zbhf',
  //   name: "直播回放",
  //   component: zbhf
  // }
];

const newRoutes = [...staticRoutes];
let router = new VueRouter({
  mode: 'history',
  routes: newRoutes,
})

router.beforeEach((to, from, next) => {
  utils.setTitle(to.name);
  if (!utils.whiteList.includes(to.path)) {
    if (to.path.indexOf('/user') === -1) {
      // chrome
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    }


    let pathname = to.path;
    let appsToken = VueCookies.get("api_token");
    if (utils.blackList.includes(pathname) || pathname.indexOf('/video/') > -1) {
      if (appsToken) {
        store.dispatch('getAppsLoginUserInfo', {}).then(res => {
          if (res.code == '0000') {
            next()
          } else if (res.code == '2001') {
            next("/login")
          }
        })
      } else {
        next("/login")
      }
    } else {
      next()
    }
  } else {
    next()
  }
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  next();
});
export default router