<template>
  <div>
    <Nav />
    <div class="long-bg-box">
      <div class="c flex-box">
        <div class="left-swiper-box h" @mouseenter="swiperEnter" @mouseleave="swiperLeave">
          
            <swiper v-show="rmggwArr.length > 0" ref="mySwiper" :options="swiperOption" >
              <template v-for="(banner, index) in rmggwArr">
                <swiper-slide v-if="index < 3" :key="index">
                  <div class="img-con">
                    <a :href="banner.url" target="_blank">
                      <img class="swiperImg" :src="banner.cover_url" />
                      <div class="mask-content">
                        <div class="shadow"></div>
                        <span>{{banner.title}}</span>
                      </div>
                    </a>

                  </div>
                </swiper-slide>
              </template>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          <!-- 骨架屏1 -->
          <div v-show="rmggwArr.length < 1" class="swiper-frame">
            <div class="frame-line">
              <div class="right-icon">
                <template v-for="(item, index) in frameArr">
                  <span v-if="index < 3" :key="index"></span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="middle-box">
          <template v-if="rmggwArr.length > 0">
            <template v-for="(banner, index) in rmggwArr">
              <a v-if="index > 2 && index < 5" :href="banner.url" :key="index" target="_blank">
                <img :src="banner.cover_url" />
              </a>
            </template>
          </template>
          <!-- 骨架屏2 -->
          <template v-else>
            <template v-for="(item, index) in frameArr">
              <div v-if="index < 2" :key="index" class="middle-item"></div>
            </template>
          </template>
        </div>
        <div class="right-box">
          <div class="top-title-box">
            <div class="label active">融媒知识库</div>
            <!-- <div class="more">更多 ></div> -->
            <router-link class="more" :to="{path: '/media', query: {type: 'rmzsk'}}">更多 ></router-link>
          </div>
          <div class="knowledge-box">
            <template v-if="rmzskArr.length > 0">
              <template v-for="(item, index) in rmzskArr">
                <router-link target="_blank" :key="index" class="knowledge-item" :to="{path: '/media/detail', query: {url: item.url, type: 'rmzsk', id: item.id}}">
                  <div class="know-title">{{item.title}}</div>
                  <div v-html="item.description" class="know-desc"></div>
                </router-link>
              </template>
            </template>
            <!-- 骨架屏3 -->
            <template v-else>
              <template v-for="(item, index) in frameArr">
                <div v-if="index < 3" :key="index" class="knowledge-frame-item">
                  <div class="frame-one"></div>
                  <div class="frame-two"></div>
                  <div class="frame-three"></div>
                </div>
              </template>
            </template>
          </div>

        </div>
      </div>
    </div>

    <!-- 职教动态 媒体快讯 党建思政 -->
    <div class="second-news-box">
      <div class="c flex-box">
        <div class="left-news-box">
          <div class="top-title-box">
            <div @click="clickSecond('zjdt')" :class="currentType == 'zjdt' ?'label active' : 'label'">职教动态</div>
            <div @click="clickSecond('mtkx')" :class="currentType == 'mtkx' ?'label active' : 'label'">媒体快讯</div>
            <!-- <div class="more">更多 ></div> -->
            <router-link target="_blank" class="more" :to="{path: '/media', query: {type: currentType}}">更多 ></router-link>
          </div>
          <div class="new-all-box">
            <template v-if="currentType == 'zjdt'">
              <div v-for="(item, index) in zjdtArr" :key="index" @click="goMediaDetails('zjdt', item.url, item.id)" class="new-options">
                <div class="imgs-fixed">
                  <img :src="item.cover_url" />
                </div>
                <div class="right-fixed">
                  <div class="title-line">{{item.title}}</div>
                  <div v-html="item.description" class="desc-line">突出思想引领、阵地管理、责任落实，坚守好</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="(item, index) in mtkxArr" :key="index" @click="goMediaDetails('mtkx', item.url, item.id)" class="new-options">
                <div class="imgs-fixed">
                  <img :src="item.cover_url" />
                </div>
                <div class="right-fixed">
                  <div class="title-line">{{item.title}}</div>
                  <div v-html="item.description" class="desc-line">突出思想引领、阵地管理、责任落实，坚守好</div>
                </div>
              </div>
            </template>
            <!-- 骨架屏4 -->
            <template v-if="mtkxArr.legnth < 1 || zjdtArr.length < 1">
              <template v-for="(item, index) in frameArr">
                <div v-if="index < 4" :key="index" class="news-frame">
                  <div class="imgs-fixed"></div>
                  <div class="right-fixed">
                    <div class="frame-one"></div>
                    <div class="frame-two"></div>
                    <div class="frame-three"></div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="right-dang-box">
          <div class="top-title-box">
            <div class="label active">党建思政</div>
            <!-- <div class="more">更多 ></div> -->
            <router-link target="_blank" class="more" :to="{path: '/media', query: {type: 'yxtdjsz'}}">更多 ></router-link>
          </div>
          <div class="dang-img-box">
            <template v-if="yxtdjszArr.length">
              <router-link v-for="(item, index) in yxtdjszArr" :key="index" class="knowledge-item" :to="{path: '/media/detail', query: {url: item.url, id: item.id, type: 'yxtdjsz'}}">
                <img class="dangImg" :src="item.cover_url" />
                <div class="mask-content">
                  <div class="shadow"></div>
                  <span>{{item.title}}</span>
                </div>
              </router-link>
            </template>
            <!-- 骨架屏5 -->
            <div v-else class="swiper-frame second">
              <div class="frame-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 课程资源 -->
    <div v-if="courseYxtData && courseYxtData.length > 0" class="classis-all-box">
      <div class="c">
        <div class="top-title-box">
          <div class="label active">课程资源</div>
          <!-- <div class="more">更多 ></div> -->
          <router-link target="_blank" class="more" :to="'/school/class'">更多 ></router-link>
        </div>
        <div class="class-lists">
          <div class="class-box" v-for="item in courseYxtData" :key="item.course_id">
            <router-link target="_blank" class="link-box" :to="'/school/class/' + item.id" exact>
              <div class="img">
                <img :src="item.list_photo" alt />
              </div>
              <div class="txt">
                <div class="title">{{ item.name }}</div>
                <div class="num">
                  <!-- <span class="iconfont iconduoren"></span> -->
                  <div class="courseFont times"></div>
                  课程总时长：{{ item.total_time }}
                </div>
              </div>
            </router-link>
          </div>
          <div class="sub-visibility"></div>
          <div class="sub-visibility"></div>
          <div class="sub-visibility"></div>
        </div>
      </div>
    </div>

    <!-- 院校服务 -->
    <div class="college-service-box">
      <div class="c">
        <div class="top-title-box">
          <div class="label active">院校服务</div>
          <router-link target="_blank" class="more" :to="'/service'">更多 ></router-link>
        </div>
        <div class="panel-box">
          <div @mouseenter="hoverPanel(1)" :class='isPanel == 1? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <img src="../../assets/site/home/ring-icon.png" />
              <span class="label">教学资源</span>
              <div class="three-box">
                <span class="ring-box one"></span>
                <span class="ring-box two"></span>
                <span class="ring-box three"></span>
              </div>
            </div>
            <div class="card-body">
              <div class="card-desc">针对核心职业技能素养教育、紧贴不同层次院校<br />教学特点的完整教材体系。</div>
              <div class="card-bottom">
                <div class="more-btn" @click="redirectFunc('/service/teaching');">了解更多</div>
                <img class="intro-imgs" style="margin-top: -26px;" src="../../assets/site/home/panel-1.png" />
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(1)" :class='isPanel == 1? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">教学资源</div>
            <div class="panel-txt">针对核心职业技能素养教育、紧贴不同层次院校教学...</div>
            <img class="panel-img" src="../../assets/site/home/panel-1.png" />
          </div>

          <div @mouseenter="hoverPanel(2)" :class='isPanel == 2? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <img src="../../assets/site/home/ring-icon.png" />
              <span class="label">在线学习平台</span>
              <div class="three-box">
                <span class="ring-box one"></span>
                <span class="ring-box two"></span>
                <span class="ring-box three"></span>
              </div>
            </div>
            <div class="card-body">
              <div class="card-desc">多端课程学习，多种模拟练习，为教师、学生<br />提供集内容、平台、服务一体化的混合式教学<br />服务平台。</div>
              <div class="card-bottom">
                <div class="more-btn" @click="redirectFunc('/service/study');">了解更多</div>
                <img class="intro-imgs" src="../../assets/site/home/panel-2.png" />
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(2)" :class='isPanel == 2? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">在线学习平台</div>
            <div class="panel-txt">多端课程学习，多种模拟练习，为教师、学生提供集...</div>
            <img class="panel-img" src="../../assets/site/home/panel-2.png" />
          </div>

          <div @mouseenter="hoverPanel(3)" :class='isPanel == 3? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <img src="../../assets/site/home/ring-icon.png" />
              <span class="label">全媒体人才培养实训平台</span>
              <div class="three-box">
                <span class="ring-box one"></span>
                <span class="ring-box two"></span>
                <span class="ring-box three"></span>
              </div>
            </div>
            <div class="card-body">
              <div class="card-desc">围绕企业工作任务，实训教学全流程场景化，<br />全面仿真融媒运营的各个过程。</div>
              <div class="card-bottom">
                <div class="more-btn" @click="redirectFunc('/service/training');">了解更多</div>
                <img class="intro-imgs" src="../../assets/site/home/panel-3.png" />
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(3)" :class='isPanel == 3? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">全媒体人才培养实训平台</div>
            <div class="panel-txt">围绕企业工作任务，实训教学全流程场景化，全面...</div>
            <img class="panel-img" src="../../assets/site/home/panel-3.png" />
          </div>

        </div>

      </div>
    </div>

    <!-- 联系我们 -->
    <div class="contact-us-box">
      <img class="pos-img-left" src="../../assets/site/home/result-left.png" />
      <img class="pos-img-right" src="../../assets/site/home/result-right.png" />
      <div class="c">
        <div class="contact-text">联系我们，获取更详细服务方案！</div>
        <div class="contact-btn" @click="goToNeed">立即咨询</div>
      </div>
    </div>

    <Aside />
    <Footer />
  </div>
</template>

<script>
import "swiper/swiper.less";
import "./home.less";
import '../../assets/css/frame.less'
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside"
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default {
  name: "Home",
  components: {
    Aside,
    Footer,
    Nav,
  },
  data () {
    return {
      frameArr: [1, 2, 3, 4], // 骨架屏数组
      services: services,
      isPanel: 1,
      swiperOption: {
        autoplay: {
          pauseOnMouseEnter: true,
        },
        loop: true,
        autoHeight: true,
        speed: 400, // 切换图片速度
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // type: "bullets"
        },
        // 一个屏幕展示的数量
        slidesPerView: 1,
        // 间距
        // spaceBetween: 26,
        // 修改swiper自己或子元素时，自动初始化swiper
        observer: true,
        // 修改swiper的父元素时，自动初始化swiper
        observeParents: true,
      },
      yxtdjszArr: [],
      mtkxArr: [],
      rmzskArr: [],
      zjdtArr: [],
      rmggwArr: [],
      // school_id: '',
      currentType: 'zjdt',
    };
  },
  mounted () {
    // if(localStorage.getItem('schoolid')){
    //   this.school_id = localStorage.getItem('schoolid');
    // } else {
    //   let info = JSON.parse(localStorage.getItem("userInfo"));
    //   this.school_id = info.school_code;
    // }
    this.$store.dispatch("getHomeCourseData", {page: 1, size: 10});

    this.getMethodMedia("rmzsk", 3);
    this.getMethodMedia("yxtdjsz", 1);
    this.getMethodMedia("zjdt", 4);
    this.getMethodMedia("mtkx", 4);
    this.getMethodMedia("rmggw", 5);
  },
  methods: {
    swiperEnter () {
      this.mySwiper.autoplay.stop();
    },
    swiperLeave () {
      this.mySwiper.autoplay.start();
    },
    hoverPanel (type) {
      this.isPanel = type;
    },
    // 首页获取融媒体数据
    getMethodMedia (type, num) {
      let params = {
        page: 1,
        length: 20,
        order_by: 'weight desc,publish_time desc',
        sub_type: 1, //排除没有图片的资源 
      }
      if (type == "rmzsk") {
        delete params.sub_type
      }
      this.$store.dispatch("loadQmtyyNewsList", {
        type: type,
        data: params
      }).then((resData) => {
        if (resData.data && resData.data.items) {
          if (type == 'mtkx') {
            this.mtkxArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.mtkxArr.length = num;
            }
          }
          if (type == "rmzsk") {
            this.rmzskArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.rmzskArr.length = num;
            }
          }
          if (type == "zjdt") {
            this.zjdtArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.zjdtArr.length = num;
            }
          }
          if (type == "yxtdjsz") {
            this.yxtdjszArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.yxtdjszArr.length = num;
            }
          }
          if (type == "rmggw") {
            this.rmggwArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.rmggwArr.length = num;
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 跳转融媒体新闻详情页
    goMediaDetails (type, url, id) {
      // this.$router.push({path: '/media/detail', query: {url: url, type: type}});
      let routeUrl = this.$router.resolve({ path: '/media/detail', query: { type: type, id: id } });
      window.open(routeUrl.href, '_blank');
    },
    clickSecond (type) {
      this.currentType = type;
    },
    goToNeed () {
      utils.redirect(this, "/need", true);
    },
    redirectFunc (name) {
      utils.redirect(this, name, true);
    }
  },
  computed: {
    mySwiper () {
      return this.$refs.mySwiper.swiper;
    },
    courseYxtData () {
      return this.$store.state.home.courseYxtData;
    }
  },
};
</script>

<style scoped>
.swiper-pagination-bullet-active {
  background: #fff;
}
</style>