<template>
  <div class="bg-all-box">
    <Nav />
    <div class="service-all-box">
      <div class="service-desc-bg">
        <img src="../../assets/site/study/study-bg.jpg" />
      </div>
      <div class="second-box">
        <div class="bread-bg-box">
          <div class="c">
            <div class="bread-line">
              <router-link class="bread-link" to="/service" exact>院校服务</router-link>
              <span class="bread-icon">></span>
              <a class="bread-link">在线学习平台</a>
            </div>
          </div>
        </div>
        <div class="charter-lines">
          <img class="fx-left" src="../../assets/site/service/fx-left.png" />
          <span>在线课程学习</span>
          <img class="fx-right" src="../../assets/site/service/fx-right.png" />
        </div>
        
        <div class="c">
          <div class="study-desc-box">
            <div class="study-items">
              <div class="top-box">
                <div>多端学习</div>
                <img src="../../assets/site/study/icon-client.png" />
              </div>
              <div class="bottom-text">突破环境限制，微信网校，电脑端网校随意切换，跨终端学习模式充分利用碎片化时间。</div>
            </div>
            <div class="split-line"></div>
            <div class="study-items">
              <div class="top-box">
                <div>学习记录</div>
                <img src="../../assets/site/study/icon-record.png" />
              </div>
              <div class="bottom-text">学习记录实时生成更新，学生的成长有迹可循。</div>
            </div>
            <div class="split-line"></div>
            <div class="study-items">
              <div class="top-box">
                <div>课程笔记</div>
                <img src="../../assets/site/study/icon-book.png" />
              </div>
              <div class="bottom-text">学习过程中随时记录笔记，辅助学生更好掌握课程内容。</div>
            </div>
            <div class="split-line"></div>
            <div class="study-items">
              <div class="top-box">
                <div>互动学习</div>
                <img src="../../assets/site/study/icon-io.png" />
              </div>
              <div class="bottom-text">班级讨论、互动话题、课程评价多种互动模式让学习不再枯燥，形成良好学习氛围，增加全员互动性积极性。</div>
            </div>

          </div>

        </div>
      </div>

      <div class="last-con-box">
        <div class="c">
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/service/fx-left.png" />
            <span>题库/测评</span>
            <img class="fx-right" src="../../assets/site/service/fx-right.png" />
          </div>
          
          <div class="evaluation-box">
            <img class="eval-imgs" src="../../assets/site/study/study-test-img.png" />
            <div class="eval-list-box">
              <div class="eval-item">
                <div class="label-box"><span class="nums">01</span><span class="text">智能题库</span></div>
                <p class="brief">单选、多选、判断、问答等多题型混合题干、选项多媒体编辑。</p>
              </div>
              <div class="eval-item">
                <div class="label-box"><span class="nums">02</span><span class="text">随堂练习</span></div>
                <p class="brief">基于智能题库，一键分发随堂练习，一课一练，助力学生更好的查漏补缺。</p>
              </div>
              <div class="eval-item">
                <div class="label-box"><span class="nums">03</span><span class="text">智能组卷</span></div>
                <p class="brief">多样的组卷形式，系统随机组卷、人工组卷，帮教师减负增效。</p>
              </div>
              <div class="eval-item">
                <div class="label-box"><span class="nums">04</span><span class="text">智能阅卷</span></div>
                <p class="brief">客观题系统评阅，主观题支持多种阅卷模式；学生自评为教师减负增效。</p>
              </div>
              <div class="eval-item">
                <div class="label-box"><span class="nums">05</span><span class="text">模拟测评</span></div>
                <p class="brief">贴合考试场景，精准高效评判学习成果。</p>
              </div>
              <div class="eval-item">
                <div class="label-box"><span class="nums">06</span><span class="text">测评统计</span></div>
                <p class="brief">测评数据助力多维度掌握学习成果。</p>
              </div>

            </div>
          </div>
          

          
        </div>

      </div>
      <!-- 联系我们 -->
      <div class="contact-us-box">
        <img class="pos-img-left" src="../../assets/site/home/result-left.png" />
        <img class="pos-img-right" src="../../assets/site/home/result-right.png" />
        <div class="c">
          <div class="contact-text">联系我们，获取更详细服务方案！</div>
          <div class="contact-btn" @click="goToNeed">立即咨询</div>
        </div>
      </div>
    </div>
    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "../home/home.less";

export default {
  name: "Study",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      services: services,
    }
  },
  mounted() { 
  },
  methods: {
    goToNeed(){
      utils.redirect(this, "/need", false);
    }
  }
}
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.service-desc-bg {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.second-box {
  width: 100%;
  padding: 40px 0 60px;
  background-color: #FFFFFF;
}
.charter-lines {
  font-size: 30px;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 50px;
  span {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
  }
  .fx-right, .fx-left {
    display: inline-block;
    vertical-align: middle;
  }
}
.study-desc-box {
  text-align: center;
  .study-items {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    text-align: center;
    .top-box {
      text-align: left;
      margin-bottom: 20px;
      div {
        font-size: 20px;
        font-weight: bold;
        color: #2B2B2B;
        display: inline-block;
        position: relative;
        z-index: 1;
        margin-top: 10px;
        padding-left: 10px;
      }
      div::after {
        content: "";
        display: inline-block;
        width: 100px;
        height: 16px;
        background: #C8D9FF;
        border-radius: 6px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      img {
        display: inline-block;
        margin-left: 50px;
      }
    }
  }
  .split-line {
    width: 4px;
    height: 172px;
    background: #161E30;
    opacity: 0.04;
    border-radius: 2px;
    display: inline-block;
    margin: 0 25px;
  }
}

.evaluation-box {
  text-align: center;
  padding-bottom: 50px;
  .eval-imgs {
    margin: 30px 100px 0 0;
    display: inline-block;
  }
  .eval-list-box {
    display: inline-block;
    width: 670px;
    .eval-item:nth-child(odd) {
      margin-right: 60px;
    }
    .eval-item {
      display: inline-block;
      width: 300px;
      margin-bottom: 30px;
      text-align: left;
      .label-box {
        margin-bottom: 10px;
        .nums {
          font-size: 30px;
          font-weight: bold;
          color: #BBD0FF;
          margin-right: 15px;
          vertical-align: middle;
        }
        .text {
          font-size: 20px;
          font-weight: bold;
          vertical-align: middle;
          color: #233359;
        }
      }
      .brief {
        font-size: 16px;
        color: #656565;
        line-height: 26px;
      }
    }
  }
}
.bread-bg-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  .bread-line {
    width: 100%;
    color: #9a9a9a;
    font-size: 15px;
    border-bottom: 1px solid #ececec;
    .bread-link {
      color: #9a9a9a;
      font-size: 15px;
    }
    .bread-link:hover {
      color: #3d77ff;
    }
    .bread-icon {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
</style>
