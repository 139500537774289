<template>
  <div class="content-box">
    <div class="content-inner">
      <div class="tips-box">
        <div class="left-imgs">
          <img src="@/assets/success.png" />
        </div>
        <div class="right-text">
          <div class="top-label">授权成功</div>
          <div v-if="loginUserInfo && loginUserInfo.id == 0" class="little-txt">您已登录成功，请绑定账号</div>
          <div v-else class="little-txt">{{showText}}</div>
        </div>
      </div>

      <div v-if="loginUserInfo && loginUserInfo.id == 0" class="bindAccount-box">
        <a-form :form="accountForm" :destroyOnClose="true" class="login-form" @submit="handleAccountSubmit">
          <a-form-item class="form-item">
            <a-input
              class="input-txt"
              placeholder="请输入账号"
              v-decorator="[
              'username',
              { rules: [{ validator: validateCheckUsername}]},
            ]"
            ></a-input>
          </a-form-item>
          <a-form-item class="form-item">
            <a-input
              class="input-txt"
              type="password"
              placeholder="请输入密码"
              v-decorator="[
              'password',
              { rules: [{ validator: validateCheckPassword}] },
            ]"
            ></a-input>
          </a-form-item>
          <a-form-item class="form-item">
            <div class="flex-codeImg-box">
              <div class="left-input-box">
                <a-input
                  class="input-txt"
                  placeholder="请输入验证码"
                  v-decorator="[
                  'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"
                ></a-input>
              </div>
              <div class="code-img" @click="methodImageCaptcha()">
                <img :src="imageCode.img" />
              </div>
            </div>
          </a-form-item>
          <a-button type="primary" html-type="submit" class="login-btn">登录</a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import services from '../../config/services'
import { mapActions, mapState } from "vuex";
import { decode } from 'js-base64';
import { Form, Input, Button} from "ant-design-vue";
export default {
  name: "WechatSuccess",
  components: {
    AForm: Form,
    AInput: Input,
    AFormItem: Form.Item,
    AButton: Button,
  },
  data() {
    return {
			services,
			imageCode: {
        key: '',
        img: '',
      },
			showText: '',
		};
  },
	computed: {
    ...mapState({
      loginUserInfo: (state) => state.appsLogin.appsUserInfo,
    }),
  },
  beforeCreate() {
    this.accountForm = this.$form.createForm(this);
  },
  created() {
		let backObj = null
		if(this.$route.query.data){
			backObj = JSON.parse(decode(this.$route.query.data)) 
			if(backObj.code == '0000' && backObj.data){
				this.showText = backObj.message
				if(backObj.data.token){
					this.methodLoginUserInfo()
				} else if (backObj.data.back_url){
					window.open(backObj.data.back_url, "_self")
				}
			}
		}
		console.log(backObj)
	},
	methods: {
    ...mapActions(["getAppsImageCaptcha", 'getAppsLoginUserInfo']),
    // 用户名验证
    validateCheckUsername (rule, value, callback) {
      if (value) {
        if (/^[A-Za-z0-9]{6,16}$/.test(value)) {
          callback();
        } else {
          callback("请输入[6-16]位字母或数字组成的账号");
        }
      } else {
        callback("请输入账号");
      }
    },
    // 密码验证
    validateCheckPassword (rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          callback();
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback('请输入密码');
      }
    },
		// 登录后获取用户信息
    methodLoginUserInfo(){
      let that = this
      that.getAppsLoginUserInfo().then(res => {
        if(res.code == '0000' && res.data){
          if(res.data.id == 0) {
						that.methodImageCaptcha()
					} else {
            that.$router.push({path: `/user/center`})
					}
         
        }
      })
    },
		// 请求图片验证码
    methodImageCaptcha (){
      let that = this;
      that.getAppsImageCaptcha().then((res) => {
        if(res.code == '0000'){
          that.imageCode = res.data
        }
      });
    },
     // 提交账号登录表单
    handleAccountSubmit (e) {
      let that = this;
      e.preventDefault();
      this.accountForm.validateFields(["username", "password", "captcha"],(err, values) => {
        if (!err) {
          let param = {...values}
          param.key = that.imageCode.key
          that.methodlogin(param);
        }
      })
    },
		// 提交微信绑定表单事件
		methodlogin(param) {
			let that = this;
      let { setFields } = this.accountForm 
      services.wechatBindAccount(param).then(res => {
        if(res.code == '0000') {
          that.$router.push({path: `/user/center`})
        } else {
          that.methodImageCaptcha()
          if (res.errors) {
            let errors = res.errors
            for(let k in errors){
              setFields({
                [k]: {errors: [new Error(errors[k])]}
              })
            }
          } else {
            that.$message.error(res.message ? res.message : "操作失败");
          }
        }
      })
		},
	},
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.content-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tips-box {
  display: flex;
  align-items: center;
	justify-content: center;
}
.left-imgs {
  width: 120px;
  height: auto;
  margin-right: 20px;
}
.left-imgs img {
  width: 100%;
  height: auto;
}
.right-text {
  text-align: left;
}
.top-label {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 50px;
}
.little-txt {
  font-size: 14px;
  color: #8a8d94;
}
.bindAccount-box {
  width: 320px;
  margin: 0 auto;
  display: block;
  padding: 60px 0 0;
  .login-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #125cfd;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 60px;
    letter-spacing: 20px;
    text-indent: 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #094ee5;
    }
  }
}
</style>

<style lang="less" scoped>
.flex-codeImg-box {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.flex-codeImg-box .left-input-box {
  flex: 1;
}
.flex-codeImg-box .code-img {
  width: 100px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-content: center;
  margin-left: 20px;
  // border-bottom: 1px solid #E5E5E5;
  // padding-bottom: 10px;
  box-sizing: border-box;
}
.flex-codeImg-box .code-img  img {
  width: 100%;
  height: auto;
}
/deep/ .form-content .form-item  .has-error .ant-form-explain, 
/deep/ .form-content .form-item  .has-error .ant-form-split,
/deep/ .form-content .form-item  .has-success .ant-form-explain,
/deep/ .form-content .form-item  .has-success .ant-form-split {
  position: absolute !important;
}
.has-success
.ant-form-item{
  margin-bottom: 0px;
}
.ant-form-item-with-help{
  margin-bottom: 0px;
}
</style>