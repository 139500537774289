<template>
  <div class="bg-all-box">
    <Nav />
    <div class="bread-bg-box">
      <div class="c">
        <div class="bread-line">
          <router-link class="bread-link" to="/" exact>首页</router-link>
          <span class="bread-icon">></span>
          <router-link class="bread-link" to="/media" exact>融媒智库</router-link>
          <span class="bread-icon">></span>
          <a class="bread-link">{{ categoryActive | filterType }}</a>
        </div>
      </div>
    </div>

    <div class="c flex-box">
      <div  class="left-contnet-box">
        <template v-if="newsDetail">
          <div class="article-title">{{newsDetail.title}}</div>
          <div class="author-box">
            <span class="date">{{newsDetail.publish_time}}</span>
            <span>来源：{{newsDetail.source}}</span>
          </div>
          <div class="content-body" v-html="newsDetail.content"></div>
          <!-- <iframe class="iframeBox" id="iframeDetail" ref="iframeDetail" @load="loadFrame" :src="detailSrc" frameborder="0" scrolling="no"></iframe> -->
          <!-- <div class="article-title">北大红楼旧址修缮后将于6月29日对外开放</div>
          <div class="author-box">
            <span class="date">2021年08月20日  18: 30: 59</span> 
            <span>来源：人民教育网</span> 
          </div>
          <div class="content-body">

          </div> -->
        </template>
        <div class="share-box">
          <div class="primary-box">
            <img class="share-icon" src="../../assets/site/wechat.png" />
            <p class="text">分享</p>
            <div class="share-hover-box">
              <div class="big-txt">分享到朋友圈</div>
              <vue-qr :text="qrUrl" :margin="6" :logoSrc="qrLogoSrc" :size="160" colorDark="#000" colorLight="#fff"></vue-qr>
              <div class="little-txt">微信扫描二维码，把网页分享到朋友圈</div>
            </div>
          </div>
        </div>

        <div class="statement-box">
          <p>免责声明：</p>
          <p>① 凡本站注明“稿件来源：中教云课”的所有文字、图片和音视频稿件，版权均属本网所有，任何媒体、网站或个人未经本网协议授权不得转载、链接、转贴或以其他方式复制发表。已经本站协议授权的媒体、网站，在下载使用时必须注明“稿件来源：中教云课”，违者本站将依法追究责任。</p>
          <p>② 本站注明稿件来源为其他媒体的文/图等稿件均为转载稿，本站转载出于非商业性的教育和科研之目的，并不意味着赞同其观点或证实其内容的真实性。如转载稿涉及版权等问题，请作者在两周内速来电或来函联系。</p>
        </div>

      </div>
      <div class="right-aside-box">
        <div class="right-auto-bg">
          <div v-if="recommendArr && recommendArr.length > 0" class="top-title-box">
            <div class="label active">相关推荐</div>
            <!-- <div class="more">更多 ></div> -->
            <router-link class="more" :to="{path: '/media', query: {type: categoryActive}}">更多 ></router-link>
          </div>
          <div v-if="recommendArr && recommendArr.length > 0" class="recommend-box">
            <template v-for="(item, index) in recommendArr">
              <router-link v-if="index < 4" :key="index" :to="{path: '/media/detail', query: {type: categoryActive, id: item.id}}" target="_blank">{{item.title}}</router-link>
              <!-- <a v-if="index < 4" :href="item.url" :key="index" target="_blank">{{item.title}}</a> -->
            </template>
          </div>

          <div class="banner-box">
            <template v-for="(banner, index) in rmggwArr">
              <a v-if="index > 4 && index < 6" :href="banner.url" :key="index" target="_blank">
                <img :src="banner.cover_url" />
              </a>
            </template>
          </div>
          <div class="top-title-box">
            <div class="label active">推荐课程</div>
            <!-- <div class="more">更多 ></div> -->
            <router-link target="_blank" class="more" :to="'/school/class'">更多 ></router-link>
          </div>

          <div class="class-lists">
            <div class="class-box" v-for="item in courseYxtData" :key="item.id">
              <router-link class="link-box" :to="'/school/class/' + item.id" exact target="_blank">
                <div class="img">
                  <img :src="item.list_photo" alt />
                </div>
                <div class="txt">
                  <div class="title">{{ item.name }}</div>
                  <!-- <div class="num">
                    <span class="iconfont iconduoren"></span>
                    {{ item.virtual_num }}人已学
                  </div> -->
                  <div class="num">
                    <!-- <span class="iconfont iconduoren"></span> -->
                    <div class="courseFont times"></div>
                    课程总时长：{{ item.total_time }}
                  </div>
                  <div class="go-btn">Go</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import vueQr from 'vue-qr';
export default {
  name: "MediaDetails",
  components: {
    Aside,
    Nav,
    Footer,
    vueQr,
  },
  data () {
    return {
      services: services,
      course: [],
      detailSrc: "",
      categoryActive: "",
      rmggwArr: [],
      recommendArr: [], // 文章相关链接
      currentId: '', // 文章id
      pageNum: 0, // 文章所处列表的页码
      qrLogoSrc: require('../../assets/site/yxt-small.png'),
    };
  },
  filters: {
    filterType (val) {
      return val == 'mtkx' ? "媒体快讯" : val == 'rmzsk' ? "融媒知识库" : val == 'zjdt' ? "职教动态" : "党建思政"
    }
  },
  computed: {
    qrUrl () {
      let url = window.location.href;
      if (process.env.NODE_ENV == 'development') {
        url = 'http://yxt-gw.43.dev.eol.com.cn' + window.location.pathname + window.location.search;
      }
      return url;
    },
    courseYxtData () {
      return this.$store.state.home.courseYxtData;
    },
    newsDetail () {
      return this.$store.state.media.newsDetail;
    }
  },
  mounted () {
    this.detailSrc = this.$route.query && this.$route.query.url;
    this.categoryActive = this.$route.query && this.$route.query.type;
    this.currentId = this.$route.query && this.$route.query.id;
    this.$store.dispatch("loadMediaDetail", {id: this.currentId});

    this.getMethodMedia("rmggw", 6);
    this.getMethodMedia(this.categoryActive, 8, true);
    this.$store.dispatch("getHomeCourseData", {page: 1, size: 3});
    // window.onload = () => {
    //   // 父级页面发送消息
    //   // document.getElementById('iframeDetail').contentWindow.postMessage(this.postData, '*')
    //   // const oIframe = document.getElementById("iframeDetail");
    //   // console.log(oIframe)
    //   // oIframe.contentWindow.postMessage('getBodyH','https://rmt.eol.cn');
    //   // console.log('send');

    // }
    // function setIframeH (e) {
    //   if (e.data.type == 'rmt') {
    //     document.getElementById("iframeDetail").height = e.data.data;
    //   }
    // }
    // // 父级页面接收消息
    // if (window.addEventListener) {
    //   window.addEventListener('message', function (e) {
    //     setIframeH(e);
    //   }, false)
    // } else {
    //   window.attachEvent('message', function (e) {
    //     setIframeH(e);
    //   }, false)
    // }
    
  },
  methods: {
    loadFrame () {
      const oIframe = document.getElementById("iframeDetail");
      oIframe.contentWindow.postMessage('getBodyH', 'https://rmt.eol.cn');

    },
    // 首页获取融媒体数据
    getMethodMedia (type, num, recommend) {
      this.$store.dispatch("loadQmtyyNewsList", {
        type: type,
        data: {
          page: 1,
          length: 20
        }
      }).then((resData) => {
        if (resData.data && resData.data.items) {
          if (type == "rmggw") {
            this.rmggwArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.rmggwArr.length = num;
            }
          }
          if (recommend) {
            this.recommendArr = resData.data.items;
            if (resData.data.items.length > num) {
              this.recommendArr.length = num;
            }
            this.recommendArr.length > 0 && this.recommendArr.forEach((element, inx) => {
              if (element.id == this.currentId) {
                utils.setTitle(`${element.title}`);
                this.recommendArr.splice(inx, 1);
              }
            });
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
};
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.bread-bg-box {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #f5f6f9;
  .bread-line {
    width: 100%;
    color: #9a9a9a;
    font-size: 15px;
    .bread-link {
      color: #9a9a9a;
      font-size: 15px;
    }
    .bread-link:hover {
      color: #3d77ff;
    }
    .bread-icon {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
.flex-box {
  display: flex;
  padding: 30px 0 100px;
}
.left-contnet-box {
  flex: 1;
  .iframeBox {
    width: 834px;
  }
  .article-title {
    font-size: 30px;
    font-weight: bold;
    color: #3A3A3A;
    text-align: center;
    margin-bottom: 25px;
  }
  .author-box {
    font-size: 14px;
    color: #858484;
    text-align: center;
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 15px;
    .date {
      display: inline-block;
      margin-right: 60px;
    }
  }
  .content-body {
    margin: 50px 0;
    color: #3A3A3A !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    /deep/ p {
      color: #3A3A3A !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 32px !important;
    }
  }
  .content-body p {
    color: #3A3A3A !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }
  .statement-box {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8f8f8f;
    line-height: 24px;
    background: #f5f6f9;
    border-radius: 4px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
}
.right-aside-box {
  width: 306px;
  margin-left: 35px;
  .right-auto-bg {
    background: #f5f6f9;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .banner-box {
    width: 268px;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .recommend-box {
    margin-bottom: 15px;
    width: 100%;
    a {
      font-size: 14px;
      margin-bottom: 15px;
      color: #3a3a3a;
      display: block;
      line-height: 16px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    a:hover {
      color: #3d77ff;
    }
  }
  .top-title-box {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    margin-bottom: 15px;
    .label {
      font-size: 18px;
      font-weight: bold;
      color: #002145;
      display: inline-block;
      margin-right: 20px;
      padding-bottom: 6px;
      border-bottom: 4px solid transparent;
      cursor: pointer;
    }
    .label.active {
      border-bottom: 4px solid #3d77ff;
      border-radius: 2px;
    }
    .more {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 14px;
      color: #9a9a9a;
      cursor: pointer;
    }
  }
}
.class-lists {
  width: 100%;
}
.class-box {
  width: 100%;
  // height: 216px;
  background: #fff;
  border: 1px solid #ececec;
  margin-bottom: 25px;
  border-radius: 8px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt .title {
      color: #364c7e;
    }
  }
  .img {
    height: 150px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  .txt {
    position: relative;
    padding: 15px 12px;
    width: 100%;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #3a3a3a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      font-size: 12px;
      color: #959595;
      margin-top: 2px;
      .iconduoren {
        font-size: 17px;
        margin-top: 7px;
        vertical-align: -1px;
        margin-right: 5px;
        color: #d5d5d5;
      }
       .courseFont {
          width: 12px;
          height: 13px;
          display: inline-block;
          vertical-align: -1px;
          background-image: url("../../assets/site/home/times-icon.png");
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
    }
    .go-btn {
      width: 50px;
      height: 26px;
      line-height: 26px;
      background: #3d77ff;
      border-radius: 13px;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      position: absolute;
      right: 12px;
      bottom: 15px;
      cursor: pointer;
    }
  }
}
.share-box {
  width: 100%;
  text-align: center;
  .primary-box {
    margin: 0 auto;
    position: relative;
    width: 50px;
    cursor: pointer;
    padding: 20px 0;
    .share-icon {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }
    .text {
      color: #3a3a3a;
      font-size: 14px;
    }
    .share-hover-box {
      position: absolute;
      left: -100px;
      bottom: 100px;
      background-color: #fff;
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      padding: 20px;
      display: none;
      width: 250px;
      .big-txt {
        font-size: 16px;
        color: #3a3a3a;
        margin-bottom: 8px;
      }
      .little-txt {
        color: #929292;
        font-size: 12px;
        padding: 8px 0 0;
      }
    }
  }
  .primary-box:hover .share-hover-box {
    display: block;
  }
}
</style>