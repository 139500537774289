<template>
  <div class="bg-all-box">
    <Nav />
    <div class="service-all-box">
      <div class="service-desc-bg">
        <img src="../../assets/site/thought/banner.jpg" />
      </div>
      <div id="pride_tab_fixed" :class="titleFixed ? 'fixed-menu-box sticky' : 'fixed-menu-box'">
        <div :class="isScrollY == 1 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(1)"><div class="menu-text">党建服务</div></div>
        <div :class="isScrollY == 2 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(2)"><div class="menu-text">思政培训</div></div>
      </div>

      <div class="second-box">
        <div class="c">
          <div id="jeol-training" class="big-lable-box">
            <div class="dang-title">党建服务</div>
            <div class="dang-little">(  VR 科技党建  )</div>
          </div>
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>人民党建数据库</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
          </div>
          <div class="data-four-box">
            <div class="database-opt red">
              <div class="data-title">党建视听库</div>
              <div class="data-lists">
                <div class="label-opt">党史微课</div>
                <div class="label-opt">国史讲堂</div>
                <div class="label-opt">党课随时学</div>
              </div>
            </div>
            <div class="database-opt yellow">
              <div class="data-title">党建学习库</div>
              <div class="data-lists">
                <div class="label-opt">党章党规</div>
                <div class="label-opt">支部党课</div>
                <div class="label-opt">党务知识</div>
                <div class="label-opt">党建研究</div>
              </div>
            </div>
            <div class="database-opt yellow">
              <div class="data-title">党建资讯库</div>
              <div class="data-lists">
                <div class="label-opt">党建动态</div>
                <div class="label-opt">党建评论</div>
                <div class="label-opt">政策解读</div>
              </div>
            </div>
            <div class="database-opt red">
              <div class="data-title">党建专题库</div>
              <div class="data-lists">
                <div class="label-opt">不忘初心<br/>牢记使命</div>
                <div class="label-opt">学习<br/>十九大精神</div>
                <div class="label-opt">习近平重要<br/>讲话数据库</div>
                <div class="label-opt">建党百年</div>
              </div>
            </div>
          </div>
          <div class="three-video-box">
            <img class="left-big-img" src="../../assets/site/thought/list-big1.jpg" />
            <div class="three-right-box">
              <video id="player-container-id1" className="video-items" preload="metadata" />
              <video id="player-container-id2" className="video-items" preload="metadata" />
              <video id="player-container-id3" className="video-items" preload="metadata" />
              <video id="player-container-id4" className="video-items" preload="metadata" />
            </div>
          </div>
        </div>
      </div>


      <div class="middle-content">
        <div class="c">
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>建设方案</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
            <div class="charter-txt"> 新技术驱动“科技党建”创新传播  ;  空间/功能设计  ;  软件平台开发  ;  全面合作赋能</div>
          </div>

          <img style="margin-bottom: 60px;width: 1200px;" src="../../assets/site/thought/content-bg.png" />
          <!-- <div class="content-box">
            <div class="tr one">
              <div class="td first">
                <div class="title-top">VR党建智慧沙盘</div>
                <div class="bottom">
                  <img class="imgs" src="../../assets/site/thought/exam-img3.png" />
                  <div class="all-list">
                    <div class="all-opt">
                      <div class="name-line"><span>1</span>展示屏</div>
                      <div class="text">可接电视、LED、投影等外设，扩展性强，适合各类场景。</div>
                    </div>
                    <div class="all-opt">
                      <div class="name-line"><span>2</span>软件</div>
                      <div class="text">同时搭载《VR智慧党建平台》、《VR党建沙盘系统》双平台。</div>
                    </div>
                    <div class="all-opt">
                      <div class="name-line"><span>3</span>触控屏</div>
                      <div class="text"> 55寸全新投射式电容屏，操作简单流畅，稳定性强。 </div>
                    </div>
                    <div class="all-opt">
                      <div class="name-line"><span>4</span>旋钮控制器</div>
                      <div class="text">通过平移、旋转实现党建内容全沉浸式的交互学习，裸眼3D效果。</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-line"></div>
              <div class="td second">
                <div class="title-top">党建VR一体机</div>
                <div class="bottom">
                  <p>4K VR一体机，小巧轻便、采用4K标准RGB排列VR定制屏，高通骁龙835处理器，2.45GHz8核64位独立使用，无需电脑，可投屏。</p>
                  <img src="../../assets/site/thought/exam-img2.png" />
                </div>
              </div>
            </div>
            <div class="outer-line"></div>
            <div class="tr two">
              <div class="td four">
                <img class="left-img" src="../../assets/site/thought/exam-img1.png" />
                <div class="right-box">
                  <div class="title-top">党建VR互动机柜</div>
                  <p>热销产品，一体化的设计、无需独立的定位支架、不用外露线材、触控大屏操作简单、占地面积小、体验内容丰富，可接任何屏幕、投影。</p>
                  <img src="../../assets/site/thought/exam-img4.png" />
                </div>
              </div>
              <div class="inner-line"></div>
              <div class="td five">
                <img class="left-img" src="../../assets/site/thought/exam-img6.png" />
                <div class="right-box">
                  <div class="title-top">党建VR观望台</div>
                  <p>场馆神器，体积小巧，使用简洁，无需佩戴头盔，对准眼镜即可观看，无需额外人员接待维护，上电自动启动，方便管理，可自由调节高度。</p>
                </div>
              </div>
            </div>
          </div> -->

          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>党建学习室线上虚拟展馆建设</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
          </div>
          <img style="padding: 0 0 60px;width: 1200px;" src="../../assets/site/thought/list-big2.jpg" />

          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>人民党建产品优势</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
          </div>

          <div class="advantage-box">
            <div class="advant-opt">
              <div class="adv-head">
                <span>01</span>政治站位高
              </div>
              <div class="adv-body">人民日报是<span>党报</span>，人民网是<span>党网</span>，人民科技以党网为底色，人民党建产品政治站位更高。</div>
            </div>
            <div class="advant-opt">
              <div class="adv-head">
                <span>02</span>资源权威
              </div>
              <div class="adv-body">人民党建产品具备海量权威内容资源，与中央党校、中央文献研究室等多家部委紧密合作，所有内容资源渐具<span>权威性</span>与<span>实用性</span></div>
            </div>
            <div class="advant-opt">
              <div class="adv-head">
                <span>03</span>实时更新
              </div>
              <div class="adv-body">人民党建产品利用先进的大数据技术，持续对党建内容进行分类筛选，内容实时更新，与时俱进。</div>
            </div>
          </div>

        </div>
      </div>

      <!-- 思政培训 -->
      <div class="second-box">
        <div class="c">
          <div id="jeol-operate" class="big-lable-box">
            <div class="dang-title">思政培训</div>
            <div class="dang-little">(  强化思想引领，落实立德树人  )</div>
          </div>
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>思政培训方案</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
            <div class="charter-bold" style="padding: 50px 0 10px;">采取专题辅导、课堂互动、集中研讨、现场教学等多种方式进行。同时充分借助数字技术，打造在线论坛、空中名师讲堂等线上学习方式。</div>
          </div>

          <div class="modal-all-box">
            <div class="modal-left">
              <div class="modal-title"><div class="mask-box"><span>理论课程培训模块</span></div><div class="lines"></div></div>
              <div class="modal-lists">
                <div class="modal-option">政策理论领悟</div>
                <div class="modal-option">师德师风建设</div>
                <div class="modal-option">意识形态把控</div>
                <div class="modal-option">思政前沿瞭望</div>
                <div class="modal-option">国内外视野</div>
                <div class="modal-option">教育教学创新</div>
                <div class="modal-option">教学案例示范</div>
                <div class="modal-option">政治素养提升</div>
                <div class="modal-option">教科研策略指导</div>
              </div>
            </div>
            <div class="modal-right">
              <div class="modal-title"><div class="mask-box"><span>外出学习模块</span></div><div class="lines"></div></div>
              <div class="modal-lists">
                <div class="modal-option">高校访学</div>
                <div class="modal-option">国家安全</div>
                <div class="modal-option">知名企业</div>
                <div class="modal-option">革命传统教育</div>
              </div>
            </div>
          </div>

          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/thought/title-left.png" />
            <span>思政培训优势</span>
            <img class="fx-right" src="../../assets/site/thought/title-right.png" />
          </div>

          <div class="train-all-box">
            <div class="train-opt">
              <div class="train-head"><span>资深的专家队伍</span></div>
              <div class="train-body">涵盖政策精神、师德师风、教育信息化、党建等通识领域专家，也具备梯度式的思政学科专家资源体系。</div>
            </div>
            <div class="train-opt">
              <div class="train-head"><span>多场景的场外实践基地</span></div>
              <div class="train-body">类型多样，主题丰富，涵盖科技、军事、农业、安全、自然、历史等多个领域。</div>
            </div>
            <div class="train-opt">
              <div class="train-head"><span>丰富的教学资源、教学形式</span></div>
              <div class="train-body">依托人民网课程资源，通过线上线下的混合式学习，深度交互、研修共同体。</div>
            </div>
            <div class="train-opt">
              <div class="train-head"><span>丰富的培训经验</span></div>
              <div class="train-body">教研队伍有丰富的教师培训经验，数次组织大型教师培训项目。</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 联系我们 -->
		<div class="contact-us-box">
			<div class="c">
				<div class="contact-text">联系我们，获取更详细服务方案！</div>
				<div class="contact-btn" @click="goToNeed">立即咨询</div>
			</div>
		</div>

    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default {
  name: "Thought",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      services: services,
      titleFixed: false,
      offsetTop: 0,
      trainingTop: 0,
      operateTop: 0,
      isScrollY: 1,
      player1: "",
      player2: '',
      player3: '',
      player4:''
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.type){
      this.scrollToHref(this.$route.query.type)
    }
    this.$nextTick(() => {
      // this.videoInfo("player-container-id1", '3701925924843474486'); 
      // this.videoInfo("player-container-id2", '3701925924843482309'); 
      // this.videoInfo("player-container-id3", '3701925924843474487'); 
      this.videoInfo("player-container-id1", '8602268011029559256'); 
      this.videoInfo("player-container-id2", '8602268011029562203'); 
      this.videoInfo("player-container-id3", '8602268011029564024'); 
      this.videoInfo("player-container-id4", '8602268011029562765'); 
      window.addEventListener("scroll", this.handleScroll, true);
      
    })
  },
  methods: {
    scrollToHref(type){
      this.$nextTick(() => {
        this.trainingTop = document.querySelector("#jeol-training").offsetTop;
        this.operateTop = document.querySelector("#jeol-operate").offsetTop;
        if(type == 1 ){
          document.body.scrollTop = this.trainingTop - 100;
          this.isScrollY = 1;
        } else if (type == 2){
          document.body.scrollTop = this.operateTop - 100;
          this.isScrollY = 2;
        } 
      })
    },
    goToNeed(){
      utils.redirect(this, "/need", true);
    },
    //滚动监听，头部固定
    handleScroll() {
      this.offsetTop = document.querySelector("#pride_tab_fixed").offsetTop;
      this.trainingTop = document.querySelector("#jeol-training").offsetTop;
      this.operateTop = document.querySelector("#jeol-operate").offsetTop;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.titleFixed = scrollTop > this.offsetTop ? true : false;
      this.trainingTop = this.trainingTop - 200;
      this.operateTop = this.operateTop - 200;
      if(scrollTop > this.trainingTop && scrollTop < this.operateTop){
        this.isScrollY = 1;
      } else if(scrollTop > this.operateTop){
        this.isScrollY = 2;
      }
    },
    videoInfo(elemId, qclude_id){
      let videoObj = '';
			utils.loadScript(window.location.origin + "/school/js/hls.min.0.8.8.js", () => {
				utils.loadScript(window.location.origin + "/school/js/tcplayer.min.js", () => {
					if (typeof window.TCPlayer === "function") {
						videoObj = window.TCPlayer(elemId, {
							// player-container-id 为播放器容器ID，必须与html中一致
							fileID: qclude_id, // 请传入需要播放的视频filID 必须
							// fileID: "5285890807937512700",
							controls: true,
							// playbackRates: [0.5, 1, 1.25, 1.5, 2],
							appID: "1500004122", // 请传入点播账号的appID 必须
							width: 300, //视频的显示宽度
							height: 154, //视频的显示高度
							definition: 30,
							autoplay: false,
							plugins: {
								// ContinuePlay: {
								// 	// 开启续播功能
								// 	auto: true, //[可选] 是否在视频播放后自动续播
								// 	text: "上次播放至 ", //[可选] 提示文案
								// 	btnText: "恢复播放", //[可选] 按钮文案
								// },
							},
							// controlBar: {
							//   playbackRateMenuButton: false
							// }
						});
            
            if(elemId == 'player-container-id1'){
              this.player1 = videoObj;
            } else if (elemId == 'player-container-id2'){
              this.player2 = videoObj;
            } else if (elemId == 'player-container-id3') {
              // videoObj.on('ended', function() {
              //   if(elemId == 'player-container-id1'){
              //     this.player2.play();
              //   } else if (elemId == 'player-container-id2'){
              //     videoObj.play();
              //   } else if (elemId == 'player-container-id3') {
              //     this.player1.play();
              //   }
              // });
              this.player3 = videoObj;
            }else if(elemId == 'player-container-id4'){
              this.player4 = videoObj;
            }
            
            
					}
				});
			});
		}
  },
  destroyed(){
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.service-desc-bg {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.second-box {
  width: 100%;
  background-color: #efdfcc;
  padding: 45px 0 70px;
}
.contact-us-box {
  height: 182px;
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(81deg, #C60506 0%, #B1002E 47%, #C60506 100%);
  text-align: center;
  position: relative;
  overflow: hidden;
  .pos-img-left {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    width: auto;
    display: inline-block;
  }
  .pos-img-right {
    position: absolute;
    z-index: 1;
    right: 0;
    height: 100%;
    width: auto;
    display: inline-block;
  }
  .c {
    padding: 40px 0 0;
    position: relative;
    z-index: 10;
    top: 0;
  }
  .contact-text {
    font-size: 22px;
    color: #FFFFFF;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .contact-btn {
    width: 140px;
    height: 40px;
    line-height: 38px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    margin: 0 auto;
    cursor: pointer;
  }
}
.fixed-menu-box {
  width: 1200px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  .menu-item {
    flex: 0 20%;
    font-weight: bold;
    color: #9D9D9D;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    .menu-text {
      font-size: 20px;
      font-weight: bold;
      position: relative;
      display: inline-block;
    }
  }
  .menu-item:hover,
  .menu-item.active {
    color: #C00000;
  }
  .menu-item:hover .menu-text::after,
  .menu-item.active .menu-text::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: #C00000;
    border-radius: 2px;
    position: absolute;
    bottom: -20px;
  }
}
.fixed-menu-box.sticky {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  opacity: 1;
  border: 1px solid #ededed;
  border-top: 0;
} 
.big-lable-box {
  width: 100%;
  height: 109px;
  background-image: url("../../assets/site/thought/header-bg.png");
  background-size: 100% auto;
  text-align: center;
  .dang-title {
    height: 75px;
    font-size: 30px;
    font-weight: bold;
    color: #F2E70B;
    line-height: 75px;
  }
  .dang-little {
    height: 20px;
    font-size: 16px;
    line-height: 20px;
    color: #FF817D;
  }
}
.charter-lines {
  font-size: 28px;
  color: #C00000;
  text-align: center;
  margin: 40px 0;
  .charter-txt {
    font-size: 16px;
    font-weight: bold;
    color: #939393;
    padding-top: 20px;
  }
  .charter-bold {
    font-size: 18px;
    color: #2B2B2B;
    padding-top: 20px;
  }
  span {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
  }
  .fx-right, .fx-left {
    display: inline-block;
    vertical-align: middle;
  }
}

.data-four-box {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  .database-opt {
    width: 274px;
    height: 168px;
    padding: 20px 24px 0;
    box-sizing: border-box;
    text-align: center;
    .data-title {
      font-weight: bold;
      color: #FFFFFF;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .data-lists {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .label-opt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 105px;
        height: 38px;
        font-weight: bold;
        color: #2B2B2B;
        font-size: 14px;
        margin-bottom: 12px;
        background-color: #fff;
      }
    }
  }
  .database-opt.red {
    background-image: url("../../assets/site/thought/little-bg1.png");
    background-size: 100% auto;
  }
  .database-opt.yellow {
    background-image: url("../../assets/site/thought/little-bg2.png");
    background-size: 100% auto;
  }
}
.three-video-box {
  display: flex;
  height: 503px;
  .left-big-img {
    width: 866px;
    height: 503px;
    margin-right: 34px;
  }
  .three-right-box {
    flex: 1;
    // flex-direction: column;
    // justify-content: space-between;
    // display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    .video-items {
      width: 100%;
      height: 154px;
    }
  }
  .three-right-box::-webkit-scrollbar-track-piece,.three-right-box::-webkit-scrollbar-thumb{
    background-color: #efdfcc;
  }
  .three-right-box::-webkit-scrollbar-thumb{
    background-color: #efdfcc;
  }
}

/deep/.video-js{
  margin-bottom: 20px;
  overflow: hidden;
}
.middle-content {
  width: 100%;
  background: #F7EFE5 url("../../assets/site/thought/middle-bg.png");
  background-size: auto 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  height: max-content;
  padding: 50px 0 40px;
  .charter-lines {
    margin: 0 0 40px;
  }
}
.advantage-box {
  display: flex;
  justify-content: space-around;
  .advant-opt {
    width: 381px;
    .adv-head {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #BC341D;
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
      span {
        font-size: 24px;
        font-weight: bold;
        color: #E9A394;
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
      }
    }
    .adv-body {
      font-size: 16px;
      font-weight: bold;
      color: #2B2B2B;
      line-height: 30px;
      padding: 30px 20px;
      background-color: #fff;
      height: 160px;
      span {
        color: #C00000;
      }
    }
  }
}
.train-all-box {
  display: flex;
  justify-content: space-between;
  .train-opt {
    width: 290px;
    .train-head {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      height: 75px;
      background-color: #BC341D;
      line-height: 75px;
      padding: 0 15px;
      position: relative;
    }
    .train-head::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 13px;
      width: 47px;
      height: 48px;
      background-image: url("../../assets/site/thought/last-right.png");
      background-repeat: no-repeat;
    }
    .train-body {
      font-size: 16px;
      color: #575757;
      line-height: 32px;
      padding: 30px 15px 0;
      background-color: #fff;
      border-top: none;
      opacity: 0.95;
      border-left: 1px solid #BC341D;
      border-right: 1px solid #BC341D;
      border-bottom: 6px solid #BC341D;
      border-radius: 0px 0px 6px 6px;
      height: 164px;
    }
  }
}
.modal-all-box {
  display: flex;
  align-items: center;
  .modal-left {
    width: 740px;
    margin-right: 33px;
  }
  .modal-right {
    width: 425px;
  }
  .modal-title {
    position: relative;
    width: 100%;
    text-align: center;
    .mask-box {
      padding: 0 40px;
      width: max-content;
      text-align: center;
      background-color: #efdfcc;
      position: relative;
      z-index: 5;
      margin: 0 auto;
      height: 52px;
      bottom: -1px;
      span {
        padding: 0 32px;
        font-size: 22px;
        display: inline-block;
        color: #FFFFFF;
        height: 52px;
        margin: 0 auto;
        background: #BC341D;
        border-radius: 6px;
        position: relative;
        z-index: 10;
        line-height: 52px;
      }
    }
    
    .lines {
      width: 100%;
      position: absolute;
      z-index: 1;
      height: 4px;
      background: #BC341D;
      border-radius: 3px 2px 2px 0px;
      top: 26px;
    }
  }
  .modal-lists {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #BC341D;
    background-color: #FDF6EA;
    padding: 40px 0 20px 40px;
    .modal-option {
      font-size: 16px;
      line-height: 32px;
      color: #2B2B2B;
      margin-bottom: 20px;
      margin-right: 40px;
      display: inline-block;
    }
  }
  .modal-right {
    .modal-lists {
      padding: 40px 60px 20px;
      .modal-option {
        margin-right: 0;
        flex: 0 0 50%;
      }
    }
  }
}
.content-box {
  border: 2px solid #BC341D;
  margin-bottom: 60px;
  .tr {
    display: flex;
    .td {
      display: inline-block;
      .title-top {
        font-size: 22px;
        font-weight: bold;
        color: #C00000;
      }
      .bottom {
        .all-list {
          display: inline-block;
          vertical-align: top;
          .all-opt {
            margin-bottom: 20px;
            .text {
              font-size: 14px;
              color: #676767;
            }
            .name-line {
              font-size: 16px;
              font-weight: bold;
              color: #2B2B2B;
              margin-bottom: 4px;
              span {
                display: inline-block;
                font-size: 12px;
                color: #BC341D;
                font-weight: bold;
                border: 1px solid #BC341D;
                border-radius: 50%;
                vertical-align: 2px;
                margin-right: 15px;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
    .td.first, .td.four {
      flex:  0 0 60%;
    }
    .td.second, .td.five {
      flex:  0 0 30%;
    }
    .td.four, .td.five {
      display: flex;
    }
    .td.first .title-top {
      padding: 20px 0 15px 90px;
    }
    .td.second {
      padding: 20px 40px 0 34px;
      box-sizing: border-box;
      .title-top {
        padding: 0 0 15px 0;
      }
      img {
        margin: 30px 0 0;
        width: 400px;
      }
    }
    .td.four {
      padding: 23px 38px 20px 55px;
      .right-box {
        margin-top: 30px;
        .title-top {
          margin-bottom: 30px;
        }
      }
    }
  }
  .outer-line {
    width: 100%;
    height: 2px;
    background-color: #BC341D;
  }
  .inner-line {
    width: 2px;
    background: #BC341D;
    // height: 80px;
  }
}

</style>
