<template>
  <div class="mainbody user-agree-page static-page-box">
    <LoginHeader />
    <div class="agree-con-box">
      <div class="main-agree-center">
        <div class="content-body">
          <p style="text-align: left">
            <span style="font-family: 宋体, SimSun; font-size: 14px"></span>
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 18px"
              ><strong>用户协议</strong></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在注册中教云课或使用中教云课的任何页面之前（包括访问任何课程资料、讨论区或使用其他电子服务）之前，请务必审慎阅读、充分理解本用户协议（下称“本服务条款”）、隐私政策、诚信守则和Cookie政策的内容，特别是免除或限制责任的条款，限制、免责条款可能以黑体加粗形式提示您注意。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本服务条款、隐私政策、诚信守则和Cookie政策是您与中教云课之间的协议（下称“本协议”）。不论您是否为注册用户，您使用本产品软件即视为您已阅读并同意接受本协议的约束。如果您不希望受本协议条款的约束，我们将无法为您提供服务。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果您未满 18
              周岁，请在法定监护人的监护、指导下阅读本协议和使用本产品。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课保留在不事先通知您的情况下随时修改本服务条款的权利。本服务条款的任何修改在本页面上发布后立即生效，并附更新后的生效日期。在本产品进行任何修改之后，您继续访问本产品即视为您已接受修改后的服务条款和本产品的其他全部修改。请定期访问本页面以了解本服务条款的最新版本。</span
            >
          </p>
          <p>
            <span style="font-family: 宋体, SimSun; font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >中教云课简述</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课主要为学生提供线上学习平台，包括但不限于：（1）包括图文、视频、讨论、作业等学习；（2）视频观看；（3）课程评论、讨论；（4）线上题库练习、测评。</span
            >
          </p>
          <p>
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >网络行为准则</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您同意对您使用本产品和您的用户发布内容承担责任。“用户发布内容”是指您和本产品的其他用户在本产品上提交、发布、发表和分发的所有内容，包括但不限于所有帖子、评论、视频和上传的文件。您同意您将根据本服务条款、诚信守则和所有适用的法律法规使用本产品，不得利用本产品从事违法违规行为。您同意尊重当地的社会公德、道德和风俗习惯，避免因使用本产品而使中教云课卷入任何政治和公共事件。如果您的行为违反本条约定，您应当为此承担全部法律及相关行政责任，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求中教云课给予协助，您对由此给中教云课或他人造成损害的，您应依法予以赔偿，中教云课不承担任何责任。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >作为您使用中教云课服务的条件之一，您不得以任何试图损坏、禁用、超载或损害中教云课服务器及连接中教云课服务器的网站，或影响他人使用本产品的方式使用本产品。您不得试图通过黑客手段、密码破解或任何其他手段未经授权地访问本产品、其他帐号、软硬件系统和连接中教云课服务器的网络。您不得通过任何不当手段获取或者试图获取存储在本产品、其服务器和相关计算机上的资料和信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品严格禁止以下内容：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）诽谤、骚扰或威胁他人的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）讨论试图从事的非法活动的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）侵犯他人知识产权及其他与知识产权有关的合法权利的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）粗俗、色情、淫秽、下流、暴力和非法的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（5）广告或者其他形式的商业宣传；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（6）与政治活动相关的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（7）病毒、木马、蠕虫、数据包炸弹、篡改的文件、恶意软件、间谍软件或者其他类似的可能损害他人计算机运行或财产的内容；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（8）恶意虚构或者故意误导他人的内容。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >此外，您同意不抓取和批量下载本产品的内容，包括但不限于系统上的用户列表或目录、在线教材、用户发布内容或用户信息。在使用本产品时，您不得冒用或者试图冒用他人身份。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >用户帐号和权限</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >为了充分参与本产品的各项活动，您必须提供姓名、手机号、微信账号或电子邮件地址和用户密码才能创建用户帐号（下称“用户帐号”）。若适用的法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本产品或在使用过程中受到限制。您同意在任何情况下均不将您的用户帐号信息泄露或分享给第三方。中教云课特别提醒您应妥善保管您的用户帐号和密码，当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃的后果，责任由您自行承担。在设置您的用户帐号时，您可能会被要求输入附加的可选信息（例如您的住址）。您应确认您所提供的全部信息均是准确和最新的。您同意维护和更新个人信息以保证其为准确和最新的。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们十分重视您个人信息的保密性和安全性。请查阅我们的隐私政策，以了解中教云课收集您的哪些信息以及如何使用这些信息的更多内容。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >您使用本产品内容的权利</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >除非标明属于公有领域，本产品的内容受中华人民共和国（下称“中国”）和其他国家著作权法保护。除非本产品另有明文规定，本产品提供的课件、考试资料、视频、图片以及其他课程资料仅供您个人在学习相关课程时使用。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品的某些参考文件、数字化教材、文章和其他信息系获得第三方许可后使用，且这些信息的使用必须遵守随其公布的相关规则和条款。您使用本产品，即视为您同意遵守前述所有规则和条款。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您同意保留您从本产品获取的内容上的所有著作权及其他通知。若未明文授权，本产品及其内容的所有权利皆被保留。未经相关权利人书面许可，您不得以任何形式进行使用或创造相关衍生作品。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >用户发布内容</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >用户发布内容相关陈述和保证。通过提交或分发您的用户发布内容，您确认、陈述并保证：（1）您具有或获得了必要的权利、许可、同意及/或权限，以复制和公开用户发布内容并授权中教云课及其用户复制、修改、发布和以您允许的其他方式使用和分发您的用户发布内容；以及（2）不论您提交用户发布内容，还是执行前文授权，均不会侵犯任何第三方的权利。您（而非中教云课）对您的用户发布内容、其发布或发表的后果及前述授权承担全部责任。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >商标及其他标记</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课、 X
              大学名称、商标和服务标记的使用：“中教云课”的名称、徽标和印章均为其特定商标或标记。同样的，大学的名称、徽标和印章也均为其各自所有的特定商标及其他标记。未经所有者事先书面同意，您不得使用任何上述商标、其他标记或其变体。您不得为推广目的使用任何上述商标或其变体，也不得以按照上述商标所有者自行判断认为是蓄意或疏忽地声称、暗示或给他人造成与该等机构有关系或为该等机构所认可的表象或印象的方式使用任何上述商标或其变体。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品上或者通过本产品的服务展示的非为中教云课或者X大学所有的商标（如有）系其各自所有者的财产。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品的任何内容均不得被解释为明示地或暗含地或以其他形式授权在未经商标所有者的书面同意的情形下使用本产品展示的任何商标。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果权利所有人认为本产品的内容侵害了其合法权利，权利所有人应立即联系x-dual@eol.cn。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >免责声明 / 责任限制</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品及经其提供的任何信息、内容或服务均按“原状发布”，无任何明示的、暗含的或其他形式的保证，包括但不限于对特定目的的适销性、适用性以及非侵权的任何暗含的保证，但是适用的法律不允许否认的暗含的保证除外。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您理解并同意，本产品的服务是按照现有技术和条件所能达到的现状提供的。中教云课和中教云课成员（定义见下文）会尽最大努力向您提供服务，但是不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。中教云课和中教云课成员不保证本产品的运作不会出现干扰或错误，不保证本产品不存在病毒或者其他有害内容，不保证本产品提供的课程或者内容符合您的需求和预期。中教云课和中教云课的参与者也不保证本产品、任何课程或内容的准确性、完整性、时序性和质量，也不保证任何特定课程会持续提供。“中教云课成员”是指X大学以及其他为本产品、课程指导老师及其职员提供信息、内容和服务的机构。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您自行承担使用本产品以及通过本产品获得的内容和服务的全部风险。您自行决定是否访问本产品或任何参考应用，或是否通过该等应用下载信息、资料或数据，并自行承担全部风险，且您对因下载或使用该等资料或数据导致的您的财产（包括手机系统）损坏或数据丢失承担全部责任，中教云课隐私政策另有明文约定的除外。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >用户发布内容的免责声明
              。您理解在使用本产品时您会接触到各种不同来源的用户发布内容，且您同意中教云课或中教云课成员不必对用户发布内容相关的准确性、实用性、可靠性或知识产权承担责任。您进一步理解和确认，您可能会接触到不准确的、令人不快的、诽谤的、不得体的或令人反感的用户发布内容，且您同意特此放弃您享有的或可能享有的对中教云课或与前述内容相关的任何中教云课成员的任何权利或救济措施。中教云课或者任何中教云课成员均不支持任何用户发布内容或其中表达的任何观点、推荐或建议。中教云课或者任何中教云课成员均无义务监控任何用户发布内容或通过本产品进行的任何其他用户通信。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >但是，中教云课保留在任何时间、为任何原因进行用户发布内容审查以及自行决定酌情全部或部分地编辑或删除任何用户发布内容的权利，以及保留允许中教云课成员进行前述行为的权利。在前述规定继续有效的前提下，在接到用户或内容所有者的通知主张某用户发布内容不符合本服务条款后，中教云课有权调查该等主张并根据其自行判断决定是否删除该用户发布内容（中教云课保留不经通知而随时进行前述行为的权利），同时，中教云课有权视您的行为性质采取包括但不限于终止您对中教云课的使用权、追究法律责任等措施。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >链接至其他网站。
              本产品可能包含由他人维护或控制的超链接，中教云课和中教云课成员不对该等网站可能提供的任何产品或服务的内容或者其使用承担任何责任，并且不会定期筛查、审批、审查或批准该等内容或者其使用。如果您决定访问链接的第三方网站，您应自行承担全部风险。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在适用法律允许的最大范围内，您同意中教云课及任何中教云课成员均不必对由下列事项导致的或与之相关的您所遭受的任何实际或间接损失承担任何责任：（
              1 ）本服务条款；或（ 2
              ）您或任何第三方使用本产品或未能使用本产品；或（ 3
              ）您在本产品上发布内容；或（ 4
              ）您依赖本产品或经本产品获取的信息，无论您的权利主张是基于合同法、侵权法或其他法律法规。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >特别地，在适用法律允许的最大范围内，中教云课及任何中教云课成员均不必对任何间接的、惩罚性的、特别的或附随的损失承担任何责任，不论是否为可预见的，也不论中教云课及任何中教云课成员是否存在疏忽或有其他过错（包括但不限于主张诽谤、错误、利益损失、数据丢失或数据提供中断）。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun">赔偿</span></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您同意赔偿中教云课、中教云课成员、代理机构及雇员，使其免受因您使用本产品引起的、导致的或在任何方面与之相关的任何第三方权利主张、诉讼或请求所产生的损失，包括但不限于任何及所有权利主张、实际或间接损失、诉讼、判决、诉讼费用及律师费所致的任何种类或性质的责任或费用。在此情形下，中教云课或任何中教云课的成员将书面告知您该等权利主张或诉讼的情况。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >杂项</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >终止权；停止课程与内容。您同意中教云课有权自行决定因任何原因或无任何原因地终止您对本产品的使用或参与，只需通知您即可。在适当的情况下对反复侵犯著作权的本产品用户予以终止是中教云课的一项基本原则。中教云课及中教云课成员保留随时自行决定取消、推迟通过中教云课提供的任何课程，或重排课程时间，或调整课程的格式，或停止提供部分或全部本产品的内容或相关服务的权利，并且您同意中教云课及任何中教云课成员均不必就上述行为向您承担任何责任。如果您不愿再参与本产品，您可以随时终止。本服务条款赋予您的权利将随您对本产品的使用权的终止而终止，但本服务条款的其他约定在该等终止后继续有效。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >全部协议。
              本服务协议、隐私政策、诚信守则和Cookie政策构成您和中教云课之间就您使用本产品所达成的全部协议，取代此前您和中教云课之间就您使用本产品所达成的任何协议。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >弃权与服务条款的可分性。中教云课未能行使本服务条款项下的任何权利或执行本服务条款的任何约定不构成其对该等权利或约定的放弃。如果本服务条款的任何条款被拥有合法管辖权的法庭认定无效，当事人仍同意法庭应尽量使该条款中反映的当事人意图有效，并且本服务条款的其他条款仍有效。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >适用法律。您同意，本服务条款及由本服务条款或经本产品获得的任何内容或服务引起的或与之相关的任何权利主张或诉讼应适用中华人民共和国（为本协议之目的，不包括香港特别行政区、澳门特别行政区和台湾地区）的法律（不包括冲突法）。您同意，所有该等权利主张和诉讼应提交本协议签订地（即北京市海淀区）的有管辖权的法院审理，您同意放弃就上述法院管辖提出任何异议。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >更新日期 2021年1月5日</span
            >
          </p>
          <p style="margin-top: 3em; margin-bottom: 1em; line-height: 1.33">
            <span style="font-size: 16px"
              ><strong
                ><span style="font-size: 18px; font-family: 宋体, SimSun"
                  >中教云课隐私政策</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课十分重视您个人信息的保密性和安全性。我们将按照本隐私政策尽合理的努力保护您的个人信息(下称“个人信息”，定义见下文)的安全。本隐私政策中使用的“我们”均指中教云课。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本隐私政策仅适用于我们通过中教云课（下称“本产品”，包含本产品内的所有内容和页面）收集的信息，不适用于我们可能通过其他方式向您收集的信息（例如，本隐私政策不适用于您可能通过电话、传真或日常信函向我们提供的信息）。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果您不同意本隐私政策的条款，您无权访问、浏览或注册本产品。如果您选择不向我们提供本产品为您提供各种服务所必要的信息，您可能无法创建用户帐号，我们可能无法向您提供该等服务。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >关于在国外处理的同意</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >通过访问本产品或向中教云课提供信息，您理解并明确同意我们为本隐私政策和中教云课服务条款所约定的目的在中华人民共和国、其他国家和地区收集、使用、披露和保存信息。您特此同意中教云课依本隐私政策收集、使用、披露和保存您的个人信息，包括但不限于在中教云课和本隐私政策所述的第三方、附属机构及分支机构之间转移您的个人数据。为进一步确定，关于本条所述的转移信息的任何同意应视为包含您同意将适用的个人信息转移至另一司法区域，该区域的隐私保护程度可能与您所在国家的隐私保护程度不同。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、适用的法律有明确规定；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、获得您的明确授权；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、您通过互联网进行跨境交易等个人主动行为。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >个人信息</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如OPENUDID/GUID等在内的描述个人常用设备基本情况的信息）；个人位置信息（包括行程信息、精准定位信息、经纬度等）。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：网络身份识别信息（包括账户名、账户昵称、邮箱地址及与前述有关的密码与密码保护问题和答案）；其他信息（包括通讯录、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息）。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们仅会出于以下目的，收集和使用您的个人信息：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（一）您须授权我们收集和使用您个人信息的情形</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们的产品与/或服务包括一些核心功能，这些功能包含了实现选学公开课、专业课学习所必须的功能、观看直播所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、实现选学公开课、专业课学习所必须的功能</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）用户注册</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您首先需要注册一个中教云课账户成为中教云课用户。当您注册时，您需要至少向我们提供您准备使用的中教云课账户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱），我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的用户名称默认为空，当您首次注册设置后仍可修改，您可以修改补充您的昵称、性别、手机号、生日以及您的实名验证相关信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供个性化的商品或服务推荐和更优的课程、学习体验，但如果您不提供这些补充信息，不会影响您网上课程学习的基本功能。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）课程与/或服务信息展示和搜索</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >为了让您快速地找到您所需要的课程与/或服务，我们可能会收集您使用我们的产品与/或服务的浏览器类型来为您提供商品或服务信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您也可以通过搜索来精准地找到您所需要的课程或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的课程或服务。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）客服与售后功能</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们的电话客服和售后功能会使用您的账号信息和订单信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供售前咨询与售后服务时，我们将会查询您的访问记录。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）&nbsp;产品业务功能所收集使用您的信息逐项列举</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <img
              style="width: 50%"
              src="https://jw.jiaoyu.cn/img/educational/pocliy-imgs.png"
            />
          </p>

          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、其他业务功能</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们其他业务功能可能会收集您浏览信息、您的兴趣爱好（您可以在账户设置中）进行数据分析以形成用户画像，用来将您感兴趣的课程商品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的课程商品或服务。我们其他业务功能还可能获取的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（二）您可选择是否授权我们收集和使用您的个人信息的情形</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、为使您消费、获取资讯更便捷或更有乐趣，从而提升您在中教云课的网上消费及课程学习体验，我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行获取资讯及学习课程，但您可能无法使用这些可以为您所带来消费乐趣的附加功能或在享受服务时需要重复填写一些信息。这些附加功能包括：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）基于位置信息的个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的服务。包括进行实训系统中进行定位完成教学辅助等。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、上述附加功能可能需要您在您的设备中向我们开启您的地理位置（位置信息），以实现这些功能所涉及的信息的收集和使用。您可以决定将这些权限随时的开启或关闭（我们会指引您在您的设备系统中完成设置）。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、实现相关业务功能可能开启您的设备访问权限的逐项列举：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >业务场景</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >场景说明</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >访问及使用权限</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >访问系统</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >实训系统中定位完成教学辅助（任务分配、指挥调度）</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >地理位置权限</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、与个人信息控制者履行法律法规规定的义务相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、与国家安全、国防安全直接相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、与公共安全、公共卫生、重大公共利益直接相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >6、所涉及的个人信息是个人信息主体自行向社会公众公开的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >7、根据个人信息主体要求签订和履行合同所必需的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >8、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >9、维护所提供产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（四）我们从第三方获得您个人信息的情形（个人信息的间接获取）</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的中教云课账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们承诺，我们从第三方间接获取您的个人信息的数量为实现中教云课产品业务功能所必须的最少数量。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们承诺，在从第三方间接获取您的个人信息时，要求第三方说明个人信息来源，并会对其个人信息来源的合法性进行确认；我们将了解并审慎注意，第三方已获得您的个人信息处理的授权同意范围，包括使用目的，您是否授权同意转让、共享、公开披露等。我们开展业务所需进行的个人信息处理活动超出该授权同意范围的，将在获取您的个人信息后的合理期限内或处理您的个人信息前，征得您的明示同意。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（五）我们在您使用产品及服务过程中收集的信息</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们会收集关于您使用我们产品或服务以及使用方式的信息，这些信息包括：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >设备信息：我们会根据您在软件安装及/或使用中授予的具体权限，接收并记录您所使用的设备相关信息（设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境等软硬件特征信息）、设备所在位置相关信息（IP&nbsp;地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站等传感器信息）。如果您在安装及/或使用过程中拒绝授予我们相应权限的，我们并不会记录您上述对应的信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >服务日志信息：当您使用我们的网站提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >请您理解，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。除非将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，那么在结合使用期间，这类非个人信息将被视为个人信息，我们会将该类个人信息做匿名化、去标识化处理（取得您的授权或法律法规另有规定的情况除外）。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >当您与我们联系时，为验证您的身份，帮助您解决问题，我们可能会记录您与我们的对话并收集其他为解决问题所需的必要信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（六）您个人信息使用的规则</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息或对您的个人信息进行匿名化处理。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >披露</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们原则上不会将您的个人信息进行公开披露。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们仅会在以下情况下，且经事先开展个人信息安全影响评估、并依评估结果采取符合业界标准的安全防护措施和去标识化处理的前提下，才会公开披露您的个人信息，并准确记录和存储个人信息的公开披露的情况，包括公开披露的日期、规模、目的、公开范围等：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、通过您的预留邮箱，告知您公开披露的目的、披露的个人信息类型、涉及的个人敏感信息的具体内容，根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们将不会公开披露个人生物识别、基因信息，不会公开披露我国公民的种族、民族、政治观点、宗教信仰等个人敏感数据的分析结果。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果我们在披露过程中违反法律法规的规定，给您造成损失的，我们将承担因此引起的相应责任。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >共享与转让：</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们不会向中教云课以外的任何公司、组织和个人共享、转让您的个人信息，但以下情况除外：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、在获取明确同意或授权的情况下共享：获得您的明确同意或授权后，我们会向其他方共享、转让您的个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、我们可能会根据法律法规规定，或按根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况对外共享、转让您的个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、其他共享情形</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）与我们的关联公司和附属机构共享</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您的个人信息可能会与我们的关联公司和附属机构共享。我们只会共享必要的个人信息，且受本隐私政策中所列明目的的约束。我们的关联公司和附属机构如要改变个人信息的处理目的，将再次征求您的授权同意。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们的关联公司包括北京中教双元科技集团有限公司现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）与授权合作伙伴共享</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于本隐私政策列明的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的授权合作伙伴无权将共享的个人信息用于任何其他用途。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >目前，我们的授权合作伙伴主要包括：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >①通过中教云课提供课程的教育机构（下称“大学”）；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >②代表我们或大学执行某些功能（包括处理您在本产品上提供给我们的信息、本产品或其中一部分的运营、提供或管理课程或有关中教云课或大学的其他服务）的服务供应商和承包商；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >③其他商业伙伴。我们还可能其他商业合作伙伴分享汇总信息，但不会分享能识别您个人身份的信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们可能为下列各项相关目的共享您的信息：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、用于帮助大学提供、管理和改进课程；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、用于帮助我们和大学个别地（例如由课程职员与学生合作改进课程内容）或整体地改进中教云课提供的内容，以实现使用体验个性化，评估本产品的访问和使用以及中教云课在全球教育领域的影响；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、用于科学研究的目的，特别是认知科学和教育学领域的科学研究，但不会分享能识别您个人身份的信息；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、用于实现您提供信息的特定目的，例如回复具体问题或向您提供您选定的具体课程内容和/或服务；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >5、用于跟踪个别和整体参与人数、在线教育的进展和完成情况，以及分析学生表现的统计数据和学生学习模式；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >6、用于监测违反诚信守则、服务条款的行为和其他滥用本产品或潜在滥用本产品的行为；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >7、用于发表收集到的中教云课访问、使用、影响和学生表现的信息，但不包括个人信息；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >8、用于向您发送中教云课提供的课程或其他事件更新，向您发送您可能感兴趣的有关中教云课的产品和服务、中教云课服务机构或精选商业合作伙伴的信件，或向您发送本产品维护或更新的电子邮件信息；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >9、用于信息归档和/或未来与您沟通；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >10、用于维护和提高本产品、软件、系统和网络的安全性和功能；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >11、用于与第三方服务进行整合，例如非由中教云课控制的页面上所载的视频和其他内容。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们仅为满足收集时已说明的和本隐私政策载明的目的向其他公司、组织和个人共享其需要的个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在共享、转让信息前，我们将开展个人信息安全影响评估，并依评估结果决定是否采取加密、去标识化等手段保障您的信息安全。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们会对接收方的数据安全环境进行调查，确认其具有合法运营资质，在系统开发、设备部署、数据保存与使用、人员安全等方面遵守国家信息安全要求。我们会与接收方签署严格的保密协定，要求他们按照本隐私政策以及我们其他相关的保密和安全规范来处理个人信息，并采取充分的安全措施保护信息不受损失、滥用、未经授权的访问或披露、修改及破坏。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >接收方无权将共享的个人信息用于任何其他用途，如要改变个人信息的处理目的，将再次征求您的授权同意。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如共享、转让的信息属于个人敏感信息的，我们还将事先另行告知您所涉个人敏感信息的类型，并在对接收方的数据安全能力进行评估后告知您评估的结果，在征得您的明示同意后进行共享、转让。我们将要求接收方采用数据脱敏和加密技术，从而更好地保护用户的个人敏感信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果我们违反法律法规规定或违反与您的约定向第三方共享、转让个人信息，且您要求删除的，我们将立即停止共享、转让的行为，并通知第三方及时删除。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如我们发现接收方违反法律法规要求或约定处理个人信息的，我们会立即要求该方停止相关行为，并视具体情况自行采取或要求该方采取有效补救措施（如更改口令、回收权限、断开网络连接等）控制或消除相关安全风险；必要时我们会解除与该方的义务关系，并要求该方及时删除从我们处获得的个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如因信息共享对您的合法权益造成损害的，我们将承担相应责任。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >收购、兼并、重组、破产</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在涉及收购、兼并、重组、破产时，您理解并同意：我们可能无法满足前述“共享与转让”中列明的关于个人信息共享与转让的步骤和要求。但我们会在发布公告后的7个工作日内，通过您预留的邮箱将相关情况告知您，并要求新的持有您个人信息的公司、组织继续受本隐私政策的约束。否则，我们将要求该公司、组织重新向您征求授权同意。如果破产且无承接方的，我们将在发布公告后7个工作日内对相关数据做删除处理。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun"
                  >共享、转让、披露征得同意的例外</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）与国家安全、国防安全直接相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）与公共安全、公共卫生、重大公共利益等有关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）与犯罪侦查、起诉、审判和执行判决等有关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（5）您自行向社会公开的个人信息；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（6）从合法公开披露的信息中收集您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（7）与我们履行法律法规规定的义务相关的。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun">委托处理</span></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >目前我们并未委托任何第三方处理个人信息，如因业务发展确实需要委托的，需要满足下述条件：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、委托行为不超出已征得您授权同意的范围。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、我们会对委托行为进行个人信息安全影响评估，确保受委托者达到数据安全能力要求。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、我们将确保受委托者符合以下要求：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）严格按照我们的要求处理个人信息，因特殊原因未按照要求的，必须及时向我们反馈；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）确需再次委托的，需要事先征得我们的授权；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）协助我们响应您提出的请求；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）在处理个人信息过程中无法提供足够的安全保护水平或发生了安全事件的，必须及时向我们反馈；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（5）在委托关系解除时不再存储相关个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、我们将对受委托者进行监督，方式包括但不限于：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）通过合同等方式规定受委托者的责任和义务；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）对受委托者进行审计。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >5、我们将准确记录和存储委托处理个人信息的情况。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >6、若我们得知或者发现受委托者未按照委托要求处理个人信息，或未能有效履行个人信息安全保护责任的，我们将立即要求受托者停止相关行为，且采取或要求受委托者采取有效补救措施（如更改口令、回收权限、断开网络连接等）控制或消除个人信息面临的安全风险。必要时我们将终止与受委托者的业务关系，并要求受委托者及时删除从我们处获得的个人信息。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >您的权利：</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、查询个人信息</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您有权查询您的个人信息，法律法规规定的例外情况除外。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >具体路径：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >用户选择“学习中心”-“个人信息”里查看姓名、性别、生日、学校/公司、学历、职称、工作年限、地址信息等。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您还可以随时通过本隐私政策载明的方式与我们联系，申请查询账号个人信息、个人信息来源、我们使用您的个人信息的目的、已经从我们处获得您的个人信息的第三方身份或类型。我们在收到您的查询邮件后，将于3个工作日内向您反馈；如我们无法提供的，将向您说明理由。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、更正个人信息</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以随时本隐私政策载明的方式与我们联系，我们将在3个工作日内进行处理；您也可以登陆您的中教云课账号，通过以下路径在您的账号中自行操作：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >用户选择“学习中心”-“个人信息”里编辑姓名、性别、生日、学校/公司、学历、职称、工作年限、地址信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、删除个人信息</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在以下情形中，您可以通过本隐私政策载明的方式与我们联系，向我们提出删除个人信息的请求：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）如果我们处理个人信息的行为违反法律法规；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）如果我们收集、使用您的个人信息，却未征得您的同意；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）如果我们处理个人信息的行为违反了与您的约定；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）如果您不再使用我们的产品或服务，或您注销了账号；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（5）如果我们不再为您提供产品或服务。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果我们违反法律法规规定或与您的约定，收集、使用您的个人信息的，我们将在收到您的前述请求后3个工作日内删除相应的信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果我们违反法律法规规定或违反与您的约定向第三方共享、转让或披露您的个人信息的，我们在收到您的前述要求后，除删除相应信息外，还将立即停止共享、转让或披露的行为，并通知第三方在3个工作日内完成删除，或发布通知要求相关接收方在3个工作日内删除相应的信息，除非法律法规另有规定，或这些实体获得您的独立授权。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、改变您授权同意的范围或撤回您的授权</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您可以通过删除信息、关闭设备功能、在中教云课网站中进行“隐私设置”等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >响应</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >对于您基于本隐私政策提出的请求，我们将在必要的身份验证通过后进行处理，最长将在不超过三十天或法律法规规定期限内做出答复。直接实现您的请求需要付出高额成本或存在显著困难的，我们将提供其他替代方法，以保障您的合法权益。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在以下情形中，按照有关法律法规及国家标准，我们可能无法响应您的请求：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（1）与我们履行法律法规规定的义务相关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（2）与国家安全、国防安全有关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（3）与公共安全、公共卫生、重大公共利益有关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（4）与犯罪侦查、起诉和审判等有关的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（5）有充分证据表明您存在主观恶意或滥用权利的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（6）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到年本人同意的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >（8）涉及商业秘密的。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >未成年人的个人信息保护</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不应创建自己的用户账户。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们非常重视对未成年人的个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应事先取得您家长或法定监护人的书面同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们只会在受到法律允许、父母或监护人明确同意、本人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母同意或本人明确同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如您对未成年人个人信息相关事宜有任何意见、建议和诉求，请联系我们。我们会随时为您提供服务。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、对于收集到的儿童的个人信息，我们除遵守本隐私政策关于用户个人信息保护的约定外，还会切实按照《儿童个人信息网络保护规定》的要求，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、我们严格遵循《网络安全法》、《中华人民共和国未成年人保护法》、《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露收集到的儿童个人信息，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、当您作为监护人为被监护的儿童选择中教云课相关服务时，我们可能需要向您收集被监护儿童的个人信息，用于向您履行相关服务之必要。在具体的服务中需要向您收集儿童个人信息的，我们会告知您收集的目的和用途，并且仅在事先取得您的授权同意的情况下收集儿童个人信息。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用注册和登录等功能时，可能会主动向我们提供儿童的个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品或服务，您应当正确引导并予以监护。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >安全</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、数据安全技术措施</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，免数据的损坏或丢失。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在个人信息使用时，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、中教云课为保护个人信息采取的其他安全措施</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课通过建立数据分类分级制度、数据安全管理规范来管理规范个人信息的存储和使用。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课建立数据安全管理组织来推进和保障个人信息安全。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们存储您个人数据的底层云技术取得了数据中心联盟颁发的“可信云”认证。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >3、我们仅允许有必要知晓这些信息的中教云课及中教云课关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制，对个人信息批量修改、拷贝、下载等重要操作设置了相应的内部审批流程。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与中教云课的合作关系。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >6、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >7、安全事件处置</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在通过中教云课宁网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >为应对个人信息泄露、损毁和丢失等可能出现的风险，中教云课制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。中教云课也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >链接到其他网站</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >本产品包含了前往第三方（包括其他内容提供者以及某些服务供应商，如代表中教云课处理支付的服务商等）发布的网站的链接。这些网站不在我们控制范围之内，您确认并同意，除非本隐私政策中另有约定，我们不必为该等网站收集和使用您的信息承担责任。我们鼓励您在被转接至第三方网站时了解并查阅您访问和使用的每个网站的隐私政策。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >修改隐私政策</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >请注意，为给您提供更好的服务以及随着中教云课业务的发展，我们可能随时审查并修改本隐私政策。我们会通过在中教云课网站、移动端上发出更新版本并通过网站公告或以其他适当方式提醒您相关内容的更新。本隐私政策修改后，本隐私政策链接将包含注记“更新（日期）”，这意味着您应查阅新条款，任何修改在本页面上发布后立即生效，并附更新后的生效日期。在本产品进行任何修改之后，您访问本产品即视为您已同意修改后的本隐私政策和本产品的其他全部修改。请定期访问本网页以了解本隐私政策的最新版本。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >隐私顾虑</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果您有隐私顾虑，或者披露了您希望保密的数据，或者希望访问我们持有的关于您的信息，请联系我们：x-dual@eol.cn。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >一般情况下，我们将在三十天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >1、向被告所在地人民法院提起民事诉讼；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >2、向中央网信办举报（举报中心网址为：http://www.12377.cn，举报电话为：12377）。</span
            >
          </p>
          <p>
            <span style="font-size: 16px"
              ><strong
                ><span style="font-family: 宋体, SimSun">停止运营</span></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果我们停止运营中教云课，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理，但国家法律法规、规章、规范性文件或政府的政策、命令等另有要求或为履行我们的合规义务而保留您的个人信息的除外。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >为免疑义，在本产品或服务停止运营时，我们对您的个人信息删除或匿名化处理并不影响我们的关联方基于您的同意而继续收集使用您的个人信息以及您届时相应地继续正常使用该等关联方的服务。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >唯一主体</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们确认：北京中教双元科技集团有限公司为本产品的唯一运营主体，即我们通过本产品收集的个人信息的唯一控制方。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >产品名称：</span
            ><span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课网站</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >公司名称：</span
            ><span style="font-family: 宋体, SimSun; font-size: 14px"
              >北京中教双元科技集团有限公司</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >注册地址：</span
            ><span style="font-family: 宋体, SimSun; font-size: 14px"
              >北京市海淀区北三环中路44号10号楼5层513</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >常用办公地址：</span
            ><span style="font-family: 宋体, SimSun; font-size: 14px"
              >北京市海淀区北三环中路44号10号楼5层513</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >更新日期：2021年9月15日</span
            >
          </p>
          <p style="margin-top: 3em; margin-bottom: 1em">
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 18px; font-family: 宋体, SimSun"
                  >中教云课诚信守则公约</span
                ></strong
              ></span
            >
          </p>
          <p style="margin-top: 1em; margin-bottom: 1em">
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >合作原则</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >通过在中教云课上选学课程，您已经加入了一个特殊的学习社区。中教云课的目标是通过应用互联网技术，为学生提供线上学习体验的机会。您已成为帮助中教云课实现这一目标的社区成员之一。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >中教云课依赖于您主动、诚实地学习相关资料。为参与中教云课，您必须同意以下诚信守则以及对某些课程适用的任何特定附加条款。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >中教云课诚信守则公约</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >参加中教云课的课程，以下内容将视为是您的承诺：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >仅凭我个人努力完成所有期中和期末考试，不会提交任何其他人的作业/成果；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >仅持有一个用户帐号并且不会让其他人使用我的用户帐号和/或密码；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >不从事任何可能不诚实地提高我的成绩，或提高或损害他人成绩的行为；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >发布用于评估学生表现的题目的答案。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >除非中教云课课程的指导老师另有说明，中教云课鼓励学生：</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >同他人合作完成讲座视频、练习、作业；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >在课程中同其他人讨论一般概念和资料；</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >将想法和书面成果展示给中教云课同学或其他人，以获得他们的意见和评论。</span
            >
          </p>
          <p>
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 14px; font-family: 宋体, SimSun"
                  >诚信守则的修改</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >请注意，我们可能不时审查和修改本诚信守则。本诚信守则的修改在本页面上发布后立即生效，并附更新后的生效日期。在本产品进行任何修改之后，您访问本产品即视为您已同意修改后的诚信守则和本产品的其他全部修改。请定期访问本网页以了解本诚信守则的最新版本。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >更新日期 ：2021年9月15日</span
            >
          </p>
          <p
            style="
              margin-top: 3em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
            "
          >
            <span style="font-size: 14px"
              ><strong
                ><span style="font-size: 18px; font-family: 宋体, SimSun"
                  >中教云课Cookie政策</span
                ></strong
              ></span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >Cookie是您访问网站时服务器放置在您计算机系统上的小型数据文件，可在用户访问本网站时发送用户信息使得网站能够识别用户。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >Cookie通常被分类为“会话”cookie或“持久”cookie。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >会话Cookie可以帮助您有效地浏览网站，跟踪您的页面到页面的进度，以便不要求您在当前访问期间重复提供的信息，比如您在切换页面时不会清空您的语言偏好。会话Cookie存储在临时内存中，并在Web浏览器关闭时被删除。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >持久性Cookie可以存储用户偏好，它们存储在您的设备上，重新启动浏览器时仍然有效。例如，我们使用持久性Cookie来记录您选择的语言和国家/地区、字体大小或其他设置。这可以使您在下次访问我们的网站时，不用重新配置。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >我们不会将Cookie用于网站服务目的之外的任何用途。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >您有权决定接受或拒绝cookies，您可以设置或修改您的网页浏览器控件，以接受或拒绝cookies。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果您选择拒绝cookies，您仍然可以使用我们的网站，尽管此时您在访问我们网站的一些功能和区域时可能会受限。很多网络浏览器均设有请勿追踪功能，该功能可向网站发布请勿追踪请求。如果用户的浏览器启用了请勿追踪功能，那么我们的网站也会尊重用户的选择。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >如果您未设置拒绝cookies，我们将视为您同意我们收集cookies，以便我们为您提供更准确的信息和便利服务。</span
            >
          </p>
          <p
            style="
              margin-top: 1em;
              margin-bottom: 1em;
              line-height: 1.33;
              font-size: 18px;
              text-indent: 2em;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              >更新日期：2021年9月15日</span
            >
          </p>
          <p style="text-align: left">
            <span style="font-family: 宋体, SimSun; font-size: 14px"
              ><br
            /></span>
          </p>
        </div>
      </div>
    </div>
    <LoginFooter />
  </div>
</template>

<script>
import LoginFooter from "../../components/loginFooter";
import LoginHeader from "../../components/loginHeader";
export default {
  name: "Policy",
  components: {
    LoginFooter,
    LoginHeader,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
.agree-con-box {
  width: 100%;
  background-color: #fff;
}
.agree-con-box .main-agree-center {
  width: 1200px;
  margin: 0 auto;
}
.agree-con-box .content-body {
  padding: 30px 0;
  color: #3a3a3a !important;
  font-size: 14px !important;
}
</style>