<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/sdcmc/logo.png" alt="" class="logo">
          <div class="headMain">
            <img src="../../../../assets/sdcmc/schoolLogo.png" alt="" class="schoolLogo">
            <span class="bgTitle">课程思政教学研究中心</span>
          </div>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}`" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/course`" exact>示范课程</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/1`" exact>新闻动态</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/2`" exact>通知文件</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/3`" exact>媒体报道</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/4`" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a :href="`https://bk.jiaoyu.cn/${utils.sdcmzyxyCode}/lesson/distribution`" target="_blank">课程思政资源管理平台</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="news-mianbao c h">
        <span class="labekl">当前位置:</span>
        <span class="labekl">资讯</span>
        <span class="iconfont">></span>
        <span class="labekl">资讯查看</span>
      </div>
      <template v-if="DetailIndex == '1'">
        <div class="art-title c">
          <div class="title">喜报！学院课程思政示范项目获得重大突破</div>
          <div class="time">时间：2021-05-12</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>日前，教育部对拟立项国家级职业教育课程思政示范项目进行公示，学院祝瑞玲教授主持申报的示范项目《数字电视技术原理与应用》在列，在此之前，学院申报的7项课程思政示范项目已全部获批省级立项。根据教育部和教育厅通知精神，获批立项的课程思政示范课程，其授课教师和团队也将同步认定为课程思政教学名师和教学团队。该系列成绩是学院在课程思政教学改革领域取得的重大突破。</span>
          </p>
          <p class="p">
            <span>学院自2月底启动该项工作以来，按照《教育部办公厅关于开展课程思政示范项目建设工作的通知》（教高厅函〔2021〕11号）和《山东省教育厅关于开展课程思政示范项目建设工作的通知》对申报工作进行了周密部署和推进，共收到申报项目21项，内容涵盖广播影视、新闻传播、艺术设计、学前教育等专业类型及大学英语、大学体育等公共课程。经过系部推选、专家评审，最终遴选7项上报参评。</span>
          </p>
          <p class="p">
            <span>在申报工作中，学院各课程团队注重体现职业教育类型特征，对前期课程思政教学改革进行了系统和细致的总结梳理，深入挖掘了课程所蕴含的思政元素，结合专业和课程特点有机融入了社会主义核心价值观、职业道德、劳动教育、工匠精神等内容，在日常教学中也取得了良好的育人成效。</span>
          </p>
          <p class="p">
            <span>近年来，学院深入落实立德树人根本任务，积极构建“三全育人”新格局，将课程思政项目建设纳入一校一案、提质培优三年行动计划和今年重点突破项目，不断完善课程思政工作体系、教学体系和内容体系，有力增强了广大教师开展课程思政建设的意识和能力，有力促进了学院人才培养质量提升。</span>
          </p>
          <p class="p">
            <span>据统计，教育部立项公示的200项职业教育课程思政示范项目中，山东省共13所院校16个项目入选；在教育厅立项公示的200项职业教育课程思政示范项目中，学院7项立项数量在全省高职院校中位列第二。</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '2'">
        <div class="art-title c">
          <div class="title">学院举行第三届教师教学能力大赛暨课程思政教学比赛</div>
          <div class="time">时间：2021-05-28</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>为深入贯彻落实习近平总书记对职业教育工作重要指示和全国职业教育大会精神，持续深化“三教”改革，落实“课程思政”要求，推进高水平、结构化教师教学团队建设，进一步提升学院教育教学质量，5月22日，学院举行第三届教师教学能力大赛暨课程思政教学比赛。校内外五位专家受邀担任本次大赛的评委，100多名专职教师现场观摩了比赛。</span>
          </p>
          <p class="p">
            <span>本次大赛历时2个多月。自3月15号比赛正式启动以来，各部门积极响应，共有22支教学团队报名参赛，参赛作品涵盖专业技能课程及公共基础课程。经过系部初选，14支队伍进入决赛环节。经过激烈角逐，根据参赛材料评审、初赛表现、现场展示和答辩情况，比赛最终决出一等奖6个、二等奖8个。成绩优异的教学团队将获得推荐参加省级比赛的资格。</span>
          </p>
          <p class="p">
            <span>本次比赛的赛制参照山东省职业院校教学能力大赛的比赛规则，涌现出一批优秀参赛作品，教学团队在教学设计中融入劳动教育、工匠精神、职业道德、1+X证书等先进理念和内容，合理运用优质网络资源组织教学，将思想政治教育与技术技能培养有机统一，实现了“以赛促教、以赛促学、以赛促改、以赛促建”的目标，对促进教师综合素质、专业化水平和创新能力的全面提升到重要作用。</span>
          </p>
          <img src="../../../../assets/sdcmc/xwdt2_1.png" alt="">
          <img src="../../../../assets/sdcmc/xwdt2_2.png" alt="">
        </div>
      </template>
      <template v-if="DetailIndex == '3'">
        <div class="art-title c">
          <div class="title">学院组织教师参加课程思政专题培训</div>
          <div class="time">时间：2021-05-30</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>为进一步提升学院教师课程思政建设水平，巩固立德树人成效，推动课程思政立项项目的内容和形式创新，5月29日，学院组织9名教师参加了为期两天的“全国高等院校课程思政认知升级与设计创新工作坊”。学院党委委员、纪委书记韩玉玲带队参加了此次专题培训。</span>
          </p>
          <p class="p">
            <span>此次课程思政专题培训由山东省级首批一流本科课程主持人、2020年度山东省优秀思政课教师吴现波主讲，并以分组工作坊形式进行。全省职业院校共80余名教师参加此次培训。</span>
          </p>
          <p class="p">
            <span>此次培训主要内容包括对课程思政内涵和意义的认知升级，思政元素的发掘、课程思政的教学设计原则与方法，以及课程思政设计案例分析等。在培训过程中，吴现波老师注重引导参训教师积极参与，在行动中感悟、在实践中思考，同时在培训中融入了诸多参与式学习活动，有效提升了教师对课程思政内涵的认知水平，丰富了教师开展教学时的方法和技巧。在培训过程中，学院参训教师就国家级课程思政示范项目、追寻中国梦大型采访活动等内容与参训教师进行了分享和交流。</span>
          </p>
          <p class="p">
            <span>此次培训学习有益提升了学院参训教师对课程思政内涵和意义的认识水平，开拓了思政育人的思路，同时促进了学院与其他院校在课程思政领域的交流与合作，对于提升学院影响力和提升思政育人水平均有重要意义。</span>
          </p>
          <img src="../../../../assets/sdcmc/xwdt3.png" alt="">
        </div>
      </template>
      <template v-if="DetailIndex == '4'">
        <div class="art-title c">
          <div class="title">我院国家级课程思政教学团队在北京参加集体备课</div>
          <div class="time">时间：2021-09-25</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>9月24日，全国职业院校新闻传播专业大类课程思政集体备课会在中国传媒大学进行，我院国家级课程思政教学团队成员张卫东、祝瑞玲、贾庆萍应邀参会并作经验分享和教学展示。</span>
          </p>
          <p class="p">
            <span>2021年，学院祝瑞玲教授主持的《数字电视技术原理与应用》成功获批国家级课程思政示范项目，项目坚持落实立德树人根本任务，将社会主义核心价值观等思政元素融入课程教学内容和教学方法，探索了“1113德智融通”课程思政建设模式，积累了丰富的课程思政教学经验，收获了同行、学生和教育主管部门的广泛肯定。此次集体备课活动线上线下相结合，教学团队从课程设计、教学实践等七个方面进行了说课，并以《后来居上的AVS标准》为题进行了教学展示，为相关专业教学提供了经验参考。</span>
          </p>
          <p class="p">
            <span>此次新闻传播专业大类课程思政集体备课会是教育部职业教育与成人教育司组织的全国职业教育课程思政集体备课活动系列之一，以“强化课程思政，回归育人初心”为主题，由中国传媒大学高等职业技术学院承办、高等教育出版社和新华网协办，旨在进一步深入贯彻习近平总书记相关重要指示和全国职业教育大会精神，落实教育部《高等学校课程思政建设指导纲要》要求，深化职业院校对课程思政的认识与理解，发挥课程思政示范项目的引领作用，总结推广职业教育课程思政经验做法，提升职业院校相关专业课程思政建设水平。</span>
          </p>
          <img src="../../../../assets/sdcmc/xwdt4.jpg" alt="">
        </div>
      </template>
      <template v-if="DetailIndex == '6'">
        <div class="art-title c">
          <div class="title">关于举办第五届山东传媒职业学院新媒体作品大赛的通知</div>
          <div class="time">时间：2021-10-26 </div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span style="font-weight:bold;">各系部：</span>
          </p>
          <p class="p">
            <span>在新一代信息技术的驱动下，我国新媒体发展不断实现创新变革，应用场景更加丰富，产业格局与生态持续优化。为响应国家对新媒体产业发展的号召，切实推进产教融合、工学结合，推动我院新媒体作品创作更上一个新台阶，形成“学、练、赛”有机结合的新媒体教学体系，鼓励和引导我院师生在专业实践中多出作品、出好作品，创造出更多内容符合当前主旋律的新媒体作品，学院将举办第五届新媒体大赛，现将有关事宜通知如下：</span>
          </p>
          <p class="p">
            <span>一、大赛指导：宣传统战部、教务处</span>
          </p>
          <p class="p">
            <span>主办：实训中心 承办：新媒体中心</span>
          </p>
          <p class="p">
            <span>协办：影视制作系、新闻传播系、信息工程系、数媒动画系、艺术设计系、学前教育与戏剧系</span>
          </p>
          <p class="p">
            <span>二、大赛原则：坚持“学生主体、教师指导、公平公正”的原则，注重新媒体作品的专业性、技术性和创新性。</span>
          </p>
          <p class="p">
            <span>三、参赛对象：目前我院具有正式学籍的全日制学生，鼓励跨专业、跨系部组建团队参赛。</span>
          </p>
          <p class="p">
            <span>四、作品类别及奖项设置：本次大赛设微博、微信、H5、短视频及音频类。为鼓励应用5G技术开展超高清视频直播、VR全景视频制作等，推动新媒体作品制作流程、展示形式和产品样态不断多元化发展，特设5G应用项目比赛。根据往年各项比赛参赛作品数量，本次大赛的奖项设置如下：</span>
          </p>
          <table class="table">
            <tr>
              <td></td>
              <td>微博</td>
              <td>微信公众号</td>
              <td>H5</td>
              <td>短视频</td>
              <td>音频</td>
              <td>5G应用</td>
            </tr>
            <tr>
              <td>一等奖</td>
              <td>2</td>
              <td>3</td>
              <td>1</td>
              <td>3</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>二等奖</td>
              <td>4</td>
              <td>5</td>
              <td>2</td>
              <td>5</td>
              <td>2</td>
              <td>2</td>
            </tr>
            <tr>
              <td>三等奖</td>
              <td>6</td>
              <td>10</td>
              <td>3</td>
              <td>10</td>
              <td>3</td>
              <td>3</td>
            </tr>
          </table>
          <p class="p">
            <span>五、评选范围及要求：</span>
          </p>
          <p class="p">
            <span>范围：包括但不限于网文、图片、H5、短视频、音频、VR、三维模型或相关新媒体优秀作品。</span>
          </p>
          <p class="p">
            <span>要求：参赛作品必须为2020年12月1日-2021年12月3日之间制作的原创作品，兼具思想性、艺术性和文化传播价值，同时有较高的浏览量、关注度。发布平台不限，未发布的可以直接将作品发送大赛指定邮箱。作品立意弘扬正能量，践行社会主义核心价值观，不得侵犯他人知识产权，抄袭、盗用他人作品或违反相关法律法规者，一经发现将自负一切责任。</span>
          </p>
          <p class="p">
            <span>每个参赛作品，作者不得超过4人，且所有成员均对参赛作品有实际贡献。作品指导教师不超过2人。</span>
          </p>
          <p class="p">
            <span>六、评选流程及办法</span>
          </p>
          <p class="p">
            <span>1、作品报送（12月3日之前）。填写附件中对应的《第五届山东传媒职业学院新媒体大赛作品报名表》，内容较多的需双面打印，也可另附书面支撑材料。</span>
          </p>
          <p class="p">
            <span>2、初评（12月4日～12月6日）。根据评选标准，确定入围候选作品。</span>
          </p>
          <p class="p">
            <span>3、评审（12月7日～12月10日）</span>
          </p>
          <p class="p">
            <span>（1）专家评审。12月7日左右组织评委进行线下评审，权重为60％。</span>
          </p>
          <p class="p">
            <span>（2）网络投票。12月8日～10日通过新媒体中心官方微信进行线上投票，网络投票权重为40％。</span>
          </p>
          <p class="p">
            <span>（3）确定名单。根据网络投票和专家评审情况，按照“投票得分*0.4＋评审得分*0.6”总得分确定表彰名单。</span>
          </p>
          <p class="p">
            <span>4、公布与表彰（12月中旬）。学院将公布获奖名单，并予以表彰。</span>
          </p>
          <p class="p">
            <span>七、其他事项</span>
          </p>
          <p class="p">
            <span>请参赛选手将作品报名表电子版发送至邮箱xinmeitidasai@163.com，报送截至时间为2021年12月3日。参赛学生加入QQ群：637348709，及时了解比赛动态。</span>
          </p>
          <p class="p">
            <span>联系人：黄增心 李昌元 联系电话：61328255</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '11'">
        <div class="art-title c">
          <div class="title">“点亮星空 融爱未来”！关爱“来自星星的孩子”大型公益活动启动</div>
          <div class="time">时间：2022-01-12</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>自闭症儿童，又被称为“来自星星的孩子”。因为疾病，他们缺乏基本的生存技能与社交技能，甚至会因怪异的举止受到排斥和歧视，就像天上的星星，在漆黑夜空中独自闪烁着。1月10日，“点亮星空 融爱未来”关爱孤独症大型公益活动在济南启动，旨在汇聚社会大爱，帮助孤独症儿童走出封闭的世界。</span>
          </p>
          <img src="../../../../assets/sdcmc/mtbd1.webp" style="max-width:60%;margin-top:10px;" />

          <p class="p">
            <span>活动由山东广播电视台联合省关工委、省教育厅、省残联、团省委、省妇联以及省内各大院校共同发起，向社会各界发出“点亮星空 融爱未来”活动倡议书，希望更多的专门平台、专项教育和专业培训，为他们敞开就业创业的大门，帮助他们融入社会、改变人生。</span>
          </p>
          <img src="../../../../assets/sdcmc/mtbd2.webp" alt="" style="max-width:60%;margin-top:10px;">

          <p class="p">
            <span>作为活动形象大使，《山东新闻联播》、《山东教育新闻联播》主播彭文馨将跟青年志愿者服务团队进社区、进乡村、进学校、进商超，开展孤独症知识普及宣传活动，传递爱心，为孤独症群体及其家庭发声。同时，活动还将寻找不少于1000名需要帮助的小龄（0-6岁）孤独症及发育迟缓儿童，由爱心专业康复机构为其提供免费评估服务，帮助他们找到更准确、更有效的干预途径，使其终身受益。此外，联合各大院校专业团队针对大龄孤独症群体进行个别化职业技能培训，为其具备一定独立生存能力提供必要支持。</span>
          </p>

          <p class="p">
            <span>这些孩子因病把自己封锁了起来，但全社会的关爱从未缺失。目前，山东各大院校机构围绕这些星星的孩子，也推出了一些针对性教学内容和特色课程，也深受孤独症孩子家庭的欢迎。像山东师范大学成立了“融爱未来”志愿服务队，致力于为0-12岁孤独症谱系障碍和发育迟缓儿童、教育机构、康复机构提供专业康复和融合教育支持。山东传媒职业学院“锋行”志愿服务队，通过走访济南、青岛及周边地区的孤独症关爱机构，以拍摄宣传视频、联合策划公益活动等方式关心关爱孤独症群体。潍坊学院则是省内较早开展孤独症儿童教育研究的高校，近些年来，在孤独症儿童教育研究方面取得了丰富成果。</span>
          </p>
        </div>
      </template>
      <template v-if="DetailIndex == '16'">
        <div class="art-title c">
          <div class="title">习近平：在纪念辛亥革命110周年大会上的讲话</div>
          <div class="time">2021-08-03 14:59:33</div>
        </div>
        <div class="school-text c">
          <p class="p">
            <span>新华社北京10月9日电</span>
          </p>

          <p class="p">
            <span>在纪念辛亥革命110周年大会上的讲话</span>
          </p>

          <p class="p">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p">
            <span>110年前，以孙中山先生为代表的革命党人发动了震惊世界的辛亥革命，推翻了清朝政府，结束了在中国延续几千年的君主专制制度，近代以来中国发生的深刻社会变革由此拉开了序幕。这是中国人民和中国先进分子为实现民族独立、人民解放进行的一次伟大而艰辛探索。</span>
          </p>

          <p class="p">
            <span>今年是辛亥革命110周年，是中国共产党成立100周年，中国人民正意气风发向着全面建成社会主义现代化强国的第二个百年奋斗目标迈进。在这个重要时刻，我们在这里隆重集会，缅怀孙中山先生等革命先驱的历史功勋，就是要学习和弘扬他们为振兴中华而矢志不渝的崇高精神，激励和团结海内外全体中华儿女为实现中华民族伟大复兴而共同奋斗。</span>
          </p>

          <p class="p">
            <span>同志们、朋友们！</span>
          </p>

          <p class="p">
            <span>辛亥革命的发生，有着深刻的社会历史背景，是近代以来中国社会矛盾激化和中国人民顽强斗争的必然结果。中华民族是世界上古老而伟大的民族，有着5000多年源远流长的文明历史，为人类文明进步作出了不可磨灭的贡献。1840年鸦片战争以后，西方列强在中华大地上恣意妄为，封建统治者孱弱无能，中国逐步成为半殖民地半封建社会，国家蒙辱、人民蒙难、文明蒙尘，中国人民和中华民族遭受了前所未有的劫难。英雄的中国人民始终没有屈服，在救亡图存的道路上一次次抗争、一次次求索，展现了不畏强暴、自强不息的顽强意志。</span>
          </p>

          <p class="p">
            <span>从那时起，实现中华民族伟大复兴就成为中华民族最伟大的梦想。</span>
          </p>

          <p class="p">
            <span>孙中山先生是伟大的民族英雄、伟大的爱国主义者、中国民主革命的伟大先驱。孙中山先生大声疾呼“亟拯斯民于水火，切扶大厦之将倾”，高扬反对封建专制统治的斗争旗帜，提出民族、民权、民生的三民主义政治纲领，率先发出“振兴中华”的呐喊。在孙中山先生领导和影响下，大批革命党人和无数爱国志士集聚在振兴中华旗帜之下，广泛传播革命思想，积极兴起进步浪潮，连续发动武装起义，推动了革命大势的形成。</span>
          </p>

          <p class="p">
            <span>1911年10月10日，武昌城头枪声一响，拉开了中国完全意义上的近代民族民主革命的序幕。辛亥革命极大促进了中华民族的思想解放，传播了民主共和的理念，打开了中国进步潮流的闸门，撼动了反动统治秩序的根基，在中华大地上建立起亚洲第一个共和制国家，以巨大的震撼力和深刻的影响力推动了中国社会变革，为实现中华民族伟大复兴探索了道路。</span>
          </p>

          <p class="p">
            <span>孙中山先生和辛亥革命先驱为中华民族建立的历史功绩彪炳千秋！在辛亥革命中英勇奋斗和壮烈牺牲的志士们名垂青史！辛亥革命永远是中华民族伟大复兴征程上一座巍然屹立的里程碑！</span>
          </p>

          <p class="p">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p">
            <span>历史发展总是螺旋式上升、波浪式前进的。由于历史进程和社会条件的制约，由于没有找到解决中国前途命运问题的正确道路和领导力量，辛亥革命没有改变旧中国半殖民地半封建的社会性质和中国人民的悲惨境遇，没有完成实现民族独立、人民解放的历史任务。辛亥革命之后，在这场革命中接受洗礼的中国人民和中国先进分子继续探寻救国救民道路。十月革命一声炮响，给中国送来了马克思列宁主义，促进了中国人民的伟大觉醒，在马克思列宁主义同中国工人运动的紧密结合中，中国共产党应运而生。中国共产党一经诞生，就把为中国人民谋幸福、为中华民族谋复兴确立为自己的初心和使命，点亮了实现中华民族伟大复兴的灯塔。</span>
          </p>

          <p class="p">
            <span>中国共产党人是孙中山先生革命事业最坚定的支持者、最忠诚的合作者、最忠实的继承者。中国共产党在成立之初就提出反帝反封建的民主革命纲领，并同孙中山先生领导的中国国民党携手合作，帮助国民党完成改组，建立最广泛的革命统一战线，掀起轰轰烈烈的大革命，给北洋军阀反动统治以沉重打击。</span>
          </p>

          <p class="p">
            <span>孙中山先生逝世后，中国共产党人继承他的遗愿，同一切忠于他的事业的人们继续奋斗，不断实现和发展了孙中山先生和辛亥革命先驱的伟大抱负。中国共产党团结带领中国人民浴血奋战、百折不挠，打败国内外一切反动势力，取得了新民主主义革命伟大胜利，建立了人民当家作主的中华人民共和国，完成了民族独立、人民解放的历史任务，开启了中华民族发展进步的历史新纪元。</span>
          </p>

          <p class="p">
            <span>新中国成立后，中国共产党团结带领中国人民，自力更生、发愤图强，创造了社会主义革命和建设的伟大成就；解放思想、锐意进取，创造了改革开放和社会主义现代化建设的伟大成就；自信自强、守正创新，统揽伟大斗争、伟大工程、伟大事业、伟大梦想，创造了新时代坚持和发展中国特色社会主义的伟大成就。</span>
          </p>

          <p class="p">
            <span>抚今追昔，孙中山先生振兴中华的深切夙愿，辛亥革命先驱对中华民族发展的美好憧憬，近代以来中国人民梦寐以求并为之奋斗的伟大梦想已经或正在成为现实，中华民族迎来了从站起来、富起来到强起来的伟大飞跃，中华民族伟大复兴进入了不可逆转的历史进程！</span>
          </p>

          <p class="p">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p">
            <span>孙中山先生在《建国方略》中说：“吾心信其可行，则移山填海之难，终有成功之日”。今天，经过长期奋斗，实现中华民族伟大复兴具备了更为完善的制度保证、更为坚实的物质基础、更为主动的精神力量。前景光明辽阔，但前路不会平坦。我们要以史为鉴、开创未来，在全面建设社会主义现代化国家新征程上继续担当历史使命，掌握历史主动，不断把中华民族伟大复兴的历史伟业推向前进。</span>
          </p>

          <p class="p">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，必须有领导中国人民前进的坚强力量，这个坚强力量就是中国共产党。中国共产党领导是历史的选择、人民的选择，是党和国家的根本所在、命脉所在，是全国各族人民的利益所系、命运所系。没有中国共产党，就没有新中国，就没有中华民族伟大复兴。</span>
          </p>

          <p class="p">
            <span>新的征程上，我们必须坚持和加强党的全面领导，充分发挥党总揽全局、协调各方的领导核心作用，提高党科学执政、民主执政、依法执政水平。要弘扬伟大建党精神，推进党的建设新的伟大工程，增强自我净化、自我完善、自我革新、自我提高能力，确保中国共产党始终成为中国人民和中华民族最可靠的主心骨。</span>
          </p>

          <p class="p">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，道路是最根本的问题。中国特色社会主义是实现中华民族伟大复兴的唯一正确道路。这条道路符合中国实际、反映中国人民意愿、适应时代发展要求，不仅走得对、走得通，而且也一定能够走得稳、走得好。</span>
          </p>

          <p class="p">
            <span>新的征程上，我们必须坚持和发展中国特色社会主义不动摇，继续推进马克思主义中国化时代化，坚定志不改、道不变的决心，牢牢把中国发展进步的命运掌握在自己手中。我们要统筹推进“五位一体”总体布局、协调推进“四个全面”战略布局，全面深化改革和扩大开放，推进国家治理体系和治理能力现代化，不断满足人民过上美好生活的新期待，不断推进全体人民共同富裕。</span>
          </p>

          <p class="p">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，必须依靠中国人民自己的英勇奋斗。历史发展从来不是风平浪静的，而是充满曲折和艰辛的。正如毛泽东同志所说的：“我们的先人以不屈不挠的斗争反对内外压迫者，从来没有停止过”，“中国人民的不屈不挠的努力必将稳步地达到自己的目的”。</span>
          </p>

          <p class="p">
            <span>新的征程上，我们必须统筹中华民族伟大复兴战略全局和世界百年未有之大变局，抓住历史机遇，增强忧患意识、始终居安思危，保持革命精神和革命斗志，勇于进行具有许多新的历史特点的伟大斗争，以敢于斗争、善于斗争的意志品质，坚决战胜任何有可能阻碍中华民族复兴进程的重大风险挑战，坚决维护国家主权、安全、发展利益。</span>
          </p>

          <p class="p">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，中国人民和中华民族必须同舟共济，依靠团结战胜前进道路上一切风险挑战。孙中山先生说过：“要恢复民族的地位，便先要恢复民族的精神。”近代以来，中国人民和中华民族弘扬伟大爱国主义精神，心聚在了一起、血流到了一起，共同书写了抵御外来侵略、推翻反动统治、建设人民国家、推进改革开放的英雄史诗。统一战线始终是中国共产党凝聚人心、汇聚力量的重要法宝。</span>
          </p>

          <p class="p">
            <span>新的征程上，我们必须大力弘扬爱国主义精神，树立高度的民族自尊心和民族自信心，铸牢中华民族共同体意识，紧紧依靠全体中华儿女共同奋斗，坚持大团结大联合，不断巩固和发展最广泛的爱国统一战线，广泛凝聚中华民族一切智慧和力量，形成海内外全体中华儿女万众一心、共襄民族复兴伟业的生动局面。</span>
          </p>

          <p class="p">
            <span>——辛亥革命110年来的历史启示我们，实现中华民族伟大复兴，不仅需要安定团结的国内环境，而且需要和平稳定的国际环境。孙中山先生曾经说过：“中国如果强盛起来，我们不但是要恢复民族的地位，还要对于世界负一个大责任。”中华民族的血液中没有侵略他人、称王称霸的基因，中国人民不仅希望自己发展得好，也希望各国人民都能拥有幸福安宁的生活。</span>
          </p>

          <p class="p">
            <span>新的征程上，我们必须始终高举和平、发展、合作、共赢旗帜，推动构建人类命运共同体，推动完善全球治理体系，弘扬和平、发展、公平、正义、民主、自由的全人类共同价值，加强同世界各国人民的团结，共同反对霸权主义和强权政治，做世界和平的建设者、全球发展的贡献者、国际秩序的维护者，努力为人类作出新的更大贡献。</span>
          </p>

          <p class="p">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p">
            <span>孙中山先生说过：“‘统一’是中国全体国民的希望。能够统一，全国人民便享福；不能统一，便要受害。”台湾问题因民族弱乱而产生，必将随着民族复兴而解决。这是中华民族历史演进大势所决定的，更是全体中华儿女的共同意志，正像孙中山先生所说：“世界潮流，浩浩荡荡，顺之则昌，逆之则亡”。</span>
          </p>

          <p class="p">
            <span>以和平方式实现祖国统一，最符合包括台湾同胞在内的中华民族整体利益。我们坚持“和平统一、一国两制”的基本方针，坚持一个中国原则和“九二共识”，推动两岸关系和平发展。两岸同胞都要站在历史正确的一边，共同创造祖国完全统一、民族伟大复兴的光荣伟业。</span>
          </p>

          <p class="p">
            <span>中华民族具有反对分裂、维护统一的光荣传统。“台独”分裂是祖国统一的最大障碍，是民族复兴的严重隐患。凡是数典忘祖、背叛祖国、分裂国家的人，从来没有好下场，必将遭到人民的唾弃和历史的审判！台湾问题纯属中国内政，不容任何外来干涉。任何人都不要低估中国人民捍卫国家主权和领土完整的坚强决心、坚定意志、强大能力！祖国完全统一的历史任务一定要实现，也一定能够实现！</span>
          </p>

          <p class="p">
            <span>同志们，朋友们：</span>
          </p>

          <p class="p">
            <span>经过近代以来的长期艰苦奋斗，中国人民创造了令世界刮目相看的伟大成就，迎来了民族复兴的光明前景。实现中华民族伟大复兴是全体中华儿女的共同光荣，也是全体中华儿女的共同使命。孙中山先生说：“惟愿诸君将振兴中国之责任，置之于自身之肩上。”我呼吁，海内外全体中华儿女更加紧密地团结起来，发扬孙中山先生等辛亥革命先驱的伟大精神，携手向着中华民族伟大复兴的目标继续奋勇前进！</span>
          </p>
        </div>
      </template>
    </main>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
import services from '../../../../config/services';
import utils from '../../../../config/utils';

export default {
  name: "detail",
  data () {
    return {
      utils,
      DetailIndex: null,
      services
    };
  },
  created () {
    window.open(services.KCSZ_SERVER + '/' + utils.sdcmzyxyCode, '_self')
  },
  mounted () {
    this.DetailIndex = this.$route.params.id;
  },
  watch: {
    $route: {
      handler () {
        this.DetailIndex = this.$route.params.id;
      },
    },
    deep: true,
  },
};
</script>

<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./detail.css" scoped>
</style>
<style lang="less" scoped>
.p {
  margin: 11.5pt 0pt 0pt;
  padding: 0pt;
  text-align: justify;
  line-height: 200%;
  text-indent: 26pt;
}
</style>
<style src="../../../../assets/sdcmc/common.css" scoped>
</style>