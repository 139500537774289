<template>
  <div class="bg-all-box">
    <Nav />
    <div class="bread-bg-box">
      <div class="c">
        <div class="bread-line">
          <router-link class="bread-link" to="/" exact>首页</router-link>
          <span class="bread-icon">></span>
          <a class="bread-link">融媒智库</a>
        </div>
      </div>
    </div>

    <div class="c flex-box">
      <div class="left-contnet-box">
        <div class="top-category-list">
          <div v-for="(item, index) in categoryArr" :key="index" @click="changeCategory(item.type)" :class="item.type == categoryActive ?'category-item active': 'category-item'">{{item.name}}</div>
        </div>

        <div class="all-lists-box">
          <template v-if="this.$store.state.media.mediaData.items && this.$store.state.media.mediaData.items.length > 0">
            <div  v-for="(item, index) in this.$store.state.media.mediaData.items" :key="index" class="all-items">
              <div v-if="item.cover_url" class="all-left-imgs">
                <div class="thumbImg" :style="`background-image: url(${item.cover_url})`"></div>
                <!-- <img :src="item.thumb_image" /> -->
              </div>
              <router-link target="_blank" :to="{path: '/media/detail', query: {type: categoryActive, id: item.id}}"
                exact class="all-right-box">
                <div class="label">{{item.title}}</div>
                <div v-html="item.description" class="intro"></div>
                <div class="three-lines">
                  <div v-if="item.source" class="three-left">
                    <!-- <div class="nums-box">浏览次数：{{item.num}}</div> -->
                    <div v-if="item.source">来源：{{item.source}}</div>
                  </div>
                  <div v-if="item.source" class="three-date">{{item.publish_time}}</div>
                  <div v-else class="three-date" style="text-align:left;">{{item.publish_time}}</div>
                </div>
              </router-link>
            </div> 
          </template>   
          <template v-else>
            <div v-for="(item, index) in frameArr" :key="index" class="all-frame-items">
              <div class="all-left-imgs"></div>
              <div class="all-right-box">
                <div class="label"></div>
                <div class="intro"></div>
                <div class="sub-line"></div>
                <div class="three-lines">
                  <div class="three-left"></div>
                  <div class="three-date"></div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="pages">
          <a-pagination
            :defaultPageSize="pageSize"
            :default-current="0"
            :total="this.$store.state.media.mediaTotal"
            @change="onPageChange"
          />
        </div>
      </div>
      <div class="right-aside-box">
        <div class="right-auto-bg">
          <div class="banner-box">
            <template v-for="(banner, index) in rmggwArr">
              <a v-if="index > 4 && index < 6" :href="banner.url" :key="index" target="_blank">
                <img :src="banner.cover_url" />
              </a>
            </template>
          </div>
          <div class="top-title-box">
            <div class="label active">推荐课程</div>
            <!-- <div class="more" >更多 ></div> -->
            <router-link class="more" target="_blank" :to="'/school/class'">更多 ></router-link>
          </div>

          <div class="class-lists">
            <div
              class="class-box"
              v-for="item in courseYxtData"
              :key="item.id"
            >
              <router-link
                class="link-box"
                :to="'/school/class/' + item.id"
                exact
                target="_blank"
              >
                <div class="img">
                  <img :src="item.list_photo" alt />
                </div>
                <div class="txt">
                  <div class="title">{{ item.name }}</div>
                  <div class="num">
                    <!-- <span class="iconfont iconduoren"></span> -->
                    <div class="courseFont times"></div>
                    课程总时长：{{ item.total_time }}
                  </div>
                  <div class="go-btn" >Go</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Aside />
    <Footer />
  </div>
</template>

<script>
import '../../assets/css/frame.less'
// import utils from "../../config/utils";
import services from "../../config/services";
// import { dataJson } from "../../config/data";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default {
  name: "Media",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      pageSize: 20,
      frameArr: [1,2,3,4,5,6], // 骨架屏数组
      services: services,
      mediaArr: this.$store.state.media.mediaData.items || [],
      total: 0,
      requestPageNum: 0, // media分页请求的参数num
      categoryActive: 'mtkx',
      categoryArr: [
        {
          type: 'mtkx',
          name: '媒体快讯',
        },
        {
          type: 'rmzsk',
          name: '融媒知识库',
        },
        {
          type: 'zjdt',
          name: '职教动态',
        },
        {
          type: 'yxtdjsz',
          name: '党建思政',
        },
      ],
      rmggwArr: [],
    };
  },
  computed: {
    courseYxtData (){
      return this.$store.state.home.courseYxtData;
    }
  },
  mounted () {
     if(this.$route.query.type){
      this.categoryActive = this.$route.query.type;
      this.changeCategory(this.categoryActive);
    }
    this.$store.dispatch("getHomeCourseData", {page: 1, size: 3});
    this.requestPageNum = 0;
    this.$store.dispatch("loadMediaData", {type: this.categoryActive, data:{page: 1,length:this.pageSize,order_by: 'weight desc,publish_time desc'}});
    this.getMethodMedia("rmggw", 6);
  },
  activated() {
    if(this.$route.query.type){
      this.categoryActive = this.$route.query.type;
      this.changeCategory(this.categoryActive);
    }
    this.$store.dispatch("getHomeCourseData", {page: 1, size: 3});
    this.requestPageNum = 0;
    this.$store.dispatch("loadMediaData", {type: this.categoryActive, data:{page: 1,length:this.pageSize,order_by: 'weight desc,publish_time desc'}});
    this.getMethodMedia("rmggw", 6);
  },
  methods: {
    backtop(){
      var timer = setInterval(function(){
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5); 
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    },
    onPageChange(pageNumber) {
      this.requestPageNum = Number(pageNumber)-1;
      this.$store.dispatch("loadMediaData", {type: this.categoryActive,data:{page: Number(pageNumber),length:this.pageSize,order_by: 'weight desc,publish_time desc'} });
      this.backtop();
    },
    changeCategory(type) {
      if(this.categoryActive != type) {
        this.categoryActive = type;
        this.requestPageNum = 0;
        this.$store.dispatch("loadMediaData", {type: this.categoryActive, data:{page: 1,length:this.pageSize,order_by: 'weight desc,publish_time desc'}});
      }
    },
    // 首页获取融媒体数据
    getMethodMedia (type, num){
      this.$store.dispatch("loadQmtyyNewsList", {
        type: type,
        data: {
          page: 1,
          length: 20,
          order_by: 'weight desc,publish_time desc'
        }
      }).then((resData) => {
        if (resData.data && resData.data.items) {
          if(type == "rmggw"){
            this.rmggwArr = resData.data.items;
            if(resData.data.items.length > num){
              this.rmggwArr.length = num;
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
};
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.bread-bg-box {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #f5f6f9;
  .bread-line {
    width: 100%;
    color: #9a9a9a;
    font-size: 15px;
    .bread-link {
      color: #9a9a9a;
      font-size: 15px;
    }
    .bread-link:hover {
      color: #3d77ff;
    }
    .bread-icon {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
.flex-box {
  display: flex;
  padding: 30px 0 65px;
}
.left-contnet-box {
  flex: 1;
  .top-category-list {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    margin-bottom: 30px;
    .category-item {
      font-size: 15px;
      color: #2a2a2a;
      display: inline-block;
      margin-right: 30px;
      padding-bottom: 8px;
      border-bottom: 4px solid transparent;
      cursor: pointer;
    }
    .category-item.active {
      border-bottom: 4px solid #3d77ff;
      border-radius: 2px;
      font-weight: bold;
      color: #002145;
      font-size: 20px;
    }
  }
  .all-lists-box {
    width: 100%;
    .all-items {
      display: flex;
      margin-bottom: 30px;
      border-bottom: 1px solid #f1f1f1;
      .all-left-imgs {
        width: 219px;
        height: 123px;
        margin-right: 30px;
        overflow: hidden;
        border-radius: 2px;
        .thumbImg {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .all-right-box {
        flex: 1;
        display: block;
        .label {
          font-size: 18px;
          max-width: 668px;
          font-weight: bold;
          color: #3a3a3a;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 10px;
        }
        .intro {
          font-size: 14px;
          font-weight: 400;
          color: #939292;
          line-height: 24px;
          margin-bottom: 20px;
          height: 48px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .three-lines {
          display: flex;
          font-size: 14px;
          color: #676767;
          margin-bottom: 40px;
          justify-content: space-between;
          width: 100%;
          .three-left {
            flex: 0 0 55%;
            div {
              display: inline-block;
              vertical-align: top;
            }
            .nums-box {
              margin-right: 30px;
            }
          }
          .three-date {
            flex: 0 0 40%;
            text-align: right;
          }
        }
      }
    }
  }
}
.right-aside-box {
  width: 306px;
  margin-left: 35px;
  .right-auto-bg {
    background: #f5f6f9;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .banner-box {
    width: 268px;
    height: 150px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 8px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .top-title-box {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    margin-bottom: 30px;
    .label {
      font-size: 18px;
      font-weight: bold;
      color: #002145;
      display: inline-block;
      margin-right: 20px;
      padding-bottom: 6px;
      border-bottom: 4px solid transparent;
      cursor: pointer;
    }
    .label.active {
      border-bottom: 4px solid #3d77ff;
      border-radius: 2px;
    }
    .more {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 14px;
      color: #9a9a9a;
      cursor: pointer;
    }
  }
}
.class-lists{
    width: 100%;
  }
  .class-box {
    width: 100%;
    // height: 216px;
    background: #fff;
    border: 1px solid #ececec;
    margin-bottom: 25px;
    border-radius: 8px;
    &:hover{
      box-shadow: 1px 2px 7px #ececec;
      transition: 0.3s box-shadow;
      .txt .title {
        color: #364c7e;
      }
    }
    .img {
      height: 150px;
      width: 100%;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
      img {
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
    .txt{
      position: relative;
      padding: 15px 12px;
      width: 100%;
      box-sizing: border-box;
      .title{
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        color: #3A3A3A;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .num{
        font-size: 12px;
        color: #959595;
        margin-top: 2px;
        .iconduoren{
          font-size: 17px;
          margin-top: 7px;
          vertical-align: -1px;
          margin-right: 5px;
          color: #d5d5d5;
        }
         .courseFont {
          width: 12px;
          height: 13px;
          display: inline-block;
          vertical-align: -1px;
          background-image: url("../../assets/site/home/times-icon.png");
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }
      .go-btn {
        width: 50px;
        height: 26px;
        line-height: 26px;
        background: #3D77FF;
        border-radius: 13px;
        text-align: center;
        color: #FFFFFF;
        font-size: 12px;
        position: absolute;
        right: 12px;
        bottom: 15px;
        cursor: pointer;
      }
    }
  }
</style>