<template>
  <div class="thoughtParty">
    <div class="top1">
      <div class="top1_center">
        <div class="top1_center_left">
          <img class="top1_img1" src="../../assets/site/thoughtParty/top1_logo1.png" alt="" />
          <span class="line">|</span>
          <span class="top1_title1">党建与思政</span>
        </div>
        <div class="tabs">
          <router-link class="top1_tab1 top1_tab top1_actived" :to="'/djsz'">思政资源库</router-link>
          <router-link class="top1_tab3 top1_tab" :to="'/thought'">智慧党建</router-link>
          <router-link class="top1_tab2 top1_tab" :to="'/thought'">思政培训</router-link>
        </div>
        <div class="top1_center_right">
          <span class="top1_company">数据支持单位：</span>
          <img class="top1_img2" src="../../assets/site/thoughtParty/top1_logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="top2">
      <!-- <div class="top2_title">课程思政资源库</div> -->
      <img class="titleImg" src="../../assets/site/thoughtParty/title.png" alt="">
      <img class="top2_img" src="../../assets/site/thoughtParty/top2_title.png" alt="">
    </div>
    <div class="body">
      <div class="body_tabs">
        <div class="body_tabs_center">
          <span :class="tabIndex=='0'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(0)">思政专题</span>
          <span :class="tabIndex=='1'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(1)">课程思政</span>
          <span :class="tabIndex=='2'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(2)">党建专区</span>
        </div>
      </div>
      <div class="body_box">
        <div class="box0" v-show="tabIndex=='0'">
            <div class="box_left">
             <a-menu
                style="width: 260px"
                mode="inline"
                theme="light"
                :selected-keys="[current0]"
                @click="handleClick0"
                :open-keys="openKeys0"
                @openChange="onOpenChange0"
              >
                <a-menu-item key="1">
                  国家概况
                </a-menu-item>
                <a-sub-menu key="sub1">
                  <span slot="title"><span>政策精神</span></span>
                  <a-menu-item key="2">
                    国家政策
                  </a-menu-item>
                  <a-menu-item key="3">
                    各地政策
                  </a-menu-item>
                  <a-menu-item key="4">
                    工作报告
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub2">
                  <span slot="title"><span>领导讲话</span></span>
                  <a-menu-item key="5">
                    领导人活动报道
                  </a-menu-item>
                  <a-menu-item key="6">
                    省部长言论
                  </a-menu-item>
                  <a-menu-item key="7">
                    外交部发言人言论
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub3">
                  <span slot="title"><span>史料文献</span></span>
                  <a-menu-item key="8">
                    经典著作
                  </a-menu-item>
                  <a-menu-item key="9">
                    领袖著作
                  </a-menu-item>
                  <a-menu-item key="10">
                    建国前重要文献选编
                  </a-menu-item>
                  <a-menu-item key="11">
                    建国以来重要文献选编
                  </a-menu-item>
                  <a-menu-item key="12">
                    新时期重要文献选编
                  </a-menu-item>
                  <a-menu-item key="13">
                    新时期专题文献选编
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub4">
                  <span slot="title"><span>重要人物</span></span>
                  <a-menu-item key="14">
                    领袖人物
                  </a-menu-item>
                  <a-menu-item key="15">
                    党史人物
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub5">
                  <span slot="title"><span>重大事件</span></span>
                  <a-menu-item key="16">
                    党史大事
                  </a-menu-item>
                  <a-menu-item key="17">
                    重大事件
                  </a-menu-item>
                </a-sub-menu>
              </a-menu>
            </div>
            <div class="box0_right">
              <div class="box_right_top">
                <div class="left1">
                  <img class="fiveStar" src="../../assets/site/thoughtParty/fiveStar.png" alt="">
                  <span class="level1">{{level1Name}}</span>
                  <img v-if="level2Name" class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter.png" alt="">
                  <img class="boxImgCenter" v-else src="../../assets/site/thoughtParty/boxImgCenter1.png" alt="">
                  <span class="level2" v-if="level2Name">· {{level2Name}}</span>
                  <img v-if="level2Name"  class="boxImgRight" src="../../assets/site/thoughtParty/boxImgRight.png" alt="">
                </div>
                <div class="right1"></div>
              </div>
              <div class="detail" v-if="detail0">
                <div class="title2">{{detail0.title}}</div>
                <div class="title1">{{detail0.sub_title}}</div>
                
                <div class="info">
                  <div class="date">
                    <img src="../../assets/site/thoughtParty/date.png" alt="" class="dateImg">
                    <span class="dateText">时间：{{detail0.date}}</span>
                  </div>
                  <div class="source" v-if="detail0.source"> 
                    <img src="../../assets/site/thoughtParty/source.png" alt="" class="sourceImg">
                    <span class="sourceText" >来源：{{detail0.source}}</span>
                  </div>
                </div>
                 <div class="content" v-html="detail0.content"></div>
              </div>
              <div class="noData" v-if="!hasData0">
                <img src="../../assets/site/thoughtParty/noData.png" alt="" class="noDataImg">
                <div class="tip">
                  <span>对不起，您暂无查看权限，请联系管理员为您开通授权。</span>
                </div>
              </div>
            </div>
        </div>
        <div class="box2" v-show="tabIndex=='2'">
          <div class="box_left">
             <a-menu
                style="width: 260px"
                :open-keys="openKeys2"
                mode="inline"
                theme="light"
                :selected-keys="[current2]"
                @click="handleClick2"
                @openChange="onOpenChange2"
              >
                <a-sub-menu key="sub1">
                  <span slot="title"><span>党建学习</span></span>
                  <a-menu-item key="1">
                    党章党规
                  </a-menu-item>
                  <a-menu-item key="2">
                    支部党课
                  </a-menu-item>
                  <a-menu-item key="3">
                    党建研究
                  </a-menu-item>
                  <a-menu-item key="4">
                    党务知识
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub2">
                  <span slot="title"><span>党建视听</span></span>
                  <a-menu-item key="5">
                    党史微课
                  </a-menu-item>
                  <a-menu-item key="6">
                    国史讲堂
                  </a-menu-item>
                  <a-menu-item key="7">
                    国家日历
                  </a-menu-item>
                  <a-menu-item key="8">
                    党史随时学
                  </a-menu-item>
                  <a-menu-item key="9">
                    综合
                  </a-menu-item>
                </a-sub-menu>
              </a-menu>
            </div>
            <div class="box2_right">
              <div class="box_right_top">
                <div class="left1">
                  <img class="fiveStar" src="../../assets/site/thoughtParty/fiveStar.png" alt="">
                  <span class="level1">{{level1NameBox2}}</span>
                  <img v-if="level2NameBox2" class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter.png" alt="">
                  <img v-else class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter1.png" alt="">
                  <span class="level2" v-if="level2NameBox2">· {{level2NameBox2}}</span>
                  <img v-if="level2NameBox2" class="boxImgRight" src="../../assets/site/thoughtParty/boxImgRight.png" alt="">
                </div>
                <div class="right1"></div>
              </div>
              <div class="detail" v-if="detail2">
                <div class="title2">{{detail2.title}}</div>
                <div class="title1">{{detail2.sub_title}}</div>
                <div class="info">
                  <div class="date">
                    <img src="../../assets/site/thoughtParty/date.png" alt="" class="dateImg">
                    <span class="dateText">时间：{{detail2.date}}</span>
                  </div>
                  <div class="source" v-if="detail2.source">
                    <img src="../../assets/site/thoughtParty/source.png" alt="" class="sourceImg">
                    <span class="sourceText">来源：{{detail2.source}}</span>
                  </div>
                </div>
                 <div class="content" v-html="detail2.content"></div>
              </div>
              <div class="noData" v-if="!hasData2">
                <img src="../../assets/site/thoughtParty/noData.png" alt="" class="noDataImg">
                <div class="tip">
                  <span>对不起，您暂无查看权限，请联系管理员为您开通授权。</span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

     <!-- 联系我们 -->
		<div class="contact-us-box">
			<div class="c">
				<div class="contact-text">联系我们，获取更详细服务方案！</div>
				<div class="contact-btn" @click="goToNeed">立即咨询</div>
			</div>
		</div>
     <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import Footer from "../../components/Footer.vue";
import services from "../../config/services"
import axios from "axios";
export default {
  name: "thoughtDetails",
  components:{
    Footer,
  },
  data(){
    return{
      tabIndex: parseInt(this.$route.query.tabIndex),
      current0: '2',//课程专题左侧导航栏目索引   
      current2:'1',//学习专区左侧导航栏目索引   
      detail0:null,//tab0详情页数据
      detail2:null,//学习专区详情页数据
      hasData0:true,
      hasData2:true,
      openKeys0: [],
      openKeys2: [],
      level1Name:"国家概况",//以及栏目标题
      level2Name:"",//二级栏目标题
      level1NameBox1:"新闻传播大类",
      level1NameBox2:"党建学习",
      level2NameBox2:"党章党规",
    }
  },
  created(){
      if(this.tabIndex==0){
        this.current0 = this.$route.query.current;
        this.openKeys0 = JSON.parse(this.$route.query.openKeys); 
        this.level1Name = this.$route.query.level1Name;
        this.level2Name =  this.$route.query.level2Name;
      }else{
        this.current2 = this.$route.query.current;
        this.openKeys2 = JSON.parse(this.$route.query.openKeys); 
        this.level1NameBox2 = this.$route.query.level1NameBox2;
        this.level2NameBox2 =  this.$route.query.level2NameBox2;
      }
  },
  mounted(){
      let that = this;
      axios.get(`${services.rmtUrl}/api/people/article/${that.$route.query.articleId}`).then(function(res){
        if(res.data.code=="0000"){
          if(that.tabIndex==0){
              that.detail0 = res.data.data;
              that.detail0.content = that.detail0.content.replace(/preload="none"/g,` `);
          }else{
              that.detail2 = res.data.data;
              that.detail2.content = that.detail2.content.replace(/preload="none"/g,` `);
          }
        }else{
            if(that.tabIndex==0){
               this.hasData0 = false;
            }else{
               this.hasData2 = false;
            }
        }
      })
  },
  methods:{
    chanageTab(index){
        this.$router.push({
            path: '/djsz',
            query:{
                tabIndex:index
            }
        });
    },
    handleClick0(e) {
        this.$router.push({
            path: '/djsz',
            query:{
                tabIndex:this.tabIndex,
                current:e.key,
                openKeys:JSON.stringify(e.key==1? []:this.openKeys0),
                level1Name:this.level1Name,
                level2Name:this.level2Name
            }
        });
    },
    handleClick2(e) {
        if(e.key==1){
            this.level2NameBox2 = "党章党规";
        }else if(e.key==2){
            this.level2NameBox2 = "支部党课";
        }else if(e.key==3){
            this.level2NameBox2 = "党建研究";
        }else if(e.key==4){
            this.level2NameBox2 = "党务知识";
        }else if(e.key==5){
            this.level2NameBox2 = "党史微课";
        }else if(e.key==6){
            this.level2NameBox2 = "国史讲堂";
        }else if(e.key==7){
            this.level2NameBox2 = "国家日历";
        }else if(e.key==8){
            this.level2NameBox2 = "国史随时学";
        }else if(e.key==9){
            this.level2NameBox2 = "综合";
        }
        this.$router.push({
            path: '/djsz',
            query:{
                tabIndex:this.tabIndex,
                current:e.key,
                openKeys:JSON.stringify(this.openKeys2),
                level1NameBox2:this.level1NameBox2,
                level2NameBox2:this.level2NameBox2
            }
        });
    },
    goToNeed(){
      utils.redirect(this, "/need", true);
    },
    onOpenChange0(openKeys0) {
        if(openKeys0.length>1){
            openKeys0.shift();
        }
        this.openKeys0 = openKeys0;
    },
    onOpenChange2(openKeys2) {
      openKeys2.shift();
      this.openKeys2 = openKeys2;
    },
    goLogin (){
      this.$store.dispatch('goToAllLogin');
    },
  },
};
</script>

<style lang="less" scoped>
.thoughtParty {
  font-family: Microsoft YaHei;
  .top1 {
    height: 58px;
    background: #d21010;
    .top1_center {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      .top1_center_left{
        flex: 1;
        display: flex;
        align-items: center;
      }
      .top1_center_right{
        flex: 1;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .tabs{
        flex: 1.5;
        display: flex;
        justify-content: space-evenly;;
      }
      .line {
        opacity: 0.74;
        color: #f4853a;
        margin-left: 15px;
        margin-right: 15px;
      }
      .top1_title1 {
        font-size: 18px;
        font-weight: bold;
        color: #feefaa;
      }
      .top1_tab {
        font-size: 16px;
        font-weight: 400;
        color: #f9e2de;
      }
      .top1_tab2{
        margin-left: 30px;
        margin-right: 30px;
      }
      .top1_actived {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
      }
      .top1_company{
        font-size: 12px;
        font-weight: 400;
        color: #FCCD7F;
        margin-right: 10px;
      }
      .top1_img2{
        display: inline-block;
        float: right;
      }
    }
  }
  .top2{
    background: url("../../assets/site/thoughtParty/top2_bg.png") no-repeat;
    height: 180px;
    background-size: 100% 100%;
    text-align: center;
    padding-top: 40px;
    .top2_title{
      text-align: center;
      font-size: 26px;
      color: #CE181F;
      background-image: -webkit-linear-gradient(bottom, rgba(249, 100, 18), rgba(219, 3, 3));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .top2_img{
      margin-top: 15px;
    }
    .titleImg{
      display: block;
      margin: 0 auto;
    }
  }
  .body{
    background: #fff;
    .body_tabs{
      height: 54px;
      width: 100%;
      border-bottom: 2px solid #F2F2F2;
      .body_tabs_center{
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        .tabItem{
          flex: 1;
          height: 100%;
          line-height: 54px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #646161;
          cursor: pointer;
          border-right: 2px solid #FEF6F5;
        }
        .tabItem:hover{
          background: #FAEFED;
        }
        .tabItem_actived{
          background: #FEF6F5;
          border-radius: 1px;
          color: #DB0303;
        }
      }
    }
    .body_box{
      width: 1200px;
      margin: 0 auto;
      margin-top: 36px;
      min-height: 600px;
      overflow: hidden;
      padding-bottom: 30px;
      .box_left{
        float: left;
        li.ant-menu-item {
          background: #F7F7F7;
          margin-top: 0px;
          margin-bottom: 2px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          letter-spacing: 2px;
        }
        li.ant-menu-submenu{
          background: #F7F7F7;
          line-height: 45px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          margin-bottom: 2px;
        }
        li.ant-menu-submenu:hover{
            background: #FAEFED;
        }
        li.ant-menu-submenu li.ant-menu-item{
          background: unset;
        }
        li.ant-menu-item-selected{
          background: #FAEFED;
          font-size: 16px;
          color: #DB0303;
          font-weight: bold;
          letter-spacing: 2px;
        }
        .ant-menu-inline .ant-menu-item-selected::after{
          opacity: 0;
        }
        /deep/.ant-menu-inline .ant-menu-submenu-title{
          height: 45px;
          line-height: 45px;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          letter-spacing: 2px;
        }
        /deep/.ant-menu-submenu-selected{
          background: #FAEFED !important;
          font-size: 16px;
          color: #DB0303 !important;
        }
        .ant-menu-inline .ant-menu-item{
          font-size: 16px;
        }
        /deep/.ant-menu-submenu-title:active{
          background: #F7F7F7;
        }
        /deep/.ant-menu-inline{
          border: none;
        }
        /deep/.ant-menu-sub.ant-menu-inline{
          border: 1px solid #f0f0f0;
        }
      }
      .box0_right,.box1_right,.box2_right{
        margin-left: 40px;
        float: left;
        width: calc(100% - 300px);
        .box_right_top{
          border-bottom: 14px solid #FFEEEB;
          height: 37px;
          .left1{
            height: 40px;
            line-height: 40px;
            width: 50%;
            float: left;
            .level1{
              font-weight: bold;
              float: left;
              font-size: 18px;
              color: #FFFFFF;
              margin-left: -15px;
              margin-top: 5px;
              background: #DB0303;
              height: 32px;
              line-height: 32px;
              display: inline-block;
              padding-right: 20px;
              padding-left: 35px;
              letter-spacing: 3px;
            }
            .level2{
              float: left;
              font-size: 14px;
              font-weight: bold;
              color: #E57F01;
              margin-top: 5px;
              height: 32px;
              line-height: 32px;
              background: #FFEFD6;
              padding-right: 20px;
              padding-left: 10px;
            }
            .fiveStar{
              float: left;
              width: 40px;
              position: relative;
            }
            .boxImgCenter,.boxImgRight{
              float: left;
              margin-top: 5px;
            }


          }
          .right1{
            background: url("../../assets/site/thoughtParty/boxImg3.png") no-repeat;
            height: 26px;
            float: right;
            width: 122px;
            margin-top: 14px;
          }
        }
        .detail{
          .title1{
            margin-top: 10px;
            font-size: 16px;
            color: #555555;
            text-align: center;
          }
          .title2{
            font-size: 26px;
            color: #272727;
            text-align: center;
            margin-top: 30px;
          }
          .info{
            height: 35px;
            line-height: 35px;
            margin-top: 30px;
            background: #F5F5F5;
            font-size: 14px;
            color: #888888;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: row;
            .date{
              display: flex;
              align-items: center;
            }
            .dateImg{
              display: inline-block;
              margin-right: 10px;
            }
            .source{
              display: flex;
              align-items: center;
            }
            .sourceImg{
              display: inline-block;
              margin-right: 10px;
            }
          }
          .content{
            margin-top: 20px;
            padding: 0 50px;
            line-height: 34px;
            /deep/video{
                width: 80% !important;
                height: 415px;
                margin: 0 auto;
                margin-bottom: 15px;
            }
            /deep/img{
                margin: 10px 0;
            }
          }


        }
        .noData{
          text-align: center;
          margin-bottom: 60px;
          .noDataImg{
            margin-top: 100px;
          }
          .tip{
            margin-top: 20px;
            font-size: 16px;
            color: #555555;
            .login{
              color: #DB0303;
              margin:0 15px;
              display: inline-block;
              position: relative;
            }
            .login::after{
              content: "";
              width: 35px;
              height: 1px;
              background: #D21010;
              border-radius: 1px;
              bottom: 0px;
              left: 0px;
              display: block;
            }
          }
        }
      }
      
    }
  }
  .contact-us-box {
    height: 182px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(81deg, #C60506 0%, #B1002E 47%, #C60506 100%);
    text-align: center;
    position: relative;
    overflow: hidden;
    .pos-img-left {
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100%;
      width: auto;
      display: inline-block;
    }
    .pos-img-right {
      position: absolute;
      z-index: 1;
      right: 0;
      height: 100%;
      width: auto;
      display: inline-block;
    }
    .c {
      padding: 40px 0 0;
      position: relative;
      z-index: 10;
      top: 0;
    }
    .contact-text {
      font-size: 22px;
      color: #FFFFFF;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .contact-btn {
      width: 140px;
      height: 40px;
      line-height: 38px;
      border: 1px solid #FFFFFF;
      border-radius: 15px;
      text-align: center;
      color: #FFFFFF;
      font-size: 18px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
li.ant-menu-item-active{
    background: #FAEFED !important;
}

</style>
<style>
.ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow:before,.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    background-image: linear-gradient(90deg,#B8B8B8,#B8B8B8) !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
    background: linear-gradient(to right, #DB0303, #DB0303) !important;
}
</style>