<template>
  <div class="live-all-box">
    <div class="live-header-box">
      <div class="eol-logo"></div>
      <div class="live-playback"></div>
    </div>
    <div class="live-main-box">
      <video class="live-video-box" id="live-video-box"></video>
      <div class="live-title">浙江同济科技职业学院 思政课实践教学成果展示活动</div>
      <div class="bottom">
        <img src="../../assets/zbhf/bottom.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../../config/utils'
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.initVideoFile("live-video-box", '3270835009246833938');
    document.oncontextmenu = new Function("event.returnValue=false");
  },
  methods: {
    // 初始化视频插件
    initVideoFile (elemId, qclude_id) {
      utils.loadScript(window.location.origin + "/school/js/hls.min.0.8.8.js", () => {
        utils.loadScript(window.location.origin + "/school/js/tcplayer.min.js", () => {
          if (typeof window.TCPlayer === "function") {
            window.TCPlayer(elemId, {
              fileID: qclude_id, // 请传入需要播放的视频filID 必须
              controls: true,
              appID: "1500004122", // 请传入点播账号的appID 必须
              width: document.body.clientWidth, //视频的显示宽度
              height: 210, //视频的显示高度
              definition: 30,
              autoplay: false,
              plugins: {
              },
            });
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.live-all-box {
  background: #0c0f21;
  width: 750px;
  margin: 0 auto;
  .live-header-box {
    height: 100px;
    line-height: 100px;
    padding: 0 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .eol-logo {
      width: 224px;
      height: 44px;
      background-size: cover;
      background-image: url('../../assets/zbhf/logo-eol.png');
    }
    .live-playback {
      width: 129px;
      height: 26px;
      background-size: cover;
      background-image: url('../../assets/zbhf/playback.png');
    }
  }
  .live-main-box {
    .live-video-box {
      width: 100%;
      height: 422px;
    }
    .live-title {
      padding: 12px 30px;
      font-size: 32px;
      font-weight: bold;
      color: #dbddea;
      line-height: 44px;
    }
    .bottom {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
</style>