<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/sdcmc/logo.png" alt="" class="logo">
          <div class="headMain">
            <img src="../../../../assets/sdcmc/schoolLogo.png" alt="" class="schoolLogo">
            <span class="bgTitle">课程思政教学研究中心</span>
          </div>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}`" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/course`" exact>示范课程</router-link>
          </li>
          <li :class="listIndex=='1'?'actived nav_item':'nav_item'">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/1`" exact>新闻动态</router-link>
          </li>
          <li :class="listIndex=='2'?'actived nav_item':'nav_item'">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/2`" exact>通知文件</router-link>
          </li>
          <li :class="listIndex=='3'?'actived nav_item':'nav_item'">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/3`" exact>媒体报道</router-link>
          </li>
          <li :class="listIndex=='4'?'actived nav_item':'nav_item'">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/4`" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a :href="`https://bk.jiaoyu.cn/${utils.sdcmzyxyCode}/lesson/distribution`" target="_blank">课程思政资源管理平台</a>
          </li>
        </ul>
      </div>
    </header>
    <template v-if="listIndex == '1'">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/1`">
                喜报！学院课程思政示范项目获得重大突破
              </router-link>
            </div>
            <div class="intro">
              日前，教育部对拟立项国家级职业教育课程思政示范项目进行公示，学院祝瑞玲教授主持申报的示范项目《数字电视技术原理与应用》在列，在此之前，学院申报的7项课程思政示范项目已全部获批省级立项。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/2`">
                学院举行第三届教师教学能力大赛暨课程思政教学比赛
              </router-link>
            </div>
            <div class="intro">
              为深入贯彻落实习近平总书记对职业教育工作重要指示和全国职业教育大会精神，持续深化“三教”改革，落实“课程思政”要求，推进高水平、结构化教师教学团队建设，进一步提升学院教育教学质量，5月22日，学院举行第三届教师教学能力大赛暨课程思政教学比赛。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/3`">
                学院组织教师参加课程思政专题培训
              </router-link>
            </div>
            <div class="intro">
              为进一步提升学院教师课程思政建设水平，巩固立德树人成效，推动课程思政立项项目的内容和形式创新，5月29日，学院组织9名教师参加了为期两天的“全国高等院校课程思政认知升级与设计创新工作坊”。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/4`"> 我院国家级课程思政教学团队在北京参加集体备课 </router-link>
            </div>
            <div class="intro">
              9月24日，全国职业院校新闻传播专业大类课程思政集体备课会在中国传媒大学进行，我院国家级课程思政教学团队成员张卫东、祝瑞玲、贾庆萍应邀参会并作经验分享和教学展示。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                学院赴莘县考察调研并走访慰问“四进”干部
              </a>
            </div>
            <div class="intro">
              12月10日，省广电局人事处处长魏茂洋和学院党委委员、副院长张涛一行6人到莘县开展考察调研，同时走访慰问学院派驻“四进”攻坚工作组干部。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == 2">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/6`">
                关于举办第五届山东传媒职业学院新媒体作品大赛的通知
              </router-link>
            </div>
            <div class="intro">
              在新一代信息技术的驱动下，我国新媒体发展不断实现创新变革，应用场景更加丰富，产业格局与生态持续优化。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">发挥带头表率作用，确保校园安全稳定---致学院党员干部职工的倡议书</a>
            </div>
            <div class="intro">
              安全责任重于泰山。为深入贯彻落实习近平总书记关于校园安全的重要指示精神，全面落实全省学校安全工作电视电话会议要求，统筹抓好校园安全稳定和疫情防控常态化工作，充分发挥党员干部职工在学院事业发展中“走在前，作表率”的模范带头作用，为学院事业发展提供坚强保障，特发出如下倡议。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 致学生家长的一封信 </a>
            </div>
            <div class="intro">
              您好!为了更好的保障孩子们的人身安全，我们特致信给您，希望您积极配合学校，认真履行监护人职责，教育、督促孩子做好安全防范工作
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                河南学子请报平安 山传与你风雨共担
              </a>
            </div>
            <div class="intro">
              全国部分省市已进入汛期，各地暴雨频发，多地遭受洪涝灾害影响，尤其是河南省遭遇极端强降雨。学院领导高度关注，时刻牵挂每一位同学，并制定了全方位的关心关爱方案，积极为受灾学生提供关心和帮扶。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                山东传媒职业学院2022届毕业生实习就业招聘双选会邀请函
              </a>
            </div>
            <div class="intro">
              为贯彻落实国家及省内有关毕业生实习就业政策，做好毕业生就业工作,满足用人单位对人才的需求，更好地帮助毕业生顺利就业，学院决定举办“2022届毕业生实习就业招聘双选会”。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == 3">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/11`">
                “点亮星空 融爱未来”！ 关爱“来自星星的孩子”大型公益活动启动
              </router-link>
            </div>
            <div class="intro">
              自闭症儿童，又被称为“来自星星的孩子”。因为疾病，他们缺乏基本的生存技能与社交技能，甚至会因怪异的举止受到排斥和歧视，就像天上的星星，在漆黑夜空中独自闪烁着。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                山东传媒职业学院实施“2+8”突破项目扎实推进“高地建设”
              </a>
            </div>
            <div class="intro">
              近年来，山东传媒职业学院立足学院发展实际，积极抢抓职教高地建设机遇，对标找差距，对表抓落实，逐级推进，逐年深入，围绕高地建设，办学质量和事业单位绩效考核，以“一校一案”为目标，以“2+8”项目为突破口，补短强弱、精准施策，学院各项事业都取得新进展。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 抓党建 创品牌 强优势 惠民生 </a>
            </div>
            <div class="intro">
              党史学习教育开展以来，山东传媒职业学院认真落实学党史、悟思想、办实事、开新局的目标要求，重点聚焦“我为群众办实事”具体行动，突出党建“先锋”性质和“服务”功能，围绕社会、师生急难愁盼，依托传媒专业技能特色，借助名师工作室资源优势，精心策划、主动对接，开展了“为群众办实事”系列实践活动
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                山东传媒职业学院举行2021“感动山传”十大励志青年人物颁奖典礼
              </a>
            </div>
            <div class="intro">
              追梦永不停歇，感动永在路上。11月16日，山东传媒职业学院2021“感动山传”十大励志青年人物颁奖典礼在学院600平方米演播厅举行。
            </div>
          </li>

          <li class="list_item">
            <div class="title">
              <a href="">
                山东传媒职业学院举行“互联网+”大学生创业大赛启动仪式暨备赛说明会
              </a>
            </div>
            <div class="intro">
              4月29日上午山东传媒职业学院举行“互联网+”大学生创业大赛启动仪式暨备赛说明会。特邀创业导师张伟民授课。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == '4'">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/16`">
                习近平：在纪念辛亥革命110周年大会上的讲话
              </router-link>
            </div>
            <div class="intro">
              110年前，以孙中山先生为代表的革命党人发动了震惊世界的辛亥革命，推翻了清朝政府，结束了在中国延续几千年的君主专制制度，近代以来中国发生的深刻社会变革由此拉开了序幕。这是中国人民和中国先进分子为实现民族独立、人民解放进行的一次伟大而艰辛探索。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 习近平向2021年世界互联网大会乌镇峰会致贺信 </a>
            </div>
            <div class="intro">
              习近平指出，数字技术正以新理念、新业态、新模式全面融入人类经济、政治、文化、社会、生态文明建设各领域和全过程，给人类生产生活带来广泛而深刻的影响。当前，世界百年变局和世纪疫情交织叠加，国际社会迫切需要携起手来，顺应信息化、数字化、网络化、智能化发展趋势，抓住机遇，应对挑战。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                坚定信心 共克时艰 共建更加美好的世界
                ——在第七十六届联合国大会一般性辩论上的讲话 （2021年9月21日）
              </a>
            </div>
            <div class="intro">
              2021年对中国人民是一个极其特殊的年份。今年是中国共产党成立100周年。今年也是中华人民共和国恢复在联合国合法席位50周年，中国将隆重纪念这一历史性事件。我们将继续积极推动中国同联合国合作迈向新台阶，为联合国崇高事业不断作出新的更大贡献。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                习近平在上海合作组织成员国元首理事会第二十一次会议上的讲话
              </a>
            </div>
            <div class="intro">
              不忘初心
              砥砺前行，开启上海合作组织发展新征程，——在上海合作组织成员国元首理事会第二十一次会议上的讲话，中华人民共和国主席
              ——习近平
            </div>
          </li>

          <li class="list_item">
            <div class="title">
              <a href="">
                坚定决心意志 埋头苦干实干 确保如期实现建军一百年奋斗目标
              </a>
            </div>
            <div class="intro">
              实现建军一百年奋斗目标，是党中央和中央军委把握强国强军时代要求作出的重大决策，是关系国家安全和发展全局的重大任务，是国防和军队现代化新“三步走”十分紧要的一步。要坚定决心意志，增强紧迫意识，埋头苦干实干，确保如期实现既定目标。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
import utils from '../../../../config/utils';
import services from '../../../../config/services';
export default {
  name: "list",
  data () {
    return {
      utils,
      listIndex: null,
      services,
    };
  },
  created () {
    window.open(services.KCSZ_SERVER + '/' + utils.sdcmzyxyCode, '_self')
  },
  mounted () {
    this.listIndex = this.$route.params.id;
  },
  watch: {
    $route: {
      handler () {
        this.listIndex = this.$route.params.id;
      },
    },
    deep: true,
  },
};
</script>

<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./list.css" scoped>
</style>
<style src="../../../../assets/sdcmc/common.css" scoped>
</style>