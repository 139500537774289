import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import {Collapse,Button,Icon,Radio,Checkbox,Input,Pagination,FormModel,Upload,Modal,Progress,Menu,ConfigProvider,Table,Carousel,Anchor} from "ant-design-vue";
//富文本编辑器组件
import VueQuillEditor from "vue-quill-editor/src";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//分片上传组件
import webuploader from "webuploader"//分片上传
window.webuploader=webuploader;

Vue.use(VueQuillEditor);
Vue.use(Collapse);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Pagination);
Vue.use(FormModel);
Vue.use(Modal);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Menu);
Vue.use(ConfigProvider);
Vue.use(Table);
Vue.use(Carousel);
Vue.use(Anchor);
Vue.config.productionTip = false
// import style
// import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

//注入全局属性$message
import { message, notification } from 'ant-design-vue'
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
message.config({
    duration: 2,// 持续时间
    top:`45%`, // 到页面顶部距离
    maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})

// console.log(2, VueCookies);
// console.log('this.$cookies', this.$cookies);
const app = new Vue({
  router,
  store,
  render: h => h(App)
})

window.onload = function() {
  app.$mount('#app')
}
