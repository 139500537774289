<template>
  <div class="satisfiedBox">
    <div class="title">活动满意度评价</div>
    <div class="title_info">
      请对参加此次《智造农场
      数创未来》线上科普活动的整体感受进行评估和总结。并进行满意度评级，“五星”表示对本次活动非常满意。
    </div>
    <div class="content">
      <div
        class="satisfied_item"
        v-for="(item, ind) in satisfiedInfo"
        :key="ind"
        @click="satisfiedNum = ind + 1"
      >
        <div :class="['item_icon', `bg${ind + 1}`,{active: satisfiedNum == ind + 1}]"></div>
        <div class="item_info">{{ item }}</div>
      </div>
      <div class="issue_btn" @click="sendSatisfied">发布</div>
    </div>
  </div>
</template>

<script>
import services from "../config/services";
export default {
  name: "zzncSatisfied",
  data() {
    return {
      satisfiedInfo: ["很糟糕", "一般", "还可以", "比较满意", "非常满意"],
      satisfiedNum: 5,
    };
  },
  computed:{
    userAllInfo() {
      return this.$store.state.appsLogin.appsUserInfo;
    },
  },
  userAllInfo() {
      return this.$store.state.appsLogin.appsUserInfo;
    },
  methods:{
    async sendSatisfied() {
         let res = await services.sendSatisfied({score: this.satisfiedNum});
         if(res.code == "0000") {
          this.$store.dispatch("getSatisfiedInfo", {user_id: this.userAllInfo.id});
         }
    }
  }
};
</script>

<style lang="less" scoped>
.satisfiedBox {
  z-index: 1001;
  width: 550px;
  height: 258px;
  background-color: #fff;
  position: absolute;
  bottom: 56px;
  right: 0;
  padding: 33px 30px 0;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 1;
  }
  .title_info {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 25px;
  }
  .content {
    height: 100px;
    width: 100%;
    margin-top: 23px;
    background: #fafbfc;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .satisfied_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .item_icon {
        width: 45px;
        height: 45px;
        background-size: 100%;
         &.bg1 {
                background-image: url("../assets/createFarm/terrible.png");
                &.active {
                  background-image: url("../assets/createFarm/terrible-hover.png");
                }
              }
              &.bg2 {
                background-image: url("../assets/createFarm/ordinary.png");
                &.active {
                 background-image: url("../assets/createFarm/ordinary-hover.png");
                }
              }
              &.bg3 {
                background-image: url("../assets/createFarm/fine.png");
                &.active {
                  background-image: url("../assets/createFarm/fine-hover.png");
                }
              }
              &.bg4 {
                background-image: url("../assets/createFarm/satisfied.png");
                &.active {
                  background-image: url("../assets/createFarm/satisfied-hover.png");
                }
              }
              &.bg5 {
                background-image: url("../assets/createFarm/very_satisfied.png");
                &.active {
                  background-image: url("../assets/createFarm/very_satisfied_hover.png");
                }
              }
      }
      .item_info {
        line-height: 1;
        margin-top: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .issue_btn {
      background-color: #125CFD;
      color: #fff;
      width: 35px;
      height: 60px;
      padding: 8px 10px;
      cursor: pointer;
    }
  }
}
</style>