<template>
  <div>
    <Nav />
    <div class="userPage">
      <div class="c h">
        <div class="userLeft fl">
          <div class="photo">
            <img v-if="loginUserInfo && loginUserInfo.user_open_data && loginUserInfo.user_open_data.weixin && loginUserInfo.user_open_data.weixin.photo" :src="loginUserInfo.user_open_data.weixin.photo" />
            <img v-else src="../../assets/user.png" />
            <!-- <p class="userName">{{$store.state.login.userInfo.username}}，{{$store.state.login.userInfo.type == 1 ? '同学' : '老师'}}</p> -->
            <p class="userName">{{ loginUserInfo && loginUserInfo.name }}</p>
          </div>
          <ul class="Userlist">
            <li>
              <p><span class="iconfont iconkecheng"></span> 课程学习</p>
              <dd>
                <router-link class="dd" :to="'/user/myClass'" exact>
                  我的课程
                  <span class="iconfont iconsanjiaoright fr"></span>
                </router-link>
              </dd>
              <dd>
                <router-link class="dd" :to="'/user/studyRecord'" exact>
                  学习记录
                  <span class="iconfont iconsanjiaoright fr"></span>
                </router-link>
              </dd>
              <dd>
                <router-link class="dd" :to="'/user/myNote'" exact>
                  我的笔记
                  <span class="iconfont iconsanjiaoright fr"></span>
                </router-link>
              </dd>
            </li>
            <li>
              <p><span class="iconfont iconshezhi"></span> 信息设置</p>
              <dd>
                <router-link class="dd" :to="'/user/userMessage'">
                  个人信息
                  <span class="iconfont iconsanjiaoright fr"></span>
                </router-link>
              </dd>
              <dd>
                <router-link class="dd" :to="'/user/updatePassword'" exact>
                  修改密码
                  <span class="iconfont iconsanjiaoright fr"></span>
                </router-link>
              </dd>
            </li>
          </ul>
        </div>
        <div class="userRight fr">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import "./user.less";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { mapActions, mapState } from "vuex";
export default {
  name: "User",
  components: {
    Nav: Nav,
    Footer: Footer
  },
  computed: {
    ...mapState({
      loginUserInfo: (state) => state.appsLogin.appsUserInfo,
    })
  },
};
</script>

<style scoped>
.main-top {
  background-color: #fff !important;
  background: #fff !important;
}
</style>
