import axios from "axios";
axios.defaults.withCredentials = true;  //允许跨域携带cookies
import Vue from 'vue'
import services from '../config/services'
import utils from '../config/utils'

export default {
  state:{
    mediaData: {},
    mediaTotal: 0,
    newsDetail: null,
  },
  getters:{
  },
  actions: {
    // 人民网cms接口调用方法 - 20231222弃用
    // loadMediaData(context, payload){
    //   let timestamp = Date.parse(new Date());
    //   axios.get(`${services.MEDIA_SERVER}/${payload.type}/NewsList_${payload.page}.json?${timestamp}`).then(resData => {
    //     // console.log("resData", resData);
    //     if(resData.data){
    //       context.commit('loadMediaData', {
    //         'mediaData': resData.data || {}
    //       })
    //       return resData;
    //     }
    //   })
    // },
    // 全媒体运营cms栏目文章列表接口调用方法
    loadMediaData(context, payload){
      let params = {
        site_id: utils.ZJYK_SITE_ID,
        ...payload.data,
      }
      if(payload && payload.type){
        params.category_id =  utils.getZjykCategoryId(payload.type)
      }
      return services.getQmtyyNewsList(params).then((res) => {
        if(res.code == '0000'){
          context.commit('setMediaData', {
            'mediaData': res.data || {}
          })
        }
      })
    },
    // 全媒体运营cms栏目文章详情接口调用方法
    loadMediaDetail(context, payload){
      let params = {
        id: payload.id,
        data: {site_id: utils.ZJYK_SITE_ID,}
      }
      return services.getQmtyyNewsDetail(params).then((res) => {
        if(res.code == '0000'){
          context.commit('setNewsDetail', {
            'data': res.data || {}
          })
        }
      })
    },
    submitNeedForm(context, payload) {
      return services.messageBoard(payload).then((res) => {
        return res
      })
    },
  },
  mutations:{
    setMediaData (state, payload) {
      Vue.set(state, "mediaData" ,payload.mediaData)
      Vue.set(state, "mediaTotal" , parseInt(Number(payload.mediaData.total)))
    },
    setNewsDetail(state, payload) {
      Vue.set(state, "newsDetail" ,payload.data)
    },
  }
}