<template>
  <div class="bg-all-box">
    <Nav />
    <div class="service-all-box">
      <div class="service-desc-bg">
        <img src="../../assets/site/service/banner.jpg" />
        <div class="c">
          <div class="bg-con-box">
            <div class="title">助力院校复合型全媒体人才培养</div>
            <div class="title-border"></div>
              <p>创新产教融合，紧跟媒体深度融合发展要求，围绕“教学、实训、人才培养与行业需求”三个核心需求点，将理论与实践紧密结合、全面为培养媒体方向的技能型、应用型人才助力。</p>
              <p>实训体系以培养“全能型媒体”人才为核心，结合“职业教育特色”，融合“产业技术、企业级经验与专业教学设计”为一体；从<span>内容审核、媒体融合运营和互联网舆情管理</span>等方面，支撑全媒体技术技能人才培养的职业技能体系。
              </p> 
          </div>
        </div>
      </div>
      <div class="second-box">
        <div class="charter-lines">
          <img class="fx-left" src="../../assets/site/service/fx-left.png" />
          <span>服务产品</span>
          <img class="fx-right" src="../../assets/site/service/fx-right.png" />
        </div>
        
        <div class="c">
          <div class="category-three-box">
            <div class="three-item">
              <div class="three-header">
                <div class="header-title">教学资源</div>
              </div>
              <div class="three-body">
                <img class="ellipse-box top" src="../../assets/site/service/ellipse2.png" />
                <img class="ellipse-box bottom" src="../../assets/site/service/ellipse1.png" />
                <p class="content-txt">借助多年运营中国教育在线融媒体平台丰富的行业经验、对全媒体相关领域发展的深入理解，多年深耕职业教育领域、深度参与媒体类1+X职业证书标准制定、教材开发；充分发挥传媒企业与教学标准的结合优势、结合人民在线媒体领域相关课程特点，开发出符合现代传媒行业要求、针对核心职业技能素养教育、紧贴不同层次院校教学特点的完整教学资源体系，全心助力高校教学育人。</p>
                <router-link target="_blank" to="/service/teaching" exact class="three-btn active">查看详细</router-link>
              </div>
            </div>

            <div class="three-item">
              <div class="three-header">
                <div class="header-title">在线学习平台</div>
              </div>
              <div class="three-body">
                <img class="ellipse-box top" src="../../assets/site/service/ellipse2.png" />
                <img class="ellipse-box bottom" src="../../assets/site/service/ellipse1.png" />
                <p class="content-txt">在线学习平台致力于为学校提供教务教学管理服务，提高学校线上教学、教务管理效率，开放学校自有门户网站，提升学校的品牌形象；</p>
                <p class="content-txt">为教师、学生提供集内容、平台、服务一体化的混合式教学服务平台，通过在线课程学习、互动问答、题库练习、模拟考试等，持续提升学习体验度。打破技术的壁垒，提供一站式解决方案。</p>
                <router-link target="_blank" to="/service/study" exact class="three-btn active">查看详细</router-link>
              </div>
            </div>

            <div class="three-item">
              <div class="three-header">
                <div class="header-title">全媒体人才培养实训平台</div>
              </div>
              <div class="three-body">
                <img class="ellipse-box top" src="../../assets/site/service/ellipse2.png" />
                <img class="ellipse-box bottom" src="../../assets/site/service/ellipse1.png" />
                <p class="content-txt">全媒体人才培养实训平台围绕中央级与主流媒体企业的业务流程研发设计，包括智能风控、媒体融合运营、舆情监测与分析实训平台。</p>
                <p class="content-txt">实训平台配套海量企业级实训教学资源，依据多名多年央企及知名媒体企业主编、运营工作者工作经验开发。</p>
                <p class="content-txt">涵盖几十种媒体仿真模拟运营场景，保证内容传播安全的情况下，一站式模拟融媒体运营工作中的各个任务场景；</p>
                 <router-link target="_blank" to="/service/training" exact class="three-btn active">查看详细</router-link>
              </div>
            </div>


          </div>

        
         
        </div>
      </div>

      <div class="last-con-box">
        <div class="c">
          <div class="charter-lines">
            <img class="fx-left" src="../../assets/site/service/fx-left.png" />
            <span>服务对象及方案</span>
            <img class="fx-right" src="../../assets/site/service/fx-right.png" />
          </div>
          <div class="sub-lines">
            <img src="../../assets/site/service/icons-license.png" />
            <span class="sub-txt">1+X 职业技能等级证书   教学及考试培训</span>
          </div>
          <div class="certificate-box">
            <router-link to="media/detail?url=https%3A%2F%2Frmt.eol.cn%2F2022%2F03%2F08%2F9914229.html&type=zjdt&id=14229">
               <img src="../../assets/site/service/certificate2.jpg" />
            </router-link>
            <router-link to="media/detail?url=https%3A%2F%2Frmt.eol.cn%2F2022%2F03%2F08%2F9914232.html&type=zjdt&id=14232">
               <img src="../../assets/site/service/certificate3.jpg" />
            </router-link>
            <router-link to="media/detail?url=https%3A%2F%2Frmt.eol.cn%2F2022%2F03%2F08%2F9914233.html&type=zjdt&id=14233">
               <img src="../../assets/site/service/certificate1.jpg" />
            </router-link>
          </div>

          <div class="sub-lines">
            <img src="../../assets/site/service/icons-teacher.png" />
            <span class="sub-txt">日常教学实训实践</span>
          </div>
          <div class="practice-box">
            <div class="prev-top-box">
              <div class="left-prev-con">
                <p>基于院校融媒体相关专业的教学需求，以及教学场景，通过丰富的产品体系和强大技术能力推进实训教学，满足多个专业日常教学管理、教务管理、实训教学，完成教、学、践、练、评的全流程教学及实践。</p>
              </div>
              <div class="right-prev-imgs">
                <img src="../../assets/site/service/leather-biref-img.png" />
              </div>
            </div>
            <div class="background-color"></div>
          </div>

          <div class="sub-lines bottom">
            <img src="../../assets/site/service/icons-rmt.png" />
            <span class="sub-txt">教育融媒体中心</span>
          </div>
          <div class="practice-box">
            <div class="prev-top-box">
              <div class="right-prev-imgs">
                <img src="../../assets/site/service/rmt-biref-img.png" />
              </div>
              <div class="left-prev-con next-box">
                <p>基于高校融媒体中心的需求场景，以丰富的教学体系、产品体系和强大技术能力推进高校融媒体中心建设。引入先进的技术手段及内容运营经验，为高校搭建集校内外舆情监测、内容生产管理及分发于一体的融媒体运营管理平台，并提供专业化的培训指导服务及教学资源、运营管理等服务，帮助高校强化校园融媒体理论及人才建设。</p>
              </div>
            </div>
            <div class="background-color"></div>
          </div>
        </div>

      </div>
      <!-- 联系我们 -->
      <div class="contact-us-box">
        <img class="pos-img-left" src="../../assets/site/home/result-left.png" />
        <img class="pos-img-right" src="../../assets/site/home/result-right.png" />
        <div class="c">
          <div class="contact-text">联系我们，获取更详细服务方案！</div>
          <div class="contact-btn" @click="goToNeed">立即咨询</div>
        </div>
      </div>
    </div>
    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "../home/home.less";

export default {
  name: "Service",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      services: services,
    }
  },
  mounted() { 
  },
  methods: {
    goToNeed(){
      utils.redirect(this, "/need", false);
    }
  }
}
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.service-desc-bg {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .bg-con-box {
    width: 42%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .title {
      font-size: 34px;
      color: #FFF;
      margin-bottom: 30px;
    }
    .title-border {
      display: inline-block;
      height: 4px;
      width: 100%;
      margin-bottom: 20px;
      background-color: #FFFFFF;
    }
    p {
      font-size: 16px;
      color: #FFF;
      line-height: 30px;
      span {
        font-weight: bold;
      }
    }
  }
}
.second-box {
  width: 100%;
  padding: 50px 0 60px;
  background-image: url("../../assets/site/service/bg-imgs.png");
  background-size: 100% auto;
  background-position: center;
}
.charter-lines {
  font-size: 30px;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 40px;
  span {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
  }
  .fx-right, .fx-left {
    display: inline-block;
    vertical-align: middle;
  }
}
.category-three-box {
  display: flex;
  justify-content: space-between;
  .three-item {
    width: 380px;
    height: 570px;
    background: #D5E8FF;
    border-radius: 6px;
    .three-header {
      text-align: center;
      .header-title {
        font-size: 20px;
        font-weight: bold;
        color: #2B2B2B;
        width: max-content;
        margin: 0 auto;
        height: 72px;
        line-height: 72px;
        position: relative;
      }
      .header-title::after {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        background-color: #B8CDFA;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
      }
    }
    .three-body {
      background: #FFFFFF;
      border: 2px solid #ECECEC;
      border-top: none;
      border-bottom: none;
      border-radius: 0px 0px 6px 6px;
      padding: 40px 30px 30px;
      height: 470px;
      position: relative;
      .ellipse-box {
        position: absolute;
      }
      .ellipse-box.bottom {
        left: 20px;
        bottom: 90px;
      }
      .ellipse-box.top {
        right: 30px;
        top: 15px;
      }
      .content-txt {
        font-size: 16px;
        color: #353535;
        line-height: 32px;
      }
      .three-btn {
        width: 147px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        background: #FFFFFF;
        border: 1px solid #3D77FF;
        border-radius: 15px;
        color: #3D77FF;
        display: block;
        margin: 0 auto;
        font-size: 18px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .three-item:hover {
    background-color: #3373FD;
  }
  .three-item:hover .header-title {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
  .three-item:hover  .three-btn {
    background: #3D77FF;
    color: #FFFFFF;
  }
  .three-item:hover .content-txt {
    color: #2B2B2B;
  }
}
.last-con-box {
  padding: 50px 0 20px;
  background-color: #F5F6F9;
  .sub-lines {
    font-size: 24px;
    color: #2B2B2B;
    line-height: 40px;
    margin: 10px 0;
    img {
      width: 40px;
      height: auto;
      margin-right: 24px;
    }
  }
  .certificate-box {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 50px;
    img {
      height: auto;
      width: 380px;
    }
  }
}
.practice-box {
  padding: 0px 0 60px;
  .prev-top-box {
    display: flex;
    position: relative;
    z-index: 10;
    margin-bottom: -20px;
    .left-prev-con {
      display: flex;
      padding-left: 20px;
      flex-direction: column;
      justify-content: flex-end;
      p {
        width: 100%;
        padding: 60px 60px 60px 50px;
        background: #FFFFFF;
        box-shadow: 0px 0px 46px 0px rgba(181, 181, 181, 0.24);
        border-radius: 6px;
        font-size: 18px;
        color: #2B2B2B;
        line-height: 33px;
      }
    }
    .left-prev-con.next-box {
      padding-right: 30px;
      padding-left: 0;
      p {
        padding: 48px 35px 42px 50px;
      }
    }
  }
  .background-color {
    width: 99%;
    height: 40px;
    background: #C3DDFF;
    opacity: 0.5;
  }
}
.sub-lines.bottom {
  margin-bottom: 40px;
}
</style>
