<template>
  <div class="header-main-box">
    <div class="page_title">
      <div class="page_logo"></div>
      <div class="right-login-box h" v-if="!userAllInfo">
        <div class="user-text fl" @click="goToRegister">免费注册</div>
        <span class="user-lines fl"></span>
        <div class="user-text fl" @click="showLogin">登录</div>
      </div>
      <div class="right-login-box login" v-if="userAllInfo">
        <div class="user-icons fl">
          <img src="../../assets/site/icons-user.png" alt="" />
        </div>
        <div class="user-text fl" @click="redirect('/user/myClass')">
          学习中心
        </div>
        <!-- <div v-if="userAllInfo && userAllInfo.type == 2" class="user-text acitveC fl">{{userAllInfo.username}}</div> -->
        <span class="right-login-line fl"></span>
        <div class="mine-submenu-box">
          <template v-if="userAllInfo">
            <div class="submenu-items classic" @click="redirect('/user/myClass')">
              <span>我的课程</span>
            </div>
            <div class="submenu-items study" @click="redirect('/user/studyRecord')">
              <span>学习记录</span>
            </div>
            <div class="submenu-items note" @click="redirect('/user/myNote')">
              <span>我的笔记</span>
            </div>
            <div class="submenu-items user" @click="redirect('/user/userMessage')">
              <span>个人信息</span>
            </div>
            <div class="submenu-items edit" @click="redirect('/user/updatePassword')">
              <span>修改密码</span>
            </div>
          </template>
          <div class="submenu-items logout" @click="logout">
            <span>退出登录</span>
          </div>
        </div>
      </div>
    </div>
    <div class="title_banner">
      <img src="../../assets/createFarm/banner.png" width="100%" alt="">
    </div>
    <div class="kpyz_box">
      <div class="kpyz_title"></div>
      <div class="title_info">
        <div>
          本栏目内容从“智慧农业、传统农耕技术、农作物植物知识”三个方向出发
        </div>
        <div>
          传递农业科技创新的信息、介绍先进的农业技术，提高对现代农业发展的认知和理解
        </div>
        <div>为能够达到对科普活动的“深度学习”，增加知识储备</div>
      </div>
      <div class="kpyz_item">
        <div class="item_title zhny"></div>
        <div class="course_list">
          <div class="course_item" v-for="item in nyxsyList" :key="item.id" @click="goToCourseInfo(item.id)">
            <!-- <router-link :to="'/school/class/' + item.id"> -->
            <div class="course_img" :style="`background-image:url('${item.photo}')`"></div>
            <div class="course_title">{{ item.name }}</div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
      <div class="kpyz_item">
        <div class="item_title ngxt"></div>
        <div class="course_list">
          <div class="course_item" v-for="item in ngxtList" :key="item.id" @click="goToCourseInfo(item.id)">
            <!-- <router-link :to="'/school/class/' + item.id"> -->
            <div class="course_img" :style="`background-image:url('${item.photo}')`"></div>
            <div class="course_title">{{ item.name }}</div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
      <div class="kpyz_item">
        <div class="item_title zwqq"></div>
        <div class="course_list">
          <div class="course_item" v-for="item in zwqqList" :key="item.id" @click="goToCourseInfo(item.id)">
            <!-- <router-link :to="'/school/class/' + item.id"> -->
            <div class="course_img" :style="`background-image:url('${item.photo}')`"></div>
            <div class="course_title">{{ item.name }}</div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sywk_content">
      <div class="szy_content">
        <div class="sywk_title sznc"></div>
        <div class="title_info">
          <div>跟随专业农业科普讲师的脚步，一起走进朝来农艺园</div>
          <div>感受数字农业“黑科技”的现实应用场景</div>
          <div>沉浸式了解科技如何助农，感受科技与农业的完美融合</div>
        </div>
        <div class="course_list">
          <div class="course_item" v-for="item in szncList" :key="item.id">
            <!-- <router-link :to="'/school/class/' + item.id"> -->
            <div class="item_content" :style="`background-image:url('${item.photo}')`" @click="goToCourseInfo(item.id)"></div>
            <div class="item_title">{{ item.name }}</div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
      <div class="szy_content">
        <div class="sywk_title wkjt"></div>
        <div class="title_info">
          <div>
            将植物科普知识与人工智能创客以简洁、精确、易于理解的微课形式进行呈现
          </div>
          <div>以达到对科普活动的“充分学习”，促进展开多维视角的思考</div>
          <div>拓展思维深度</div>
        </div>
        <div class="course_list">
          <div class="course_item" v-for="item in wkjtList" :key="item.id">
            <!-- <router-link :to="`/school/class/${item.id}`"> -->
            <div class="item_content" :style="`background-image:url('${item.photo}')`" @click="goToCourseInfo(item.id)"></div>
            <div class="item_title">{{ item.name }}</div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <div class="satisfied_content">
      <div class="satisfied_title"></div>
      <div class="title_info">
        <div>
          请对参加此次《智造农场 数创未来》线上科普活动的整体感受进行评估和总结
        </div>
        <div>并进行满意度评级，“五星”表示对本次活动非常满意</div>
      </div>
      <div class="content_info">
        <div class="satisfied_box">
          <div class="satisfied_list">
            <div class="satisfied_item" v-for="(item, ind) in satisfiedInfo" :key="ind" @click="isSatisfied == 1 ? satisfiedNum :satisfiedNum = ind + 1">
              <div :class="[
                  'satisfied_icon',
                  `bg${ind + 1}`,
                  { active: satisfiedNum == ind + 1 },
                ]"></div>
              <div class="satisfied_info">{{ item }}</div>
            </div>
          </div>
          <div class="isSatisfied" v-if="isSatisfied == 1">感谢您的评价!</div>
          <div class="satisfied_submit" @click="sendSatisfied" v-else>发布</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Footer from "../../components/Footer";
export default {
  name: "createFfarm",
  components: {
    Footer,
  },
  data () {
    return {
      nyxsyList: [],
      ngxtList: [],
      zwqqList: [],
      szncList: [],
      wkjtList: [],
      satisfiedInfo: ["很糟糕", "一般", "还可以", "比较满意", "非常满意"],
      satisfiedNum: 5,
    };
  },
  computed: {
    userAllInfo () {
      return this.$store.state.appsLogin.appsUserInfo;
    },
    isSatisfied () {
      return this.$store.state.appsLogin.satisfiedInfo.is_score;
    }
  },
  async created () {
    utils.setTitle("智造农场")
    await this.getAllAccount();
    this.getsatisfiedInfo()
    this.getnyxsyCouurse();
    this.getngxtCouurse();
    this.getzwqqCouurse();
    this.getszncCouurse();
    this.getwkjtCouurse();
  },
  methods: {
    async getAllAccount () {
      await this.$store.dispatch("getAppsLoginUserInfo", {});

    },
    async getsatisfiedInfo () {
      if (this.userAllInfo && this.userAllInfo.id) {
        await this.$store.dispatch("getSatisfiedInfo", { user_id: this.userAllInfo.id });
      }
      this.satisfiedNum = this.$store.state.appsLogin.satisfiedInfo.score || 5
    },
    goToRegister () {
      this.$router.push("/register?from=/createFarm");
    },
    showLogin () {
      this.$router.push("/login?from=/createFarm");
    },

    redirect (path) {
      utils.redirect(this, path);
    },
    async logout () {
      let that = this;
      this.$store.dispatch("requestAppsLogout", {}).then((res) => {
        if (
          utils.blackList.includes(this.$route.path) ||
          this.$route.path.indexOf("/video/") > -1
        ) {
          this.showLogin();
        }
      });
    },
    async getnyxsyCouurse () {
      let res = await services.getzzncCourseList({
        category_id: 126,
      });
      if (res.code == "0000" && res.data && res.data.course_list.length) {
        this.nyxsyList = res.data.course_list;
      }
    },
    async getngxtCouurse () {
      let res = await services.getzzncCourseList({
        category_id: 127,
      });
      if (res.code == "0000" && res.data && res.data.course_list.length) {
        this.ngxtList = res.data.course_list;
      }
    },
    async getzwqqCouurse () {
      let res = await services.getzzncCourseList({
        category_id: 128,
      });
      if (res.code == "0000" && res.data && res.data.course_list.length) {
        this.zwqqList = res.data.course_list;
      }
    },
    async getszncCouurse () {
      let res = await services.getzzncCourseList({
        category_id: 129,
      });
      if (res.code == "0000" && res.data && res.data.course_list.length) {
        this.szncList = res.data.course_list;
      }
    },
    async getwkjtCouurse () {
      let res = await services.getzzncCourseList({
        category_id: 130,
      });
      if (res.code == "0000" && res.data && res.data.course_list.length) {
        this.wkjtList = res.data.course_list;
      }
    },
    async sendSatisfied () {
      if (this.userAllInfo && this.userAllInfo.id) {
        let res = await services.sendSatisfied({ score: this.satisfiedNum });
        if (res.code == "0000") {
          this.getsatisfiedInfo()
        }
      } else {
        this.$router.push({ path: '/login', query: { from: this.$route.path } })
      }
    },
    goToCourseInfo (id) {
      window.open(`/school/class/${id}?from=${this.$route.path}`)
      // this.$router.push({
      //   path: "/school/class/" + id,
      //   query: {
      //     // classId: id,
      //     from: this.$route.path
      //   }
      // })

    }
  },
};
</script>

<style lang="less" scoped>
.header-main-box {
  .page_title {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page_logo {
      width: 190px;
      height: 34px;
      background-image: url('../../assets/createFarm/logo.png');
      background-size: 100%;
    }
  }
  .kpyz_box {
    // height: 1080px;
    position: relative;
    padding-top: 45px;
    background-image: url('../../assets/createFarm/kpyzbg.png');
    background-size: cover;
    padding-bottom: 40px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 78px;
      background: linear-gradient(
        0deg,
        rgba(241, 241, 241, 0) 0%,
        #dfede5 100%
      );
    }
    .kpyz_title {
      margin: 0 auto;
      width: 100%;
      height: 65px;
      background-image: url('../../assets/createFarm/kpyz_title.png');
      width: 1200px;
      background-size: 100%;
    }
    .title_info {
      margin-top: 22px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #878787;
      line-height: 22px;
    }
    .kpyz_item {
      width: 1200px;
      margin: 0 auto;
      .item_title {
        height: 44px;
        background-size: 100%;
        &.zhny {
          background-image: url('../../assets/createFarm/zhny_title.png');
          margin-top: 27px;
        }
        &.ngxt {
          background-image: url('../../assets/createFarm/ngxt_title.png');
          margin-top: 30px;
        }
        &.zwqq {
          background-image: url('../../assets/createFarm/zwqq_title.png');
          margin-top: 30px;
        }
      }
      .course_list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .course_item {
          width: 285px;
          background-color: #fff;
          margin-left: 20px;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
          .course_img {
            height: 160px;
            width: 100%;
            background-size: cover;
          }
          .course_title {
            padding: 22px 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            line-height: 1;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
  .sywk_content {
    padding-top: 57px;
    background-color: #e0f0e7;
    background-image: url('../../assets/createFarm/szncbg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 57px;
    .szy_content {
      margin: 0 auto;
      width: 1200px;
      .sywk_title {
        width: 100%;
        height: 65px;
        background-size: 100%;
        &.sznc {
          background-image: url('../../assets/createFarm/numFarm_title.png');
        }
        &.wkjt {
          background-image: url('../../assets/createFarm/wkjt_title.png');
          margin-top: 40px;
        }
      }
      .title_info {
        margin-top: 22px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
        line-height: 22px;
      }
      .course_list {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .course_item {
          height: 370px;
          width: 585px;
          background-image: url('../../assets/createFarm/course_background.png');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom;
          position: relative;
          margin-bottom: 20px;
          &::before {
            display: block;
            position: absolute;
            bottom: 20px;
            left: 18px;
            content: '';
            width: 6px;
            height: 16px;
            background-image: url('../../assets/createFarm/course_icon.png');
            background-size: 100%;
          }
          .item_content {
            margin: 0 auto;
            width: 554px;
            height: 313px;
            background: #ffffff;
            border: 2px solid #ffffff;
            border-radius: 12px 12px 0px 0px;
            background-size: cover;
            cursor: pointer;
          }
          .item_title {
            padding: 20px 15px 20px 30px;
            font-size: 16px;
            line-height: 1;
            font-weight: bold;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .satisfied_content {
    padding-top: 40px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 44px;
    .satisfied_title {
      width: 100%;
      height: 65px;
      background-image: url('../../assets/createFarm/satisfied_title.png');
      background-size: 100%;
    }
    .title_info {
      margin-top: 22px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #878787;
      line-height: 22px;
    }
    .content_info {
      height: 267px;
      width: 100%;
      background-image: url('../../assets/createFarm/satisfied_bcak.png');
      background-size: cover;
      padding-left: 570px;
      padding-top: 80px;
      padding-right: 190px;
      margin-top: 10px;
      .satisfied_box {
        .satisfied_list {
          display: flex;
          justify-content: space-between;
          .satisfied_item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            .satisfied_icon {
              width: 50px;
              height: 50px;
              background-size: cover;
              &.bg1 {
                background-image: url('../../assets/createFarm/terrible.png');
                &.active {
                  background-image: url('../../assets/createFarm/terrible-hover.png');
                }
              }
              &.bg2 {
                background-image: url('../../assets/createFarm/ordinary.png');
                &.active {
                  background-image: url('../../assets/createFarm/ordinary-hover.png');
                }
              }
              &.bg3 {
                background-image: url('../../assets/createFarm/fine.png');
                &.active {
                  background-image: url('../../assets/createFarm/fine-hover.png');
                }
              }
              &.bg4 {
                background-image: url('../../assets/createFarm/satisfied.png');
                &.active {
                  background-image: url('../../assets/createFarm/satisfied-hover.png');
                }
              }
              &.bg5 {
                background-image: url('../../assets/createFarm/very_satisfied.png');
                &.active {
                  background-image: url('../../assets/createFarm/very_satisfied_hover.png');
                }
              }
            }
            .satisfied_info {
              margin-top: 18px;
              font-size: 14px;
              text-align: center;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .satisfied_submit {
          cursor: pointer;
          margin: 0 auto;
          margin-top: 35px;
          width: 88px;
          height: 34px;
          background: linear-gradient(0deg, #039843 0%, #04a349 100%);
          border-radius: 17px;
          font-size: 14px;
          line-height: 34px;
          text-align: center;
          font-weight: bold;
          color: #ffffff;
        }
        .isSatisfied {
          margin-top: 35px;
          font-size: 14px;
          line-height: 34px;
          text-align: center;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}
.header-main-box .right-login-box.login {
  position: relative;
}
.header-main-box .right-login-box .user-lines {
  width: 1px;
  height: 12px;
  background: #2b2b2b;
  margin: 24px 10px 0 10px;
}
.header-main-box .right-login-box .user-text {
  color: #2b2b2b;
  font-size: 14px;
  line-height: 60px;
  height: 60px;
  cursor: pointer;
  vertical-align: middle;
}
.header-main-box .right-login-box .user-text:hover {
  color: #3d77ff;
}
.header-main-box .right-login-box .user-text.acitveC:hover {
  color: #2b2b2b;
}
.header-main-box .right-login-box .user-icons {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 57px;
  height: 60px;
  margin-right: 10px;
}
.header-main-box .user-icons img {
  width: 26px;
  height: auto;
  vertical-align: middle;
}
.header-main-box .user-icons p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: inline-block;
  vertical-align: middle;
}
.header-main-box .right-login-box .right-login-line {
  /* width: 1px;
  height: 18px;
  background-color: #999999;
  display: inline-block;
  vertical-align: middle; 
  margin: 26px 10px; */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #2462f5;
  margin: 26px 0 0 10px;
}
.header-main-box .right-login-box .right-login-line {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.header-main-box .right-login-box:hover .right-login-line {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.header-main-box .right-login-box.login .mine-submenu-box {
  display: none;
}
.header-main-box .right-login-box.login:hover .mine-submenu-box {
  display: block;
  /* min-width: 150px; */
  width: max-content;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 1000;
  text-align: left;
  padding: 16px 0 10px;
  background-color: #ffffff;
  border-top: 1px solid #2462f5;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 2px;
}
.header-main-box .right-login-box.login:hover .mine-submenu-box::after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  background-color: #2462f5;
  top: 0;
  left: -1px;
  right: -1px;
  border-radius: 2px 2px 0 0;
}

.mine-submenu-box::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #2462f5;
}
.mine-submenu-box .submenu-items {
  font-size: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
  cursor: pointer;
  padding: 0 20px;
}
.mine-submenu-box .submenu-items:hover {
  color: #125cfb;
}
.mine-submenu-box .submenu-items::before {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  margin-right: 8px;
  vertical-align: -2px;
  background-repeat: no-repeat;
  background-position: center;
}
.mine-submenu-box .submenu-items.classic::before {
  background-image: url('../../assets/site/mineMenu/classic.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.classic:hover::before {
  background-image: url('../../assets/site/mineMenu/classic-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.study::before {
  background-image: url('../../assets/site/mineMenu/study.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.study:hover::before {
  background-image: url('../../assets/site/mineMenu/study-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.logout::before {
  background-image: url('../../assets/site/mineMenu/logout.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.logout:hover::before {
  background-image: url('../../assets/site/mineMenu/logout-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.note::before {
  background-image: url('../../assets/site/mineMenu/note.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.note:hover::before {
  background-image: url('../../assets/site/mineMenu/note-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.user::before {
  background-image: url('../../assets/site/mineMenu/user.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.user:hover::before {
  background-image: url('../../assets/site/mineMenu/user-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.edit::before {
  background-image: url('../../assets/site/mineMenu/edit.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items.edit:hover::before {
  background-image: url('../../assets/site/mineMenu/edit-hover.png');
  background-size: 100% auto;
}
.mine-submenu-box .submenu-items span {
  vertical-align: top;
}
</style>