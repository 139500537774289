import Vue from 'vue'
import services from '../config/services'

export default {
  state:{
    newsData: {},
    newsDetailData: {}
  },
  getters:{
    state (state){
      return state
    },
  },
  actions: {
    loadNewsData(context, payload){
      return services.news_lists(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadNewsData', {
            'newsData': res.data || {}
          })
        }
        return res;
      })
    },
    loadNewsDetailData(context, payload){
      return services.news_get(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('newsDetailData', {
            'newsDetailData': res.data || {}
          })
        }
        return res.data
      })
    },
  },
  mutations:{
    loadNewsData (state, payload) {
      Vue.set(state, "newsData" ,payload.newsData)
    },
    newsDetailData (state, payload) {
      Vue.set(state, "newsDetailData" ,payload.newsDetailData)
    },
  }
}