<template>
<!-- 2021-10-19 课程笔记开发 -->
	<div>
		<div class="d-header">
			<div class="sub-title" :style="{ width: vListWdith + 'px' }">
				<div :class="currentNav == 1 ?'nav-type active':'nav-type'" @click="changeNavType(1)"><div class="nav-icon menu"></div>课程目录</div>
				<div :class="currentNav == 2 ?'nav-type active':'nav-type'" @click="changeNavType(2)"><div class="nav-icon note"></div>课程笔记</div>
			</div>
			<div class="title h">
				<div class="txt fl">
					<div class="d-header-logo fl">
						<img src="../../assets/site/logo-zjyk.png" alt />
					</div>
					{{ this.currentMaterial.name }}
				</div>
				<div class="fr btn-back" @click="btnBack">
					<span class="iconfont iconleft-"></span>返回
				</div>
				<div v-if="userAllInfo && userAllInfo.id" class="fr btn-back" @click="clickEvaluateModal(true)">评价课程</div>
			</div>
		</div>
		<div class="v-frame h" :style="{ height: vFrameHeight + 'px' }">
			<div class="v-box">
				<template v-if="this.currentMaterial.status == 1">
					<div class="video-con" v-if="this.currentMaterial.type == 1">
						<video
							id="player-container-id2"
							className="kdjw"
							preload="auto" playsinline webkit-playsinline
						/>
					</div>
					<div
						class="pdf-view"
						v-if="
							this.currentMaterial.type == 2 || this.currentMaterial.type == 3
						"
					>
					<!-- :src="services.IMG_SERVER + this.videoInfo.items.view_path" -->
						<iframe id="pdfPreload"
							:src="pdfPagesSrc"
							frameborder="0"
						></iframe>
					</div>
				</template>
				<div v-if="isContentFly" class="fail-info">此篇内容飞走了~~</div>
				<div class="pre-next h">
					<div class="fl btn-prev" @click="preVideo()">
						<span class="iconfont iconleft-"></span>上一讲
					</div>
					<div class="fr btn-next" @click="nextVideo()">
						下一讲
						<span class="iconfont iconright1"></span>
					</div>
				</div>
				<!-- 评论box -->
				<div v-show="isEvaluateModal" class="evalmodal-outer-box">
					<div class="evalmodal-wrap"> </div>
					<div class="evaluate-form-box">
						<div class="t-title">
							<div class="txt">发表评论</div>
							<div class="close" @click="clickEvaluateModal(false)"></div>
						</div>
						<div class="evaluate-lines-box">
							<div class="label-box">总<span></span>评：</div>
							<div class="star-num-box">
								<div v-for="(i,index) in starList" :key="`${index}star`" @click="starChange(index)" class="star-items">
									<img :src="evaluateNum > index ? stara : starb" />
								</div>
							</div>
						</div>
						<div class="evaluate-lines-box">
							<div class="label-box">评价内容：</div>
							<div class="evaluate-textarea-box">
								<textarea v-model="evaluateText" @change="changeEvaluate" rows="2" maxLength='50' placeholder="可以从课程的内容，授课方式等评价课程，或者分享您学习课程的感受，课程给您带来了哪些收获。"></textarea>
								<div class="limit-box">
									<span>至少输入5个字</span>
									<span>{{evaluateText.length}}/50</span>
								</div>
								<p v-if="showError" class="error-info">{{textareaErrorTxt}}</p>
							</div>
						</div>
						<div style="text-align: center;">
							<div class="evaluate-button cancel" @click="clickEvaluateModal(false)">取消</div>
							<div class="evaluate-button" @click="submitEvaluate">提交</div>
						</div>
					</div>
				</div>
				<satisfiedBox v-if="isSatisfiedShow && isSatisfied == 2"/>
			</div>
			<div class="v-list" :style="{ width: vListWdith + 'px' }">
				<div :style="{ width: vListWdith + 'px', height: '100%' }">
					<div class="btn-slide-left">
						<span class="iconfont iconleft-" @click="vSlideLeft"></span>
						<span class="iconfont iconright1" @click="vSlideRight"></span>
					</div>
					<div v-show="currentNav == 1" :style="{ overflow: 'auto' }">
						<a-collapse v-model="activeKey" :bordered="false">
							<template #expandIcon="props">
								<div>
									<span
										:class="
											props.isActive
												? 'iconfont icontop'
												: 'iconfont iconbottom'
										"
									/>
								</div>
							</template>
							<a-collapse-panel
								v-for="(item, index) in this.classDetail2"
								:key="`${index}class`"
							>
							<template slot="header">
								<span class="middle-name">{{item.name}}</span>
							</template>
							<div>
								<div
									class="menu-li"
									v-for="(children, i) in item.children"
									:key="`${i}menu`"
								>
									<template v-if="children.chapter_type == 1">
										<div class="menu-title" v-if="children.name">
											{{ children.name }}
										</div>
										<div
											:class="
												mate.cm_id == cm_id ? 'munu-list h cur' : 'munu-list h'
											"
											v-for="(mate, m) in children.children"
											:key="`${m}mater`"
											@click="videoPlay(mate.cm_id)"
										>
											<span
												class="iconfont iconshipin1 fl"
												v-if="mate.type == 1"
											></span>
											<span
												class="iconfont iconPPT fl"
												v-if="mate.type == 2"
											></span>
											<span
												class="iconfont iconword fl"
												v-if="mate.type == 3"
											></span>
											<div class="menu-txt fl">
												<p class="menu-txt-t">{{ mate.name }}</p>
												<p
													class="menu-txt-subt"
													v-if="mate.is_finish == 2 || mate.type==1"
												>
													{{ mate.duration }}
												</p>
												<p class="menu-txt-subt" v-else>{{ mate.size }}</p>
											</div>
											<div class="last-tag fr" v-if="mate.position">
												<span class="last-bf">上次学到</span>
											</div>
											<div class="last-tag fr" v-if="mate.is_finish == 2">
												<span class="finish"></span>
											</div>
										</div>
									</template>
									<template v-else-if="children.chapter_type == 2">
										<div :class="children.cm_id == cm_id ? 'munu-list h cur' : 'munu-list h'" @click="videoPlay(children.cm_id)">
											<span class="iconfont iconshipin1 fl" v-if="children.type == 1"></span>
											<span class="iconfont iconPPT fl" v-if="children.type == 2"></span>
											<span class="iconfont iconword fl" v-if="children.type == 3" ></span>
											<div class="menu-txt fl">
												<p class="menu-txt-t">{{ children.name }}</p>
												<p
													class="menu-txt-subt"
													v-if="children.is_finish == 2 || children.type==1"
												>
													{{ children.duration }}
												</p>
												<p class="menu-txt-subt" v-else>{{ children.size }}</p>
											</div>
											<div class="last-tag fr" v-if="children.position">
												<span class="last-bf">上次学到</span>
											</div>
											<div class="last-tag fr" v-if="children.is_finish == 2">
												<span class="finish"></span>
											</div>
										</div>
									</template>
								</div>
								</div>
							</a-collapse-panel>
						</a-collapse>
					</div>
					<div v-show="currentNav == 2" class="right-note-box">
						<div class="note-list-box" v-infinite-scroll="handleInfiniteOnLoad" :infinite-scroll-disabled="false" :infinite-scroll-distance="10" >
							<template v-if="notesData.length > 0">
								<div v-for="(item, inx) in notesData" :key="inx" class="note-items">
									<p v-if="item.isShowLong" class="note-txt">
										{{item.isShowLong &&  item.isShowFlag ? item.contentFormat : item.content }}
										<span @click="item.isShowFlag = !item.isShowFlag" class="more-btn"><span :class="item.isShowFlag ? 'more-icon up' : 'more-icon down' "></span>{{item.isShowFlag ? '展开' : '收起'}}</span>
									</p>
									<p v-else class="note-txt">
										{{item.content }}
									</p>
									<div class="bottom-record-box">
										<div class="date">{{item.created_at | addTimeFormat }}</div>
										<div v-if="item.type == 1" class="position">记录位置：{{item.position | formatSeconds}}</div>
										<div v-else class="position">记录位置：{{ item.position }}页</div>
										<div class="btn-group">
											<div class="edit" @click="editNotesMethod(item)"><img :src="require('../../assets/site/note/note-edit.png')" /></div>
											<div class="del" @click="delNotesMethod(item)"><img :src="require('../../assets/site/note/note-del.png')" /></div>
										</div>
									</div>
								</div>
								<div v-if="((notePageNum + 1) >= notesListTotal) && (notePageNum > 1)" class="notes-info">没有更多了~</div>
							</template>
							<div v-else class="notes-info"><img src="../../assets/site/note/no-note.png" /><div>还没有笔记内容，快去记录吧~</div></div>
						</div>
						<div v-show="!isContentFly" class="note-optear-box">
							<div v-show="!showNoteArea" class="note-button" @click="closeNoteArea(true)">记笔记</div>
							<div v-show="showNoteArea" class="note-add-box">
								<div class="close" @click="closeNoteArea(false)"></div>
								<div class="areaText">
									<textarea v-model="notesText" @change="notesChangeTxt" placeholder="开始记笔记吧~" :maxlength="noteMaxNum"></textarea>
									<div class="areaNums">( {{notesText.length}}/{{noteMaxNum}} )</div>
								</div>
								<div class="bottom-line">
									<div class="message"><span v-if="showNotesError">{{notesErrorTxt}}</span></div>
									<div class="save-btn" @click="submitNotes()">保存</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "./class.less";
import "./note.less";
import { Collapse, Message, Modal } from "ant-design-vue";
import services from "../../config/services";
import utils from "../../config/utils";
import infiniteScroll from 'vue-infinite-scroll';
import { mapActions, mapState } from "vuex";
import satisfiedBox from "../../components/zzncSatisfied.vue"

const { Panel } = Collapse;
export default {
	directives: { infiniteScroll },
	name: "ClassVideo",
	components: {
		ACollapse: Collapse,
		ACollapsePanel: Panel,
		satisfiedBox
	},
	data() {
		return {
			vBoxWidth: "",
			vFrameHeight: "",
			videoHeight: "",
			vListWdith: "375",
			activeKey: ["0class"],
			loadingError: false,
			isContentFly: false,
			player: "",
			chapter_id: '',
			cm_id: this.$route.params.videoId,
			services: services,
			maxNum: 70,
			shouqiTxt: [],
			shouqiClass: [],
			evaluateText: '',
			evaluateNum: 5,
			starList: [1,2,3,4,5],
			stara: require('../../assets/icon-star-active.png'),
			starb: require('../../assets/icon-star.png'),
			textareaErrorTxt: "",
			showError: false,
			isEvaluateModal: false,
			currentNav: 1,
			showNoteArea: false,
			notesText: '',
			noteFormType: 'add',
			showNotesError: false,
			notesErrorTxt: '',
			noteMaxNum: 1000,
			notesData: [],
			notesListTotal: 0,
			notePageNum: 1,
			noteSize: 10,
			note_id: '',
			pdfPagesSrc: '', // pdf.js 预览文件地址
			currentMaterial: {}, // 当前播放的素材
			allCmidArr: [],
			inter: null, // 学习日志定时器
			isSatisfiedShow: false
		};
	},
	created() {
		if(this.$route.query.from == "/createFarm") {
			this.isSatisfiedShow = true
		}
	},
	computed: {
		...mapState({ 
			userAllInfo: state => state.appsLogin.appsUserInfo,
			classDetail2: state => state.class.classDetail2,
			videoInfo: state => state.class.videoInfo,
			isSatisfied: state => state.appsLogin.satisfiedInfo.is_score,
		}),
	},
	async mounted() {
		await this.getAppsLoginUserInfo()
		this.$store.dispatch("getSatisfiedInfo", {user_id: this.userAllInfo.id});
		let w = document.body.scrollWidth || document.documentElement.scrollWidth 
		let h = document.body.scrollHeight || document.documentElement.scrollHeight
		this.vBoxWidth = w - 375;
		this.vFrameHeight = h - 188 + 98;
		this.videoHeight = h - 188 - 56 + 98;

		this.loadVideo(this.$route.params.videoId);
	},
	methods: {
		...mapActions(['getAppsLoginUserInfo']),
		closeNoteArea(val){
			this.showNoteArea = val;
			if(!val){
				this.notesText = '';
			}
		},
		videoPlay(cm_id) {
			let routeData = this.$router.resolve({
				path: `/video/${this.$route.params.classId}/${cm_id}`,
				query: {
					from: this.$route.query.from
				},
			});
			window.open(routeData.href, '_self');
		},
		btnBack() {
			var url = this.$route.query.from || '/user/myClass';
			utils.redirect(this, url);
		},
		vSlideLeft() {
			this.vListWdith = "375";
			this.vBoxWidth = document.body.clientWidth - 375;
			let playerDom = document.getElementsByClassName(
				"player-container-id2-dimensions"
			)[0];
			playerDom.style.width = this.vBoxWidth + "px";
			document.getElementById("player-container-id2_html5_api").style.width =
				this.vBoxWidth + "px";
		},
		vSlideRight() {
			this.vListWdith = "0";
			this.vBoxWidth = document.body.clientWidth;
			let playerDom = document.getElementsByClassName(
				"player-container-id2-dimensions"
			)[0];
			playerDom.style.width = this.vBoxWidth + "px";
			document.getElementById("player-container-id2_html5_api").style.width =
				this.vBoxWidth + "px";
		},
		initVideo(data) {
			if (!this.currentMaterial.file_id) {
				return;
			}
			let that = this
			utils.loadScript(window.location.origin + "/school/js/hls.min.0.8.8.js", () => {
				utils.loadScript(window.location.origin + "/school/js/tcplayer.min.js", () => {
					if (typeof window.TCPlayer === "function") {
						that.player = window.TCPlayer("player-container-id2", {
							// player-container-id 为播放器容器ID，必须与html中一致
							fileID: this.currentMaterial.file_id, // 请传入需要播放的视频filID 必须
							// fileID: "5285890807937512700",
							controls: true,
							playbackRates: [0.5, 1, 1.25, 1.5, 2],
							appID: "1500004122", // 请传入点播账号的appID 必须
							width: this.vBoxWidth, //视频的显示宽度
							height: this.videoHeight, //视频的显示高度
							definition: 30,
							autoplay: true,
						});
						
						if(this.$route.query.type && this.$route.query.type == 1){
							if(this.$route.query.position){
								this.player.currentTime(this.$route.query.position);
							}
						} else {
							this.player.currentTime(this.videoInfo.position);
						}
						that.player.on("play", () => {
							that.addLog({
								cm_id: data.cm_id,
								chapter_id: data.chapter_id,
							});
						});
						that.player.on("ended", () => {
							clearInterval(that.inter)
							that.inter = null
							that.addLogOne({
								cm_id: data.cm_id,
								chapter_id: data.chapter_id,
							});
						});
						that.player.on("pause", () => {
							clearInterval(that.inter)
							that.inter = null
							that.addLogOne({
								cm_id: data.cm_id,
								chapter_id: data.chapter_id,
							});
						});
						that.player.on("error", () => {
							clearInterval(that.inter)
							that.inter = null
						});
						that.player.on("fullscreenchange", () => {
							if (this.player.isFullscreen()) {
									let playerDom = document.getElementsByClassName(
										"player-container-id2-dimensions"
									)[0];
									let winwidth = document.body.scrollWidth || document.documentElement.scrollWidth
									let winheight = document.body.scrollHeight || document.documentElement.scrollHeight
									playerDom.style.width = winwidth + "px";
									playerDom.style.height = winheight  + "px";
									let playerDom2 = document.getElementById(
										"player-container-id2_html5_api"
									);
									playerDom2.style.width = winwidth + "px";
									playerDom2.style.height = winheight + "px";
							} else {
									let playerDom = document.getElementsByClassName(
										"player-container-id2-dimensions"
									)[0];
									playerDom.style.width = this.vBoxWidth + "px";
									playerDom.style.height = this.videoHeight + "px";
									let playerDom2 = document.getElementById(
										"player-container-id2_html5_api"
									);
									playerDom2.style.width = this.vBoxWidth + "px";
									playerDom2.style.height = this.videoHeight + "px";
							}
						});
					}
				});
			});
		},
		calcCol(data){
			this.activeKey = data
		},
		// 下载资源type 为2、3的文件流
		downLoadFile(param){
			services.courseFilePreview(param).then((res) => {
				var fileUrl = this.getObjectURL(res);
				if(this.$route.query.type && (this.$route.query.type == 2 || this.$route.query.type == 3)){
					if(this.$route.query.position){
						this.pdfPagesSrc = window.location.origin + "/school/js/pdf/web/viewer.html?file=" + encodeURIComponent(fileUrl) + '&page=' + this.$route.query.position;
					}
				} else {
					this.pdfPagesSrc = window.location.origin + "/school/js/pdf/web/viewer.html?file=" + encodeURIComponent(fileUrl) + '&page=1';
				}
			})        
		},
		// 将返回的文件流转换为url
		getObjectURL(file) {
			let url = null;
			if (window.createObjectURL != undefined) { // basic
					url = window.createObjectURL(file);
			} else if (window.webkitURL != undefined) { // webkit or chrome
				try {
					url = window.webkitURL.createObjectURL(file);
				} catch (error) {
					console.log(error)
				}
			} else if (window.URL != undefined) { // mozilla(firefox)
				try {
					url = window.URL.createObjectURL(file);
				} catch (error) {
					console.log(error)
				}
			}
			return url;
		},
		async loadVideo(cm_id) {
			let that = this;
			await this.$store.dispatch("loadCourseChapter", {
				id: this.$route.params.classId,
				data: {
					action: 'learn'
				}
			}).then((data)=>{
				if(data.code == '0000'){
					var items = data.data;
					for (let i = 0; i < items.length; i++){
						for(let j = 0;j < items[i].children.length; j++){
							// chapter_type 1为章节 2为素材
							if(items[i].children[j].chapter_type == 1 && items[i].children[j].children){
								for(let k = 0;k < items[i].children[j].children.length; k++){
									this.allCmidArr.push(items[i].children[j].children[k].cm_id)
									if(cm_id == 0){
										if(items[i].children[j].children[k].position){
											this.chapter_id =  items[i].children[j].children[k].chapter_id
											cm_id = items[i].children[j].children[k].cm_id
											this.currentMaterial = items[i].children[j].children[k]
											let routeData = this.$router.resolve({
												path: `/video/${this.$route.params.classId}/${cm_id}`,
												query: {
													from: this.$route.query.from
												},
											});
											window.open(routeData.href, '_self');
										}
									} else {
										if(items[i].children[j].children[k].cm_id == cm_id){
											this.chapter_id =  items[i].children[j].children[k].chapter_id
											this.currentMaterial = items[i].children[j].children[k]
											this.calcCol([i + 'class'])
											// return;
										}
									}
								}
							} else {
								this.allCmidArr.push(items[i].children[j].cm_id)
								if(cm_id == 0){
									if(items[i].children[j].position){
										this.chapter_id =  items[i].children[j].chapter_id
										cm_id = items[i].children[j].cm_id
										this.currentMaterial = items[i].children[j]
										let routeData = this.$router.resolve({
											path: `/video/${this.$route.params.classId}/${cm_id}`,
											query: {
												from: this.$route.query.from
											},
										});
										window.open(routeData.href, '_self');
									}
								} else {
									if(items[i].children[j].cm_id == cm_id){
										this.chapter_id =  items[i].children[j].chapter_id
										this.currentMaterial = items[i].children[j]
										this.calcCol([i + 'class'])
										// return;
									}
								}
							}
						}
					}
					// 当cmid为 0 并且没有position值时 默认定位到第一个素材
					if(cm_id == 0) {
						if(items[0].children[0].chapter_type == 1 && items[0].children[0].children){
							cm_id = items[0].children[0].children[0].cm_id
						} else {
							cm_id = items[0].children[0].cm_id
						}
						let routeData = this.$router.resolve({
							path: `/video/${this.$route.params.classId}/${cm_id}`,
							query: {
								from: this.$route.query.from
							},
						});
						window.open(routeData.href, '_self');
					}
				} else if (data.code == '2004'){
					Message.error(data.message);
				}
			});
			// 获取笔记数据
			this.getNotesList(1);
			this.isContentFly = this.currentMaterial && this.currentMaterial.status == 2 ? true : false
			await this.$store.dispatch("loadVideo", {
				id: this.$route.params.classId,
				data: {
					cm_id: cm_id,
					chapter_id: this.chapter_id,
				}
			}).then((res) => {
					if (res.code == "0000") {
						utils.setTitle(this.currentMaterial.name);
						if(this.currentMaterial.type == 1 && this.currentMaterial.status == 1){
							this.initVideo(this.currentMaterial);
						} else if(this.currentMaterial.type == 2 || this.currentMaterial.type == 3) {
							// 下载课程资源pdf文件流
							this.downLoadFile({	course_id: this.$route.params.classId, cm_id: cm_id});
						}
					} else if(res.code == '2004'){
						utils.redirect(
							this,
							"/class/notfound"
						);
					}
			});
		},
		// 下一讲事件
		nextVideo() {
			let index = this.allCmidArr.indexOf(this.currentMaterial.cm_id)
			if(index > -1 && index < this.allCmidArr.length){
				this.videoPlay(this.allCmidArr[index+1])
			}
		},
		// 上一讲事件
		preVideo() {
			let index = this.allCmidArr.indexOf(this.currentMaterial.cm_id)
			if(index > 0 && index < this.allCmidArr.length){
				this.videoPlay(this.allCmidArr[index-1])
			}
		},
		addLog(params) {
			this.inter = setInterval(() => {
				this.addLogOne(params);
			}, 15000);
		},
		addLogOne(params) {
			services
				.addCourseLog({
					course_id: this.$route.params.classId,
					position: this.player.currentTime(),
					user_id: this.userAllInfo.id,
					...params,
				})
		},
		clickEvaluateModal(val){
			this.isEvaluateModal = val;
		},
		changeNavType(type){
			this.currentNav = type;
		},
		starChange (i){
			this.evaluateNum = i+1;
		},
		changeEvaluate (){
			if(this.evaluateText.length > 5) {
				this.showError = false;
				this.textareaErrorTxt = '';
			}
		},
		// 提交课程评价
		submitEvaluate (){
			if(!this.evaluateText || this.evaluateText.length < 5){
				this.showError = true;
				this.textareaErrorTxt = '请至少输入5个字';
				return false;
			} 
			this.$store.commit('setCheckCodeVisible', {checkCodeVisible: true});   
			services.addCourseComment({
				course_id: this.$route.params.classId,
				content: this.evaluateText,
				level: this.evaluateNum,
			}).then((res) => {
				if(res.code === '0000') {
					this.$store.dispatch("loadEvaluateLists", {
						course_id: this.$route.params.classId,
					});
					Message.success("评价成功");
				} else {
					Message.error(res.message);
				}
				this.evaluateText = '';
				this.evaluateNum = 5;
				this.clickEvaluateModal(false);
				return res;
			})
		},
		// 笔记输入框验证
		notesChangeTxt(){
			if(this.notesText.length > 5) {
				this.showNotesError = false;
				this.notesErrorTxt = '';
			}
		},
		// 保存笔记事件
		submitNotes (){
			let that = this;
			if(!this.notesText || this.notesText.length < 5){
				this.showNotesError = true;
				this.notesErrorTxt = '请至少输入5个字';
				return false;
			} 
			if(this.noteFormType == 'add'){
				let positionVal = 0;
				if(this.currentMaterial.type == 1){
					positionVal = parseInt(this.player.currentTime());
				} else {
					var iFrame = document.getElementById('pdfPreload'); 
					if (iFrame.contentDocument) { 
						positionVal = iFrame.contentDocument.getElementById('pageNumber').value; 
					}  
				}
				services.addCourseNote({
					course_id: this.$route.params.classId,
					chapter_id: this.chapter_id,
					cm_id: this.cm_id,
					content: this.notesText,
					position: positionVal,
					type: this.currentMaterial.type,
				}).then((res) => {
					that.notesAfterM(res);
				})
			} else {
				services.editUserCourseNote({
					id: this.note_id,
					data: {
						_method: 'put',
						content: this.notesText,
					}
				}).then((res) => {
					that.notesAfterM(res);
				})
			}
		},
		// 笔记保存请求结果后统一处理
		notesAfterM (res){
			let that = this;
			if(res.code === '0000') {
				Message.success("保存成功");
				that.notePageNum = 1;
				that.getNotesList(that.notePageNum);
			} else {
				Message.error(res.message);
			}
			
			that.notesText = '';
			that.notesErrorTxt = '';
			that.showNotesError = false;
			that.showNoteArea = false;
			that.noteFormType = "add";
		},
		// 获取笔记列表数据
		getNotesList (page){
			services.getUserCourseNote({
				course_id: this.$route.params.classId,
				chapter_id: this.currentMaterial.chapter_id,
				cm_id: this.currentMaterial.cm_id,
				page: page,
				size: this.noteSize,
			}).then((res) => {
				if(res.code === '0000') {
					this.noteContentFormat(res);
					if(page > 1){
						this.notesData = this.notesData.concat(res.data.items);
					} else {
						this.notesData = res.data.items;
					}
					this.notesListTotal = Math.ceil(res.data.total / this.noteSize);
				}
			}).catch(err => {
				console.log(err)
			})
		},
		// 笔记content过长处理 展开 收起
		noteContentFormat(res){
			if(res.data.items && res.data.items.length > 0){
				res.data.items.forEach(elem => {
					if(elem.content && elem.content.length > this.maxNum){
						elem.isShowLong = true;
						elem.isShowFlag = true;
						elem.contentFormat = elem.content.substring(0, this.maxNum) + '...';
					} else {
						elem.isShowLong = false;
					}
				});
			}
			return res;
		},
		// 笔记列表数据分页
		handleInfiniteOnLoad() {
			if ((this.notePageNum + 1) <= this.notesListTotal) {
				this.notePageNum = Number(this.notePageNum) + 1;
				this.getNotesList(this.notePageNum);
			}
    },
		// 编辑笔记方法
		editNotesMethod(item) {
			this.showNoteArea = true;
			this.notesText = item.content;
			this.noteFormType = "edit";
			this.note_id = item.id;
		},
		// 删除笔记方法
		delNotesMethod(item) {
			let that = this;
			Modal.confirm({
        title: '确定删除笔记吗',
        okText: '确定',
        okType: 'info',
        cancelText: '取消',
        onOk() {
					services.editUserCourseNote({
						id: item.id,
            data: {
              _method: 'delete',
            }
					}).then((res) => {
						if(res.code == '0000'){
							Message.success("删除成功");
							that.notePageNum = 1;
							that.getNotesList(that.notePageNum);
						} else {
							Message.error(res.message);
						}
					})
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
		}
	},
	destroyed() {
		clearInterval(this.inter);
		this.inter = null
		this.player && this.player.dispose();
	},
	filters: {
		addTimeFormat (val){
			let date = val.split(" ")[0];
			let time = val.split(" ")[1];
			let second = time.split(":")[1];
			return date + ' ' + time.split(":")[0] + ":" + second;
		},
		formatSeconds(value) {
			let result = parseInt(value);
			let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
			let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
			let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
			return `${h}:${m}:${s}`;
		},
	}
};
</script>

<style scoped>
.evalmodal-outer-box {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
	width: 100%;
	height: 100%;
}
.evalmodal-wrap {
	width: 100%;
	height: 100%;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.evaluate-form-box {
	width: 700px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1001;
	background: #fff;
	padding: 0 0 30px;
}
.evaluate-form-box .t-title {
	/* overflow: hidden;
	border-bottom: 1px solid #d6d6d6; */
	padding: 0 15px;
    background-color: #E5ECFB;
    height: 40px;
    line-height: 40px;
	position: relative;
}
.evaluate-form-box .t-title .txt {
	font-size: 14px;
    line-height: 40px;
    color: #333;
    font-weight: bold;
}
.evaluate-form-box .t-title .close {
	position: absolute;
	top: 12px;
    right: 15px;
	margin-top: 0px;
	width: 16px;
    height: 16px;
    background-image: url("../../assets/icons-close.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.evaluate-form-box .evaluate-lines-box {
	padding: 20px;
	position: relative;
}
.evaluate-form-box .label-box {
	font-size: 14px;
	color: #666;
	width: 70px;
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
	position: absolute;
}
.evaluate-form-box:first-child .label-box {
	position: static;
}
.evaluate-form-box .label-box span {
	display: inline-block;
	width: 28px;
	vertical-align: top;
}
.evaluate-form-box .star-num-box {
	vertical-align: top;
	display: block;
	margin-left: 80px;
}
.evaluate-form-box .star-num-box .star-items {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}
.evaluate-form-box .star-num-box .star-items img {
	width: 100%;
	height: 100%;
}
.evaluate-form-box .evaluate-textarea-box {
	display: block;
	vertical-align: top;
	padding: 10px 10px 5px;
	border: 1px solid #E0DFE4;
	margin-left: 80px;
	box-sizing: border-box;
	position: relative;
	background-color: #fff;
}
.evaluate-form-box .evaluate-textarea-box .error-info {
	position: absolute;
	bottom: -20px;
	font-size: 12px;
	color: #C40000;
	left: 10px;
}
.evaluate-form-box .evaluate-textarea-box textarea {
	border: none;
	line-height: 24px;
	width: 100%;
	outline: none;
	resize: none;
	font-size: 14px;
}
.evaluate-form-box .limit-box {
	color: #999;
	font-size: 12px;
	text-align: right;
}
.evaluate-form-box .limit-box span {
	display: inline-block;
	margin-left: 10px;
}
.evaluate-form-box .evaluate-button {
	background: #3370ff;
	color: #fff;
	border-radius: 26px;
	line-height: 40px;
	height: 40px;
	cursor: pointer;
	width: 100px;
	text-align: center;
	display: inline-block;
}
.evaluate-form-box .evaluate-button.cancel {
	background-color: #d7d7d7;
	color: #333;
	margin-right: 20px;
}
textarea::-webkit-input-placeholder{
	color:#999;
}
textarea::-moz-placeholder{  
	color:#999;
}
textarea:-moz-placeholder{   
	color:#999;
}
textarea:-ms-input-placeholder{ 
	color:#999;
}
</style>