import axios from "axios";
axios.defaults.withCredentials = true;  //允许跨域携带cookies

var HOST = '';
var IMG_SERVER = '';
var SCHOOL_PHOTO_SERVER = "";
var LOGIN_SERVER = "";
var rmtUrl = "";
var APPS_SERVIER = ''; //信息服务中心
var SZ_SERVIER = ''; //思政资源库
var XUEXI_SERVER = ''; //新版在线学习
var KCSZ_SERVER = ''; // 课程思政资源库
var QMTYY_SERVER = ''; // 全媒体运营平台
var  XUEXI_SITE = ''; // 学习中心
if (process.env.NODE_ENV == 'development') {
  HOST = '';
  IMG_SERVER = 'http://yxt-api.43.dev.eol.com.cn';
  SCHOOL_PHOTO_SERVER = "http://passport.53.dev.eol.com.cn";
  LOGIN_SERVER = "http://passport.53.dev.eol.com.cn";
  rmtUrl = "https://rmtx.53.dev.eol.com.cn";
  APPS_SERVIER = 'http://apps-api.66.dev.eol.com.cn'; //信息服务中心
  // APPS_SERVIER = 'http://apps-api.dev.gophper.com'; // 测试微信登录
  SZ_SERVIER = 'http://sz.66.dev.eol.com.cn';
  XUEXI_SITE = "http://xuexi.66.dev.eol.com.cn";
  XUEXI_SERVER = 'http://xuexi-api.66.dev.eol.com.cn';
  KCSZ_SERVER = 'http://kcsz.66.dev.eol.com.cn';
  QMTYY_SERVER = 'https://qmt66.jiaoyu.cn';
} else {
  // 第二层判断打包后是测试环境还是正式环境 
  if(process.env.VUE_APP_FLAG === 'pro'){
    // 正式环境 
    HOST = 'https://api-yxt.jiaoyu.cn';
    IMG_SERVER = 'https://api-yxt.jiaoyu.cn';
    SCHOOL_PHOTO_SERVER = "https://jw.jiaoyu.cn";  // eslint-disable-line no-unused-vars
    LOGIN_SERVER = "https://jw.jiaoyu.cn"; // eslint-disable-line no-unused-vars
    rmtUrl = "https://rmt.jiaoyu.cn";
    APPS_SERVIER = 'https://apps-api.jiaoyu.cn'; //信息服务中心
    SZ_SERVIER = 'https://sz.jiaoyu.cn';
    XUEXI_SERVER = 'https://xuexi-api.jiaoyu.cn';
    XUEXI_SITE = "http://xuexi.jiaoyu.cn";
    KCSZ_SERVER = 'https://kcsz.jiaoyu.cn';
    QMTYY_SERVER = 'https://qmt.jiaoyu.cn';
  } else {
    // 测试环境 学校logo路径为passport
    HOST = 'http://yxt-api.43.dev.eol.com.cn';
    IMG_SERVER = 'http://yxt-api.43.dev.eol.com.cn';
    SCHOOL_PHOTO_SERVER = "http://passport.53.dev.eol.com.cn"; // eslint-disable-line no-unused-vars
    LOGIN_SERVER = "http://passport.53.dev.eol.com.cn"; // eslint-disable-line no-unused-vars
    rmtUrl = "https://rmtx.53.dev.eol.com.cn";
    APPS_SERVIER = 'http://apps-api.66.dev.eol.com.cn';
    SZ_SERVIER = 'http://sz.66.dev.eol.com.cn';
    XUEXI_SERVER = 'http://xuexi-api.66.dev.eol.com.cn';
    XUEXI_SITE = "http://xuexi.66.dev.eol.com.cn";
    KCSZ_SERVER = 'http://kcsz.66.dev.eol.com.cn';
    QMTYY_SERVER = 'https://qmt66.jiaoyu.cn';
  }
}

function handleFunc (res) {
  let pathname = window.location.pathname;
  // if(res.code == '2001') {
   
  // } else if(res.code == '3001'){
    
  // } 
}

//请求发送拦截
axios.interceptors.request.use((request) => {
  return request;
}, function (error) {
  return Promise.reject(error);
});

const request = (method, url, params, needLogin) => {
  if (needLogin) params.login_sign = '';

  if (method == 'post') {
    return axios.post(url, params).then((res) => res.data).then((res)=>{
      handleFunc(res);
      return res
    })
  } else if (method == 'get') {
    return axios.get(url, { params }).then((res) => res.data).then((res)=>{
      handleFunc(res);
      return res
    })
  }
}
export default {
  IMG_SERVER: IMG_SERVER,
  HOST: HOST,
  SCHOOL_PHOTO_SERVER: SCHOOL_PHOTO_SERVER,
  LOGIN_SERVER: LOGIN_SERVER,
  MEDIA_SERVER: "https://rmt.eol.cn", // 融媒体请求地址
  rmtUrl:rmtUrl,//党建思政请求地址
  APPS_SERVIER: APPS_SERVIER,
  SZ_SERVIER: SZ_SERVIER,
  XUEXI_SERVER:XUEXI_SERVER,
  XUEXI_SITE: XUEXI_SITE,
  KCSZ_SERVER: KCSZ_SERVER,
  QMTYY_SERVER,
  home_home (params) {
    return request('post', HOST +'/api/home/home', params)
  },
  //主站推荐课程列表接口
  courseYxtTop(params){
    return request('post', HOST +'/api/course/yxtTop', params, true)
  },
  //测评文件分片上传
  upload(params){
    return request('post',HOST +'/api/user/assessFile/uploads',params,true);
  },
  need_getcode () {
    return axios.get(HOST+'/api/common/register/getcode', {
      responseType: 'blob'
    }).then((res) => res.data)
  },

  // V2.0接口
  // 信息服务中心 - 登录之前调用判断是否需要验证图片验证码
  getLoginBeforeInfo (params) {
    return request('get', APPS_SERVIER + '/api/login/info', params)
  },
  // 信息服务中心 - 获取图片验证码
  getAppsImageCaptcha (params) {
    return request('get', APPS_SERVIER + '/api/captcha', params)
  },
  // 信息服务中心 - 获取短信验证码
  getAppsSmsCaptcha (params) {
    return request('get', APPS_SERVIER + '/api/sms/send', params)
  },
  // 信息服务中心 - 官网注册
  postAppsRegister (params) {
    return request('post', APPS_SERVIER + '/api/register', params)
  },
  // 信息服务中心 - 登录
  postAppsLogin (params) {
    return request('post', APPS_SERVIER + '/api/login', params)
  },
  // 信息服务中心 - 退出登录
  postAppsLogout(params){
    return request('post', APPS_SERVIER + '/api/logout', params)
  },
  // 信息服务中心 - 修改个人密码
  postAppsEditPassword(params) {
    return request('post', APPS_SERVIER + '/api/user/password', params)
  },
  // 信息服务中心 - 重置密码
  postAppsResetPassword(params) {
    return request('post', APPS_SERVIER + '/api/reset', params)
  },
  // 信息服务中心 - 修改个人信息
  postAppsEditInfo(params) {
    return request('post', APPS_SERVIER + '/api/user/update', params)
  },
  // 信息服务中心 - 重置密码
  getAppsUserInfo(params) {
    return request('get', APPS_SERVIER + '/api/user/info', params)
  },
  // 信息服务中心 - 绑定/换绑手机号
  updateUserPhone(params) {
    return request('post', APPS_SERVIER + '/api/user/phone', params)
  },
  // 信息服务中心 - 解绑微信
  getWechatUnbind(params) {
    return request('post', APPS_SERVIER + '/api/user/unbind', params)
  },
  // 信息服务中心 - 微信登录绑定
  wechatBindAccount(params) {
    return request('post', APPS_SERVIER + '/api/weixin/bind', params)
  },
  // 课程分类
  getCourseCategory(params) {
    return request('get', XUEXI_SERVER + '/api/course/category', params)
  },
  // 课程学习日志新增
  addCourseLog(params) {
    return request('get', XUEXI_SERVER + '/api/course/log/add', params)
  },
  // 课程章节详情
  getCourseChapterDetail(params) {
    return request('get', XUEXI_SERVER + '/api/course/'+params.id+'/chapter', params.data)
  },
  // 课程章节播放信息
  getCoursePlay(params) {
    return request('get', XUEXI_SERVER + '/api/course/course/'+ params.id +'/play', params.data)
  },
  // 课程列表
  getCourseLists(params) {
    return request('get', XUEXI_SERVER + '/api/course/course', params)
  },
  // 课程详情
  getCourseDetails(params) {
    return request('get', XUEXI_SERVER + '/api/course/course/'+ params.id, params.data)
  },
  // 课程讲师信息
  getCourseTeacher(params) {
    return request('get', XUEXI_SERVER + '/api/course/teacher', params)
  },
  // 课程详情-获取课程评价列表
  getCourseComment(params) {
    return request('get', XUEXI_SERVER + '/api/course/comment', params)
  },
  // 课程详情-添加课程评价
  addCourseComment(params) {
    return request('post', XUEXI_SERVER + '/api/course/comment', params)
  },
  // 课程详情-删除课程评价
  removeCourseComment(params) {
    return request('post', XUEXI_SERVER + '/api/course/comment/'+ params.id, params.data)
  },
  // 课程详情-添加课程笔记
  addCourseNote(params) {
    return request('post', XUEXI_SERVER + '/api/my/course/note', params)
  },
  // 学习中心-我的课程列表
  getUserCourseList(params) {
    return request('get', XUEXI_SERVER + '/api/my/course', params)
  },
  // 学习中心-我的笔记列表
  getUserCourseNote(params) {
    return request('get', XUEXI_SERVER + '/api/my/course/note', params)
  },
  // 学习中心-修改、删除我的笔记
  editUserCourseNote(params) {
    return request('post', XUEXI_SERVER + '/api/my/course/note/'+ params.id, params.data)
  },
  // 学习中心-我的学习记录列表
  getUserLearnLog(params) {
    return request('get', XUEXI_SERVER + '/api/my/learn/log', params)
  },
  //课程资源pdf文件流下载v2
  courseFilePreview(params){
    return axios.request({ 
      url: XUEXI_SERVER + "/api/course/course/preview", 
      data: params,
      responseType: 'blob',
      method: 'post'
    }).then((res) => res.data);
  },
  //免费试用留言板
  messageBoard(params){
    return request('post', XUEXI_SERVER +'/api/message/add', params)
  },
  //在线学习- 获取图片验证码
  getXuexiCaptcha(params){
    return request('get', XUEXI_SERVER +'/api/captcha', params)
  },
  getzzncCourseList(params){
    return request('get', XUEXI_SERVER +'/api/zznc/index', params)
  },
  sendSatisfied(params){
    return request('post', XUEXI_SERVER +'/api/zznc/sat_save', params)
  },
  getSatisfied(params){
    return request('get', XUEXI_SERVER +'/api/zznc/sat_score', params)
  },
  // 获取全媒体运营数据
  getQmtyyNewsList(params){
    return request('get', QMTYY_SERVER +'/api/cms/news', params)
  },
  // 获取全媒体运营数据
  getQmtyyNewsDetail(params){
    return request('get', QMTYY_SERVER +'/api/cms/news/' + params.id, params.data)
  },
}