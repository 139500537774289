<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/zjiet/logo.png" alt="" class="logo">
          <img src="../../../../assets/zjiet/schoolLogo.png" alt="" class="schoolLogo">
          <span class="bgTitle">课程思政教学研究中心</span>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item">
            <router-link :to="'/school/zjiet'" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="'/school/zjiet/course'" exact>示范课程</router-link>
          </li>
          <li :class="listIndex=='1'?'actived nav_item':'nav_item'">
            <router-link :to="'/school/zjiet/list/1'" exact>新闻动态</router-link>
          </li>
          <li :class="listIndex=='2'?'actived nav_item':'nav_item'">
            <router-link :to="'/school/zjiet/list/2'" exact>通知文件</router-link>
          </li>
          <li :class="listIndex=='3'?'actived nav_item':'nav_item'">
            <router-link :to="'/school/zjiet/list/3'" exact>媒体报道</router-link>
          </li>
          <li :class="listIndex=='4'?'actived nav_item':'nav_item'">
            <router-link :to="'/school/zjiet/list/4'" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a href="https://sz.jiaoyu.cn/szzyk" target="_blank">思政资源库</a>
          </li>
        </ul>
      </div>
    </header>
    <template v-if="listIndex == '1'">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="'/school/zjiet/detail/1'">
                首届浙江省供销社行业类专场校园招聘会成功举办
              </router-link>
            </div>
            <div class="intro">
              11月23日，2021年浙江省技能人才岗位进校园系列招聘会暨浙江省供销社行业类专场校园招聘会在浙江经贸职业技术学院隆重举行。浙江省供销合作社联合社党委委员、理事会副主任张建，党委委员、人事处处长孙鲁东，浙江省职业介绍服务指导中心副主任张建，浙江经贸职业技术学院党委书记劳赐铭、校长陈德泉、副校长张红、副校长蒋丽君等出席启动仪式，会议由校长陈德泉主持。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                钱江晚报·小时新闻丨有企业开出万元月薪！首届浙江省供销社行业类专场校园招聘会举
              </a>
            </div>
            <div class="intro">
              银杏变黄、枫叶渐红，深秋的杭城有一种令人怦然心动的美，又是一年收获季！11月23日，在浙江经贸职业技术学院风雨操场上，2021年浙江省技能人才岗位进校园系列招聘会暨浙江省供销社行业类专场校园招聘会顺利举行。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                我校在“第十五届全国高校商业精英挑战赛会展创新创业总决赛”
                中荣获佳绩
              </a>
            </div>
            <div class="intro">
              2021年11月18日至21日，由中国国际贸易促进委员会主办，中国贸促会海南省委员会、三亚市人民政府等承办的“第十五届高校商业精英挑战赛会展创新创业实践竞赛全国总决赛”成功举办。我校会展策划与管理专业派出2支队伍参赛，在钱小轮、华尹、胡芳芳、张俊磊四位老师指导下，我校参赛队在487支队伍中脱颖而出。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 省纪委省监委驻省经信厅纪检监察组 调研指导学校工作 </a>
            </div>
            <div class="intro">
              11月18日，省纪委省监委驻省经信厅纪检监察组组长郑灵仙、副组长陈陆军等一行来校调研指导学校党委全面从严治党、“三重一大”决策、重点权力运行等情况。省供销社直属机关党委专职副书记、直属机关纪委书记施建锋陪同调研，学校领导班子参加调研。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                喜报丨我校在第六届“踏瑞杯”全国高职高专人力资源管理技能大赛中荣获特等奖
              </a>
            </div>
            <div class="intro">
              2021年第六届“踏瑞杯”全国高职高专人力资源管理技能大赛（总决赛）于近日圆满结束。经过长达8个月的备战训练，我校工商管理学院工商企业管理专业的刘萍、马有骏、何庆洁三位同学在企管专业带头人沈飚老师的倾力支持和柴妍冬、潘晓霞两位老师的指导下，继中部赛区以特等奖的成绩晋级后，又在本次全国总决赛中，以总成绩第二名的优异成绩获得特等奖。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == 2">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="'/school/zjiet/detail/6'">
                关于《潮韵》征稿启事的通知
              </router-link>
            </div>
            <div class="intro">
              由学校团委主办的文学刊物《潮韵》创办于1989年，旨在为广大师生提供一个发挥才能、传播文化的文学平台，为学校营造浓厚人文氛围，积淀特色校园文化。为进一步激发高校师生的责任担当，厚植学生的爱国主义情怀，现面向全体校友、全校师生征稿。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 校园建设处关于招聘基建项目管理顾问的公告 </a>
            </div>
            <div class="intro">
              学校产教融合大楼建设工程项目已全面开工建设，现面向社会招聘项目基建项目管理顾问一名，具体要求...
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 关于申报2021年度全省供销社课题研究项目的通知 </a>
            </div>
            <div class="intro">
              坚持以习近平新时代中国特色社会主义思想为指导，深入贯彻落实党的十九大和十九届二中、三中、四中、五中全会精神及习近平总书记对供销合作社工作和职业教育的重要指示批示，围绕深化“三位一体”改革、全面推进供销社高质量发展、促进供销行业职业教育发展等开展理论和对策研究，为持续推进供销合作社综合改革、着力提高供销合作社职业教育质量和办学水平提供理论支持和实践参考。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                浙江经贸职业技术学院关于2021年求职创业补贴申请发放的公示
              </a>
            </div>
            <div class="intro">
              根据《浙江省人力资源和社会保障厅等5部门关于做好求职创业补贴发放工作的通知》(浙人社发〔2019〕56号）文件精神，经本人申请，学校初审，人社复审共174名同学符合要求（名单详见附件），现予以公示，公示时间：2021年10月25日-2021年10月29日。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                浙江经贸职业技术学院关于举办“裕农通杯”第四届浙江省大学生乡村振兴创意大赛校内选拔赛的通知
              </a>
            </div>
            <div class="intro">
              “裕农通杯”第四届浙江省大学生乡村振兴创意大赛是浙江省大学生科技竞赛，旨在为高校知识青年服务乡村搭建实践平台，引导广大青年学生用热情和创意助力乡村振兴、助推乡村发展。为做好“裕农通杯”第四届浙江省大学生乡村振兴创意大赛的参赛工作，现将校内选拔赛有关事项通知如下。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == 3">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="'/school/zjiet/detail/11'">
                人民新闻报：追寻红色印迹，共筑淳美住龙
              </router-link>
            </div>
            <div class="intro">
              2021年是“十四五”开局之年，是中国共产党建党一百周年，百年大党，风华正茂。为响应党和国家的号召，激发青年大学生爱国爱党情怀，深入学习党史，自觉接受红色教育，浙江经贸职业技术学院“寻访红色党史印迹”小分队于8月3日奔赴浙西南革命根据地—浙江省龙泉市住龙镇开展为期九天的红色主题暑期社会实践。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                中国教育在线：别人家的新生报到日
                浙经贸合作经济学院带学生家长云逛校园
              </a>
            </div>
            <div class="intro">
              “欢迎各位家长云逛我们的新生寝室楼，卫生间干湿分离，还配备空调、洗衣机、烘干机……”
              9月16日9：00，浙经贸合作经济学院的师生开启了一场“金秋‘农’意‘合’你同行”带新生家长云逛校园直播活动。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 中华合作时报：“阳光空间”助力偏远山村产业协调发展 </a>
            </div>
            <div class="intro">
              8月6日，在浙江省常山县球川镇东坑村文化礼堂，东坑村支书、主任廖端泉从浙江经贸职业技术学院院长陈德泉手中接过“阳光空间”示范村的牌子，标志着学院与东坑村共建社会服务平台，助力乡村振兴工作取得了阶段性成果。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                中华合作时报： 朱超云： 愿做直通学生心灵的“造桥师”
              </a>
            </div>
            <div class="intro">
              “我觉得学生党支部工作的重点应该是让学生找到信仰，用信仰凝聚学生，借此贯通青年学生与党组织之间的‘最后一米’。”朱超云说。
            </div>
          </li>

          <li class="list_item">
            <div class="title">
              <a href="">
                中国反邪教协会薄荷茶社：浙江经贸职业技术学院：喜迎建党百年，传播反邪强音
              </a>
            </div>
            <div class="intro">
              为深入贯彻落实习近平总书记七一重要讲话精神，庆祝建党100周年，激发青年学子的爱国情、报国志，强化居民崇尚科学、反对邪教的意识，推动平安社区建设，营造共同反邪氛围。近日，浙江经贸职业技术学院反邪教暑期社会实践团队在杭州市钱塘区开展了形式多样的反邪宣传和调研活动。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <template v-if="listIndex == '4'">
      <main>
        <ul class="list">
          <li class="list_item">
            <div class="title">
              <router-link :to="'/school/zjiet/detail/16'">
                习近平：在纪念辛亥革命110周年大会上的讲话
              </router-link>
            </div>
            <div class="intro">
              110年前，以孙中山先生为代表的革命党人发动了震惊世界的辛亥革命，推翻了清朝政府，结束了在中国延续几千年的君主专制制度，近代以来中国发生的深刻社会变革由此拉开了序幕。这是中国人民和中国先进分子为实现民族独立、人民解放进行的一次伟大而艰辛探索。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href=""> 习近平向2021年世界互联网大会乌镇峰会致贺信 </a>
            </div>
            <div class="intro">
              习近平指出，数字技术正以新理念、新业态、新模式全面融入人类经济、政治、文化、社会、生态文明建设各领域和全过程，给人类生产生活带来广泛而深刻的影响。当前，世界百年变局和世纪疫情交织叠加，国际社会迫切需要携起手来，顺应信息化、数字化、网络化、智能化发展趋势，抓住机遇，应对挑战。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                坚定信心 共克时艰 共建更加美好的世界
                ——在第七十六届联合国大会一般性辩论上的讲话 （2021年9月21日）
              </a>
            </div>
            <div class="intro">
              2021年对中国人民是一个极其特殊的年份。今年是中国共产党成立100周年。今年也是中华人民共和国恢复在联合国合法席位50周年，中国将隆重纪念这一历史性事件。我们将继续积极推动中国同联合国合作迈向新台阶，为联合国崇高事业不断作出新的更大贡献。
            </div>
          </li>
          <li class="list_item">
            <div class="title">
              <a href="">
                习近平在上海合作组织成员国元首理事会第二十一次会议上的讲话
              </a>
            </div>
            <div class="intro">
              不忘初心
              砥砺前行，开启上海合作组织发展新征程，——在上海合作组织成员国元首理事会第二十一次会议上的讲话，中华人民共和国主席
              ——习近平
            </div>
          </li>

          <li class="list_item">
            <div class="title">
              <a href="">
                坚定决心意志 埋头苦干实干 确保如期实现建军一百年奋斗目标
              </a>
            </div>
            <div class="intro">
              实现建军一百年奋斗目标，是党中央和中央军委把握强国强军时代要求作出的重大决策，是关系国家安全和发展全局的重大任务，是国防和军队现代化新“三步走”十分紧要的一步。要坚定决心意志，增强紧迫意识，埋头苦干实干，确保如期实现既定目标。
            </div>
          </li>
        </ul>
      </main>
    </template>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  data () {
    return {
      listIndex: null,
    };
  },
  mounted () {
    this.listIndex = this.$route.params.id;
  },
  watch: {
    $route: {
      handler () {
        this.listIndex = this.$route.params.id;
      },
    },
    deep: true,
  },
};
</script>

<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./list.css" scoped>
</style>