<template>
  <div>
    <Nav />
      <div class="noAuth-body">
        <!-- <div class="class404" :style="{height: vFrameHeight + 'px'}">
          <img class="img404" src="../assets/404.png" alt="" style="margin-top: 40px">
          <p class="info-txt" style="margin-bottom: 15px;">没有访问权限~ </p>
          <a-button type="primary" shape="round" @click="logout">重新登录</a-button>
        </div>
        <Footer /> -->
        <div class="noAuth-box">
          <img class="noImgs" src="../assets/noauth-school-img.png" alt="">
          <p class="noAuth-txt">系统监测您已登录教师账号，此操作仅对学生用户开放，</p>
          <p class="noAuth-txt">如需查看请退出重新登录或使用其他浏览器。</p>
        </div>
      
      </div>
    <Aside/>
    <Footer />
  </div>
</template>

<script>
import Aside from "./Aside"
import Nav from "./Nav";
import Footer from "./Footer";
export default {
	name: "NoAuth",
	components: {
		Footer,
    Nav,
    Aside,
	},
	data() {
		return {
      vFrameHeight: '',
		};
	},
	mounted() {
    this.vFrameHeight = document.body.clientHeight - 98;
	},
	methods: {
    // async logout () {
    //   // 调用教务退出登录接口
    //   let loginTicket = JSON.parse(localStorage.getItem('loginTicket'));
    //   console.log("loginTicket 退出", loginTicket);
    //   if(!loginTicket || loginTicket == null || typeof loginTicket != "object" ){
    //     await this.$store.dispatch("getLoginTicket", {}).then(resData => {
    //       if(resData.code == "0000"){
    //         loginTicket = resData.data;
    //       }
    //     })
    //   }
      
    //   console.log("调用教务退出登录接口");
    //   const passportData = await axios.get(`${services.LOGIN_SERVER}/login/out?label=${loginTicket.label}&sign=${loginTicket.sign}`).then(function (response) {
    //     console.log("教务退出登录了", JSON.stringify( response.data));
    //     return response.data;
    //   }).catch(function (error) {
    //     console.log("教务退出出错了", error);
    //   });
    //   if(passportData.code == "1") {
    //     console.log("用户端调转了退出", `${services.LOGIN_SERVER}/login/auto?label=${loginTicket.label}&sign=${loginTicket.sign}&type="student"`);
    //     window.open(`${services.LOGIN_SERVER}/login/auto?label=${loginTicket.label}&sign=${loginTicket.sign}&type="student"`, '_self');
    //   }       
    // },
	},
	computed: {

	}
};
</script>

<style scoped>
.noAuth-body {
  width: 100vw;
  height: 558px;
  position: relative;
  background-color: #F1F2F7;
}
.noAuth-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.noImgs {
  width: 140px;
  height: auto;
  margin-bottom: 40px;
}
.noAuth-txt {
  color: #777777;
  line-height: 20px;
  font-size: 12px;
}
</style>