import Vue from 'vue'
import Vuex from 'vuex'
import modulesHome from './home'
import modulesClass from './class'
import modulesNews from './news'
import modulesMedia from "./media"
import appsLogin from './appsLogin'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  modules: {
    home: modulesHome,
    class: modulesClass,
    news: modulesNews,
    media: modulesMedia,
    appsLogin: appsLogin,
  }
})

export default store