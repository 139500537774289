// 首页及示范课程页面 课程6
export  var courseList = [
  // {
  //   name: '数字广播电视技术',
  //   imgSrc: '../../../../assets/sdcmc/tologin.png',
  //   studyNum: '34',
  //   url: 'https://jw.jiaoyu.cn/login',
  // },
  {
    name: '数字电视技术原理及应用-课程思政典型案例',
    imgSrc: require('../../../assets/sdcmc/course7.jpg'),
    studyNum: '534',
    url: 'https://www.jiaoyu.cn/school/class/22',
  },
  {
    name: '院校统筹推进课程思政建设的经验分享',
    imgSrc: require('../../../assets/sdcmc/course2.jpg'),
    studyNum: '435',
    url: 'https://www.jiaoyu.cn/school/class/20',
  },
  {
    name: '关于课程思政“金课”打造的思考与探讨',
    imgSrc: require('../../../assets/sdcmc/course3.jpg'),
    studyNum: '413',
    url: 'https://www.jiaoyu.cn/school/class/19',
  },
  {
    name: '课程思政实施路径与方法',
    imgSrc: require('../../../assets/sdcmc/course4.jpg'),
    studyNum: '552',
    url: 'https://www.jiaoyu.cn/school/class/18',
  },
  {
    name: '职业院校“课程思政”的探索与实践',
    imgSrc: require('../../../assets/sdcmc/course5.jpg'),
    studyNum: '721',
    url: 'https://www.jiaoyu.cn/school/class/17',
  },
  {
    name: '课程思政的三个基本问题',
    imgSrc: require('../../../assets/sdcmc/course6.jpg'),
    studyNum: '686',
    url: 'https://www.jiaoyu.cn/school/class/16',
  },
];