import Vue from 'vue'
import services from '../config/services'
import utils from '../config/utils'
import axios from "axios";
axios.defaults.withCredentials = true;  //允许跨域携带cookies
export default {
  state:{
    homeData: {},
    schoolData: {},
    courseYxtData: [],
  },
  getters:{
    // state (state){
    //   return state
    // },
    // nameInfo (state){
    //   return "姓名:" + state.name
    // },
  },
  actions: {
    initData(context, payload){
      return services.home_home(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('homeData', {
            'homeData': res.data.items || {}
          })
        }
        return res;
      })
    },
    schoolData(context, payload){
      return services.schools_get(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('schoolData', {
            'schoolData': res.data || {}
          })
        }
      })
    },
    getHomeCourseData(context, payload){
      return services.getCourseLists(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('courseYxtData', {
            'courseYxtData': res.data.items || []
          })
        }
      })
    },
    // 人民网cms接口调用方法 - 20231222弃用
    // loadHomeMediaData(context, payload){
    //   let timestamp = Date.parse(new Date());
    //   return new Promise((resolve, reject) => {
    //     axios.get(`${services.MEDIA_SERVER}/${payload.type}/NewsList_${payload.page}.json?${timestamp}`).then(resData => {
    //       // console.log("resData", resData);
    //       resolve(resData);
    //     }).catch(err => {
    //       reject(err);
    //     })
    //   });  
    // },
    // 全媒体运营cms栏目文章列表接口调用方法
    loadQmtyyNewsList(context, payload){
      let params = {
        site_id: utils.ZJYK_SITE_ID,
        ...payload.data,
      }
      if(payload && payload.type){
        params.category_id =  utils.getZjykCategoryId(payload.type)
      }
      return services.getQmtyyNewsList(params).then((res) => {
        return res;
      })
    },
  },
  mutations:{
    homeData (state, payload) {
      Vue.set(state, "homeData" ,payload.homeData)
    },
    courseYxtData (state, payload) {
      Vue.set(state, "courseYxtData" ,payload.courseYxtData)
    },
    schoolData (state, payload) {
      if(payload.schoolData && payload.schoolData.intro){
        let intro = payload.schoolData.intro;
        payload.schoolData.intro.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
          if(capture.indexOf('http://') == -1 && capture.indexOf('base64') == -1){
            let captureUrl = capture.replace(/\/app/g,`${services.IMG_SERVER}/app`);
            capture = capture.replace(/\//g, "\\/");
            let reg = new RegExp(capture, "g");
            intro = intro.replace(reg, `${captureUrl}`);
          }
        });
        payload.schoolData.intro = intro;
      }
      Vue.set(state, "schoolData" ,payload.schoolData)
    },
  }
}