// 首页及示范课程页面 课程6
export  var courseList = [
  {
    name: '课程思政示范课 - 意大利三式薄记',
    imgSrc: require('../../../assets/zjiet/Italy.jpg'),
    studyNum: '112',
    url: 'https://www.jiaoyu.cn/school/class/14',
  },
  {
    name: '课程思政示范课 - 进出口业务（运输方式)',
    imgSrc: require('../../../assets/zjiet/transport.jpg'),
    studyNum: '208',
    url: 'https://www.jiaoyu.cn/school/class/13',
  },
  {
    name: '课程思政示范课 - 商务数据分析管理',
    imgSrc: require('../../../assets/zjiet/business.jpg'),
    studyNum: '156',
    url: 'https://www.jiaoyu.cn/school/class/12',
  },
  {
    name: '课程思政示范课 - 名优绿茶审评技术',
    imgSrc: require('../../../assets/zjiet/tea.jpg'),
    studyNum: '321',
    url: 'https://www.jiaoyu.cn/school/class/11',
  },
  {
    name: '课程思政示范课 - 进出口业务（海洋运输)',
    imgSrc: require('../../../assets/zjiet/sea.jpg'),
    studyNum: '125',
    url: 'https://www.jiaoyu.cn/school/class/10',
  },
  {
    name: '毛泽东思想和中国特色社会主义理论体系概论',
    imgSrc: require('../../../assets/zjiet/course3.png'),
    studyNum: '186',
    url: 'https://yxt.jiaoyu.cn/school/100004/class/36',
  },
];