<template>
  <div class="bg-box">
    <div class="c">
      <div class="category-list-box">
        <div class="category-name">类别：</div>
        <div class="tags-box">
          <div :class="classType == 1 ? 'tag-opt classic active' : 'tag-opt'" @click="changeType(1)">
            公开课
          </div>
          <div :class="classType == 2 ? 'tag-opt classic active' : 'tag-opt'" @click="changeType(2)">
            专业课
          </div>
        </div>
      </div>
      <div class="category-list-box">
        <div class="category-name">分类：</div>
        <div class="tags-box">
          <div :class="'tag-opt ' + (oneCateId == item.id ? 'active' : '')" v-on:click="oneClick(item.id, $event)" v-for="(item) in classCategory" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-show="classCategory2.length > 0" class="category-list-box">
        <div class="category-name"></div>
        <div class="tags-box">
          <div :class="'tag-opt ' + (twoCateId == item.id ? 'active' : '')" v-on:click="twoClick(item.id, $event)" v-for="(item) in classCategory2" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-show="classCategory3.length > 0" class="category-list-box">
        <div class="category-name"></div>
        <div class="tags-box">
          <div :class="'tag-opt ' + (threeCateId == item.id ? 'active' : '')" v-on:click="threeClick(item.id, $event)" v-for="(item) in classCategory3" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="class-lists">
        <div class="class-box" v-for="(item, index) in $store.state.class.classData.items" :key="index">
          <router-link class="link-box" :to="'/school/class/' + item.id" exact>
            <div class="img">
              <img :src="item.list_photo" alt />
            </div>
            <div class="txt">
              <div class="title">{{ item.name }}</div>
              <div class="num">
                <!-- <span class="iconfont iconduoren"></span> -->
                <div class="courseFont times"></div>
                课程总时长：{{ item.total_time }}
              </div>
            </div>
          </router-link>
        </div>
        <div class="sub-visibility"></div>
        <div class="sub-visibility"></div>
        <div class="sub-visibility"></div>
      </div>
    </div>

    <div class="pages c">
      <template v-if="classData.items && classData.items.length > 0">
        <a-pagination :defaultPageSize="size" :default-current="1" :current="currentPage" :total="classData.total" @change="onChange" />
      </template>
      <div v-else class="noData-box">
        <img src="@/assets/empty.png" alt="">
        <div class="text">暂无相关内容</div>
      </div>
    </div>
    <Aside />
  </div>
</template>

<script>
import { Pagination } from "ant-design-vue";
import utils from "../../config/utils";
import Aside from "../../components/Aside";
import services from "../../config/services";
import { mapActions, mapState } from "vuex";
export default {
  name: "Class",
  components: {
    Aside,
    APagination: Pagination,
  },
  data () {
    return {
      services: services,
      currentPage: 1,
      size: 15,
      classType: 1, // 1公开课 2专业课
      root_id: 0,
      category_id: 0, // 当前选中的分类id 最深层级 默认为0全部
      categoryLevel: 1, // 分类级别 最多3级
      oneCateId: '',
      twoCateId: '',
      threeCateId: '',
    };
  },
  computed: {
    ...mapState({ 
      classCategory: (state) => state.class.classCategory,
      classCategory2: (state) => state.class.classCategory2,
      classCategory3: (state) => state.class.classCategory3,
      classData: (state) => state.class.classData,
    }),
  },
  async mounted () {
    let newObj = Object.keys(this.$route.query);
    await this.loadCategoryData({root_id: 0, level: 1});
    if(newObj.length > 0){
      if(this.$route.query.type){
        this.classType = this.$route.query.type
      }
      if(this.$route.query.categoryLevel){
        this.categoryLevel = this.$route.query.categoryLevel
      }
      if(this.$route.query.oneCateId){
        this.oneCateId = this.$route.query.oneCateId
        this.category_id = this.oneCateId
        this.root_id = this.oneCateId
        await this.loadCategoryData({root_id: this.root_id, level: 2});
      }
      if(this.$route.query.twoCateId){
        this.twoCateId = this.$route.query.twoCateId
        this.category_id = this.twoCateId
        this.root_id = this.twoCateId
        await this.loadCategoryData({root_id: this.root_id, level: 3});
      }
      if(this.$route.query.threeCateId){
        this.threeCateId = this.$route.query.threeCateId
        this.category_id = this.threeCateId
      }
    }
    await this.courseListMethod();
  },
  methods: {
    ...mapActions(["loadCategoryData","getCourseData"]),
    // 请求获取课程列表
    async courseListMethod () {
      let that = this
      await that.getCourseData({
        page: that.currentPage,
        size: that.size,
        type: that.classType,
        category_id: that.category_id
      });
    },
    classToDetail (path) {
      utils.redirect(this, path);
    },
    onChange (pageNumber) {
      this.currentPage = pageNumber;
      this.courseListMethod()
    },
    // 课程分类点击事件
    changeType (type) {
      this.currentPage = 1;
      this.classType = type;
      this.routeLink()
    },
    oneClick (id) {
      this.oneCateId = id
      this.twoCateId = ''
      this.threeCateId = ''
      this.categoryLevel = 1
      this.routeLink()
    },
    twoClick (id) {
      this.twoCateId = id
      this.threeCateId = ''
      this.categoryLevel = 2
      this.routeLink()
    },
    threeClick (id) {
      this.threeCateId = id
      this.categoryLevel = 3
      this.routeLink()
    },
    // 跳转页面参数
    routeLink(){
      let param = {
        categoryLevel: this.categoryLevel,
        type: this.classType
      }
      if(this.oneCateId){
        param.oneCateId = this.oneCateId
      }  
      if(this.twoCateId){
        param.twoCateId = this.twoCateId
      } 
      if(this.threeCateId){
        param.threeCateId = this.threeCateId
      }
      let routeUrl = this.$router.resolve({ 
        path: '/school/class', 
        query: param
      });
      window.open(routeUrl.href, '_self');
    }
  },
  
};
</script>

<style lang="less" scoped>
.bg-box {
  background-color: #fff;
  width: 100%;
  padding: 40px 0 50px;
}
.category-list-box {
  width: 100%;
  display: flex;
  .category-name {
    width: 60px;
    color: #666666;
    font-size: 14px;
    padding: 5px 0 0;
  }
  .tags-box {
    flex: 1;
    color: #666666;
    font-size: 14px;
    .tag-opt {
      display: inline-block;
      padding: 4px 8px;
      margin: 0 15px 15px;
      cursor: pointer;
      font-size: 14px;
    }
    .tag-opt:first-child {
      font-size: 12px;
    }
    .tag-opt.active,
    .tag-opt:hover {
      background: #e2ebff;
      border-radius: 4px;
      color: #3d77ff;
    }
  }
}
.class-lists {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 0;
  text-align: left;
}
.class-box {
  width: 220px;
  // height: 216px;
  background: #fff;
  border: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 8px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt .title {
      color: #364c7e;
    }
  }
  .link-box {
    display: inline-block;
  }
  .img {
    height: 125px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      transition: all .8s;
    }
  }
  .txt {
    padding: 15px 12px;
    width: 220px;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #3a3a3a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      font-size: 12px;
      color: #959595;
      margin-top: 2px;
      .iconduoren {
        font-size: 17px;
        margin-top: 7px;
        vertical-align: -1px;
        margin-right: 5px;
        color: #d5d5d5;
      }
      .courseFont {
        width: 12px;
        height: 13px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: -1px;
        background-image: url('../../assets/site/home/times-icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
}
.class-lists .sub-visibility {
  flex: 0 0 220px;
  display: inline-block;
  overflow: visible;
}
.noData-box {
  width: 100%;
  text-align: center;
  height: 770px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
  img {
    width: 240px;
    height: auto;
    margin: 0 auto 30px;
  }
  .text {
    color: #76797e;
    font-size: 14px;
  }
}
</style>