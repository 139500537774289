<template>
	<div class="content-box">
		<div class="content-inner">
			<div class="left-imgs">
				<img src="@/assets/error.png" />
			</div>
			<div class="right-text">
				<div class="top-label">授权失败</div>
				<div class="little-txt">{{showText}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { decode } from 'js-base64';

export default {
	name: 'WechatError',
	data(){
		return {
			showText: ''
		}
	},
	created() {
		let backObj = null
		if(this.$route.query.data){
			backObj = JSON.parse(decode(this.$route.query.data)) 
			if(backObj.message){
				this.showText = backObj.message
			}
		}
		console.log(backObj)
	},
}
</script>

<style scoped>
.content-box {
	width: 100vh;
	height: 100vw;
	position: relative;
}
.content-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
}
.left-imgs {
	width: 120px;
	height: auto;
	margin-right: 20px;
}
.left-imgs img {
	width: 100%;
	height: auto;
}
.right-text {
	text-align: left;
}
.top-label {
	font-size: 18px;
	font-weight: bold;
	color: #333333;
	line-height: 50px;
}
.little-txt {
	font-size: 14px;
	color: #8a8d94;
}
</style>