<template>
  <div class="bg-all-box">
    <Nav />
    <div class="need-bg-box">
      <div class="c">
        <div class="center-form-box">
          <div class="toptip">告诉我们您的需求，专属顾问为您提供详细服务方案。</div>
          <a-form :form="form" @submit="handleSubmit" class="VForm">
            <a-form-item v-bind="formItemLayout" label="需求类型">
              <a-select placeholder="请选择需求类型"
								v-decorator="[
								'type',
								{
									rules: [
										{
											required: true,
											message: '请选择需求类型',
										}
									],
								},
							]" style="width: 120px" @change="handleChange">
                <a-select-option value="1">实训平台</a-select-option>
                <a-select-option value="2">专业教学资源</a-select-option>
                <a-select-option value="5">课程思政资源</a-select-option>
                <a-select-option value="6">思政培训</a-select-option>
                <a-select-option value="7">大思政育人</a-select-option>
                <a-select-option value="8">媒体宣传服务</a-select-option>
                <a-select-option value="9">党建培训</a-select-option>
                <a-select-option value="99">其它</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="联系人">
							<a-input
								placeholder=""
								v-decorator="[
								'contact_name',
								{
									rules: [
										{
											required: true,
											message: '请输入联系人姓名',
										}
									],
								},
							]"
							/>
						</a-form-item>
            <a-form-item v-bind="formItemLayout" label="联系方式">
							<a-input
								placeholder=""
								v-decorator="[
								'mobile',
								{
									rules: [
										{
											required: true,
											message: '请填写您的联系方式',
										}
									],
								},
							]"
							/>
						</a-form-item>
            <a-form-item v-bind="formItemLayout" label="从教院校">
							<a-input
								placeholder=""
								v-decorator="[
								'school_name',
								{
									rules: [
										{
											required: true,
											message: '请输入从教院校',
										}
									],
								},
							]"
							/>
						</a-form-item>
            <a-form-item v-bind="formItemLayout" label="专业/部门">
							<a-input
								placeholder=""
								v-decorator="[
								'belong',
								{
									rules: [
										{
											required: true,
											message: '请输入专业/部门',
										}
									],
								},
							]"
							/>
						</a-form-item>
						<a-form-item v-bind="formItemLayout" label="职务">
							<a-input
								placeholder=""
								v-decorator="[
								'job',
								{
									rules: [
										{
											required: true,
											message: '请输入职务',
										}
									],
								},
							]"
							/>
						</a-form-item>
            <a-form-item v-bind="formItemLayout" label="需求描述">
              <a-textarea
                placeholder=""
                :auto-size="{ minRows: 3, maxRows: 3 }"
								v-decorator="['description',
									{
										rules: [
											{
												required: true,
												message: '请输入需求描述',
											},
											{
												validator: validateToDescribe,
											}
										],
									},
								]"
              />
						</a-form-item>
						<a-form-item v-bind="formItemLayout" label="验证码">
							<a-input
								placeholder="请输入图形验证码"
								class="login-code-box"
								v-decorator="[
								'captcha',
								{
									rules: [
										{
											required: true,
											message: '图形验证码不能为空',
										}
									],
								},
							]"
							/>
							<div class="login-code" @click="loginCode">
								<img v-if="imageCode" :src="imageCode.img" alt />
							</div>
						</a-form-item>
						
						<div class="form-btn">
							<a-button type="primary" html-type="submit">提交留言</a-button>
						</div>
					</a-form>
        </div>
      </div>

    </div>
    <Aside />
    <Footer />
  </div>
</template>

<script>
// import utils from "../../config/utils";
import services from "../../config/services";
// import { dataJson } from "../../config/data";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Form, Input, Button, Select } from "ant-design-vue";
const { Item } = Form;
const { Option } = Select;
export default {
  name: "Need",
  components: {
    Aside,
    Nav,
    Footer,
    AForm: Form,
		AFormItem: Item,
		AInput: Input,
		AButton: Button,
    ASelect: Select,
    ASelectOption: Option,
  },
  data() {
    return {
			services:services,
      imageCode: {
				key:'',
				img: '',
			},
      formItemLayout: {
				labelCol: {
					xs: { span: 12 },
					sm: { span: 4 },
				},
				wrapperCol: {
					xs: { span: 12 },
					sm: { span: 16 },
				},
			},
    };
  },
  beforeCreate() {
		this.form = this.$form.createForm(this, { name: "register" });
	},
  mounted() {
    this.loginCode();
  },
  methods: {
    handleSubmit(e) {
			let that = this;
			e.preventDefault();
			let { setFields } = that.form;
			that.form.validateFieldsAndScroll((err, values) => {
				if (!err) {
					that.$store.dispatch("submitNeedForm", {
						...values,
						key: that.imageCode.key,
					}).then((resData) => {
						if(resData.code == '0000'){
							that.form.resetFields();
							that.$notification['success']({
								message: '提交成功！',
								description: '专属顾问会尽快与您联系。',
							});
						} else {
							that.loginCode();
							if (resData.errors) {
								let errors = resData.errors;
								for (let k in errors) {
									setFields({
										[k]: { errors: [new Error(errors[k])] },
									});
								}
							} else {
								that.$message.error(
									resData.message ? resData.message : "请求出错"
								);
							}
						}
					}).catch(err => {
						console.log(err);
					})
				}
			});
		},
    loginCode() {
			services.getXuexiCaptcha({}).then((res) => {
				if(res.code == '0000'){
          this.imageCode = res.data
        }
			});
		},
    handleChange() {
      // console.log(`selected ${value}`);
    },
		validateToDescribe(rule, value, callback) {
			if (value && value.length < 10) {
				callback("需求描述不能少于10个字符");
			} else {
				callback();
			}
		},
  },
};
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.need-bg-box {
  width: 100%;
  height: 880px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px 0 50px;
  // background-size: auto 100%;
  background-image: url('../../assets/site/need-bg.jpg');
}
.center-form-box {
  margin: 0 auto;
  width: 820px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 40px 50px 40px 130px;
  box-sizing: border-box;
  .toptip {
    font-size: 20px;
    font-weight: bold;
    color: #2B2B2B;
    margin-bottom: 40px;
		text-align: center;
    padding-right: 80px;
  }
}
form .ant-select {
  width: 100% !important;
}
.login-code-box {
  width: calc(100% - 130px);
  display: inline-block;
  margin-right: 10px;
}
.login-code {
  display: inline-block;
  width: 120px;
  vertical-align: top;
  height: 32px;
  margin: 4px 0 0;
  cursor: pointer;
  img {
    height: 32px;
    width: 100%;
  }
}
.form-btn {
  text-align: center;
	margin-right: 80px;
  .ant-btn-primary  {
    width: 132px;
    height: 42px;
    background: #3D77FF;
    border-radius: 6px;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 0;
  }
}
.ant-select-dropdown-menu-item-active:hover {
  background-color: #D8E4FE !important;
}
.ant-form-item-control {
	position: relative;
}
.ant-form-explain {
	position: absolute;
}
</style>