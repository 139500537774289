var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isClientHeight ? 'mainbody static-page-box' : 'mainbody'},[_c('LoginHeader'),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"container-box"},[_c('div',{staticClass:"middle-outer-box"},[_c('div',{staticClass:"login-inner-box show"},[_vm._m(0),_c('div',{staticClass:"right-form-box"},[_c('div',{staticClass:"jeol-auth show"},[_c('div',{staticClass:"login-ways-box"},[_c('div',{class:_vm.formType == 1 ? 'ways-option active' : 'ways-option',on:{"click":function($event){return _vm.changeFormWays(1)}}},[_vm._v(" 账号登录 ")]),_c('div',{staticClass:"split-line"}),_c('div',{class:_vm.formType == 2 ? 'ways-option active' : 'ways-option',on:{"click":function($event){return _vm.changeFormWays(2)}}},[_vm._v(" 验证码登录 ")])]),_c('div',{staticClass:"form-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formType == 1),expression:"formType == 1"}]},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.accountForm,"destroyOnClose":true},on:{"submit":_vm.handleAccountSubmit}},[_c('a-form-item',{staticClass:"form-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'username',
                        { rules: [{ validator: _vm.validateCheckUsername}]} ]),expression:"[\n                        'username',\n                        { rules: [{ validator: validateCheckUsername}]},\n                      ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入账号"}})],1),_c('a-form-item',{staticClass:"form-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        { rules: [{ validator: _vm.validateCheckPassword}] } ]),expression:"[\n                        'password',\n                        { rules: [{ validator: validateCheckPassword}] },\n                      ]"}],staticClass:"input-txt",attrs:{"type":"password","placeholder":"请输入密码"}})],1),(_vm.loginBefore && _vm.loginBefore.is_captcha_check)?_c('a-form-item',{staticClass:"form-item"},[_c('div',{staticClass:"flex-codeImg-box"},[_c('div',{staticClass:"left-input-box"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'captcha', { rules: [{ required: true, message: '请输入验证码' }] } ]),expression:"[\n                            'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入验证码"}})],1),_c('div',{staticClass:"code-img",on:{"click":function($event){return _vm.methodImageCaptcha()}}},[_c('img',{attrs:{"src":_vm.imageCode.img}})])])]):_vm._e(),_c('div',{staticClass:"forget-password"},[_c('router-link',{staticClass:"left",attrs:{"to":"/resetPassword"}},[_vm._v("忘记密码？")]),_c('router-link',{staticClass:"right",attrs:{"to":("/register?" + (_vm.$route.query.from&&_vm.$route.query.from.indexOf('createFarm')?'from=/createFarm':''))}},[_vm._v("免费注册")])],1),_c('a-button',{staticClass:"jeol-login-btn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formType == 2),expression:"formType == 2"}]},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.mobileForm,"destroyOnClose":true},on:{"submit":_vm.handleMobileSubmit}},[_c('a-form-item',{staticClass:"form-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'mobile',
                        { rules: [{ validator: _vm.validateCheckPhone}] } ]),expression:"[\n                        'mobile',\n                        { rules: [{ validator: validateCheckPhone}] },\n                      ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入手机号"}})],1),_c('a-form-item',{staticClass:"form-item"},[_c('div',{staticClass:"flex-codeImg-box"},[_c('div',{staticClass:"left-input-box"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'captcha', { rules: [{ required: true, message: '请输入验证码' }] } ]),expression:"[\n                            'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入验证码"}})],1),_c('div',{staticClass:"code-img",on:{"click":function($event){return _vm.methodImageCaptcha()}}},[_c('img',{attrs:{"src":_vm.imageCode.img}})])])]),_c('a-form-item',{staticClass:"form-item"},[_c('div',{staticClass:"flex-codeImg-box"},[_c('div',{staticClass:"getCode-box"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'code', { rules: [{ required: true, message: '请输入短信验证码' }] } ]),expression:"[\n                            'code', { rules: [{ required: true, message: '请输入短信验证码' }] },]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入短信验证码"}})],1),_c('div',{class:_vm.loginSmsClass,on:{"click":function($event){return _vm.clickloginSms()}}},[_vm._v(_vm._s(_vm.loginSmsText))])])]),_c('div',{staticClass:"forget-password"},[_c('router-link',{staticClass:"left",attrs:{"to":"/resetPassword"}},[_vm._v("忘记密码？")]),_c('router-link',{staticClass:"right",attrs:{"to":'/register'}},[_vm._v("免费注册")])],1),_c('a-button',{staticClass:"jeol-login-btn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1)])])])])])])]),_c('LoginFooter')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-imgs-box"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"jeol-carousel-login","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('img',{staticClass:"d-block",attrs:{"src":require("../../assets/site/newLogin/login-bg.png"),"alt":""}})])])])])}]

export { render, staticRenderFns }