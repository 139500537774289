<template>
  <div>
    <div class="myClass">
      <!-- <ul class="tabs">
        <li @click="changeCourseType(1)" :class="courseType == 1 ? 'classic active' : ''">公开课</li>
        <li @click="changeCourseType(2)" :class="courseType == 2 ? 'classic active' : ''">专业课</li>
      </ul> -->
      <ul class="classContents">
        <!-- 官网 已下架class-list-grey 已完成iconwancheng  去掉待学习、剩余天数 -->
        <li v-for="(item, i) in this.data" :key="i" :class="item.status == 3 ? 'disabled' : ''">
          <div class="classImages"  @click="goClassDetails(item)">
            <!-- <span class="iconfont iconsuo" v-if="item.status == 1">{{item.status_name}}</span> -->
            <span class="iconfont iconwancheng" v-if="item.status == 2">{{item.status_name}}</span>
            <span class="iconfont class-list-grey" v-else-if="item.status == 3">{{item.status_name}}</span>
            
            <img :src="item.photo" alt />
          </div>
          <div class="classInfo">
            <div class="info">
              <h3  @click="goClassDetails(item)">{{item.name}}</h3>
              <p>
                <span v-if="item.category_name" class="className">{{item.category_name}}</span>
                <span class="className">课程总时长：{{item.total_time}}</span>
                <span>课程讲师：{{item.teacher}}</span>
              </p>
              <p class="con">{{item.abstract}}</p>
              <div class="btm">
                <!-- <p class="residue" v-if="item.course_type != 1 && item.remaining_day != 0">
                  学习有效期剩余：
                  <span>{{item.remaining_day}}天</span>
                </p> -->
                <!-- <p class="residue residue-daoqi" v-if="item.course_type != 1 && item.remaining_day == 0">
                  <span>{{item.end_date}}已到期</span>
                </p> -->
                <p class="pre">
                  <span class="pre-label">学习进度：</span>
                  <a-progress :percent="item.learn_percent" />
                </p>
              </div>
            </div>
            <div class="rightButtom">
              <a-button v-if="item.status != 3" type="primary" shape="round" @click="classToDetail('/video/' + item.id + '/0', item)">立即学习</a-button>
            </div>
          </div>
        </li>
      </ul>
      <div class="pages" v-if="this.data.length > 0">
        <a-pagination :defaultPageSize="10" @change="onChange" :locale="zhCN" :default-current="1" :total="this.num" :current="currentPage" />
      </div>
      <div v-else class="noData-box">
        <img src="@/assets/empty.png" alt="">
        <div class="text">暂无相关内容</div>
      </div>
    </div>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale-provider/zh_CN";
import { Button, Progress, Pagination } from "ant-design-vue";
import services from "../../../config/services";
import utils from "../../../config/utils";
import { mapState } from "vuex";
export default {
  name: "MyClass",
  components: {
    AButton: Button,
    AProgress: Progress,
    APagination: Pagination,
  },
  data () {
    return {
      data: [],
      num: 0,
      zhCN,
      services: services,
      tabs: 0,
      courseType: 1, // 官网体系仅可看1 公开课 
      currentPage: 1, 
    };
  },
  computed: {
		...mapState({ 
			userAllInfo: state => state.appsLogin.appsUserInfo,
		}),
	},
  methods: {
    onChange (pageNumber) {
      this.lodData({
        page: pageNumber
      });
      this.currentPage = pageNumber;
    },
    classToDetail (path, item) {
      if (item.status != 3) {
        utils.redirect(this, path + '?from=' + this.$route.path, true);
      } else {
        this.$message.info("课程已下架")
      }
    },
    goClassDetails(item){
      if (item.status != 3) {
        utils.redirect(this, `/school/class/${item.id}`, true);
      }
    },
    lodData (params) {
      services
        .getUserCourseList({
          page: 1,
          size: 10,
          // type: this.courseType,
          ...params,
        })
        .then((res) => {
          if (res.code === "0000") {
            this.data = res.data.items;
            this.num = res.data.total;
          }
          // this.imgCode = window.URL.createObjectURL(res);
        });
    },
    changeCourseType (type) {
      if (type != this.courseType) {
        this.courseType = type;
        this.currentPage = 1;
        this.lodData({ page: 1 });
      }
    }
  },

  mounted () {
    this.$store.commit("setNavTitle", {
      navTitle: "学习中心",
    });
    this.lodData({ page: 1 });
  },
};
</script>
<style lang="less" scoped>
.major-box {
  width: 100%;
  .major-items {
    width: 60px;
    height: 28px;
    background: inherit;
    background-color: rgba(36, 98, 245, 1);
    border-radius: 6px;
    color: #ffffff;
  }
}
.noData-box {
  width: 100%;
  text-align: center;
  height: 770px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
  img {
    width: 240px;
    height: auto;
    margin: 0 auto 30px;
  }
  .text {
    color: #76797e;
    font-size: 14px;
  }
}
</style>