<template>
  <div class="education">
    <img class="education-top-img" src="@/assets/education/topBackground.png" />
    <div class="education-main">
      <div class="business-types">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              业务类型
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>
        <div class="icon">
          <div class="text">
            <p>全课程体系</p>
          </div>
          <div class="line-box"></div>
          <div class="icon-item icon-itemA">A</div>
          <div class="icon-item icon-itemB">B</div>
          <div class="icon-item icon-itemC">C</div>
          <div class="icon-item icon-itemD">D</div>
        </div>
        <div class="other">
          <img src="@/assets/education/type-arrow.png">
          <div class="FClass">
            <div class="FClass-content">
              <div class="text">
                <div class="text-item text-itemA ">综合实践</div>
                <div class="text-item text-itemB">校本素养</div>
                <div class="text-item text-itemC">场景建设</div>
                <div class="text-item text-itemD">教师培训</div>
              </div>
              <div class="list">
                <div class="content contentA">
                  <div class="left-text">
                    <div class="text">▪ 综合实践课程</div>
                    <div class="text">▪ 开放性科学实践活动</div>
                  </div>
                  <div class="line"></div>
                  <div class="right-text">
                    <div class="text">▪ 校本课程</div>
                    <div class="text">▪ 科技节</div>
                  </div>
                </div>
                <div class="content contentB">
                  <div class="left-text">
                    <div class="text">▪ 校本课程</div>
                  </div>
                  <div class="line"></div>
                  <div class="right-text">
                    <div class="text"></div>
                    <div class="text"></div>
                  </div>
                </div>
                <div class="content contentC">
                  <div class="left-text">
                    <div class="text">▪ 营地教育</div>
                    <div class="text">▪ 研学基地</div>
                  </div>
                  <div class="line"></div>
                  <div class="right-text">
                    <div class="text"></div>
                    <div class="text">▪ 综合实践基地</div>
                  </div>
                </div>
                <div class="content contentD">
                  <div class="left-text">
                    <div class="text">▪ 素养类</div>
                    <div class="text">▪ 思维发展类</div>
                  </div>
                  <div class="line"></div>
                  <div class="right-text">
                    <div class="text">▪ 技能类</div>
                    <div class="text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="various">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              活动影响力
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>

        <div class="various-box" v-if="variousData">
          <div class="various-content" v-for="(item,index) in variousData" :key="index">
            <div class="left">
              <img :src="item.src">
            </div>
            <div class="right">
              <div class="count">{{item.count}} <span class="suffix">{{item.suffix}}</span></div>
              <div class="text">
                {{item.text}}
              </div>
            </div>
            <div class="line" :style="{width:item.width}"></div>
          </div>

        </div>
      </div>
      <div class="resources">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              资源优势
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>
        <div class="icon">
          <img src="@/assets/education/bg-left-top.png" class="FirstIcon">
          <img src="@/assets/education/bg-right-bottom.png" class="LastIcon">
        </div>
        <div class="resource-content" v-if="resourcesData">
          <div class="resource-item" v-for="(item,index) in resourcesData" :key="index">
            <img :src="item.src">
            <p>{{item.title}}</p>
            <span>{{item.describe}}</span>
          </div>
        </div>
      </div>
      <div class="wonderful-activities">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              精彩活动介绍
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>
        <div class="activities-content">
          <div class="content" v-for="item,index in wonderfulActivities" :key="index">
            <div class="leftImg">
              <img :src="item.src" class="activities-item-src">
            </div>
            <div class="rightText">
              <div class="title">{{item.title}}
                <div class="line"></div>
              </div>
              <p class="location">{{item.location}}</p>
              <p class="introduce">{{item.introduce}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="activies-evaluation">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              活动评价
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>
        <div class="panel-box">
          <div @mouseenter="hoverPanel(1)" :class='isPanel == 1? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <span class="label">"皇家印象 古典园林" 主题定向活动</span>
              <div class="panel-star">
                <p class="star-title">评价星级</p>
                <div class="fStar">
                  <div class="star"></div>
                </div>
              </div>
              <div class="panel-tj">
                <p class="tj-title">推荐指数</p>
                <div class="fTj">
                  <div class="tj"></div>
                </div>
              </div>
              <div class="panel-text">
                我们今天参加了圆明园科学探索活动，这次活动很有意义，让我看到了不一样的圆明园，在这座曾经的皇家园林中，不仅能够缅怀历史，也走进了皇家生活，这门课程让我们学到了很多知识，期待下次课程的到来！
              </div>
              <div class="panel-name">
                <p>一 北京日坛中学东润分校&nbsp;&nbsp;&nbsp;王艺</p>
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(1)" :class='isPanel == 1? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">"皇家印象 古典园林" 主题定向活动</div>
            <div class="panel-star">
              <p class="star-title">评价星级</p>
              <div class="fStar">
                <div class="star"></div>
              </div>
            </div>
            <div class="panel-tj">
              <p class="tj-title">推荐指数</p>
              <div class="fTj">
                <div class="tj"></div>
              </div>
            </div>
            <div class="panel-text">
              我们今天参加了圆明园科学探索活动，这次活动很有意义，让我看到了不一样的圆明园，在这座曾经的皇家园林中，不仅能够缅怀历史，也走进了皇家生活，这门课程让我们学到了很多知识，期待下次课程的到来！
            </div>
            <div class="panel-name">
              <p>北京日坛中学东润分校 王艺</p>
            </div>
          </div>

          <div @mouseenter="hoverPanel(2)" :class='isPanel == 2? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <span class="label">城市里的植物花园</span>
              <div class="panel-star">
                <p class="star-title">评价星级</p>
                <div class="fStar">
                  <div class="star"></div>
                </div>
              </div>
              <div class="panel-tj">
                <p class="tj-title">推荐指数</p>
                <div class="fTj">
                  <div class="tj"></div>
                </div>
              </div>
              <div class="panel-text">
                今天参加了北京植物园活动，活动内容有趣，新颖，我和同学们分组进行了丝网花郁金香的制作，用树叶作画，探究了不同PH值对土壤的影响，在这里我看见了很多植物，跟着老师学习他们的生长坏境与植物作用，课程让我受益匪浅！
              </div>
              <div class="panel-name">
                <p>—— 首师大二附&nbsp;&nbsp;&nbsp;李晓依</p>
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(2)" :class='isPanel == 2? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">"城市里的植物花园</div>
            <div class="panel-star">
              <p class="star-title">评价星级</p>
              <div class="fStar">
                <div class="star"></div>
              </div>
            </div>
            <div class="panel-tj">
              <p class="tj-title">推荐指数</p>
              <div class="fTj">
                <div class="tj"></div>
              </div>
            </div>
            <div class="panel-text">
              今天参加了北京植物园活动，活动内容有趣，新颖，我和同学们分组进行了丝网花郁金香的制作，用树叶作画，探究了不同PH值对土壤的影响，在这里我看见了很多植物，跟着老师学习他们的生长坏境与植物作用，课程让我受益匪浅！
            </div>
            <div class="panel-name">
              <p>——首师大二附&nbsp;&nbsp;&nbsp;李晓依</p>
            </div>
          </div>

          <div @mouseenter="hoverPanel(3)" :class='isPanel == 3? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <span class="label">春天花花园游会</span>
              <div class="panel-star">
                <p class="star-title">评价星级</p>
                <div class="fStar">
                  <div class="star"></div>
                </div>
              </div>
              <div class="panel-tj">
                <p class="tj-title">推荐指数</p>
                <div class="fTj">
                  <div class="tj"></div>
                </div>
              </div>
              <div class="panel-text">
                这次参加春天花花园游会活动，让我见到了一直想看的猪笼草，并且在植物园的温室中见到了很多北方没有的植物，非常有趣，通过老师的讲解，学习到了很多关于植物的知识，希望有机会参加其他活动。
              </div>
              <div class="panel-name">
                <p>——北京市上地实验学校 &nbsp;&nbsp;&nbsp;张天一</p>

              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(3)" :class='isPanel == 3? "panel-items panel-none" : "panel-items panel-block"'>

            <div class="panel-title">春天花花园游会</div>
            <div class="panel-star">
              <p class="star-title">评价星级</p>
              <div class="fStar">
                <div class="star"></div>
              </div>
            </div>
            <div class="panel-tj">
              <p class="tj-title">推荐指数</p>
              <div class="fTj">
                <div class="tj"></div>
              </div>
            </div>
            <div class="panel-text">
              这次参加春天花花园游会活动，让我见到了一直想看的猪笼草，并且在植物园的温室中见到了很多北方没有的植物，非常有趣，通过老师的讲解，学习到了很多关于植物的知识，希望有机会参加其他活动。
            </div>
            <div class="panel-name">
              <p>北京市上地实验学校 张天一</p>
            </div>
          </div>
          <div @mouseenter="hoverPanel(4)" :class='isPanel == 4? "card-items panel-block" : "card-items panel-none"'>
            <div class="card-header">
              <span class="label">"野鸭湖"观鸟系列活动</span>
              <div class="panel-star">
                <p class="star-title">评价星级</p>
                <div class="fStar">
                  <div class="star"></div>
                </div>
              </div>
              <div class="panel-tj">
                <p class="tj-title">推荐指数</p>
                <div class="fTj">
                  <div class="tj"></div>
                </div>
              </div>
              <div class="panel-text">
                一直就喜欢观鸟，正好有机会参加了野鸭湖观鸟活动，一早我们去出发前往野鸭湖，老师们教我们如何让使用观鸟的望远镜，活动非常有趣，老师们非常细心的给我们讲解鸟类的知识，认真负责！
              </div>
              <div class="panel-name">
                <p>——人大附石景山学校 &nbsp;&nbsp;&nbsp;鲁一</p>
              </div>
            </div>
          </div>
          <div @mouseenter="hoverPanel(4)" :class='isPanel == 4? "panel-items panel-none" : "panel-items panel-block"'>
            <div class="panel-title">"野鸭湖"观鸟系列活动</div>
            <div class="panel-star">
              <p class="star-title">评价星级</p>
              <div class="fStar">
                <div class="star"></div>
              </div>
            </div>
            <div class="panel-tj">
              <p class="tj-title">推荐指数</p>
              <div class="fTj">
                <div class="tj"></div>
              </div>
            </div>
            <div class="panel-text">
              一直就喜欢观鸟，正好有机会参加了野鸭湖观鸟活动，一早我们去出发前往野鸭湖，老师们教我们如何让使用观鸟的望远镜，活动非常有趣，老师们非常细心的给我们讲解鸟类的知识，认真负责！
            </div>
            <div class="panel-name">
              <p>人大附石景山学校 鲁一</p>
            </div>
          </div>
        </div>
      </div>
      <div class="student-achievement">
        <div class="container">
          <div class="shadow-t">
            <div class="title">
              <div class="bgc"></div>
              <img src="@/assets/education/dot-left.png" alt="">
              <span>
                学生成果展示
              </span>
              <img src="@/assets/education/dot-right.png" alt="">
            </div>
          </div>
          <div class="achievement-content">
            <div class="achievement-img" v-if="achievement">
              <img :class="`fx-imgs${index}`" :src="item.src" v-for="(item,index) in achievement" :key="index">
            </div>
            <div class="bottom-img" bottomImgData>
              <img src="../../assets/education/show-student.png" class="rectangle">
              <div class="flex-box">
                <img src="../../assets/education/show-student-t.png" class="little littleA">
                <img src="../../assets/education/show-student-b.png" class="little littleB">
              </div>
              <img src="../../assets/education/show-photo.png" class="rectangle">
              <img src="../../assets/education/show-score.png" class="last">
            </div>
          </div>
        </div>
      </div>
      <div class="marvellous-activity">
        <div class="shadow-t">
          <div class="title">
            <div class="bgc"></div>
            <img src="@/assets/education/dot-left.png" alt="">
            <span>
              活动精彩瞬间
            </span>
            <img src="@/assets/education/dot-right.png" alt="">
          </div>
        </div>
        <div class="img-show-box">
          <div class="imgs-lines">
            <div class="left-box">
              <img src="../../assets/education/moment-tree.jpg" />
            </div>
            <div class="right-box">
              <img src="../../assets/education/moment-experiment.jpg" />
              <img src="../../assets/education/moment-speech.jpg" />
            </div>
          </div>
          <div class="imgs-lines">
            <div class="right-box two">
              <img src="../../assets/education/moment-sand.jpg" />
              <img src="../../assets/education/moment-putty.jpg" />
            </div>
            <div class="left-box two">
              <img src="../../assets/education/moment-training.jpg" />
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import "./education.less";
import Footer from '@/components/Footer'
export default {
  name: "education",
  data () {
    return {
      variousData: [
        { src: require('../../assets/education/influence-cooperation.png'), text: "合作院校", count: "200", suffix: "所", width: '2px' },
        { src: require('../../assets/education/influence-service.png'), text: "服务学生", count: "20万", suffix: "人次", width: '2px' },
        { src: require('../../assets/education/influence-route.png'), text: "制定路线", count: "30", suffix: "条", width: '2px' },
        { src: require('../../assets/education/influence-plan.png'), text: "成熟方案", count: "100+", suffix: "套", width: '0px' },
      ],
      resourcesData: [{
        src: require('../../assets/education/resource-team.png'),
        title: "知名专家团队",
        describe: 'Famous expert team'
      }, {
        src: require('../../assets/education/resource-experince.png'),
        title: "难忘的探究过程体验",
        describe: 'Unforgettable exploration process experience'
      }, {
        src: require('../../assets/education/resource-reach.png'),
        title: "一般公众难以涉及的活动场所 ",
        describe: 'Venues that are difficult for the general public to reach  '
      }, {
        src: require('../../assets/education/resource-activity.png'),
        title: "层出不穷的活动体验",
        describe: 'Endless activity experience'
      },

      ],
      achievement: [
        {
          src: require("../../assets/education/show-classical.png")
        }, {
          src: require("../../assets/education/show-ruins.png")
        }, {
          src: require("../../assets/education/show-SummerPalace.png")
        }, {
          src: require("../../assets/education/show-atlantic.png")
        },
      ],
      bottomImgData: [
        {
          src: require("../../assets/education/show-student.png"),
          class: "rectangle"
        }, {
          src: require("../../assets/education/show-student-t.png"),
          class: "little"
        }, {
          src: require("../../assets/education/show-student-b.png"),
          class: "little"
        }, {
          src: require("../../assets/education/show-photo.png"),
          class: "rectangle"
        }, {
          src: require("../../assets/education/introduce-SummerPalace.jpg"),
          class: "last"
        },
      ],
      wonderfulActivities: [
        { src: require("../../assets/education/introduce-garden.jpg"), title: '春天花花园游会', location: '活动地点：中科院北京植物园', introduce: '本课程以国家紧急救援训练基地为依托，利用实景废墟、斜楼、专业救援装备库、急救训练专业道具等资源，为学生提供地震安全教育场景。学生在专业救援队员的带领下，进入到真实的废墟环境和专业的救援装备库中，在身临其境的体验中，学习震中生存知识和急救方法，掌握行之有效的安全技能。' },
        { src: require("../../assets/education/introduce-SummerPalace.jpg"), title: '“皇家印象  古典园林“主题定向活动', location: '活动地点：圆明园', introduce: '在园林课程的体系中，圆明园是不可或缺的一战，活动从园林造景、历史等角度探究圆明园，基于圆明园的遗存了解其历史文化和皇家园林的独特性。' },
        { src: require("../../assets/education/introduce-ruins.jpg"), title: '废墟下的希望', location: '活动地点：国家地震救援训练基地', introduce: '本课程以国家紧急救援训练基地为依托，利用实景废墟、斜楼、专业救援装备库、急救训练专业道具等资源，为学生提供地震安全教育场景。学生在专业救援队员的带领下，进入到真实的废墟环境和专业的救援装备库中，在身临其境的体验中，学习震中生存知识和急救方法，掌握行之有效的安全技能。' },
        { src: require("../../assets/education/introduce-space.jpg"), title: '走入航天、触摸太空', location: '活动地点：中科院光电研究所', introduce: '“走入航天，触摸太空”课程紧密结合数学、物理、信息技术等学科知识，通过参观体验、讲座学习、科技实践等多种学习途径，带领学生走中科院—光电研究院，实地接触世界一流的研究院所、实验室，与专家学者互动交流，学习科学实验的准备工作与数据获取方法；化身工程师，亲自进行科 技实践，体会科学魅力，感受科技之光······激发同学对科技的热情，主动探索研究身边的科学问题，培养科学态度，弘扬科学精神，提高学生的科学素养，激发学生爱科学、讲科学、用科学的热情。' },
      ],
      isPanel: 1
    }
  },
  components: {
    Footer
  },
  methods: {
    hoverPanel (type) {
      this.isPanel = type;
    },
  }
}
</script>

<style scoped lang="less">
.footer-box {
  margin-top: 50px;
}
</style>