import Vue from 'vue'
import services from '../config/services'

export default {
  state:{
    classCategory: [],
    classCategory2: [],
    classCategory3: [],
    classData: {},
    classDetail1: {},
    classDetail2: [],
    classDetail3: [],
    videoInfo: {},
    evaluateData: [],
    checkCodeVisible: false,
  },
  getters:{
    // state (state){
    //   return state
    // },
    // nameInfo (state){
    //   return "姓名:" + state.name
    // },
  },
  actions: {
    // 列表页获取课程分类
    loadCategoryData(context, payload){
      return services.getCourseCategory(payload).then((res) => {
        if(res.code === '0000') {
          if (payload.level == 2) {
            context.commit('setclassCategory2', {
              'data': res.data || []
            })
          }else if(payload.level == 3){
            context.commit('setclassCategory3', {
              'data': res.data || []
            })
          } else {
            context.commit('setclassCategory', {
              'data': res.data || []
            })
          }
        }
      })
    },
    // 获取课程列表数据
    getCourseData(context, payload) {
      return services.getCourseLists(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadClassData', {
            'classData': res.data || {}
          })
        }
      })
    },
    // 获取课程详情信息
    loadCourseDetails(context, payload) {
      return services.getCourseDetails(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadClassData1', {
            'classDetail1': res.data || {}
          })
        }
        return res
      })
    },
    // 课程详情-获取章节信息
    loadCourseChapter(context, payload) {
      return services.getCourseChapterDetail(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadClassData2', {
            'classDetail2': res.data || []
          })
        }
        return res;
      })
    },
    // 课程详情-获取讲师信息
    loadCourseTeacher(context, payload) {
      return  services.getCourseTeacher(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadClassData3', {
            'classDetail3': res.data || []
            // 'classDetail3':  [{
            //   name: '222',
            //   intro: '查看更多查看更多查，看查看更多查看更多。查看查看更多查看更多查看查看更，多查看更多查看查查看查看更多查看更多查看查看更，多查看更多查看查看更看更多查看更多查看111111111'
            // },{
            //   name: '222',
            //   intro: '查看更多查看更多查，333333看更多查看查看更，多查看更多查看查查看查看更多查看更多查看查看更，多查看更多查看查看更看更多查看更多查看111111111'
            // }]
          })
        }
        return res;
      })
    },
    loadVideo(context, payload) {
      return services.getCoursePlay(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadVideo', {
            'videoInfo': res.data || {}
          })
        } else {
          context.commit('loadVideo', {
            'videoInfo': {items:{}}
          })
        }
        return res;
      })
    },
    // 获取课程评价列表
    loadEvaluateLists(context, payload) {
      return services.getCourseComment(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('setEvaluateData', {
            'evaluateData': res.data.items || [],
            // 'evaluateData': [{
            //   "is_private": 1, // 是否为登录用户发布1是｜2否
            //   "user_photo": "", // "用户头像"
            //   "user_name": "hahaha",
            //   "user_id": 14,
            //   "add_time": "2021-09-04",
            //   "content": "评论内容评论内容评论内容评论内容",
            //   "level": 3,
            //   "course_id": 45,
            //   "id": 60,
            // }]
          })
        } else {
          context.commit('setEvaluateData', {
            'evaluateData': []
          })
        }
        return res;
      })
    },
  },
  mutations:{
    setclassCategory (state, payload) {
      if(payload.data && payload.data.length > 0){
        payload.data.unshift({
          name: "全部",
          id: "",
        })
        state.classCategory = payload.data
      } else {
        state.classCategory = []
      }
    },
    setclassCategory2 (state, payload) {
      if(payload.data && payload.data.length > 0){
        payload.data.unshift({
          name: "全部",
          id: "",
        })
        state.classCategory2 = payload.data
      } else {
        state.classCategory2 = []
      }
    },
    setclassCategory3(state, payload) {
      if(payload.data && payload.data.length > 0){
        payload.data.unshift({
          name: "全部",
          id: "",
        })
        state.classCategory3 = payload.data
      } else {
        state.classCategory3 = []
      }
    },
    loadClassData (state, payload) {
      Vue.set(state, "classData" ,payload.classData)
    },
    loadClassData1 (state, payload) {
      Vue.set(state, "classDetail1" ,payload.classDetail1)
    },
    loadClassData2 (state, payload) {
      Vue.set(state, "classDetail2" ,payload.classDetail2)
    },
    loadClassData3 (state, payload) {
      Vue.set(state, "classDetail3" ,payload.classDetail3)
    },
    loadVideo (state, payload) {
      Vue.set(state, "videoInfo" ,payload.videoInfo)
    },
    setEvaluateData (state, payload) {
      Vue.set(state, "evaluateData", payload.evaluateData)
    },
    setCheckCodeVisible (state, payload) {
      Vue.set(state, "checkCodeVisible", payload.checkCodeVisible)
    },
  }
}