<template>
	<div class="userMessage">
		<p class="bread">
			<span class="buleColor">/</span>个人信息
		</p>

		<component 
    :is="dialogConfig.name" 
    :dialogConfig="dialogConfig" 
    @cancleDialog="cancleDialog(arguments)"
		@refreshData="refreshInfo"
    ></component>

		<div class="info-list">
			<!-- <div class="info-imgs">
				<img v-if="data.weixin && data.weixin.photo" :src="data.weixin.photo" />
			</div> -->
			<div class="info-group">
				<div class="info-label">账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：</div>
				<div class="info-content">{{appsUserInfo && appsUserInfo.username}}</div>
			</div>
			<div class="info-group">
				<div class="info-label">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</div>
				<div class="info-content">{{appsUserInfo && appsUserInfo.name}}</div>
			</div>
			<div class="info-group">
				<div class="info-label">手&nbsp;&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;号：</div>
				<div class="info-content">{{appsUserInfo && appsUserInfo.mobile}}
					<div class="change-phone-btn" @click="changePhoneModal">更换绑定手机号</div>
				</div>
			</div>
				<div class="info-group">
				<div class="info-label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</div>
				<div class="info-content">{{appsUserInfo && appsUserInfo.email}}
					<div class="change-phone-btn" @click="changeEmailModal">{{appsUserInfo && appsUserInfo.email ? '更换绑定邮箱' :'绑定邮箱'}}</div>
				</div>
			</div>
			<!-- <div class="info-group">
				<div class="info-label">微&nbsp;信&nbsp;账&nbsp;号&nbsp;：</div>
				<div v-if="appsUserInfo && appsUserInfo.user_open_data && appsUserInfo.user_open_data && appsUserInfo.user_open_data.weixin" class="info-content">
					<div class="name">{{appsUserInfo.user_open_data.weixin.nickname}}</div>
					<div class="wechat-btn" @click="UnboundWechat">解绑微信</div>
				</div>
				<div v-else class="info-content blue" @click="bindWechat">绑定微信</div>
			</div> -->
		</div>
	</div>
</template>

<script>

import { Modal } from "ant-design-vue";
import services from "../../../config/services";
import { mapActions, mapState } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;  //允许跨域携带cookies
export default {
	name: "UserMessage",
	components: {
		changePhoneModal:() => import("./changePhoneModal.vue"),
		changeEmailModal: () => import("./changeEmailModal.vue"),
	},
	data() {
		return {
			data: {},
			dialogConfig: {
				name: '',
				title: '',
				data: null,
				dialogVisible: true,
			},
		};
	},
	computed: {
		...mapState({appsUserInfo: state => state.appsLogin.appsUserInfo}),
	},
	methods: {
		...mapActions(['getAppsLoginUserInfo', 'requestWechatUnbind']),
		// 解绑微信
		UnboundWechat(){
			let that = this;
			Modal.confirm({
        title: '您确定要解绑微信吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
					that.unbindMethod();
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
		
		},
		// 微信解绑方法
		unbindMethod(){
			let that = this;
			that.requestWechatUnbind({
          _method: 'put',
          type: 'weixin'
        }).then(res => {
          if(res.code == '0000'){
            that.refreshInfo();
            that.$message.success(res.message ? res.message : "操作成功");
          } else {
            that.$message.error(res.message ? res.message : "操作失败")
          }
        })
		},
		// 绑定微信
		bindWechat(){
			if (process.env.NODE_ENV == 'development') {
        // 开发环境测试
        window.open('http://apps-api.dev.gophper.com' + "/api/weixin/binding?back_url=" + window.location.href + '&from=' + window.location.origin, "_self")
      } else {
        // 生成环境
        window.open(services.HOST + "/api/weixin/binding?back_url=" + window.location.href + '&from=' + window.location.origin, "_self")
      }
		},
		//关闭弹框
		cancleDialog(val){
			this.dialogConfig.name = null;
		},
		changePhoneModal(){
			this.dialogConfig.data  = this.appsUserInfo;
			this.dialogConfig.name  = 'changePhoneModal';
			this.dialogConfig.title = "更换手机";
		},
		changeEmailModal(){
			this.dialogConfig.data  = this.appsUserInfo;
			this.dialogConfig.name  = 'changeEmailModal';
			this.dialogConfig.title = "绑定邮箱";
		},
		refreshInfo(){
			this.lodData();
		},
		lodData() {
			let that = this;
			that.getAppsLoginUserInfo().then((res) => {
			});

		},
	},
	mounted() {
		this.$store.commit("setNavTitle", {
			navTitle: "学习中心",
		});
		this.lodData();
	},
};
</script>

<style scoped>
.userInfo .userItem .ant-descriptions-item-label {
	vertical-align: top;
}
.info-list {
	margin: 20px auto 0;
	text-align: center;
}
.info-list  .info-imgs {
	width: 80px;
	height: 80px;
	margin: 0 auto 40px;
	border-radius: 50%;
	overflow: hidden;
}
.info-list  .info-imgs img {
	width: 100%;
	height: auto;
	display: block;
}
.info-list .info-group {
	width: 100%;
	margin-bottom: 30px;
}
.info-list .info-group  .info-label {
	display: inline-block;
	width: 100px;
	font-size: 14px;
	color: #333;
	line-height: 30px;
	text-align: right;
	margin-right: 10px;
}
.info-list .info-group  .info-content {
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #F6F6F6;
	width: 340px;
	padding: 0 15px 5px;
	line-height: 30px;
	text-align: left;
	position: relative;
	min-height: 36px;
}
.info-list .info-group  .info-content.blue {
	color: #2462F5;
	cursor: pointer;
}
.info-list .info-group  .info-content .change-phone-btn,
.info-list .info-group  .info-content .wechat-btn {
	color: #2462F5;
	position: absolute;
	right: 0;
	bottom: 5px;
	font-size: 12px;
	cursor: pointer;
}
.info-list .info-group  .info-content .name {
	display: inline-block;
	max-width: 200px;
}
.info-list .info-group  .info-content .change-phone-btn::before {
	content: "";
	width: 13px;
	height: 12px;
	margin-right: 10px;
	background: url("../../../assets/site/user/edit-icon.png") no-repeat center;
	background-size: 100% auto;
	display: inline-block;
	vertical-align: -1px;
}
</style>