<template>
  <div :class="isClientHeight ? 'mainbody static-page-box' : 'mainbody'">
    <LoginHeader />
    <div class="main-container">
      <div class="container-box">
        <div class="middle-outer-box">
          <div class="login-inner-box show">
            <div class="top-label-box">
              <div class="reset-left-box">重置密码</div>
              <div class="login-right-box"><router-link to="/login">返回登录</router-link></div>
            </div>

            <div class="right-form-box"> 
              <div class="form-content">
                <a-form :form="mobileForm" :destroyOnClose="true" class="login-form" @submit="handleSubmit">
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">手机号：</div>
                      <a-input class="input-txt" placeholder="请输入手机号" v-decorator="[
                          'mobile',
                          { rules: [{ validator: validateCheckPhone}] },
                        ]"></a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item flex-codeImg-box">
                      <div class="left-input-box">
                        <div class="label-box">验证码：</div>
                        <a-input class="input-txt" placeholder="请输入验证码" v-decorator="[
                              'captcha', { rules: [{ required: true, message: '请输入验证码' }] },]"></a-input>
                      </div>
                      <div class="code-img"  @click="methodImageCaptcha()">
                        <img :src="imageCode.img" />
                      </div>
                    </div>
                  </a-form-item>
                  <a-form-item >
                    <div class="form-item flex-codeImg-box">
                      <div class="getCode-box">
                        <div class="label-box">短信验证码：</div>
                        <a-input class="input-txt" placeholder="请输入验证码" v-decorator="[
                              'code', { rules: [{ required: true, message: '请输入短信验证码' }] },]"></a-input>
                      </div>
                      <div :class="loginSmsClass"  @click="clickloginSms()">{{ loginSmsText }}</div>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">设置新密码：</div>
                      <a-input class="input-txt" type="password" placeholder="请输入新密码" v-decorator="[
                          'password',
                          { rules: [{ validator: validateCheckPassword}] },]"></a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <div class="form-item">
                      <div class="label-box">确认新密码：</div>
                      <a-input class="input-txt" type="password" placeholder="请再次输入新密码" v-decorator="[
                          'password_confirmation',
                          { rules: [{ validator: validatePasswordConfirm}] },]"></a-input>
                    </div>
                  </a-form-item>
                  <a-button type="primary" html-type="submit"  class="jeol-login-btn">确认修改</a-button>
                </a-form>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import LoginFooter from "../../components/loginFooter";
import LoginHeader from "../../components/loginHeader";
import { Form, Input, Button } from "ant-design-vue";
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  components: {
    LoginFooter,
    LoginHeader,
    AForm: Form,
    AInput: Input,
    AFormItem: Form.Item,
    AButton: Button,
  },
  data() {
    return {
      isClientHeight: false,
      imageCode: {
        key: '',
        img: '',
      },
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "jeol-getCode", 
    };
  },
  beforeCreate() {
    this.mobileForm = this.$form.createForm(this);
  },
  mounted() {
    let that = this
    that.getClientHeight();
    window.onresize = () => {
      that.getClientHeight();
    }
    that.methodImageCaptcha();
  },
  methods: {
    ...mapActions(["getAppsImageCaptcha",'getAppsSmsCaptcha','requestAppsResetPassword']),
     // 密码验证
    validateCheckPassword (rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          callback();
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback('请输入新密码');
      }
    },
    // 确认新密码验证
    validatePasswordConfirm(rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          if(value !== this.mobileForm.getFieldValue('password')){
            callback("两次输入的新密码不一致");
          } else{
            callback();
          }
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback('请再次输入新密码');
      }
    },
    // 手机号验证
    validateCheckPhone (rule, value, callback) {
      if (value) {
        if (/^1[3-9][0-9]{9}$/.test(value)) {
          callback();
        } else {
          callback("请输入正确的手机号");
        }
      } else {
        callback('请输入手机号');
      }
    },
    // 获取页面高度判断
    getClientHeight(){
      if (document.body.clientHeight < 760) {
        this.isClientHeight = true
      } else {
        this.isClientHeight = false
      }
    },
    // 获取图片验证码
    methodImageCaptcha (){
      let that = this;
      that.getAppsImageCaptcha().then((res) => {
        if(res.code == '0000'){
          that.imageCode = res.data
        }
      });
    },
    // 提交注册用户表单
    handleSubmit (e) {
      let that = this;
      e.preventDefault();
      this.mobileForm.validateFields(["mobile", "password", "captcha", "code","password_confirmation"],(err, values) => {
        if (!err) {
          values._method = 'put'
          that.methodReset(values);
        }
      })
    },
     // 请求重置密码方法
    async methodReset(param) {
      let that = this;
      let { setFields } =  this.mobileForm
      that.requestAppsResetPassword(param).then((res) => {
        if(res.code == '0000') {
          that.$message.success(res.message ? res.message : "操作成功");
          that.$router.push("/login")
        } else {
          that.methodImageCaptcha()
          if (res.errors) {
            let errors = res.errors
            for(let k in errors){
              setFields({
                [k]: {errors: [new Error(errors[k])]}
              })
            }
          } else {
            that.$message.error(res.message ? res.message : "操作失败");
          }
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms() {
      let that = this;
      if (that.loginSmsClass === "jeol-getCode") {
        that.mobileForm.validateFields(["mobile", "captcha"],(err, values) => {
          if (!err) {
            values.key = that.imageCode.key;
            that.getAppsSmsCaptcha(values).then((res) => {
              if (res.code === "0000") {
                that.smsCountDown()
                that.$message.success(res.message ? res.message : "短信验证码发送成功");
              } else {
                that.methodImageCaptcha()
                if (res.errors) {
                  let errors = res.errors
                  for(let k in errors){
                    that.mobileForm.setFields({
                      [k]: {errors: [new Error(errors[k])]}
                    })
                  }
                } else {
                  that.$message.error(res.message ? res.message : "短信验证码发送失败");
                }
              }
            });
          }
        })
        
      }
    },
    // 验证码倒计时
    smsCountDown (){
      let that = this
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "jeol-getCode disabled";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "jeol-getCode";
          clearInterval(that.interval1);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped src="../../assets/css/register.css">
</style>

<style lang="less" scoped>
/deep/ .form-content .has-error .ant-form-explain, 
/deep/ .form-content .has-error .ant-form-split,
/deep/ .form-content .has-success .ant-form-explain,
/deep/ .form-content .has-success .ant-form-split {
  position: absolute !important;
}
.jeol-login-btn {
  margin-top: 30px;
}
</style>