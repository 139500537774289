<template>
	<div>
    <!-- <Header /> -->
		<Nav />
		<router-view></router-view>
    <Footer />
	</div>
</template>

<script>
import Nav from "./components/Nav";
// import Header from './components/Header'
import Footer from './components/Footer'

export default {
	name: "Index",
	components: {
    Nav,
    // Header,
    Footer
	},
	data() {
		return {};
	},
	mounted() {
		// console.log("Index mounted");
	},
};
</script>