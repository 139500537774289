<template>
  <div>
    <header>
      <div class="header_top">
        <div class="bgImg">
          <img src="../../../../assets/sdcmc/logo.png" alt="" class="logo">
          <div class="headMain">
            <img src="../../../../assets/sdcmc/schoolLogo.png" alt="" class="schoolLogo">
            <span class="bgTitle">课程思政教学研究中心</span>
          </div>
        </div>
      </div>
      <div class="header_nav">
        <ul class="nav">
          <li class="nav_item actived">
            <router-link :to="`/school/${utils.sdcmzyxyCode}`" exact>首页</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/course`" exact>示范课程</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/1`" exact>新闻动态</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/2`" exact>通知文件</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/3`" exact>媒体报道</router-link>
          </li>
          <li class="nav_item">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/list/4`" exact>政策文件</router-link>
          </li>
          <li class="nav_item">
            <a :href="`https://bk.jiaoyu.cn/${utils.sdcmzyxyCode}/lesson/distribution`" target="_blank">课程思政资源管理平台</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="banner">
        <a-carousel autoplay>
          <div>
            <img src="../../../../assets/sdcmc/banner1.jpg" alt="" />
          </div>
          <div>
            <img src="../../../../assets/sdcmc/banner2.jpg" alt="" />
          </div>
        </a-carousel>
      </div>
      <div class="course">
        <div class="course_top">
          <img src="../../../../assets/zjiet/left.png" alt="" />
          <img src="../../../../assets/zjiet/dang.png" alt="" class="dang" />
          <span class="course_top_name">示范课程</span>
          <img src="../../../../assets/zjiet/right.png" alt="" />
        </div>
        <ul class="course_box">

          <li v-for="(item, index) in dataList" :key="index" class="course_item">
            <a :href="item.url" target="_blank" class="course_img">
              <img :src="item.imgSrc" alt="" class="showImg" />
            </a>
            <div class="line"></div>
            <div class="course_info">
              <p class="course_name">{{item.name}}</p>
              <p class="course_study">
                <img src="../../../../assets/zjiet/study.png" alt="" />
                <span class="study">{{item.studyNum}}人已学</span>
              </p>
            </div>
          </li>

        </ul>
        <div class="seeMore">
          <div class="button">
            <router-link :to="`/school/${utils.sdcmzyxyCode}/course`" exact class="button">查看更多</router-link>
          </div>
        </div>
      </div>
      <div class="radian">
        <img src="../../../../assets/zjiet/downRadian.png" alt="" />
      </div>
      <div class="news">
        <div class="news_left">
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" />
              <span class="news_top_title">新闻动态</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="`/school/${utils.sdcmzyxyCode}/list/1`" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/1`">
                    喜报！学院课程思政示范项目获得重大突破
                  </router-link>
                </span>
                <span class="newsItem_date">2021-05-12</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/2`">
                    学院举行第三届教师教学能力大赛暨课程思政教学比赛
                  </router-link>
                </span>
                <span class="newsItem_date">2021-05-28</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/3`">
                    学院组织教师参加课程思政专题培训
                  </router-link>
                </span>
                <span class="newsItem_date">2021-05-30</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/4`">
                    我院国家级课程思政教学团队在北京参加集体备课
                  </router-link>
                </span>
                <span class="newsItem_date">2021-09-25</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/5`">
                    学院赴莘县考察调研并走访慰问“四进”干部
                  </router-link>
                </span>
                <span class="newsItem_date">2021-12-13</span>
              </li>
            </ul>
          </div>
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" />
              <span class="news_top_title">通知文件</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="`/school/${utils.sdcmzyxyCode}/list/2`" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/6`">
                    关于举办第五届山东传媒职业学院新媒体作品大赛的通知
                  </router-link>
                </span>
                <span class="newsItem_date">2021-10-26</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">
                  发挥带头表率作用，确保校园安全稳定---致学院党员干部职工的倡议书
                </span>
                <span class="newsItem_date">2021-10-05</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">致学生家长的一封信</span>
                <span class="newsItem_date">2021-09-30</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">河南学子请报平安 山传与你风雨共担</span>
                <span class="newsItem_date">2021-07-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">山东传媒职业学院2022届毕业生实习就业招聘双选会邀请函 </span>
                <span class="newsItem_date">2021-06-15</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="news_line"></div>
        <div class="news_right">
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" class="news_dang" />
              <span class="news_top_title">媒体报道</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="`/school/${utils.sdcmzyxyCode}/list/3`" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="`/school/${utils.sdcmzyxyCode}/detail/11`">
                    “点亮星空 融爱未来”！ 关爱“来自星星的孩子”大型公益活动启动
                  </router-link>
                </span>
                <span class="newsItem_date">2022-01-12</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">山东传媒职业学院实施“2+8”突破项目扎实推进“高地建设”</span>
                <span class="newsItem_date">2021-12-23</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">抓党建 创品牌 强优势 惠民生</span>
                <span class="newsItem_date">2021-12-14</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">山东传媒职业学院举行2021“感动山传”十大励志青年人物颁奖典礼</span>
                <span class="newsItem_date">2021-11-17</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">山东传媒职业学院举行“互联网+”大学生创业大赛启动仪式暨备赛说明会</span>
                <span class="newsItem_date">2021-04-30</span>
              </li>
            </ul>
          </div>
          <div class="news_box">
            <div class="news_box_top">
              <img src="../../../../assets/zjiet/dang.png" alt="" class="news_dang" />
              <span class="news_top_title">政策文件</span>
              <img src="../../../../assets/zjiet/right.png" alt="" class="right_line" />
              <span class="news_top_more">
                <router-link :to="'/school/zjiet/list/4'" exact>more +</router-link>
              </span>
            </div>
            <ul class="news_list">
              <li class="newsItem">
                <span class="newsItem_title">
                  <router-link :to="'/school/zjiet/detail/16'">
                    习近平：在纪念辛亥革命110周年大会上的讲话
                  </router-link>
                </span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">习近平向2021年世界互联网大会乌镇峰会致贺信</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">坚定信心 共克时艰 共建更加美好的世界 ——在第七十六届联合国大会一般性辩论上的讲话 （2021年9月21日）</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">习近平在上海合作组织成员国元首理事会第二十一次会议上的讲话</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
              <li class="newsItem">
                <span class="newsItem_title">坚定决心意志 埋头苦干实干 确保如期实现建军一百年奋斗目标</span>
                <span class="newsItem_date">2021-11-22</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <div class="footer">
      <p>
        <span>版权所有 </span>
        <span>北京中教互联教育科技有限公司</span>
        <span> EOL Corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">京ICP备2022007846号-1</a>
        <a href="https://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2016-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
import { courseList } from "../sdcmcData.js"
import utils from '../../../../config/utils';
import services from '../../../../config/services';
export default {
  name: "sdcmc",
  data () {
    return {
      utils,
      dataList: courseList,
      services
    };
  },
  created () {
    window.open(services.KCSZ_SERVER + '/' + utils.sdcmzyxyCode, '_self')
  },
  methods: {},
};
</script>

<style scoped lang="less">
/deep/.ant-carousel .slick-slide {
  width: 100%;
  // height: 400px;
}
/deep/.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
}
</style>
<style src="../../../../assets/zjiet/css/public.css" scoped>
</style>
<style src="./home.css" scoped>
</style>
<style src="../../../../assets/sdcmc/common.css" scoped>
</style>