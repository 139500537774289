<template>
  <div class="bg-all-box">
    <Nav />
    <div class="service-all-box">
      <div id="service-desc-bg" class="service-desc-bg">
        <img src="../../assets/site/training/training-bg.jpg" />
        <div class="c">
          <div class="bg-con-box">
            <div class="title">全媒体人才培养 · 实训平台</div>
            <div class="intro">全媒体人才培训的各个实训平台围绕中央级与主流媒体企业的业务流程，基于人民网数据及技术支持自主研发设计，包括智能风控、媒体融合运营、舆情监测与分析实训平台。从内容审核、媒体融合运营、舆情监测与管理等方面全面支撑院校实训实践教学。</div>
          </div>
        </div>
      </div>
      <div id="pride_tab_fixed" :class="titleFixed ? 'fixed-menu-box sticky' : 'fixed-menu-box'">
        <div :class="isScrollY == 1 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(1)"><div class="menu-text">实训平台特色</div></div>
        <div :class="isScrollY == 2 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(2)"><div class="menu-text">融媒体运营实训平台</div></div>
        <div :class="isScrollY == 3 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(3)"><div class="menu-text">舆情监测管理实训平台</div></div>
        <div :class="isScrollY == 4 ? 'menu-item active' : 'menu-item'" @click="scrollToHref(4)"><div class="menu-text">智能风控实训平台</div></div>
      </div>
      <div class="last-con-box">
        <div class="bread-bg-box">
          <div class="c">
            <div class="bread-line">
              <router-link class="bread-link" to="/service" exact>院校服务</router-link>
              <span class="bread-icon">></span>
              <a class="bread-link">全媒体人才培养实训平台</a>
            </div>
          </div>
        </div>
        <div id="jeol-training" class="category-label">实训平台特色</div>
        <div class="c">
          <div class="group-imgs-box">
            <img class="group-left" src="../../assets/site/training/group1.png" />
            <div class="group-con-box">
              <div class="top-icons-box">
                <div class="circle yellow"></div>
                <div class="circle green"></div>
                <div class="circle orange"></div>
              </div>
              <div class="con-text">
                <p>全媒体实训平台基于人民网数据库由我们自主设计研发，围绕传统媒体和新媒体的业务流程，其囊括了时下炙手可热的智能风控、媒体融合运营、舆情监测与分析三大专业方向，基于媒体融合运营、内容审核、舆情监测与管理的三大实训内容，全方位模拟全媒体实操环境，真实再现全媒体运营的工作场景，全面支撑院校实践教学，助力院校输出高复合技能型全媒体人才。</p>
                <p>整个实训系统中，在教学中将学校视为一个公司，各班级模拟成为公司中的各个部门，有教师引领各个班级的学生进行实训演练及实训教学。</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="second-box" style="background: #F3F7FF;padding: 20px 0 0;">
        <div class="c">
          <div class="characteristic-list">
            <div class="character-item">
              <div class="one-option">
                <span class="text">全面实训资源及素材数据支撑</span>
                <span class="one-nums">01</span>
              </div>
              <div class="two-brief">配套完整的媒体行业实训教学资源和数据库，依托中国教育在线教育媒体矩阵运营的多年经验，以多名主流媒体从业者的从业经历为背书，从运营、风控、舆情等场景开发与实训教学资源配套的教学数据。</div>
            </div>

            <div class="character-item">
              <div class="one-option">
                <span class="text">全面实训资配套运营所需智能工具包<br/>及相关资源源及素材数据支撑</span>
                <span class="one-nums">02</span>
              </div>
              <div class="two-brief">平台包含多套媒体运营及教学的智能工具，其涵盖了媒体从业者在工作中常用到的图片设计、短视频制作、H5页面制作等运营工具，还配有海量图片和视频素材库、模板库等，在满足全媒体行业人才培养的同时，轻松完成教学工作。</div>
            </div>

            <div class="character-item">
              <div class="one-option">
                <span class="text">仿真教学环境 保障教学安全</span>
                <span class="one-nums">03</span>
              </div>
              <div class="two-brief">通过局域网环境模拟运营模式，让学生真实再现媒体运营、内容风控、舆情分析的学习实践，规避社交媒体或第三方平台的教学使用中产生不利影响。</div>
            </div>

            <div class="character-item">
              <div class="one-option">
                <span class="text">匹配媒体岗位所需技能<br/>场景化教学模拟实战</span>
                <span class="one-nums">04</span>
              </div>
              <div class="two-brief">涵盖几十种媒体高仿真模拟运营场景，通过策、采、编、审、发、管、馈、评运营闭环，一站式模拟全媒体运营场景。</div>
            </div>
            <div class="character-item">
              <div class="one-option">
                <span class="text">以教学为主线 教师实时掌握<br/>教学情况，积累教学资源库</span>
                <span class="one-nums">05</span>
              </div>
              <div class="two-brief">实训平台方便教师对学生集中教学管理，进行教学结果评定，将实训过程中的产生的数据形成教学资源库。</div>
            </div>
            <div class="character-item">
              <div class="one-option">
                <span class="text">系统服务形式多样</span>
                <span class="one-nums">06</span>
              </div>
              <div class="two-brief">系统采用B/S架构，支持saas/本地化等多种部署方式，功能丰富，扩展性及安全性强。</div>
            </div>


          </div>
        </div>
      </div>

      <div class="second-box">
        <div class="c">
          <div id="jeol-operate" class="category-label" style="margin: 0px 0 40px">融媒体运营实训平台</div>
          <img src="../../assets/site/training/group2.png" />
        </div>
      </div>

      <div class="threeC-box">
        <div class="c">
          <div class="platform-box">
            <img src="../../assets/site/training/group3.png" />
            <div class="platform-list">
              <div class="platform-item">
                <div class="plat-num">01</div>
                <div class="plat-text">围绕中央级与主流媒体运营企业<span class="blod">【 策 采 编 发 监 】</span>工作流程建设，模拟实现融媒体新闻生产传播流程。</div>
              </div>
              <div class="platform-item">
                <div class="plat-num">02</div>
                <div class="plat-text">与教学紧密结合，配套各类素材资源、案例库、通过各功能模块锻炼学生技能点。</div>
              </div>
              <div class="platform-item">
                <div class="plat-num">03</div>
                <div class="plat-text">通过虚拟场景、“一次采集、一处生产、多级审核、多种生成、全媒传播、智能分析”的融媒体生产传播格局。</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="second-box re-linear-box">
        <div class="linear-line"></div>
        <div class="charter-lines">
          <img class="fx-left" src="../../assets/site/service/fx-left.png" />
          <span>配套实训资源</span>
          <img class="fx-right" src="../../assets/site/service/fx-right.png" />
        </div>
        <div class="c">
          <div class="content-linear-box">
            <div class="linear-item"><span class="label">实时新闻热点</span></div>
            <div class="linear-item"><span class="label">丰富的媒体资源库</span></div>
            <div class="linear-item"><span class="label">图片/视频设计素材及模板库</span></div>
            <div class="linear-item"><span class="label">多个行业站点运营模板</span></div>
            <div class="linear-item"><span class="label">互动运营案例库</span></div>
            <div class="linear-item"><span class="label">多维度的运营数据分析案例</span></div>
          </div>
        </div>
      </div>

      <div class="second-box">
        <div class="c">
          <div id="jeol-popular" class="category-label" style="margin: 50px 0 40px">舆情监测管理实训平台</div>
          <img src="../../assets/site/training/group4.png" />
        </div>
      </div>

      <div class="threeC-box">
        <div class="c">
          <div class="core-box">
            <img src="../../assets/site/training/group5.png" />
            <div class="core-list">
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">01</div>
                  <div class="core-txt">舆情监测</div>
                </div>
                <div class="core-desc">自定义关键词，进行任务监测</div>
              </div>
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">04</div>
                  <div class="core-txt">舆情分析</div>
                </div>
                <div class="core-desc">智能分析、传播分析、对比<br/>分析、环比分析</div>
              </div>
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">02</div>
                  <div class="core-txt">舆情预警</div>
                </div>
                <div class="core-desc">自定义预警关键词，支持网站、<br/>邮件方式的预警</div>
              </div>
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">05</div>
                  <div class="core-txt">舆情大屏</div>
                </div>
                <div class="core-desc">自定义关键词/任务的大屏展示</div>
              </div>
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">03</div>
                  <div class="core-txt">舆情榜单</div>
                </div>
                <div class="core-desc">媒体榜、微信榜、微博榜</div>
              </div>
              <div class="core-item">
                <div class="core-top">
                  <div class="core-num">06</div>
                  <div class="core-txt">舆情报告</div>
                </div>
                <div class="core-desc">对比报告、监测报告</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="threeC-box re-linear-box">
        <div class="linear-line" style="top: 84px;"></div>
        <div class="charter-lines">
          <img class="fx-left" src="../../assets/site/service/fx-left.png" />
          <span>配套实训资源</span>
          <img class="fx-right" src="../../assets/site/service/fx-right.png" />
        </div>
        <div class="c">
          <div class="content-linear-box" style="width: 75%;">
            <div class="linear-item"><span class="label">模拟舆情监测数据库</span></div>
            <div class="linear-item"><span class="label">全网各领域实时监测数据</span></div>
          </div>
        </div>
      </div>

      <div class="second-box">
        <div class="c">
          <div id="jeol-risk" class="category-label" style="margin: 0 0 40px">智能风控实训平台</div>
          <img style="margin: 0 auto;display: block;" src="../../assets/site/training/group8.png" />
        </div>
      </div>

      <div class="threeC-box" style="padding: 60px 0 0px;">
        <div class="c">
          <div class="platform-box">
            <img src="../../assets/site/training/group7.png" style="margin-right: 175px" />
            <div class="platform-list reset-list">
              <div class="platform-item reset-item">
                <div class="plat-num">01</div>
                <div class="plat-text">支持文本、图片、音频、视频多种内容形式</div>
              </div>
              <div class="platform-item reset-item">
                <div class="plat-num">02</div>
                <div class="plat-text">支持自定义违规关键词、谣言库、过滤词</div>
              </div>
              <div class="platform-item reset-item">
                <div class="plat-num">03</div>
                <div class="plat-text">支持自定义用户ID、用户IP、图片名单等黑白名单</div>
              </div>
            </div>
          </div>
          <div class="platform-box" style="width: 100%">
            <div class="platform-list reset-list" style="width: 100%">
              <div class="platform-item reset-item">
                <div class="plat-num">04</div>
                <div class="plat-text">覆盖涉政、色情、违禁、暴恐、广告、谩骂、灌水、谣言等多种违规检测类别</div>
              </div>
              <div class="platform-item reset-item">
                <div class="plat-num">05</div>
                <div class="plat-text">通过质检任务、质检审核、质检报告、质检分析等功能模块，完成内容再次质检审核</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="threeC-box re-linear-box">
        <div class="linear-line" style="top: 84px;"></div>
        <div class="charter-lines">
          <img class="fx-left" src="../../assets/site/service/fx-left.png" />
          <span>配套实训资源</span>
          <img class="fx-right" src="../../assets/site/service/fx-right.png" />
        </div>
        <div class="c">
          <div class="content-linear-box" style="width: 85%;">
            <div class="linear-item"><span class="label">多种违规检测类别案例</span></div>
            <div class="linear-item" style="width: max-content;padding: 0 40px;"><span class="label">涵盖多种内容形式、多种违规类型的海量教学内容数据库</span></div>
          </div>
        </div>
      </div>

   
      <!-- 联系我们 -->
      <div class="contact-us-box">
        <img class="pos-img-left" src="../../assets/site/home/result-left.png" />
        <img class="pos-img-right" src="../../assets/site/home/result-right.png" />
        <div class="c">
          <div class="contact-text">联系我们，获取更详细服务方案！</div>
          <div class="contact-btn" @click="goToNeed">立即咨询</div>
        </div>
      </div>
    </div>
    <Aside />
    <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import services from "../../config/services";
import Aside from "../../components/Aside";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "../home/home.less";

export default {
  name: "Training",
  components: {
    Aside,
    Nav,
    Footer,
  },
  data() {
    return {
      services: services,
      titleFixed: false,
      offsetTop: 0,
      trainingTop: 0,
      operateTop: 0,
      popularTop: 0,
      riskTop: 0,
      isScrollY: 1,
    }
  },
  mounted() { 
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll, true);
      
    })
  },
  destroyed(){
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    scrollToHref(type){
      this.$nextTick(() => {
        this.trainingTop = document.querySelector("#jeol-training").offsetTop;
        this.operateTop = document.querySelector("#jeol-operate").offsetTop; 
        this.popularTop = document.querySelector("#jeol-popular").offsetTop;
        this.riskTop = document.querySelector("#jeol-risk").offsetTop;
        if(type == 1 ){
          document.body.scrollTop = this.trainingTop - 100;
          this.isScrollY = 1;
        } else if (type == 2){
          document.body.scrollTop = this.operateTop - 100;
          console.log("pupu",this.popularTop)
          this.isScrollY = 2;
        } else if (type == 3){
          document.body.scrollTop = this.popularTop- 100;
          this.isScrollY = 3;
        } else if (type == 4){
          document.body.scrollTop = this.riskTop- 100;
          this.isScrollY = 4;
        } 
      })
    },
    goToNeed(){
      utils.redirect(this, "/need", false);
    },
    //滚动监听，头部固定
    handleScroll() {
      this.offsetTop = document.querySelector("#pride_tab_fixed").offsetTop;
      this.trainingTop = document.querySelector("#jeol-training").offsetTop;
      this.operateTop = document.querySelector("#jeol-operate").offsetTop;
      this.popularTop = document.querySelector("#jeol-popular").offsetTop;
      this.riskTop = document.querySelector("#jeol-risk").offsetTop;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.titleFixed = scrollTop > this.offsetTop ? true : false;
      this.trainingTop = this.trainingTop - 200;
      this.operateTop = this.operateTop - 200;
      this.popularTop = this.popularTop - 200;
      this.riskTop = this.riskTop - 200;
      if(scrollTop > this.trainingTop && scrollTop < this.operateTop){
        this.isScrollY = 1;
      } else if(scrollTop > this.operateTop && scrollTop < this.popularTop){
        this.isScrollY = 2;
      } else if(scrollTop > this.popularTop && scrollTop < this.riskTop){
        this.isScrollY = 3;
      } else if(scrollTop > this.riskTop){
        this.isScrollY = 4;
      }
    }
  }
}
</script>


<style lang="less" scoped>
.bg-all-box {
  background-color: #fff;
}
.service-desc-bg {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .bg-con-box {
    width: 44%;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #F5F6F9;
      margin-bottom: 15px;
    }
    .intro {
      font-size: 16px;
      color: #F5F6F9;
      line-height: 32px;
    }
  }
}
.second-box {
  width: 100%;
  padding: 40px 0 60px;
  background-color: #FFFFFF;
}
.last-con-box {
  // background-color: #F5F6F9;
  padding: 40px 0 60px;
}
.charter-lines {
  font-size: 28px;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 40px;
  span {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
  }
  .fx-right, .fx-left {
    display: inline-block;
    vertical-align: middle;
  }
}
.spacing::before {
  width: 9px;
  height: 10px;
  content: "";
  display: inline-block;
  margin-right: 6px;
  background-image: url("../../assets/site/service/fx-left.png");
  background-position: 0 -5px;
}
.fixed-menu-box {
  width: 1200px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  opacity: 0.84;
  border-radius: 8px 8px 0px 0px;
  margin-top: -70px;
  .menu-item {
    flex: 1;
    font-weight: bold;
    color: #757373;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    .menu-text {
      font-size: 20px;
      font-weight: bold;
      position: relative;
      display: inline-block;
    }
  }
  .menu-item:hover,
  .menu-item.active {
    color: #3D77FF;
  }
  .menu-item:hover .menu-text::after,
  .menu-item.active .menu-text::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: #3D77FF;
    border-radius: 2px;
    position: absolute;
    bottom: -20px;
  }
}
.fixed-menu-box.sticky {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  opacity: 1;
  border: 1px solid #ededed;
} 
.category-label {
  text-align: center;
  font-size: 30px;
  color: #2b2b2b;
}
.category-label::before,
.category-label::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #4886FF;
  border-radius: 50%;
  margin: 0 40px;
  vertical-align: 7px;
}
.characteristic-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 0 0;
  .character-item {
    flex: 0 0 32%;
    height: 306px;
    margin-bottom: 60px;
    background: #FFFFFF;
    border: 1px solid #3D77FF;
    border-radius: 6px;
    .one-option {
      width: 100%;
      height: 100px;
      position: relative;
      background-color: #3D77FF;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 0 0;
      .text {
        font-weight: bold;
        color: #F5F6F9;
        line-height: 30px;
        font-size: 17px;
      }
      .one-nums {
        position: absolute;
        right: 0px;
        top: -33px;
        font-size: 48px;
        font-weight: bold;
        color: #BBD0FF;
      }
    }
    .two-brief {
      font-size: 16px;
      font-weight: 400;
      color: #383838;
      line-height: 28px;
      padding: 25px 20px 0 20px;
    }
  }
}
.group-imgs-box {
  padding: 70px 0 0;
  text-align: center;
  .group-left {
    height: 459px;
    width: auto;
    display: inline-block;
  }
  .group-con-box {
    width: 544px;
    display: inline-block;
    .top-icons-box {
      width: 100%;
      height: 47px;
      background: #3D77FF;
      border: 2px solid #2F3838;
      text-align: right;
      .circle {
        width: 25px;
        height: 25px;
        border: 2px solid #2F3838;
        border-radius: 50%;
        margin-left: 3px;
        margin-top: 11px;
        display: inline-block;
      }
      .circle.yellow {
        background: #FBB046;
      }
      .circle.green {
        background: #8EEFEA;
      }
      .circle.orange {
        background: #F15A25;
        margin-right: 13px;
      } 
    }
    .con-text {
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      color: #2F3838;
      line-height: 30px;
      border: 2px solid #2F3838;
      border-top: none;
      padding: 45px 25px;
      p {
        margin-bottom: 10px;
      }
    }
  }
}
.threeC-box {
  padding: 60px 0 50px;
  background: #F3F7FF;
}
.platform-box {
  text-align: center;
  img {
    margin-right: 40px;
  }
  .platform-list {
    width: 620px;
    display: inline-block;
    .platform-item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .plat-num {
        text-align: center;
        margin-right: 20px;
        display: inline-block;
        width: 56px;
        height: 56px;
        line-height: 56px;
        background: #BBD0FF;
        border-radius: 50%;
        font-size: 22px;
        font-weight: bold;
        color: #FFFFFF;
      }
      .plat-text {
        text-align: left;
        flex: 1;
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        color: #2B2B2B;
        line-height: 36px;
        .blod {
          font-weight: bold;
        }
      }
    }
  }
  .platform-list.reset-list {
    width: 440px;
    text-align: left;
    .reset-item {
      flex: 1;
      .plat-text { 
        font-size: 16px;
        color: #2B2B2B;
      }
    }
  }
}
.re-linear-box {
  position: relative;
  background-color: #F3F7FF;
  .linear-line {
    position: absolute;
    top: 63px;
    z-index: 1;
    left: 0;
    height: 4px;
    width: 100%;
    background: linear-gradient(85deg, rgba(161, 196, 253, 0.8) 0%, rgba(194, 233, 251, 0.8) 100%);
  }
  .charter-lines {
    background-color: #F3F7FF;
    position: relative;
    z-index: 10;
    width: max-content;
    margin: 0 auto;
    padding: 0 20px;
  }
}
.content-linear-box {
  width: 90%;
  display: flex;
  margin: 0 auto;
  padding: 50px 0 0;
  justify-content: space-between;
  flex-wrap: wrap;
  .linear-item {
    width: 296px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    background: linear-gradient(85deg, rgba(161, 196, 253, 0.8) 0%, rgba(194, 233, 251, 0.8) 100%);
    border-radius: 30px 2px 45px 2px;
    margin-bottom: 30px;
    .label {
      font-size: 18px;
      font-weight: bold;
      color: #233359;
    }
  }
}
.core-box {
  text-align: center;
  img {
    margin-right: 110px;
    display: inline-block;
  }
  .core-list {
    display: inline-block;
    width: 620px;
    text-align: center;
    .core-item {
      width: 250px;
      margin-left: 60px;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 20px;
      .core-top {
        margin-bottom: 10px;
        .core-num {
          width: 48px;
          height: 48px;
          line-height: 48px;
          margin-right: 20px;
          background: #BBD0FF;
          border-radius: 50%;
          font-size: 22px;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
          display: inline-block;
        }
        .core-txt {
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          color: #2B2B2B;
        }
      }
      .core-desc {
        font-size: 14px;
        color: #676767;
        line-height: 22px;
      }
    }
    .core-item:nth-child(2) .core-num {
      background: #427BEC;
    }
    .core-item:nth-child(3) .core-num {
      background: #7FA7F5
    }
    .core-item:nth-child(4) .core-num {
      background: #2867E2
    }
    .core-item:nth-child(5) .core-num {
      background: #427BEC
    }
    .core-item:nth-child(6) .core-num {
      background: #6A86BF
    }
  }
} 
.bread-bg-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 30px;
  .bread-line {
    width: 100%;
    color: #9a9a9a;
    font-size: 15px;
    border-bottom: 1px solid #ececec;
    .bread-link {
      color: #9a9a9a;
      font-size: 15px;
    }
    .bread-link:hover {
      color: #3d77ff;
    }
    .bread-icon {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
</style>
