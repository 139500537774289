<template>
	<div>
		<!-- <UserNav /> -->
		<Nav />
		<div class="class404" :style="{ height: vFrameHeight + 'px' }">
			<img class="img404" src="../../assets/404.png" alt="" />
			<p class="info-txt">院校不存在。</p>
			<!-- <p class="info-time">{{ time }}s 后自动跳转</p>
			<div class="btn-video-detail">
				<div class="btn-xuexi" @click="backTo">返回学习中心</div>
			</div> -->
		</div>
		<Footer />
	</div>
</template>

<script>
// import UserNav from "../../components/UserNav";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "../class/class.less";
// import utils from "../../config/utils";
export default {
	name: "Home404",
	components: {
		// UserNav,
		Nav,
		Footer,
	},
	data() {
		return {
			vFrameHeight: "",
			// time: 5,
			// inter: null,
		};
	},
	destroyed() {
		clearInterval(this.inter);
	},
	mounted() {
		this.vFrameHeight = document.body.clientHeight - 98 - 121;
		// this.inter = setInterval(() => {
		// 	if (this.time > 1) {
		// 		this.time = this.time - 1;
		// 	} else {
		// 		clearInterval(this.inter);
		// 		utils.redirect(this, "/user/myClass");
		// 	}
		// }, 1000);
		this.$store.commit("setNavTitle", {
			navTitle: "",
		});
	},
	methods: {
		// backTo() {
		// 	utils.redirect(this, "/user/myClass");
		// },
		// classToDetail(path) {
		// 	utils.redirect(this, path);
		// },
	},
	computed: {},
};
</script>

<style scoped>
</style>