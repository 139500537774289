<template>
  <div class="thoughtParty">
    <div class="top1">
      <div class="top1_center">
        <div class="top1_center_left">
          <img class="top1_img1" src="../../assets/site/thoughtParty/top1_logo1.png" alt="" />
          <span class="line">|</span>
          <span class="top1_title1">党建与思政</span>
        </div>
        <div class="tabs">
          <router-link class="top1_tab1 top1_tab top1_actived" :to="'/djsz'">思政资源库</router-link>
          <router-link class="top1_tab3 top1_tab" :to="'/thought'">智慧党建</router-link>
          <router-link class="top1_tab2 top1_tab" :to="'/thought'">思政培训</router-link>
        </div>
        <div class="top1_center_right">
          <span class="top1_company">数据支持单位：</span>
          <img class="top1_img2" src="../../assets/site/thoughtParty/top1_logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="top2">
      <!-- <div class="top2_title"></div> -->
      <img class="titleImg" src="../../assets/site/thoughtParty/title.png" alt="">
      <img class="top2_img" src="../../assets/site/thoughtParty/top2_title.png" alt="">
    </div>
    <div class="body">
      <div class="body_tabs">
        <div class="body_tabs_center">
          <span :class="tabIndex=='0'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(0)">思政专题</span>
          <span :class="tabIndex=='1'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(1)">课程思政</span>
          <span :class="tabIndex=='2'?'tabItem tabItem_actived':'tabItem'" @click="chanageTab(2)">党建专区</span>
        </div>
      </div>
      <div class="body_box">
        <div class="box0" v-show="tabIndex=='0'">
            <div class="box_left">
             <a-menu
                style="width: 260px"
                :default-selected-keys="['1']"
                mode="inline"
                theme="light"
                :selected-keys="[current0]"
                @click="handleClick0"
                :open-keys="openKeys0"
                @openChange="onOpenChange0"
              >
                <a-menu-item key="1">
                  国家概况
                </a-menu-item>
                <a-sub-menu key="sub1">
                  <span slot="title"><span>政策精神</span></span>
                  <a-menu-item key="2">
                    国家政策
                  </a-menu-item>
                  <a-menu-item key="3">
                    各地政策
                  </a-menu-item>
                  <a-menu-item key="4">
                    工作报告
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub2">
                  <span slot="title"><span>领导讲话</span></span>
                  <a-menu-item key="5">
                    领导人活动报道
                  </a-menu-item>
                  <a-menu-item key="6">
                    省部长言论
                  </a-menu-item>
                  <a-menu-item key="7">
                    外交部发言人言论
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub3">
                  <span slot="title"><span>史料文献</span></span>
                  <a-menu-item key="8">
                    经典著作
                  </a-menu-item>
                  <a-menu-item key="9">
                    领袖著作
                  </a-menu-item>
                  <a-menu-item key="10">
                    建国前重要文献选编
                  </a-menu-item>
                  <a-menu-item key="11">
                    建国以来重要文献选编
                  </a-menu-item>
                  <a-menu-item key="12">
                    新时期重要文献选编
                  </a-menu-item>
                  <a-menu-item key="13">
                    新时期专题文献选编
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub4">
                  <span slot="title"><span>重要人物</span></span>
                  <a-menu-item key="14">
                    领袖人物
                  </a-menu-item>
                  <a-menu-item key="15">
                    党史人物
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub5">
                  <span slot="title"><span>重大事件</span></span>
                  <a-menu-item key="16">
                    党史大事
                  </a-menu-item>
                  <a-menu-item key="17">
                    重大事件
                  </a-menu-item>
                </a-sub-menu>
              </a-menu>
            </div>
            <div class="box0_right">
              <div class="box_right_top">
                <div class="left1">
                  <img class="fiveStar" src="../../assets/site/thoughtParty/fiveStar.png" alt="">
                  <span class="level1">{{level1Name}}</span>
                  <img v-if="level2Name" class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter.png" alt="">
                  <img class="boxImgCenter" v-else src="../../assets/site/thoughtParty/boxImgCenter1.png" alt="">
                  <span class="level2" v-if="level2Name">· {{level2Name}}</span>
                  <img v-if="level2Name"  class="boxImgRight" src="../../assets/site/thoughtParty/boxImgRight.png" alt="">
                </div>
                <div class="right1"></div>
              </div>
              <div class="select1" v-if="!closeLevel3&&level3List.length>0">
                <span v-for="(item,index) in level3List" 
                :key="index" 
                :class="curLevel3Index==0&&index==0?'select1_Item select1_Item_actived':item.id==curLevel3Index? 'select1_Item select1_Item_actived':'select1_Item'"
                @click="changeColumn(item.id)"
                >
                {{item.name}}</span>
              </div>
              <div class="select2" v-if="false">
                <div class="select2_Item">
                  <div class="radio0 radio"></div>
                  <label for="radio0">不限</label>
                </div>
               <div class="select2_Item">
                  <div class="radio1 radio"></div>
                  <label for="radio1">给90后讲讲马克思</label>
                </div>
                <div class="select2_Item">
                  <div class="radio2 radio"></div>
                  <label for="radio2">给90后讲讲马克思</label>
                </div>
                <div class="select2_Item select2_Item_active">
                  <div class="radio3 checkbox"></div>
                  <label for="radio3">重温马克思主义经典</label>
                </div>
              </div>
              <ul class="newsList" v-if="hasData0&&!closebox0&&newsList0.length>0">
                <li v-for="(item,index) in newsList0" :key="index" class="newsListItem" @click="toDetail0(item.id)">
                  <span class="dot"></span>
                  <span class="title">{{item.list_title?item.list_title:item.title}}</span>
                  <span class="date">{{item.date}}</span>
                </li>
              </ul>
              <div class="noData" v-if="!hasData0&&closebox0">
                <img src="../../assets/site/thoughtParty/noData.png" alt="" class="noDataImg">
                <div class="tip">
                  <span>对不起，您暂无查看权限，请联系管理员为您开通授权。</span>
                </div>
              </div>
              <div class="page" v-if="!closebox0&&hasData0">
                <a-config-provider :locale="locale">
                   <a-pagination show-quick-jumper :default-current="1" :page-size.sync="pageSize" :total="100"  @change="onChange0" />
                </a-config-provider>
              </div>
            </div>
        </div>
        <div class="box1" v-show="tabIndex=='1'">
          <div class="box_left">
             <a-menu
                style="width: 260px"
                :default-selected-keys="['1']"
                mode="inline"
                theme="light"
                :selected-keys="[current1]"
                @click="handleClick1"
              >
                <a-menu-item key="1">
                 新闻传播大类
                </a-menu-item>
                <a-menu-item key="2">
                  装备制造大类 
                </a-menu-item>
                <a-menu-item key="3">
                  农林牧渔大类 
                </a-menu-item>
                <a-menu-item key="4">
                  资源环境与安全大类 
                </a-menu-item>
                <a-menu-item key="5">
                  能源动力与材料大类 
                </a-menu-item>
                <a-menu-item key="6">
                  土木建筑大类 
                </a-menu-item>
                <a-menu-item key="7">
                  水利大类 
                </a-menu-item>
                <a-menu-item key="8">
                  生物与化工大类 
                </a-menu-item>
                <a-menu-item key="9">
                  轻工纺织大类 
                </a-menu-item>
                <a-menu-item key="10">     
                  食品药品与粮食大类 
                </a-menu-item>
                <a-menu-item key="11">     
                  交通运输大类 
                </a-menu-item>
                <a-menu-item key="12">
                  电子与信息大类 
                </a-menu-item>
                <a-menu-item key="13">
                  医药卫生大类 
                </a-menu-item>
                <a-menu-item key="14">
                  财经商贸大类 
                </a-menu-item>
                <a-menu-item key="15">
                  旅游大类 
                </a-menu-item>
                <a-menu-item key="16">
                  文化艺术大类 
                </a-menu-item>
                <a-menu-item key="17">
                  教育与体育大类 
                </a-menu-item>
                <a-menu-item key="18">
                  公安与司法大类 
                </a-menu-item>
                <a-menu-item key="19">
                  公共管理与服务大类 
                </a-menu-item>
              </a-menu>
            </div>
            <div class="box1_right">
              <div class="box_right_top">
                <div class="left1">
                  <img class="fiveStar" src="../../assets/site/thoughtParty/fiveStar.png" alt="">
                  <span class="level1">{{level1NameBox1}}</span>
                  <img class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter1.png" alt="">
                  <!-- <span class="level2">· {{level2Name}}</span> -->
                </div>
                <div class="right1"></div>
              </div>
              <ul class="box1List" v-if="hasData1&&current1=='1'">
                <li class="header">
                  <div class="header1">序号</div>
                  <div class="header2">思政元素</div>
                  <div class="header3">元素相关资源</div>
                </li>
                <li class="tr">
                  <div class="td1">1</div>
                  <div class="td2">爱国主义</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>百集文献纪录片《山河岁月》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>纪录片《大国崛起》《故宫》《新丝绸之路》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">2</div>
                  <div class="td2">社会责任</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>中国新闻奖、长江韬奋奖颁奖报告会汇编</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>裤脚沾满泥土的新闻人（时代先锋）</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">3</div>
                  <div class="td2">政治认同</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>百集微纪录片《百炼成钢：中国共产党的100年》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《百年求索》系列微纪录片</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>庆祝新中国成立70周年系列理论视频</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                 <li class="tr">
                  <div class="td1">4</div>
                  <div class="td2">法治意识</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>新闻传播法规汇编</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>传媒行业行业政策、法律法规、行业标准</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">5</div>
                  <div class="td2">职业操守</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>《中国新闻工作者职业道德准则》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>《媒体社会责任报告》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《中国新闻传播教育人物志》系列专题片</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">6</div>
                  <div class="td2">人文精神</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>云讲堂：百年历程中的伟大精神</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《百年巨匠》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">7</div>
                  <div class="td2">社会主义核心价值观</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《社会主义“有点潮”》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《十九大·理论新视野》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">8</div>
                  <div class="td2">科学思维</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>媒体的科学精神</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <ul class="box1List" v-if="hasData1&&current1=='2'">
                <li class="header">
                  <div class="header1">序号</div>
                  <div class="header2">思政元素</div>
                  <div class="header3">元素相关资源</div>
                </li>
                <li class="tr">
                  <div class="td1">1</div>
                  <div class="td2">爱国主义</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>百集文献纪录片《山河岁月》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>纪录片《大国崛起》《故宫》《新丝绸之路》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">2</div>
                  <div class="td2">社会责任</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>中国品牌 中国故事</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">3</div>
                  <div class="td2">政治认同</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>百集微纪录片《百炼成钢：中国共产党的100年》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《百年求索》系列微纪录片</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>庆祝新中国成立70周年系列理论视频</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                 <li class="tr">
                  <div class="td1">4</div>
                  <div class="td2">法治意识</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>装备制造行业法规汇编</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>装备制造行业政策、法律法规、行业标准</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">5</div>
                  <div class="td2">职业操守</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/word.png" alt="">
                        <span>职业技能培训教材——职业道德</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>纪录片《中国劳模》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">6</div>
                  <div class="td2">人文精神</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>云讲堂：百年历程中的伟大精神</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《百年巨匠》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">7</div>
                  <div class="td2">社会主义核心价值观</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《社会主义“有点潮”》</span>
                      </li>
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>《十九大·理论新视野》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="tr">
                  <div class="td1">8</div>
                  <div class="td2">科学思维</div>
                  <div class="td3">
                    <ul class="td3List">
                      <li @click="seeMore()">
                        <img src="../../assets/site/thoughtParty/video.png" alt="">
                        <span>纪录片《科学的力量》</span>
                      </li>
                      <li class="seeMore" @click="seeMore()">
                        查看更多 >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <div class="noData" v-if="!hasData1">
                <img src="../../assets/site/thoughtParty/noData.png" alt="" class="noDataImg">
                <div class="tip">
                  <span>对不起，您暂无查看权限，请联系管理员为您开通授权。</span>
                </div>
              </div>
            </div>
        </div>
        <div class="box2" v-show="tabIndex=='2'">
          <div class="box_left">
             <a-menu
                style="width: 260px"
                :default-selected-keys="['1']"
                :open-keys="openKeys2"
                mode="inline"
                theme="light"
                :selected-keys="[current2]"
                @click="handleClick2"
                @openChange="onOpenChange2"
              >
                <a-sub-menu key="sub1">
                  <span slot="title"><span>党建学习</span></span>
                  <a-menu-item key="1">
                    党章党规
                  </a-menu-item>
                  <a-menu-item key="2">
                    支部党课
                  </a-menu-item>
                  <a-menu-item key="3">
                    党建研究
                  </a-menu-item>
                  <a-menu-item key="4">
                    党务知识
                  </a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="sub2">
                  <span slot="title"><span>党建视听</span></span>
                  <a-menu-item key="5">
                    党史微课
                  </a-menu-item>
                  <a-menu-item key="6">
                    国史讲堂
                  </a-menu-item>
                  <a-menu-item key="7">
                    国家日历
                  </a-menu-item>
                  <a-menu-item key="8">
                    党史随时学
                  </a-menu-item>
                  <a-menu-item key="9">
                    综合
                  </a-menu-item>
                </a-sub-menu>
              </a-menu>
            </div>
            <div class="box2_right">
              <div class="box_right_top">
                <div class="left1">
                  <img class="fiveStar" src="../../assets/site/thoughtParty/fiveStar.png" alt="">
                  <span class="level1">{{level1NameBox2}}</span>
                  <img v-if="level2NameBox2" class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter.png" alt="">
                  <img v-else class="boxImgCenter" src="../../assets/site/thoughtParty/boxImgCenter1.png" alt="">
                  <span class="level2" v-if="level2NameBox2">· {{level2NameBox2}}</span>
                  <img v-if="level2NameBox2" class="boxImgRight" src="../../assets/site/thoughtParty/boxImgRight.png" alt="">
                </div>
                <div class="right1"></div>
              </div>
              <ul class="newsList" v-if="hasData2&&!closebox2">
                <li v-for="(item,index) in newsList2" :key="index" class="newsListItem" @click="toDetail2(item.id)">
                  <span class="dot"></span>
                  <span class="title">{{item.list_title?item.list_title:item.title}}</span>
                  <span class="date">{{item.date}}</span>
                </li>
              </ul>
              <div class="noData" v-if="!hasData2&&closebox2">
                <img src="../../assets/site/thoughtParty/noData.png" alt="" class="noDataImg">
                <div class="tip">
                  <span>对不起，您暂无查看权限，请联系管理员为您开通授权。</span>
                </div>
              </div>
              <div class="page" v-if="!closebox2&&hasData2">
                <a-config-provider :locale="locale">
                   <a-pagination show-quick-jumper :default-current="1" :page-size.sync="pageSize" :total="100"  @change="onChange2" />
                </a-config-provider>
              </div>
            </div>
        </div>
      </div>

    </div>

     <!-- 联系我们 -->
		<div class="contact-us-box">
			<div class="c">
				<div class="contact-text">联系我们，获取更详细服务方案！</div>
				<div class="contact-btn" @click="goToNeed">立即咨询</div>
			</div>
		</div>
     <Footer />
  </div>
</template>

<script>
import utils from "../../config/utils";
import Footer from "../../components/Footer.vue";
import zhCN from "ant-design-vue/es/locale-provider/zh_CN";
import services from "../../config/services"
import axios from "axios";
export default {
  name: "thoughtParty",
  components:{
    Footer,
  },
  data(){
    return{
      tabIndex:this.$route.query.tabIndex?this.$route.query.tabIndex:0,
      current0: "0",//课程专题左侧导航栏目索引
      current1: '1',//课程思政左侧导航栏目索引   
      current2:"1",//学习专区左侧导航栏目索引   
      locale: zhCN,
      level3List:[],//三级栏目列表
      level1Name:"国家概况",//以及栏目标题
      level2Name:"",//二级栏目标题
      level1NameBox1:"新闻传播大类",
      level1NameBox2:"党建学习",
      level2NameBox2:"党章党规",
      rootSubmenuKeys: ['sub1','sub2','sub3', 'sub4','sub5'],
      openKeys0: [],
      openKeys2: ['sub1'],
      pageNumber0:1,//当前页
      pageNumber2:1,//当前页
      hasData0:true,//当前新闻列表书否有数据
      hasData1:true,//课程思政列表是否有数据
      hasData2:false,//当前学习专区列表书否有数据
      curLevel3Index:0,//当前三级栏目索引
      services,
      newsList0:[],//新闻列表
      newsList2:[],//学习专区列表
      closeLevel3:false,//关闭第三级栏目
      closebox0:false,//关闭tab0的列表页
      closebox1:false,//关闭tab1的列表页
      closebox2:false,//关闭学习专区列表页
      pageSize:20,
      idList0:[{name:"国家概况",id:1},{name:"国家衡策",id:2},{name:"各地政策",id:3},{name:"工作报告",id:4},{name:"领导人活动报告",id:5},{name:"省部长言论",id:6},{name:"外交部发言人言论",id:7},{name:"马恩全集",id:8},
      {name:"列宁全集",id:9},{name:"毛泽东选集",id:10},{name:"周恩来选集",id:11},{name:"刘少奇选集",id:12},{name:"朱德选集",id:13},{name:"任弼时选集",id:14},{name:"邓小平文选",id:15},{name:"陈云文选",id:16},
      {name:"江泽民文选",id:17},{name:"胡锦涛文选",id:18},{name:"中共中央文件选集一（1921—1925）",id:19},{name:"中共中央文件选集十八（1949.1—9）",id:20},{name:"建国以来重要文献选编第一册",id:21},{name:"建国以来重要文献选编第二十册",id:22},
      {name:"三中全会以来重要文献选编上",id:23},{name:"十八大以来重要文献选编下",id:24},{name:"坚持改革、开放、搞活——十一届三中全会以来有关重要文献摘编",id:25},{name:"一国两制重要文献选编",id:26},
      {name:"毛泽东",id:27},{name:"刘少奇",id:28},{name:"周恩来",id:29},{name:"朱德",id:30},{name:"任弼时",id:31},{name:"邓小平",id:32},{name:"党史人物",id:33},{name:"党史大事",id:34},{name:"重大事件",id:35}],//存储栏目名称和id
      idList2:[{name:"党章党规",id:36},{name:"支部党课",id:37},{name:"党建研究",id:38},{name:"党务知识",id:39},{name:"党史微课",id:40},
      {name:"国史讲堂",id:41},{name:"国家日历",id:42},{name:"党史随时学",id:43},{name:"综合",id:44}],
    }
  },
  mounted(){
      let that = this;
      if(that.tabIndex==0){
          that.current0 = that.$route.query.current?that.$route.query.current:"1";
          that.openKeys0 = that.$route.query.openKeys?JSON.parse(that.$route.query.openKeys):[];
          this.level1Name = this.$route.query.level1Name?this.$route.query.level1Name:"国家概况";
          this.level2Name = this.$route.query.level2Name?this.$route.query.level2Name:"";
      }else if(that.tabIndex==2){
          that.current2 = that.$route.query.current?that.$route.query.current:"1";
          that.openKeys2 = that.$route.query.openKeys?JSON.parse(that.$route.query.openKeys):['sub1'];
          that.getData2(parseInt(that.current2)+35);
          this.level1NameBox2 = this.$route.query.level1NameBox2?this.$route.query.level1NameBox2:"党建学习";
          this.level2NameBox2 = this.$route.query.level2NameBox2?this.$route.query.level2NameBox2:"党章党规";
      }
  },
  watch:{
    'current0':function(val){
      if(val=="1"){
        this.level1Name = "国家概况";
        this.level2Name = "";
        this.level3List = [];
        this.getData0(1);
      }else if(val=="2"){
        this.level1Name = "政策精神";
        this.level2Name = "国家政策";
        this.level3List = [];
        this.getData0(2);
      }else if(val=="3"){
        this.level1Name = "政策精神";
        this.level2Name = "各地政策";
        this.level3List = [];
        this.getData0(3);
      }else if(val=="4"){
        this.level1Name = "政策精神";
        this.level2Name = "工作报告";
        this.level3List = [];
        this.getData0(4);
      }else if(val=="5"){
        this.level1Name = "领导讲话";
        this.level2Name = "领导人活动报道";
        this.level3List = [];
        this.getData0(5);
      }else if(val=="6"){
        this.level1Name = "领导讲话";
        this.level2Name = "省部长言论";
        this.level3List = [];
        this.getData0(6);
      }else if(val=="7"){
        this.level1Name = "领导讲话";
        this.level2Name = "外交部发言人言论";
        this.level3List = [];
        this.getData0(7);
      }else if(val=="8"){
        this.level1Name = "史料文献";
        this.level2Name = "经典著作";
        this.level3List = [{name:"马恩全集",id:8},{name:"列宁全集",id:9}];
        this.getData0(8);
      }else if(val=="9"){
        this.level1Name = "史料文献";
        this.level2Name = "领袖著作";
        this.level3List = [{name:"毛泽东选集",id:10},{name:"周恩来选集",id:11},{name:"刘少奇选集",id:12},{name:"朱德选集",id:13},{name:"任弼时选集",id:14},{name:"邓小平文选",id:15},{name:"陈云文选",id:16},
      {name:"江泽民文选",id:17},{name:"胡锦涛文选",id:18}];
        this.getData0(10);
      }else if(val=="10"){
        this.level1Name = "史料文献";
        this.level2Name = "建国前重要文献选编";
        this.level3List = [{name:"中共中央文件选集一（1921—1925）",id:19},{name:"中共中央文件选集十八（1949.1—9）",id:20}];
        this.getData0(19);
      }else if(val=="11"){
        this.level1Name = "史料文献";
        this.level2Name = "建国以来重要文献选编";
        this.level3List = [{name:"建国以来重要文献选编第一册",id:21},{name:"建国以来重要文献选编第二十册",id:22}];
        this.getData0(21);
      }else if(val=="12"){
        this.level1Name = "史料文献";
        this.level2Name = "新时期重要文献选编";
        this.level3List = [{name:"三中全会以来重要文献选编上",id:23},{name:"十八大以来重要文献选编下",id:24}];
        this.getData0(23);
      }else if(val=="13"){
        this.level1Name = "史料文献";
        this.level2Name = "新时期专题文献选编";
        this.level3List = [{name:"坚持改革、开放、搞活——十一届三中全会以来有关重要文献摘编",id:25},{name:"一国两制重要文献选编",id:26}];
        this.getData0(25);
      }else if(val=="14"){
        this.level1Name = "重要人物";
        this.level2Name = "领袖人物";
        this.level3List = [{name:"毛泽东",id:27},{name:"刘少奇",id:28},{name:"周恩来",id:29},{name:"朱德",id:30},{name:"任弼时",id:31},{name:"邓小平",id:32}];
        this.getData0(27);
      }else if(val=="15"){
        this.level1Name = "重要人物";
        this.level2Name = "党史人物";
         this.level3List = [];
        this.getData0(33);
      }else if(val=="16"){
        this.level1Name = "重大事件";
        this.level2Name = "党史大事";
        this.level3List = [];
        this.getData0(34);
      }else if(val=="17"){
        this.level1Name = "重大事件";
        this.level2Name = "重大事件";
        this.level3List = [];
        this.getData0(35);
      }
    },
    'pageNumber0':function(pageNumber0){
      if(pageNumber0>1){
        this.hasData0 = false;
        this.closebox0 = true;
      }else{
        this.hasData0 = true;
      }
    },
    'pageNumber2':function(pageNumber2){
      if(pageNumber2>1){
        this.hasData2 = false;
        this.closebox2 = true;
      }else{
        this.hasData2 = true;
      }
    },

  },
  methods:{
    chanageTab(index){
      if(index==2&&this.tabIndex!=index){
        this.getData2(36);
        this.tabIndex = index;
        this.current2 = "1";
        this.level2NameBox2 = "党章党规";
        this.level1NameBox2 = "党建学习";
        this.openKeys2 = ["sub1"];
      }else if(index==0&&this.tabIndex!=index){
        this.current0 = "1";
        this.tabIndex = index;
        this.level2Name = "";
        this.level1Name = "国家概况";
        this.openKeys0 = [];
      }else{
        this.tabIndex = index;
      }
    },
    handleClick0(e) {
      this.pageNumber0 = 1;
      this.closeLevel3 = false;
      if(e.key==1){
        this.openKeys0 = [];
      }
      if(e.key==this.current0){
        this.closebox0 = false;
        if(this.newsList0.length>0){
          this.hasData0 = true;
        }
      }else{
        this.curLevel3Index = 0;
        this.current0 = e.key;
      }
    },
    handleClick1(e) {
      this.current1 = e.key;
      if(e.key<=2){
        this.hasData1 = true;
      }else{
        this.hasData1 = false;
      }
      this.level1NameBox1 = e.item.$el.innerText;
    },
    handleClick2(e) {
      this.pageNumber2 = 1;
      this.current2 = e.key;
      this.getData2(parseInt(e.key)+35);
      if(e.key<=4){
        this.level1NameBox2 = "党建学习";
      }else{
        this.level1NameBox2 = "党建试听";
      }
      if(e.key==1){
        this.level2NameBox2 = "党章党规";
      }else if(e.key==2){
        this.level2NameBox2 = "支部党课";
      }else if(e.key==3){
        this.level2NameBox2 = "党建研究";
      }else if(e.key==4){
        this.level2NameBox2 = "党务知识";
      }else if(e.key==5){
        this.level2NameBox2 = "党史微课";
      }else if(e.key==6){
        this.level2NameBox2 = "国史讲堂";
      }else if(e.key==7){
        this.level2NameBox2 = "国家日历";
      }else if(e.key==8){
        this.level2NameBox2 = "国史随时学";
      }else if(e.key==9){
        this.level2NameBox2 = "综合";
      }
    },
    //三级栏目切换
    changeColumn(id){
       this.getData0(id);
       this.curLevel3Index = id;
    },
    goToNeed(){
      utils.redirect(this, "/need", true);
    },
    onChange0(pageNumber0) {
      this.pageNumber0 = pageNumber0;
    },
    onChange2(pageNumber2) {
      this.pageNumber2 = pageNumber2;
    },
    onOpenChange0(openKeys0) {
      const latestOpenKey = openKeys0.find(key => this.openKeys0.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys0 = openKeys0;
      } else {
        this.openKeys0 = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onOpenChange2(openKeys2) {
      const latestOpenKey = openKeys2.find(key => this.openKeys2.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys2 = openKeys2;
      } else {
        this.openKeys2 = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    goLogin (){
      this.$store.dispatch('goToAllLogin');
    },
    //切换详情页
    toDetail0(id){
      let routeData = this.$router.resolve({
          path: '/djsz/thoughtDetails', 
          query:{
            articleId:id,
            tabIndex:0,
            current:this.current0,
            openKeys:JSON.stringify(this.current0==1?[]:this.openKeys0),
            level1Name:this.level1Name,
            level2Name:this.level2Name
          }
      });
      window.open(routeData.href, '_blank');
    },
     //学习专区切换详情页
    toDetail2(id){
      let routeData = this.$router.resolve({
            path: '/djsz/thoughtDetails', 
            query:{
              articleId:id,
              tabIndex:2,
              current:this.current2,
              openKeys:JSON.stringify(this.openKeys2),
              level1NameBox2:this.level1NameBox2,
              level2NameBox2:this.level2NameBox2
            }
        });
        window.open(routeData.href, '_blank');
    },
    //课程专题请求数据
    getData0(id){
      let that = this;
      axios.get(`${services.rmtUrl}/api/people/article?category_id=${id}&length=20`).then(function(res){
        if(res.data.code=="0000"){
          that.newsList0 = res.data.data.items;
          if(res.data.data.items.length>0){
            that.hasData0 = true;
            that.closebox0 = false;
          }else{
            that.hasData0 = false;
            that.closebox0 = true;
          }
        }else{
          that.hasData0 = false;
          that.closebox0 = true;
        }
      })
    },
    //学习专区请求数据
    getData2(id){
      let that = this;
      axios.get(`${services.rmtUrl}/api/people/article?category_id=${id}&length=20`).then(function(res){
        if(res.data.code=="0000"){
          that.newsList2 = res.data.data.items;
          if(res.data.data.items.length>0){
            that.hasData2 = true;
            that.closebox2 = false;
          }else{
            that.hasData2 = false;
            that.closebox2 = true;
          }
        }else{
          that.hasData2 = false;
          that.closebox2 = true;
        }
      })
    },
    //查看更多
    seeMore(){
      this.hasData1 = false;
    }
  },
};
</script>

<style lang="less" scoped>
.thoughtParty {
  font-family: Microsoft YaHei;
  .top1 {
    height: 58px;
    background: #d21010;
    .top1_center {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      .top1_center_left{
        flex: 1;
        display: flex;
        align-items: center;
      }
      .top1_center_right{
        flex: 1;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .tabs{
        flex: 1.5;
        display: flex;
        justify-content: space-evenly;;
      }
      .line {
        opacity: 0.74;
        color: #f4853a;
        margin-left: 15px;
        margin-right: 15px;
      }
      .top1_title1 {
        font-size: 18px;
        font-weight: bold;
        color: #feefaa;
      }
      .top1_tab {
        font-size: 16px;
        font-weight: 400;
        color: #f9e2de;
      }
      .top1_tab2{
        margin-left: 30px;
        margin-right: 30px;
      }
      .top1_actived {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
      }
      .top1_company{
        font-size: 12px;
        font-weight: 400;
        color: #FCCD7F;
        margin-right: 10px;
      }
      .top1_img2{
        display: inline-block;
        float: right;
      }
    }
  }
  .top2{
    background: url("../../assets/site/thoughtParty/top2_bg.png") no-repeat;
    height: 180px;
    background-size: 100% 100%;
    text-align: center;
    padding-top: 40px;
    .top2_title{
      text-align: center;
      font-size: 26px;
      color: #CE181F;
      background-image: -webkit-linear-gradient(bottom, rgba(249, 100, 18), rgba(219, 3, 3));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .top2_img{
      margin-top: 15px;
    }
    .titleImg{
      display: block;
      margin: 0 auto;
    }
  }
  .body{
    background: #fff;
    .body_tabs{
      height: 54px;
      width: 100%;
      border-bottom: 2px solid #F2F2F2;
      .body_tabs_center{
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        .tabItem{
          flex: 1;
          height: 100%;
          line-height: 54px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #646161;
          cursor: pointer;
          border-right: 2px solid #FEF6F5;
        }
        .tabItem:hover{
          background: #FAEFED;
        }
        .tabItem_actived{
          background: #FEF6F5;
          border-radius: 1px;
          color: #DB0303;
        }
      }
    }
    .body_box{
      width: 1200px;
      margin: 0 auto;
      margin-top: 36px;
      min-height: 600px;
      overflow: hidden;
      padding-bottom: 30px;
      .box_left{
        float: left;
        li.ant-menu-item {
          background: #F7F7F7;
          margin-top: 0px;
          margin-bottom: 2px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          letter-spacing: 2px;
        }
        li.ant-menu-submenu{
          background: #F7F7F7;
          line-height: 45px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          margin-bottom: 2px;
        }
        li.ant-menu-submenu:hover{
            background: #FAEFED;
        }
        li.ant-menu-submenu li.ant-menu-item{
          background: unset;
        }
        li.ant-menu-item-selected{
          background: #FAEFED;
          font-size: 16px;
          color: #DB0303;
          font-weight: bold;
          letter-spacing: 2px;
        }
        .ant-menu-inline .ant-menu-item-selected::after{
          opacity: 0;
        }
        /deep/.ant-menu-inline .ant-menu-submenu-title{
          height: 45px;
          line-height: 45px;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 16px;
          color: #806E66;
          transition:unset;
          letter-spacing: 2px;
        }
        /deep/.ant-menu-submenu-selected{
          background: #FAEFED !important;
          font-size: 16px;
          color: #DB0303 !important;
        }
        .ant-menu-inline .ant-menu-item{
          font-size: 16px;
        }
        /deep/.ant-menu-submenu-title:active{
          background: #F7F7F7;
        }
        /deep/.ant-menu-inline{
          border: none;
        }
        /deep/.ant-menu-sub.ant-menu-inline{
          border: 1px solid #f0f0f0;
        }
      }
      .box0_right,.box1_right,.box2_right{
        margin-left: 40px;
        float: left;
        width: calc(100% - 300px);
        .box_right_top{
          border-bottom: 14px solid #FFEEEB;
          height: 37px;
          .left1{
            height: 40px;
            line-height: 40px;
            width: 50%;
            float: left;
            .level1{
              float: left;
              font-size: 18px;
              font-weight: bold;
              color: #FFFFFF;
              margin-left: -15px;
              margin-top: 5px;
              background: #DB0303;
              height: 32px;
              line-height: 32px;
              display: inline-block;
              padding-right: 20px;
              padding-left: 35px;
              letter-spacing: 3px;
            }
            .level2{
              float: left;
              font-size: 14px;
              font-weight: bold;
              color: #E57F01;
              margin-top: 5px;
              height: 32px;
              line-height: 32px;
              background: #FFEFD6;
              padding-right: 20px;
              padding-left: 10px;
            }
            .fiveStar{
              float: left;
              width: 40px;
              position: relative;
            }
            .boxImgCenter,.boxImgRight{
              float: left;
              margin-top: 5px;
            }

          }
          .right1{
            background: url("../../assets/site/thoughtParty/boxImg3.png") no-repeat;
            height: 26px;
            float: right;
            width: 122px;
            margin-top: 14px;
          }
        }
        .select1{
          // height: 80px;
          background: #F4F4F4;
          margin-top: 20px;
          padding-bottom: 10px;
          .select1_Item{
            font-size: 14px;
            color: #737373;
            padding: 10px 15px;
            white-space: nowrap;
            display: inline-block;
            margin-right: 20px;
            cursor: pointer;
            // margin-bottom: 5px;
            // padding-top: 15px;
            margin-left: 10px;
          }
          .select1_Item_actived{
            font-weight: bold;
            color: #DB0303;
            position: relative;
          }
          .select1_Item_actived::after{
            content: "";
            height: 2px;
            background: #F7DCD7;
            border-radius: 5px;
            position: absolute;
            bottom: 0px;
            width: calc(100% - 30px);
            left: 15px;
          }
        }
        .select2{
          background: #FFFDF3;
          .select2_Item{
            margin:10px 0 10px 20px;
            display: inline-block;
            .radio{
              width: 14px;
              height: 14px;
              background: #FFFFFF;
              border: 1px solid #DEDEDE;
              border-radius: 2px;
              display: inline-block;
              margin-top: 4px;
            }
            .checkbox{
              display: inline-block;
              background: url("../../assets/site/thoughtParty/checkbox.png") no-repeat;
              width: 14px;
              height: 14px;
              margin-top: 4px;
            }
            label{
              font-size: 14px;
              color: #9D9E9E;
              margin-left: 10px;
            }
          }
          .select2_Item_active{
            label{
              color: #E79631;
            }
          }
        }
        .newsList{
          margin-top: 10px;
          border-bottom: 3px solid #FEA18F;;
          li{
            border-bottom: 1px solid rgba(234, 232, 232, 0.3);
            height: 45px;
            line-height: 45px;
            display: flex;
            position: relative;
            align-items: center;
            cursor: pointer;
            .dot{
              width: 5px;
              height: 5px;
              background: #F76B50;
              border-radius: 50%;
              display: inline-block;
              margin-right: 16px;
              margin-left: 16px;
            }
            .title{
              font-size: 16px;
              color: #3F3F3F;
              display: inline-block;
              width: 75%;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
            .date{
              color: #A09189;
              font-size: 14px;
              float: right;
              position: absolute;
              right: 0px;
            }
          }
          li:last-child{
            border: none;
          }
        }
        .noData{
          text-align: center;
          margin-bottom: 60px;
          .noDataImg{
            margin-top: 100px;
          }
          .tip{
            margin-top: 20px;
            font-size: 16px;
            color: #555555;
            .login{
              color: #DB0303;
              margin:0 15px;
              display: inline-block;
              position: relative;
            }
            .login::after{
              content: "";
              width: 35px;
              height: 1px;
              background: #D21010;
              border-radius: 1px;
              bottom: 0px;
              left: 0px;
              display: block;
            }
          }
        }
        .page{
          margin-top: 30px;
          text-align: center;
          /deep/.ant-pagination-item-active{
            border: none;
            background: #DE0A0A;
            border-radius: 3px;
            color: #fff;
          }
          /deep/.ant-pagination-item-active a{
            color: #fff;
            height: 32px;
            line-height: 32px;
          }
          /deep/.ant-pagination-item a{
            height: 32px;
            line-height: 32px;
          }
          /deep/.ant-pagination-item{
            border: none;
          }
          /deep/.ant-pagination-item:hover a{
            color: #000000a6;
          }
          /deep/.ant-pagination-item-active:hover a{
            color: #fff;
          }
        }
      }
      
    }
  }
  .contact-us-box {
    height: 182px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(81deg, #C60506 0%, #B1002E 47%, #C60506 100%);
    text-align: center;
    position: relative;
    overflow: hidden;
    .pos-img-left {
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100%;
      width: auto;
      display: inline-block;
    }
    .pos-img-right {
      position: absolute;
      z-index: 1;
      right: 0;
      height: 100%;
      width: auto;
      display: inline-block;
    }
    .c {
      padding: 40px 0 0;
      position: relative;
      z-index: 10;
      top: 0;
    }
    .contact-text {
      font-size: 22px;
      color: #FFFFFF;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .contact-btn {
      width: 140px;
      height: 40px;
      line-height: 38px;
      border: 1px solid #FFFFFF;
      border-radius: 15px;
      text-align: center;
      color: #FFFFFF;
      font-size: 18px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
.box1{
  .box1_right{
    .box1List{
      margin-top: 30px;
      border: 1px solid #E3E3E3;
      .header{
        border-bottom: 1px solid #E3E3E3;
        background: #F5F5F5;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        div{
          border-right: 1px solid #E3E3E3;
          text-align: center;
          float: left;
          font-size: 14px;
          font-weight: bold;
          color: #2E2E2E;
        }
        .header1{
          width: 10%;
        }
        .header2{
          width: 25%;
        }
        .header3{
          width: 65%;
          border-right: none;
        }
      }
      .tr{
        border-bottom: 1px solid #E3E3E3;
        overflow: hidden;
        display: flex;
        div{
          border-right: 1px solid #E3E3E3;
          text-align: center;
          float: left;
          color: #2E2E2E;
          font-size: 14px;
        }
        .td1{
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .td2{
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .td3{
          width: 65%;
          border-right: none;
          .td3List{
            text-align: left;
            padding: 10px 0px;
            li{
              height: 32px;
              line-height: 32px;
              display: flex;
              align-items: center;
              padding-left: 20px;
              cursor: pointer;
              img{
                margin-right: 5px;
              }
            }
            li.seeMore{
              font-size: 12px;
              color: #DE0A0A;
              cursor: pointer;
            }
          }
        }
      }
      .tr:nth-child(2n+1){
        background: #FFF7F7;
      }
      .tr:last-child{
        border-bottom: none;
      }

    }

  }

}
li.ant-menu-item-active{
    background: #FAEFED !important;
}
</style>