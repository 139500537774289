var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isClientHeight ? 'mainbody static-page-box' : 'mainbody'},[_c('LoginHeader'),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"container-box"},[_c('div',{staticClass:"middle-outer-box"},[_c('div',{staticClass:"login-inner-box show"},[_c('div',{staticClass:"top-label-box"},[_c('div',{staticClass:"reset-left-box register"},[_vm._v("免费注册")]),_c('div',{staticClass:"login-right-box"},[_c('router-link',{attrs:{"to":("/login?" + (_vm.window.location.search))}},[_vm._v("返回登录")])],1)]),_c('div',{staticClass:"right-form-box"},[_c('div',{staticClass:"form-content"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.mobileForm,"destroyOnClose":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"label-box"},[_vm._v("姓名：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'name',
                        {
                          rules: [{ required: true, message: '请输入姓名' }],
                        } ]),expression:"[\n                        'name',\n                        {\n                          rules: [{ required: true, message: '请输入姓名' }],\n                        },\n                      ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入姓名"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"label-box"},[_vm._v("手机号：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'mobile',
                        { rules: [{ validator: _vm.validateCheckPhone }] } ]),expression:"[\n                        'mobile',\n                        { rules: [{ validator: validateCheckPhone }] },\n                      ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入手机号"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"label-box"},[_vm._v("密码：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        { rules: [{ validator: _vm.validateCheckPassword }] } ]),expression:"[\n                        'password',\n                        { rules: [{ validator: validateCheckPassword }] },\n                      ]"}],staticClass:"input-txt",attrs:{"type":"password","placeholder":"请输入密码"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"form-item flex-codeImg-box"},[_c('div',{staticClass:"left-input-box"},[_c('div',{staticClass:"label-box"},[_vm._v("验证码：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'captcha',
                          {
                            rules: [
                              { required: true, message: '请输入验证码' } ],
                          } ]),expression:"[\n                          'captcha',\n                          {\n                            rules: [\n                              { required: true, message: '请输入验证码' },\n                            ],\n                          },\n                        ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入验证码"}})],1),_c('div',{staticClass:"code-img",on:{"click":function($event){return _vm.methodImageCaptcha()}}},[_c('img',{attrs:{"src":_vm.imageCode.img}})])])]),_c('a-form-item',[_c('div',{staticClass:"form-item flex-codeImg-box"},[_c('div',{staticClass:"getCode-box"},[_c('div',{staticClass:"label-box"},[_vm._v("短信验证码：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'code',
                          {
                            rules: [
                              { required: true, message: '请输入短信验证码' } ],
                          } ]),expression:"[\n                          'code',\n                          {\n                            rules: [\n                              { required: true, message: '请输入短信验证码' },\n                            ],\n                          },\n                        ]"}],staticClass:"input-txt",attrs:{"placeholder":"请输入验证码"}})],1),_c('div',{class:_vm.loginSmsClass,on:{"click":function($event){return _vm.clickloginSms()}}},[_vm._v(" "+_vm._s(_vm.loginSmsText)+" ")])])]),_c('div',{staticClass:"agreement-box",on:{"click":function($event){$event.stopPropagation();_vm.isAgree = !_vm.isAgree}}},[_c('div',{class:_vm.isAgree ? 'checkbox-icon active' : 'checkbox-icon'}),_c('div',{staticClass:"agreement-text"},[_vm._v(" 我已认真阅读并同意"),_c('router-link',{attrs:{"to":"/policy","target":"_blank"}},[_vm._v("服务协议和隐私政策")])],1)]),_c('a-button',{staticClass:"jeol-login-btn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("注册")])],1)],1)])])])])]),_c('LoginFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }